<template>
  <div id="user_edit">
    <h1 class="ui dividing header">Edit User</h1>

    <div class="ui top attached tabular menu tabs">
      <div
        class="item"
        :class="{ active: tabs.isUserSettings }"
        @click="switchTab('userSettings')"
      >
        User Settings
      </div>
      <div
        class="item"
        :class="{ active: tabs.isDashboardSettings }"
        @click="switchTab('dashboardSettings')"
      >
        Dashboard Settings
      </div>
      <div
        class="item"
        :class="{ active: tabs.isShipmentSettings }"
        @click="switchTab('shipmentSettings')"
      >
        Shipment Settings
      </div>
      <div
        class="item"
        :class="{ active: tabs.isEmailSettings }"
        @click="switchTab('emailSettings')"
      >
        Email Settings
      </div>
      <div
        class="item"
        :class="{ active: tabs.isBillingSettings }"
        @click="switchTab('billingSettings')"
      >
        Billing Settings
      </div>
      <div
        class="item"
        :class="{ active: tabs.isApiSettings }"
        @click="switchTab('apiSettings')"
      >
        Api Settings
      </div>
    </div>

    <div
      class="ui bottom attached tab segment"
      :class="{ active: tabs.isUserSettings }"
      id="user_settings"
    >
      <div class="ui two column grid">
        <div class="seven wide column">
          <div class="ui user form" :class="{ loading: loading }">
            <div class="inline field">
              <label>Name:</label>
              <input
                type="text"
                placeholder="Name"
                v-model="userSettings.name"
              />
            </div>
            <div class="inline field">
              <label>Email:</label>
              <input
                type="text"
                placeholder="Email"
                v-model="userSettings.email"
              />
            </div>
            <div class="inline field">
              <label>Password:</label>
              <input
                type="text"
                placeholder="***********"
                v-model="userSettings.password"
              />
            </div>
            <div class="inline field">
              <label>Customer #'s:</label>
              <select
                multiple=""
                class="ui fluid search dropdown customer_numbers"
                v-model="userSettings.customerNumbers"
              >
                <option :value="null"></option>
                <option
                  v-for="num in customerNumberOptions"
                  :key="num.id"
                  :value="num.id"
                >
                  {{ num.number }}
                </option>
              </select>
            </div>
            <div class="inline field">
              <label>Companies:</label>
              <select
                multiple=""
                class="ui fluid search dropdown companies"
                v-model="userSettings.companies"
              >
                <option :value="null"></option>
                <option
                  v-for="name in companyOptions"
                  :key="name.id"
                  :value="name.id"
                >
                  {{ name.name }}
                </option>
              </select>
            </div>
            <div class="inline field">
              <label>Image Types:</label>
              <select
                multiple=""
                class="ui fluid search dropdown"
                v-model="userSettings.imageTypes"
              >
                <option :value="null" disabled></option>
                <option
                  v-for="type in imageTypeOptions"
                  :key="type.ImgDescription"
                  :value="type.ImgDescription"
                >
                  {{ type.ImgDescription }}
                </option>
              </select>
            </div>
            <div class="inline field">
              <label>Logo:</label>
              <input type="file" name="logo" @change="processLogo($event)" />
            </div>
            <div class="inline field">
              <label>Admin:</label>
              <div class="ui checkbox">
                <input
                  type="checkbox"
                  name="admin"
                  v-model="userSettings.admin"
                />
                <label for=""></label>
              </div>
            </div>
            <div class="inline field">
              <label>Shipment Entry:</label>
              <div class="ui checkbox">
                <input
                  type="checkbox"
                  name="shipment_entry"
                  v-model="userSettings.shipmentEntry"
                />
                <label for=""></label>
              </div>
            </div>
            <div class="inline field">
              <label>Tracking Report:</label>
              <div class="ui checkbox">
                <input
                  type="checkbox"
                  name="tracking_report"
                  v-model="userSettings.trackingReport"
                />
                <label for=""></label>
              </div>
            </div>
            <div class="inline field">
              <label>Total Charges:</label>
              <div class="ui checkbox">
                <input
                  type="checkbox"
                  name="total_revenue"
                  v-model="userSettings.totalRevenue"
                />
                <label for=""></label>
              </div>
            </div>
          </div>
        </div>
        <div class="nine wide column">
          <br />
          <div v-show="!loading">
            <p>Last Login: {{ user.last_login }}</p>

            <p>
              Created By:
              {{ createdByUser.name !== undefined ? createdByUser.name : "" }}
            </p>

            <p>
              Created At: {{ moment(user.created_at).format("LT") }} on
              {{ moment(user.created_at).format("MM-DD-YYYY") }}
            </p>

            <button
              class="ui left floated button prim"
              @click="disableUser()"
              v-if="!user.deleted_at"
            >
              <i class="fas fa-ban"></i>Disable
            </button>
            <button
              class="ui left floated button tert"
              @click="enableUser()"
              v-else
            >
              <i class="fas fa-check"></i>Enable
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Dashboard Settings Tab Content -->
    <div
      class="ui bottom attached tab segment"
      :class="{ active: tabs.isDashboardSettings }"
      id="dashboard_settings"
    >
      <div class="ui two column grid">
        <div class="seven wide column">
          <div class="ui user form" :class="{ loading: loading }">
            <div class="inline field d-flex">
              <label class="d-flex flex-column">
                <span>Visibility:</span>
                <div>
                  <button
                    class="btn btn-default mt-2"
                    @click="toggleAllDashSettings"
                  >
                    <i
                      class="fas"
                      :class="
                        hasDashSettings
                          ? hasAllDashSettings
                            ? 'fa-square'
                            : 'fa-minus-square'
                          : 'fa-check-square'
                      "
                      aria-hidden="true"
                    ></i>
                    Toggle All
                  </button>
                </div>
              </label>
              <div>
                <div
                  class="ui checkbox d-block mb-3"
                  v-for="({ value, label }, index) in dashboardSettingOptions"
                  :key="index"
                >
                  <input
                    type="checkbox"
                    :value="true"
                    :name="`dash_settings_${index}`"
                    v-model="dashboardSettings.dashSettings[value]"
                  />
                  <label
                    :for="`dash_settings_${index}`"
                    v-text="label"
                    class="w-100"
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Shipment Settings Tab Content -->
    <div
      class="ui bottom attached tab segment"
      :class="{ active: tabs.isShipmentSettings }"
      id="shipment_settings"
    >
      <div class="ui shipping form" :class="{ loading: loading }">
        <div class="ui grid dropdowns">
          <div class="two wide column">Services Allowed:</div>
          <div class="three wide column">
            <select
              multiple=""
              class="ui fluid search dropdown"
              v-model="shippingSettings.servicesAllowed"
            >
              <option :value="null" disabled></option>
              <option
                v-for="service in serviceTypes"
                :key="service.Code"
                :value="service.Code"
              >
                {{ service.Code }} {{ service.Description }}
              </option>
            </select>
          </div>
          <div class="two wide column">
            Default service:

            <br /><br />
            Lock:
          </div>
          <div class="three wide column">
            <select
              class="ui fluid dropdown"
              v-model="shippingSettings.servicesDefault"
            >
              <option :value="null" disabled></option>
              <option
                v-for="service in serviceTypes"
                :key="service.ID"
                :value="service.Code"
              >
                {{ service.Code }} {{ service.Description }}
              </option>
            </select>
            <br />

            <div class="ui checkbox">
              <input
                type="checkbox"
                name="services_lock"
                v-model="shippingSettings.servicesLock"
              />
              <label for=""></label>
            </div>
          </div>
          <div class="two wide column">
            Customer #:

            <br /><br />
            Lock:
          </div>
          <div class="three wide column">
            <select
              class="ui fluid dropdown"
              v-model="shippingSettings.customerNumberDefault"
            >
              <option :value="null" disabled></option>
              <option
                v-for="num in userCustomerNumbers"
                :key="num.id"
                :value="num.id"
              >
                {{ num.number }}
              </option>
            </select>
            <br />

            <div class="ui checkbox">
              <input
                type="checkbox"
                name="customer_number_lock"
                v-model="shippingSettings.customerNumberLock"
              />
              <label for=""></label>
            </div>
          </div>
        </div>

        <div class="ui grid shipment">
          <div class="eight wide column left shipper">
            <h2 class="ui header">Default Shipper Information</h2>
            <div class="ui grid">
              <div class="four wide column">
                <label for="">Number:</label>
              </div>
              <div class="twelve wide column">
                <div class="ui icon input">
                  <input
                    id="shipper_number"
                    name="shipper_number_default"
                    type="text"
                    placeholder=""
                    v-model="shippingSettings.shipperNumberDefault"
                  />
                  <i class="search icon" @click="searchShipper"></i>
                </div>
              </div>

              <div class="four wide column">
                <label for="">Name:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="shipper_name_default"
                  v-model="shippingSettings.shipperNameDefault"
                />
              </div>

              <div class="four wide column">
                <label for="">Address:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="shipper_address_default"
                  v-model="shippingSettings.shipperAddressDefault"
                />
              </div>
              <div class="four wide column"></div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="shipper_address2_default"
                  v-model="shippingSettings.shipperAddress2Default"
                />
              </div>

              <div class="four wide column">
                <label for="">City:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="shipper_city_default"
                  v-model="shippingSettings.shipperCityDefault"
                />
              </div>

              <div class="four wide column">
                <label for="">State:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="shipper_state_default"
                  v-model="shippingSettings.shipperStateDefault"
                />
              </div>

              <div class="four wide column">
                <label for="">Zip code:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="shipper_zip_default"
                  v-model="shippingSettings.shipperZipDefault"
                />
              </div>

              <div class="four wide column">
                <label for="">Country:</label>
              </div>
              <div class="twelve wide column">
                <select 
                name="shipper_country_default" 
                v-model="shippingSettings.shipperCountryDefault" 
                >
                <option value="UNITED STATES">UNITED STATES</option>
                <option value="CANADA">CANADA</option>
              </select>
              </div>

              <div class="four wide column">
                <label for="">Contact:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="shipper_contact_default"
                  v-model="shippingSettings.shipperContactDefault"
                />
              </div>

              <div class="four wide column">
                <label for="">Phone:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="shipper_phone_default"
                  v-model="shippingSettings.shipperPhoneDefault"
                />
              </div>

              <div class="four wide column">
                <label for="">Lock:</label>
              </div>
              <div class="twelve wide column">
                <div class="ui checkbox">
                  <input
                    type="checkbox"
                    name="shipper_lock"
                    v-model="shippingSettings.shipperLock"
                  />
                  <label for=""></label>
                </div>
              </div>
            </div>

            <h2 class="ui header">Declared Value or Insurance</h2>
            <div class="ui grid">
              <div class="four wide column">
                <label for="">DV or INS:</label>
              </div>
              <div class="twelve wide column">
                <select
                  class="ui fluid dv_ins dropdown"
                  name="dv_ins"
                  v-model="shippingSettings.dvInsDefault"
                >
                  <option :value="null" disabled selected></option>
                  <option key="DV" value="DV">DV</option>
                  <option key="INS" value="INS">INS</option>
                </select>
              </div>

              <div class="four wide column">
                <label for="">Amount:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="dv_ins_amount"
                  v-model="shippingSettings.dvInsAmountDefault"
                />
              </div>

              <div class="four wide column">
                <label for="">Lock:</label>
              </div>
              <div class="twelve wide column">
                <div class="ui checkbox">
                  <input
                    type="checkbox"
                    name="dv_ins_lock"
                    v-model="shippingSettings.dvInsLock"
                  />
                  <label for=""></label>
                </div>
              </div>
            </div>

            <h2 class="ui header">Pickup</h2>
            <div class="ui grid pickup">
              <div class="six wide column">
                <label for="">Allowed Pickup Services:</label>
              </div>
              <div class="ten wide column">
                <select
                  multiple=""
                  class="ui fluid search dropdown"
                  name="allowed_pickup_services"
                  v-model="shippingSettings.pickupServicesAllowed"
                >
                  <option :value="null" disabled selected></option>
                  <option
                    v-for="type in prevenueTypes"
                    :key="type.ID"
                    :value="type.Name"
                  >
                    {{ type.Name }}
                  </option>
                </select>
              </div>

              <div class="six wide column">
                <label for="">Default Pickup Services:</label>
              </div>
              <div class="ten wide column">
                <select
                  multiple=""
                  class="ui fluid search dropdown"
                  name="default_pickup_services"
                  v-model="shippingSettings.pickupServicesDefault"
                >
                  <option :value="null" disabled selected></option>
                  <option
                    v-for="type in prevenueTypes"
                    :key="type.ID"
                    :value="type.Name"
                  >
                    {{ type.Name }}
                  </option>
                </select>
              </div>

              <div class="six wide column">
                <label for="">Lock:</label>
              </div>
              <div class="ten wide column">
                <div class="ui checkbox">
                  <input
                    type="checkbox"
                    name="pick_up_services_lock"
                    v-model="shippingSettings.pickupServicesLock"
                  />
                  <label for=""></label>
                </div>
              </div>

              <div class="six wide column">
                <label for="">Pickup Instructions:</label>
              </div>
              <div class="ten wide column">
                <textarea
                  name="pickup_instructions"
                  id="pickup_instructions"
                  cols="30"
                  rows="8"
                  placeholder="Add directions here..."
                  v-model="shippingSettings.pickupInsDefault"
                ></textarea>
              </div>
            </div>

            <h2 class="ui header">Default Bill To Information</h2>
            <div class="ui grid bill_to">
              <div class="four wide column">
                <label for="">Number:</label>
              </div>
              <div class="twelve wide column">
                <div class="ui icon input">
                  <input
                    id="bill_to_number"
                    type="text"
                    name="bill_to_number_default"
                    placeholder=""
                    v-model="shippingSettings.billToNumberDefault"
                  />
                  <i class="search icon" @click="searchBillTos"></i>
                </div>
              </div>

              <div class="four wide column">
                <label for="">Name:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="bill_to_name_default"
                  v-model="shippingSettings.billToNameDefault"
                />
              </div>

              <div class="four wide column">
                <label for="">Address:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="bill_to_address_default"
                  v-model="shippingSettings.billToAddressDefault"
                />
              </div>

              <div class="four wide column"></div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="bill_to_address_default"
                  v-model="shippingSettings.billToAddress2Default"
                />
              </div>

              <div class="four wide column">
                <label for="">City:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="bill_to_city_default"
                  v-model="shippingSettings.billToCityDefault"
                />
              </div>

              <div class="four wide column">
                <label for="">State:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="bill_to_state_default"
                  v-model="shippingSettings.billToStateDefault"
                />
              </div>

              <div class="four wide column">
                <label for="">Zip code:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="bil_tp_zip_default"
                  v-model="shippingSettings.billToZipDefault"
                />
              </div>

              <div class="four wide column">
                <label for="">Country:</label>
              </div>
              <div class="twelve wide column">
                <select 
                  name="bill_to_country_default" 
                  v-model="shippingSettings.billToCountryDefault" 
                  >
                  <option value="UNITED STATES">UNITED STATES</option>
                  <option value="CANADA">CANADA</option>
                </select>
              </div>

              <div class="four wide column">
                <label for="">Lock:</label>
              </div>
              <div class="twelve wide column">
                <div class="ui checkbox">
                  <input
                    type="checkbox"
                    name="bill_to_lock"
                    v-model="shippingSettings.billToLock"
                  />
                  <label for=""></label>
                </div>
              </div>
            </div>
          </div>

          <div class="eight wide column right consignee">
            <h2 class="ui header">Default Consignee Information</h2>
            <div class="ui grid con_info">
              <div class="four wide column">
                <label for="">Number:</label>
              </div>
              <div class="twelve wide column">
                <div class="ui icon input">
                  <input
                    id="consignee_number"
                    name="consignee_number_default"
                    type="text"
                    placeholder=""
                    v-model="shippingSettings.consigneeNumberDefault"
                  />
                  <i class="search icon" @click="searchConsignee"></i>
                </div>
              </div>

              <div class="four wide column">
                <label for="">Name:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="consignee_name_default"
                  v-model="shippingSettings.consigneeNameDefault"
                />
              </div>

              <div class="four wide column">
                <label for="">Address:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="consignee_address_default"
                  v-model="shippingSettings.consigneeAddressDefault"
                />
              </div>
              <div class="four wide column"></div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="consignee_address2_default"
                  v-model="shippingSettings.consigneeAddress2Default"
                />
              </div>

              <div class="four wide column">
                <label for="">City:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="consignee_city_default"
                  v-model="shippingSettings.consigneeCityDefault"
                />
              </div>

              <div class="four wide column">
                <label for="">State:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="consignee_state_default"
                  v-model="shippingSettings.consigneeStateDefault"
                />
              </div>

              <div class="four wide column">
                <label for="">Zip code:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="consignee_zip_default"
                  v-model="shippingSettings.consigneeZipDefault"
                />
              </div>

              <div class="four wide column">
                <label for="">Country:</label>
              </div>
              <div class="twelve wide column">
                <select 
                  name="consignee_country_default" 
                  v-model="shippingSettings.consigneeCountryDefault" 
                  >
                  <option value="UNITED STATES">UNITED STATES</option>
                  <option value="CANADA">CANADA</option>
                </select>
              </div>

              <div class="four wide column">
                <label for="">Contact:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="consignee_contact_default"
                  v-model="shippingSettings.consigneeContactDefault"
                />
              </div>

              <div class="four wide column">
                <label for="">Phone:</label>
              </div>
              <div class="twelve wide column">
                <input
                  type="text"
                  name="consignee_phone_default"
                  v-model="shippingSettings.consigneePhoneDefault"
                />
              </div>

              <div class="four wide column">
                <label for="">Lock:</label>
              </div>
              <div class="twelve wide column">
                <div class="ui checkbox">
                  <input
                    type="checkbox"
                    name="consignee_lock"
                    v-model="shippingSettings.consigneeLock"
                  />
                  <label for=""></label>
                </div>
              </div>
            </div>

            <h2 class="ui header">Appointment Information</h2>
            <div class="ui grid">
              <div class="six wide column">
                <label for="">Require appointment:</label>
              </div>
              <div class="ten wide column">
                <div class="ui checkbox">
                  <input
                    type="checkbox"
                    name="appt_req_lock"
                    v-model="shippingSettings.apptReqLock"
                  />
                  <label for=""></label>
                </div>
              </div>
              <div class="six wide column">
                <label for="">Lock:</label>
              </div>
              <div class="ten wide column">
                <div class="ui checkbox">
                  <input
                    type="checkbox"
                    name="appt_lock"
                    v-model="shippingSettings.apptLock"
                  />
                  <label for=""></label>
                </div>
              </div>
            </div>

            <h2 class="ui header">Delivery</h2>
            <div class="ui grid">
              <div class="six wide column">
                <label for="">Allowed Delivery Services:</label>
              </div>
              <div class="ten wide column">
                <select
                  multiple=""
                  class="ui fluid search dropdown"
                  name="allowed_delivery_services"
                  v-model="shippingSettings.deliveryServicesAllowed"
                >
                  <option :value="null" disabled selected></option>
                  <option
                    v-for="type in drevenueTypes"
                    :key="type.ID"
                    :value="type.Name"
                  >
                    {{ type.Name }}
                  </option>
                </select>
              </div>

              <div class="six wide column">
                <label for="">Default Delivery Services:</label>
              </div>
              <div class="ten wide column">
                <select
                  multiple=""
                  class="ui fluid search dropdown"
                  name="default_delivery_services"
                  v-model="shippingSettings.deliveryServicesDefault"
                >
                  <option :value="null" disabled selected></option>
                  <option
                    v-for="type in drevenueTypes"
                    :key="type.ID"
                    :value="type.Name"
                  >
                    {{ type.Name }}
                  </option>
                </select>
              </div>

              <div class="six wide column">
                <label for="">Lock:</label>
              </div>
              <div class="ten wide column">
                <div class="ui checkbox">
                  <input
                    type="checkbox"
                    name="consignee_lock"
                    v-model="shippingSettings.deliveryServicesLock"
                  />
                  <label for=""></label>
                </div>
              </div>

              <div class="six wide column">
                <label for="">Delivery Instructions:</label>
              </div>
              <div class="ten wide column">
                <textarea
                  name="delivery_instructions"
                  id="delivery_instructions"
                  cols="30"
                  rows="8"
                  placeholder="Add directions here..."
                  v-model="shippingSettings.deliveryInsDefault"
                ></textarea>
              </div>
            </div>

            <h2 class="ui header">Paycodes</h2>
            <div class="ui grid">
              <div class="six wide column">
                <label for="">Allowed Paycodes:</label>
              </div>
              <div class="ten wide column">
                <select
                  multiple=""
                  class="ui fluid search dropdown"
                  name="allowed_pay_codes"
                  v-model="shippingSettings.paycodesAllowed"
                >
                  <option :value="null" disabled selected></option>
                  <option
                    v-for="code in paycodes"
                    :key="code.ID"
                    :value="code.Code"
                  >
                    [{{ code.Code }}] {{ code.Description }}
                  </option>
                </select>
              </div>

              <div class="six wide column">
                <label for="">Default Paycode:</label>
              </div>
              <div class="ten wide column">
                <select
                  class="ui fluid dropdown"
                  name="default_pay_code"
                  v-model="shippingSettings.paycodesDefault"
                >
                  <option :value="null" disabled selected></option>
                  <option
                    v-for="code in paycodes"
                    :key="code.ID"
                    :value="code.Code"
                  >
                    [{{ code.Code }}] {{ code.Description }}
                  </option>
                </select>
              </div>

              <!-- <div class="six wide column">
                <label for="">Default Paycode BillTo:</label>
              </div>
              <div class="ten wide column">
                <input type="text">
              </div> -->

              <div class="six wide column">
                <label for="">Lock:</label>
              </div>
              <div class="ten wide column">
                <div class="ui checkbox">
                  <input
                    type="checkbox"
                    name="paycode_lock"
                    v-model="shippingSettings.paycodesLock"
                  />
                  <label for=""></label>
                </div>
              </div>
            </div>
          </div>

          <div class="sixteen wide column details">
            <h2 class="ui header">Details</h2>
            <div class="ui two column grid">
              <div class="column">
                <label for="">Commodities</label>

                <div class="ui grid">
                  <div class="four wide column">
                    <label for="">Allowed:</label>
                  </div>
                  <div class="ten wide column">
                    <select
                      multiple=""
                      class="ui fluid search dropdown"
                      name="allowed_commodities"
                      v-model="shippingSettings.detailsCommoditiesAllowed"
                    >
                      <option :value="null" disabled selected></option>
                      <option
                        v-for="commodity in commodities"
                        :key="commodity.Code"
                        :value="commodity.Code"
                      >
                        {{ commodity.Description }}
                      </option>
                    </select>
                  </div>

                  <div class="four wide column">
                    <label for="">Default:</label>
                  </div>
                  <div class="ten wide column">
                    <select
                      class="ui fluid dropdown"
                      name="default_commodities"
                      v-model="shippingSettings.detailsCommoditiesDefault"
                    >
                      <option :value="null" disabled selected></option>
                      <option
                        v-for="commodity in commodities"
                        :key="commodity.Code"
                        :value="commodity.Code"
                      >
                        {{ commodity.Description }}
                      </option>
                    </select>
                  </div>

                  <div class="four wide column">
                    <label for="">Lock:</label>
                  </div>
                  <div class="ten wide column">
                    <div class="ui checkbox">
                      <input
                        type="checkbox"
                        name="commodity_lock"
                        v-model="shippingSettings.detailsCommoditiesLock"
                      />
                      <label for=""></label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                <label for="">Description</label>
                <textarea
                  type="text"
                  placeholder="Default description..."
                  name="default_description"
                  v-model="shippingSettings.detailsDescriptionDefault"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="ui bottom attached tab segment"
      :class="{ active: tabs.isEmailSettings }"
      id="email_settings"
    >
      <div class="ui email form">
        <div class="inline field">
          <label>Email to user:</label>
          <input
            type="checkbox"
            placeholder=""
            v-model="emailSettings.emailToUser"
          />
        </div>
        <div class="inline field">
          <label>Email to station:</label>
          <input
            type="checkbox"
            placeholder=""
            v-model="emailSettings.emailToStation"
          />
        </div>
        <div class="inline field">
          <label>Email to sales rep:</label>
          <input
            type="checkbox"
            placeholder=""
            v-model="emailSettings.emailToSalesRep"
          />
        </div>
      </div>

      <div class="ui numbers" v-for="e in emailData" :key="e.number">
        <h3 for="">{{ e.number }}</h3>
        <div class="ui three column grid">
          <div class="column">
            <label for="">Send to user:</label> {{ e.user }}
          </div>
          <div class="column">
            <label for="">Send to station:</label> {{ e.station }}
          </div>
          <div class="column">
            <label for="">Send to sales rep:</label> {{ e.salesRep }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="ui bottom attached tab segment"
      :class="{ active: tabs.isBillingSettings }"
      id="billing_settings"
    >
      <div class="ui billing form">
        <div class="ui two column billtos grid">
          <div class="column">
            <h2>BillTos Allowed:</h2>
            <div
              class="ui active centered inline small loader"
              v-if="loading"
            ></div>
            <div class="ui grid" v-show="billingSettings.billTos" v-else>
              <div
                class="four wide column"
                v-for="number in billingSettings.billTos"
                :key="number"
              >
                <label for=""
                  >{{ number }}
                  <i class="fas fa-times" @click="removeBillTo(number)"></i
                ></label>
              </div>
            </div>
          </div>

          <div class="column">
            <div class="inline field">
              <label>Add BillTo:</label>
              <input type="input" placeholder="" v-model="billTo" />
            </div>
            <button class="ui button prim" @click="addBillTo()">
              <i class="fas fa-check"></i>Submit
            </button>
          </div>
        </div>

        <h2>Reports Allowed:</h2>
        <div class="inline field">
          <label>Account Summary:</label>
          <input
            type="checkbox"
            placeholder=""
            v-model="billingSettings.accountSummary"
          />
        </div>
        <div class="inline field">
          <label>Payment History:</label>
          <input
            type="checkbox"
            placeholder=""
            v-model="billingSettings.paymentHistory"
          />
        </div>
        <div class="inline field">
          <label>Open Invoices:</label>
          <input
            type="checkbox"
            placeholder=""
            v-model="billingSettings.openInvoices"
          />
        </div>

        <div class="inline field">
          <label for=""><h2>Invoice Reprint Allowed:</h2></label>
          <input
            type="checkbox"
            placeholder=""
            v-model="billingSettings.reprintInvoice"
          />
        </div>
      </div>
    </div>
    <div
      class="ui bottom attached tab segment"
      :class="{ active: tabs.isApiSettings }"
      id="api_settings"
    >
      <div class="ui billing form">
        <h2>Reports Allowed:</h2>
        <div class="inline field">
          <label>API Access:</label>
          <input
            type="checkbox"
            placeholder=""
            v-model="apiSettings.hasApiAccess"
          />
        </div>
        <div class="field"
          v-if="apiSettings.hasApiAccess"  
        >
          <label>Api Token:</label>
          <span
          >
          {{apiSettings.apiToken}}
          </span>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <button class="ui left floated button sec" @click="cancel()">
        <i class="fas fa-times"></i>Cancel
      </button>
      <button
        class="ui right floated button prim"
        :class="{ loading: loading }"
        @click="updateUser()"
      >
        <i class="fas fa-check"></i> Submit
      </button>
        <p v-show="userSaved"> User Saved!</p>

    </div>

    <ShipperSearchModal
      id="shipper_search_modal"
      :shippers="searchedShippers"
      :shipment="shippingSettings"
    />
    <ConsigneeSearchModal
      id="consignee_search_modal"
      :consignees="searchedConsignees"
      :shipment="shippingSettings"
    />
    <BillToSearchModal
      id="bill_to_search_modal"
      :shipment="shippingSettings"
      :billTos="searchedBillTos"
    />
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
import ShipperSearchModal from "@/components/modals/ShipperSearchModal.vue";
import ConsigneeSearchModal from "@/components/modals/ConsigneeSearchModal.vue";
import BillToSearchModal from "@/components/modals/BillToSearchModal.vue";

export default {
  components: {
    ShipperSearchModal,
    ConsigneeSearchModal,
    BillToSearchModal,
  },
  data() {
    return {
      response: [],
      loading: true,
      tabs: {
        isUserSettings: true,
        isDashboardSettings: false,
        isShipmentSettings: false,
        isEmailSettings: false,
        isBillingSettings: false,
        isApiSettings: false,
      },
      userSaved: false,
      user: "",
      billTo: "",
      createdByUser: {},
      emailData: [],
      userCustomerNumbers: [],
      userCompanies: [],
      userSettings: {
        name: "",
        email: "",
        password: "",
        admin: 0,
        customerNumbers: [],
        companies: [],
        imageTypes: [],
        logo: "",
        shipmentEntry: "",
        trackingReport: "",
        totalRevenue: "",
        createdBy: "",
      },
      dashboardSettings: {
        dashType: null,
        dashSettings: {},
      },
      shippingSettings: {
        customerNumberDefault: null,
        customerNumberLock: 0,
        shipperNumberDefault: "",
        shipperNameDefault: "",
        shipperAddressDefault: "",
        shipperAddress2Default: "",
        shipperCityDefault: "",
        shipperStateDefault: "",
        shipperZipDefault: "",
        shipperCountryDefault: "",
        shipperContactDefault: "",
        shipperPhoneDefault: "",
        shipperLock: 0,
        consigneeNumberDefault: "",
        consigneeNameDefault: "",
        consigneeAddressDefault: "",
        consigneeAddress2Default: "",
        consigneeCityDefault: "",
        consigneeStateDefault: "",
        consigneeZipDefault: "",
        consigneeCountryDefault: "",
        consigneeContactDefault: "",
        consigneePhoneDefault: "",
        consigneeLock: 0,
        pickupInsDefault: "",
        deliveryInsDefault: "",
        billToNumberDefault: "",
        billToNameDefault: "",
        billToAddressDefault: "",
        billToAddress2Default: "",
        billToCityDefault: "",
        billToStateDefault: "",
        billToZipDefault: "",
        billToCountryDefault: "",
        billToLock: 0,
        servicesAllowed: [],
        servicesDefault: "",
        servicesLock: 0,
        paycodesAllowed: [],
        paycodesDefault: "",
        paycodesLock: 0,
        pickupServicesAllowed: [],
        pickupServicesDefault: [],
        pickupServicesLock: 0,
        deliveryServicesAllowed: [],
        deliveryServicesDefault: [],
        deliveryServicesLock: 0,
        detailsCommoditiesAllowed: [],
        detailsCommoditiesDefault: "",
        detailsCommoditiesLock: 0,
        detailsDescriptionDefault: "",
        dvInsDefault: "",
        dvInsAmountDefault: "",
        dvInsLock: 0,
        apptReqLock: 0,
        apptLock: 0,
      },
      emailSettings: {
        emailToUser: 0,
        emailToStation: 0,
        emailToSalesRep: 0,
      },
      billingSettings: {
        billTos: [],
        accountSummary: 0,
        paymentHistory: 0,
        openInvoices: 0,
        reprintInvoice: 0,
      },
      apiSettings: {
        hasApiAccess: 0,
        apiToken: "",
      },
      dashboardSettingOptions: [],
      customerNumberOptions: [],
      companyOptions: [],
      dashboardOptions: [],
      imageTypeOptions: [],
      serviceTypes: [],
      commodities: [],
      drevenueTypes: [],
      prevenueTypes: [],
      paycodes: [],
      classes: [],
      searchedShippers: [],
      searchedConsignees: [],
      searchedBillTos: [],
      errors: [],
    };
  },
  created() {},
  mounted() {
    this.$http
      .get("/users/" + this.$route.params.id + "/edit")
      .then((response) => {
        this.response = response.data.created_by_user;

        this.user = response.data.user;
        this.createdByUser = response.data.created_by_user;
        this.userCustomerNumbers = response.data.user_customer_numbers;

        this.customerNumberOptions = response.data.all_customer_numbers;
        this.companyOptions = response.data.all_companies;
        this.imageTypeOptions = response.data.image_types;
        this.dashboardSettingOptions = response.data.dashboard_setting_options;
        this.serviceTypes = response.data.service_types;
        this.commodities = response.data.commodities;
        this.drevenueTypes = response.data.drevenue_types;
        this.prevenueTypes = response.data.prevenue_types;
        this.paycodes = response.data.pay_codes;
        this.classes = response.data.classes;

        this.emailData = Object.keys(response.data.email_data).map((key) => {
          let curr = response.data.email_data[key];

          return {
            number: key,
            user: curr.user,
            station: curr.station,
            salesRep: curr.sales_rep,
          };
        });

        this.userSettings.name = response.data.user.name;
        this.userSettings.email = response.data.user.email;
        this.userSettings.password = response.data.user.password;
        this.userSettings.admin = response.data.user.admin;
        this.userSettings.createdBy = response.data.user.created_by;

        this.apiSettings.hasApiAccess = response.data.user.has_api_access;
        this.apiSettings.apiToken = response.data.user.api_token;

        // dashboard settings
        //this.dashboardSettings.dashSettings = response.data.user.dash_settings;
        this.$set(
          this.dashboardSettings,
          "dashSettings",
          response.data.user.dash_settings
        );

        // user settings
        (this.userSettings.shipmentEntry = response.data.user.shipment_entry),
          (this.userSettings.trackingReport =
            response.data.user.tracking_report);
        this.userSettings.totalRevenue = response.data.user.total_revenue;
        this.userSettings.customerNumbers = response.data.customer_numbers;
        this.userSettings.companies = response.data.companies;
        this.userSettings.imageTypes = response.data.user_image_types;

        this.emailSettings.emailToUser = response.data.user.email_user;
        this.emailSettings.emailToStation = response.data.user.email_station;
        this.emailSettings.emailToSalesRep = response.data.user.email_sales_rep;

        this.billingSettings.billTos = response.data.user_allowed_numbers;
        this.billingSettings.accountSummary =
          response.data.user.account_summary;
        this.billingSettings.paymentHistory =
          response.data.user.payment_history;
        this.billingSettings.openInvoices = response.data.user.open_invoices;
        this.billingSettings.reprintInvoice =
          response.data.user.reprint_invoice;

        let shipment_settings;
        if (Object.keys(response.data.shipment_settings).length > 1) {
          shipment_settings = response.data.shipment_settings;

          this.shippingSettings.servicesAllowed =
            shipment_settings.services_allowed;
          this.shippingSettings.servicesDefault =
            shipment_settings.services_default;
          this.shippingSettings.servicesLock = shipment_settings.services_lock;

          this.shippingSettings.customerNumberDefault =
            shipment_settings.customer_number_default;
          this.shippingSettings.customerNumberLock =
            shipment_settings.customer_number_lock;

          this.shippingSettings.shipperNumberDefault =
            shipment_settings.shipper_number_default;
          this.shippingSettings.shipperNameDefault =
            shipment_settings.shipper_name_default;
          this.shippingSettings.shipperAddressDefault =
            shipment_settings.shipper_address_default;
          this.shippingSettings.shipperAddress2Default =
            shipment_settings.shipper_address2_default;
          this.shippingSettings.shipperCityDefault =
            shipment_settings.shipper_city_default;
          this.shippingSettings.shipperStateDefault =
            shipment_settings.shipper_state_default;
          this.shippingSettings.shipperZipDefault =
            shipment_settings.shipper_zip_default;
          this.shippingSettings.shipperCountryDefault =
            shipment_settings.shipper_country_default;
          this.shippingSettings.shipperContactDefault =
            shipment_settings.shipper_contact_default;
          this.shippingSettings.shipperPhoneDefault =
            shipment_settings.shipper_phone_default;
          this.shippingSettings.shipperLock = shipment_settings.shipper_lock;

          this.shippingSettings.consigneeNumberDefault =
            shipment_settings.consignee_number_default;
          this.shippingSettings.consigneeNameDefault =
            shipment_settings.consignee_name_default;
          this.shippingSettings.consigneeAddressDefault =
            shipment_settings.consignee_address_default;
          this.shippingSettings.consigneeAddress2Default =
            shipment_settings.consignee_address2_default;
          this.shippingSettings.consigneeCityDefault =
            shipment_settings.consignee_city_default;
          this.shippingSettings.consigneeStateDefault =
            shipment_settings.consignee_state_default;
          this.shippingSettings.consigneeZipDefault =
            shipment_settings.consignee_zip_default;
          this.shippingSettings.consigneeCountryDefault =
            shipment_settings.consignee_country_default;
          this.shippingSettings.consigneeContactDefault =
            shipment_settings.consignee_contact_default;
          this.shippingSettings.consigneePhoneDefault =
            shipment_settings.consignee_phone_default;
          this.shippingSettings.consigneeLock =
            shipment_settings.consignee_lock;

          this.shippingSettings.billToNumberDefault =
            shipment_settings.bill_to_number_default;
          this.shippingSettings.billToNameDefault =
            shipment_settings.bill_to_name_default;
          this.shippingSettings.billToAddress2Default =
            shipment_settings.bill_to_address2_default;
          this.shippingSettings.billToAddressDefault =
            shipment_settings.bill_to_address_default;
          this.shippingSettings.billToCityDefault =
            shipment_settings.bill_to_city_default;
          this.shippingSettings.billToStateDefault =
            shipment_settings.bill_to_state_default;
          this.shippingSettings.billToZipDefault =
            shipment_settings.bill_to_zip_default;
          this.shippingSettings.billToCountryDefault =
            shipment_settings.bill_to_country_default;
          this.shippingSettings.billToContactDefault =
            shipment_settings.bill_to_contact_default;
          this.shippingSettings.billToPhoneDefault =
            shipment_settings.bill_to_phone_default;
          this.shippingSettings.billToLock = shipment_settings.bill_to_lock;

          this.shippingSettings.pickupInsDefault =
            shipment_settings.pickup_ins_default;
          this.shippingSettings.deliveryInsDefault =
            shipment_settings.delivery_ins_default;

          this.shippingSettings.paycodesAllowed =
            shipment_settings.pay_codes_allowed;
          this.shippingSettings.paycodesDefault =
            shipment_settings.pay_codes_default;
          this.shippingSettings.paycodesLock = shipment_settings.pay_codes_lock;

          this.shippingSettings.pickupServicesAllowed =
            shipment_settings.pickup_services_allowed;
          this.shippingSettings.pickupServicesDefault =
            shipment_settings.pickup_services_default;
          this.shippingSettings.pickupServicesLock =
            shipment_settings.pickup_services_lock;
          this.shippingSettings.deliveryServicesAllowed =
            shipment_settings.delivery_services_allowed;
          this.shippingSettings.deliveryServicesDefault =
            shipment_settings.delivery_services_default;
          this.shippingSettings.deliveryServicesLock =
            shipment_settings.delivery_services_lock;

          this.shippingSettings.dvInsDefault = shipment_settings.dv_ins_default;
          $(".ui.fluid.dv_ins.dropdown").dropdown(
            "set selected",
            this.shippingSettings.dvInsDefault
          );

          this.shippingSettings.dvInsAmountDefault =
            shipment_settings.dv_ins_amount_default;
          this.shippingSettings.dvInsLock = shipment_settings.dv_ins_lock;

          this.shippingSettings.apptReqLock = shipment_settings.appt_req_lock;
          this.shippingSettings.apptLock = shipment_settings.appt_lock;

          this.shippingSettings.detailsCommoditiesAllowed =
            shipment_settings.details_commodities_allowed;
          this.shippingSettings.detailsCommoditiesDefault =
            shipment_settings.details_commodities_default;
          this.shippingSettings.detailsCommoditiesLock =
            shipment_settings.details_commodities_lock;
          this.shippingSettings.detailsDescriptionDefault =
            shipment_settings.details_description_default;
        }

        this.loading = !this.loading;
      })
      .catch((error) => {
        this.errors = error;
      });

    $(".ui.fluid.dropdown").dropdown();
  },
  methods: {
    switchTab: function (tab) {
      switch (tab) {
        case "userSettings":
          this.tabs.isUserSettings = true;

          this.tabs.isDashboardSettings = false;
          this.tabs.isShipmentSettings = false;
          this.tabs.isEmailSettings = false;
          this.tabs.isBillingSettings = false;
          this.tabs.isApiSettings = false;
          break;
        case "dashboardSettings":
          this.tabs.isDashboardSettings = true;

          this.tabs.isUserSettings = false;
          this.tabs.isShipmentSettings = false;
          this.tabs.isEmailSettings = false;
          this.tabs.isBillingSettings = false;
          this.tabs.isApiSettings = false;
          break;
        case "shipmentSettings":
          this.tabs.isShipmentSettings = true;

          this.tabs.isUserSettings = false;
          this.tabs.isDashboardSettings = false;
          this.tabs.isEmailSettings = false;
          this.tabs.isBillingSettings = false;
          this.tabs.isApiSettings = false;
          break;
        case "emailSettings":
          this.tabs.isEmailSettings = true;

          this.tabs.isUserSettings = false;
          this.tabs.isDashboardSettings = false;
          this.tabs.isShipmentSettings = false;
          this.tabs.isBillingSettings = false;
          this.tabs.isApiSettings = false;
          break;
        case "billingSettings":
          this.tabs.isBillingSettings = true;

          this.tabs.isUserSettings = false;
          this.tabs.isDashboardSettings = false;
          this.tabs.isShipmentSettings = false;
          this.tabs.isEmailSettings = false;
          this.tabs.isApiSettings = false;
          break;
        case "apiSettings":
          this.tabs.isApiSettings = true;

          this.tabs.isUserSettings = false;
          this.tabs.isDashboardSettings = false;
          this.tabs.isShipmentSettings = false;
          this.tabs.isEmailSettings = false;
          this.tabs.isBillingSettings = false;
          break;
      }
    },
    updateUser: function () {
      let params = {
        name: this.userSettings.name,
        email: this.userSettings.email,
        password: this.userSettings.password,
        admin: this.userSettings.admin,
        customer_numbers: this.userSettings.customerNumbers,
        companies: this.userSettings.companies,
        dash_settings: this.dashboardSettings.dashSettings,
        image_types: this.userSettings.imageTypes,
        logo: this.userSettings.logo,
        shipment_entry: this.userSettings.shipmentEntry,
        tracking_report: this.userSettings.trackingReport,
        total_revenue: this.userSettings.totalRevenue,
        email_user: this.emailSettings.emailToUser,
        email_station: this.emailSettings.emailToStation,
        email_sales_rep: this.emailSettings.emailToSalesRep,
        account_summary: this.billingSettings.accountSummary,
        payment_history: this.billingSettings.paymentHistory,
        open_invoices: this.billingSettings.openInvoices,
        reprint_invoice: this.billingSettings.reprintInvoice,
        bill_tos: this.billingSettings.billTos,
        customer_number_default: this.shippingSettings.customerNumberDefault,
        customer_number_lock: this.shippingSettings.customerNumberLock,
        shipper_number_default: this.shippingSettings.shipperNumberDefault,
        shipper_name_default: this.shippingSettings.shipperNameDefault,
        shipper_address_default: this.shippingSettings.shipperAddressDefault,
        shipper_address2_default: this.shippingSettings.shipperAddress2Default,
        shipper_city_default: this.shippingSettings.shipperCityDefault,
        shipper_state_default: this.shippingSettings.shipperStateDefault,
        shipper_zip_default: this.shippingSettings.shipperZipDefault,
        shipper_country_default: this.shippingSettings.shipperCountryDefault,
        shipper_contact_default: this.shippingSettings.shipperContactDefault,
        shipper_phone_default: this.shippingSettings.shipperPhoneDefault,
        shipper_lock: this.shippingSettings.shipperLock,
        consignee_number_default: this.shippingSettings.consigneeNumberDefault,
        consignee_name_default: this.shippingSettings.consigneeNameDefault,
        consignee_address_default: this.shippingSettings
          .consigneeAddressDefault,
        consignee_address2_default: this.shippingSettings
          .consigneeAddress2Default,
        consignee_city_default: this.shippingSettings.consigneeCityDefault,
        consignee_state_default: this.shippingSettings.consigneeStateDefault,
        consignee_zip_default: this.shippingSettings.consigneeZipDefault,
        consignee_country_default: this.shippingSettings
          .consigneeCountryDefault,
        consignee_contact_default: this.shippingSettings
          .consigneeContactDefault,
        consignee_phone_default: this.shippingSettings.consigneePhoneDefault,
        consignee_lock: this.shippingSettings.consigneeLock,
        dv_ins_default: this.shippingSettings.dvInsDefault,
        dv_ins_amount_default: this.shippingSettings.dvInsAmountDefault,
        dv_ins_lock: this.shippingSettings.dvInsLock,
        appt_req_lock: this.shippingSettings.apptReqLock,
        appt_lock: this.shippingSettings.apptLock,
        pickup_ins_default: this.shippingSettings.pickupInsDefault,
        delivery_ins_default: this.shippingSettings.deliveryInsDefault,
        bill_to_number_default: this.shippingSettings.billToNumberDefault,
        bill_to_name_default: this.shippingSettings.billToNameDefault,
        bill_to_address2_default: this.shippingSettings.billToAddress2Default,
        bill_to_address_default: this.shippingSettings.billToAddressDefault,
        bill_to_city_default: this.shippingSettings.billToCityDefault,
        bill_to_state_default: this.shippingSettings.billToStateDefault,
        bill_to_zip_default: this.shippingSettings.billToZipDefault,
        bill_to_country_default: this.shippingSettings.billToCountryDefault,
        bill_to_lock: this.shippingSettings.billToLock,
        services_allowed: this.shippingSettings.servicesAllowed,
        services_default: this.shippingSettings.servicesDefault,
        services_lock: this.shippingSettings.servicesLock,
        pay_codes_allowed: this.shippingSettings.paycodesAllowed,
        pay_codes_default: this.shippingSettings.paycodesDefault,
        pay_codes_lock: this.shippingSettings.paycodesLock,
        pickup_services_allowed: this.shippingSettings.pickupServicesAllowed,
        pickup_services_default: this.shippingSettings.pickupServicesDefault,
        pickup_services_lock: this.shippingSettings.pickupServicesLock,
        delivery_services_allowed: this.shippingSettings
          .deliveryServicesAllowed,
        delivery_services_default: this.shippingSettings
          .deliveryServicesDefault,
        delivery_services_lock: this.shippingSettings.deliveryServicesLock,
        details_commodities_allowed: this.shippingSettings
          .detailsCommoditiesAllowed,
        details_commodities_default: this.shippingSettings
          .detailsCommoditiesDefault,
        details_commodities_lock: this.shippingSettings.detailsCommoditiesLock,
        details_description_default: this.shippingSettings
          .detailsDescriptionDefault,
        has_api_access: this.apiSettings.hasApiAccess,
        // user_api_token: this.apiSettings.apiToken

      };

      this.$http
        .patch("/users/" + this.$route.params.id, params)
        .then((response) => {
          this.response = response.data;
          //No Need to navigate to where we are
          //this.router.push("/admin/users/" + this.$route.params.id + "/edit");
          this.userSaved = true;
          setTimeout(() => this.userSaved = false, 3000);
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    enableUser: function () {
      let params = {
        user_id: this.$route.params.id,
      };

      this.$http
        .patch("/user/enable", params)
        .then(() => {
          this.router.push("/admin/users");
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    disableUser: function () {
      let params = {
        user_id: this.$route.params.id,
      };

      this.$http
        .delete("/user/disable", { params })
        .then(() => {
          this.router.push("/admin/users");
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    searchShipper: function () {
      this.loading = !this.loading;

      this.$http
        .get("/shipment/shippersearch")
        .then((response) => {
          this.searchedShippers = Object.keys(response.data.shippers).map(
            function (index) {
              let shipper = response.data.shippers[index];

              let address =
                shipper.Address1 +
                " " +
                shipper.Address2 +
                " " +
                shipper.City +
                ", " +
                shipper.State +
                " " +
                shipper.Zip;

              return {
                number: shipper.Number,
                name: shipper.Name,
                address: address,
              };
            }
          );

          this.loading = !this.loading;

          $("#shipper_search_modal").modal("show");

          setTimeout(function () {
            $(".VueTables__limit-field > select").addClass(
              "ui fluid limit dropdown"
            );

            $(".ui.fluid.dropdown").dropdown();

            $(".VueTables__search__input").attr("placeholder", "Search All...");
          }, 100);
        })
        .catch((error) => {
          this.errors = error;
        });
    },

    searchConsignee: function () {
      this.loading = !this.loading;

      this.$http
        .get("/shipment/consigneesearch")
        .then((response) => {
          this.searchedConsignees = Object.keys(response.data.consignees).map(
            function (index) {
              let consignee = response.data.consignees[index];

              let address =
                consignee.Address1 +
                " " +
                consignee.Address2 +
                " " +
                consignee.City +
                ", " +
                consignee.State +
                " " +
                consignee.Zip;

              return {
                number: consignee.Number,
                name: consignee.Name,
                address: address,
              };
            }
          );

          this.loading = !this.loading;

          $("#consignee_search_modal").modal("show");

          setTimeout(function () {
            $(".VueTables__limit-field > select").addClass(
              "ui fluid limit dropdown"
            );

            $(".ui.fluid.dropdown").dropdown();

            $(".VueTables__search__input").attr("placeholder", "Search All...");
          }, 100);
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    searchBillTos: function () {
      this.loading = !this.loading;

      this.$http
        .get("/shipment/billtosearch")
        .then((response) => {
          this.searchedBillTos = Object.keys(response.data.bill_tos).map(
            function (index) {
              let billTo = response.data.bill_tos[index];

              let address =
                billTo.Address1 +
                " " +
                billTo.Address2 +
                " " +
                billTo.City +
                ", " +
                billTo.State +
                " " +
                billTo.Zip;

              return {
                number: billTo.Number,
                name: billTo.Name,
                address: address,
              };
            }
          );

          this.loading = !this.loading;

          $("#bill_to_search_modal").modal("show");

          setTimeout(function () {
            $(".VueTables__limit-field > select").addClass(
              "ui fluid limit dropdown"
            );

            $(".ui.fluid.dropdown").dropdown();

            $(".VueTables__search__input").attr("placeholder", "Search All...");
          }, 100);
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    addBillTo: function () {
      let params = {
        user_id: this.$route.params.id,
        number: this.billTo,
      };

      this.$http
        .patch("/user/billtos", params)
        .then((response) => {
          this.billingSettings.billTos = response.data;

          this.billTo = "";
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    removeBillTo: function (number) {
      let params = {
        user_id: this.$route.params.id,
        number: number,
      };

      this.$http
        .delete("/user/billtos", { params })
        .then((response) => {
          this.billingSettings.billTos = response.data;
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    cancel: function () {
      this.router.push("/admin/users");
    },

    toggleAllDashSettings() {
      let setting =
        this.hasDashSettings && this.hasAllDashSettings ? false : true;

      this.dashboardSettingOptions.forEach(({ value }) => {
        this.dashboardSettings.dashSettings[value] = setting;
      });
    },
  },

  computed: {
    hasDashSettings() {
      let checked = 0;
      Object.values(this.dashboardSettings.dashSettings).forEach(
        (setting, key) => {
          checked += setting ? 1 : 0;
        }
      );

      return Boolean(checked);
    },

    hasAllDashSettings() {
      let checked = 0;
      let options = Object.values(this.dashboardSettingOptions).length;
      Object.values(this.dashboardSettings.dashSettings).forEach(
        (setting, key) => {
          checked += setting ? 1 : 0;
        }
      );

      return Boolean(checked == options);
    },
  },
};
</script>

<style lang="scss">
#user_edit {
  margin: $layoutmargin;

  .ui.user.form {
    margin-top: 20px;
    padding-left: 24px;

    label {
      font-size: 16px;
      font-weight: bold;
      color: $algdarkblue;
      width: 130px;
    }

    input {
      width: 300px;
      border: $darkblueborder;
    }

    select {
      // width: 300px;
      // height: 40px;
      // border: $darkblueborder;
      // border-radius: 4px;
      // font-size: 16px;
      // padding: 0px 0px 0px 10px !important;
    }

    .inline.field.buttons {
      width: 430px;
      margin-top: 54px;
      padding-bottom: 50px;
    }

    .single-select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      background-color: #fff;
      color: $alggrey;
    }

    .ui.fluid.dropdown {
      display: inline-block !important;
      width: 100% !important;
      max-width: 300px !important;
      border: $darkblueborder;
    }
  }

  .ui.shipping.form {
    label {
      color: $algdarkblue;
      font-family: "Lato Bold" sans-serif;
      font-weight: 600;
      padding-left: 15px;
    }

    .ui.grid.dropdowns {
      .two.wide.column {
        text-align: right;
        padding-top: 23px;
      }

      .ui.fluid.dropdown {
        width: 225px !important;
        border-radius: 20px !important;
        border: $darkblueborder;
        margin-left: 10px;
      }
    }

    .shipment {
      .ui.header {
        padding: 14px 0px 14px 16px;
        background-color: #f4f4f4;
        border-radius: 8px;
      }

      .ui.grid {
        .column {
          padding-bottom: 0px;

          input,
          select,
          textarea {
            border: $darkblueborder;
          }

          .ui.icon.input {
            width: 100%;

            .search.icon {
              left: 0;
              color: $algdarkblue;
              cursor: pointer;
              z-index: 1000;
            }
          }

          #shipper_number,
          #consignee_number,
          #bill_to_number {
            padding-left: 30px;
          }
        }
      }

      .left {
        padding-right: 40px;
      }

      .right {
        padding-left: 40px;
      }

      .details {
        padding-bottom: 20px;

        input,
        div.dropdown {
          // margin-left: 15px !important;
        }

        .nmfc2 {
          input {
            margin-top: 28px;
          }
        }

        .add_detail {
          i {
            color: $alglightblue;
            padding-top: 40px;
            padding-left: 20px;
          }
        }
      }

      .totals {
        .column {
          padding-bottom: 1rem !important;
        }

        .ui.header {
          background-color: #0d4b6a20;

          color: $algdarkblue;
        }
      }

      .bill_to {
        padding-bottom: 60px;
      }

      .appt_info {
        padding-left: 20px;
        label {
          color: $alggrey !important;
          font-weight: 400 !important;
          padding-left: 40px !important;
        }
      }

      label {
        color: $algdarkblue;
        font-family: "Lato Bold" sans-serif;
        font-weight: 600;
        padding-left: 15px;
      }

      .fa.fa-plus {
        color: $alglightblue;
        padding-top: 14px;
        padding-right: 20px;
        margin-left: -8px;
      }
    }
  }

  .ui.email.form {
    margin-top: 20px;
    padding-left: 24px;

    label {
      font-size: 16px;
      font-weight: bold;
      color: $algdarkblue;
      width: 175px;
    }

    input {
      width: 300px;
      border: $darkblueborder;
    }

    select {
      // width: 300px;
      // height: 40px;
      // border: $darkblueborder;
      // border-radius: 4px;
      // font-size: 16px;
      // padding: 0px 0px 0px 10px !important;
    }

    .inline.field.buttons {
      width: 430px;
      margin-top: 54px;
      padding-bottom: 50px;
    }

    .single-select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      background-color: #fff;
      color: $alggrey;
    }

    .ui.fluid.dropdown {
      display: inline-block !important;
      width: 100% !important;
      max-width: 300px !important;
      border: $darkblueborder;
    }
  }

  .ui.billing.form {
    margin-top: 20px;
    padding-left: 24px;

    label {
      font-size: 16px;
      font-weight: bold;
      color: $algdarkblue;
      min-width: 175px;
    }

    input {
      width: 200px;
      border: $darkblueborder;
    }

    select {
      // width: 300px;
      // height: 40px;
      // border: $darkblueborder;
      // border-radius: 4px;
      // font-size: 16px;
      // padding: 0px 0px 0px 10px !important;
    }

    .fas.fa-times {
      color: $algred;
      cursor: pointer;
    }

    .inline.field.buttons {
      width: 430px;
      margin-top: 54px;
      padding-bottom: 50px;
    }

    .single-select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      background-color: #fff;
      color: $alggrey;
    }

    .ui.fluid.dropdown {
      display: inline-block !important;
      width: 100% !important;
      max-width: 300px !important;
      border: $darkblueborder;
    }

    .ui.billtos.grid {
      padding-bottom: 50px;
    }
  }

  .ui.numbers {
    padding: 20px 20px 20px 20px;

    label {
      color: $algdarkgrey;
    }
  }

  .dropdown.icon {
    color: $algdarkblue;
  }

  .ui.fluid.dropdown,
  input {
    border: 2px solid $algdarkblue25opac;
  }

  .ui.icon.input > i.icon:not(.link) {
    pointer-events: unset;
  }
}
</style>
