<template>
  <div id="open_invoice_hawbs">
    <h1 class="ui dividing header">Hawbs for Master Invoice: {{invoiceNumber}}</h1>
    
    <!-- {{data}} -->
    <div class="ui active centered inline massive loader" v-if="loading"></div>
    
    <v-client-table class="open_invoice_hawbs_table" :columns="columns" :data="data" :options="options" v-else>
      <router-link class="hawb" :to="'/tracking/details?hawb_number='+row.Hawb" slot="Hawb" slot-scope="{row}">{{row.Hawb}}</router-link>
      <div class="amount" slot="Amount" slot-scope="{row}">{{"$"+parseInt(row.Amount).toLocaleString('en-US', {minimumFractionDigits: 2})}}</div>
    </v-client-table>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
export default {
  components: {
  },
  data () {
    return {
      loading: true,
      data: [],
      invoiceNumber: this.$route.query.invoice_number,
      columns: [
        'Hawb',
        'DateInvoiced',
        'Amount',
      ],
      options: {
        headings: {
          Hawb: 'Hawb #',
          DateInvoiced: 'Invoice Date',
          Amount: 'Amount',
        },
        sortable: ['Hawb', 'DateInvoiced', 'Amount'],
        filterable: ['Hawb', 'DateInvoiced', 'Amount'],
        perPage: 10,
        perPageValues: [10, 25, 50],
        texts: {
            filter: '',
            filterBy: '',
            count: '',
            limit: ''
        },
        pagination: { chunk: 10, dropdown: false },
        sortIcon: {
          base : 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        },
        customSorting: {
          DateInvoiced: function (ascending) {
            return function (a, b) {
              let dateA = new Date(a.DateInvoiced);
              let dateB = new Date(b.DateInvoiced);

              if (ascending)
                  return dateA >= dateB ? 1 : -1;

              return dateA <= dateB ? 1 : -1;
            }
          },
          Amount: function(ascending) {
            return function (a, b) {
              let amountA = parseFloat(a.Amount.substr(1).replace(/,/g, ''));
              let amountB = parseFloat(b.Amount.substr(1).replace(/,/g, ''));

              if (ascending)
                  return amountA >= amountB ? 1 : -1;

              return amountA <= amountB ? 1 : -1;
            }
          }
        }
      }
    }
  },
  created () {
    this.$http.get('/billing/openinvoice/hawbs', {params: {invoice_number: this.invoiceNumber}})
    .then((response) => {
      this.data = response.data.ois;

      this.loading = !this.loading;

      this.initiateDropdowns();
    })
    .catch((error) => {
      this.errors = error;
    });
  },
  mounted () {
  },
  methods: {
    initiateDropdowns: function () {
      setTimeout(() => {
        $('.VueTables__table').attr("id", "open_invoice_hawb_table");

        $('.VueTables__limit-field > select').addClass("ui fluid limit dropdown");

        $('.ui.fluid.dropdown').dropdown();

        $('.VueTables__search__input').attr("placeholder", "Search All...");

      }, 1000);
    }
  },
  computed: {
  }
};
</script>

<style lang="scss">
#open_invoice_hawbs {
  margin: $layoutmargin;

  .VueTables__search {
    float: right !important;
  }

  .VueTables__search__input {
    width: 350px;
    height: 40px;
    border: $darkblueborder;
  }

  .ui.fluid.limit.dropdown {
    width: 60px;
    height: 40px;
    border-radius: 20px !important;
    border: $darkblueborder;
    margin-left: 10px;
  }

  .open_invoice_hawbs_table {
    border: none;
    box-shadow: none;

    thead {
      tr {
        th {
            color: $algdarkblue;
            background-color: $algdarkbluetenopac;
            border: none;
        }

        th:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        th:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
      }
    }

    tbody {
      tr {
        td:first-child {
          border-top-left-radius: 8px !important;
          border-bottom-left-radius: 8px !important;
        }
        td:last-child {
          border-top-right-radius: 8px !important;
          border-bottom-right-radius: 8px !important;
        }

        td {
            border: none;
        }
      }
      tr:nth-child(odd) {
          background-color: #fff;
      }

      tr:nth-child(even) {
          background-color: rgba(0,0,0,.05);
      }
    }

    .hawb {
      color: $algdarkblue !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
