<template>
  <div id="alaska">
      <svg version="1.1" id="Icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
        <g>
            <path class="st0" d="M424.2,354.5c1,0.2,2,0.3,3,0.5c-1.1-5.3,5.7-6.1,9.4-7.4c1.7,1.7,3.3,3.3,5,5c-0.2,2.8-0.7,4.9,0.5,5.9
                c3.6,5.8,11.4,10.5,17.3,18.3c3.8,5,2.2,9.3,5.9,12.9c0.3,0.8,0.7,1.7,1,2.5c3,3.5,3,9.9,7.4,11.4c-0.8,2.5-1,1-1,4.5
                c1.6-0.1,2.2-0.4,3.5,1c-1.6,1.1-0.7,1.7-1,3.5c3.3,0.8,0.9,2.1,4,5.5c1.1-1.2,0.6-0.5,2.5-1c0.6,2.9,7.9,7.4,12.9,7.4
                c0.2,4.4,0.9,2.4,4.5,4c0.3,1.2,0.7,2.3,1,3.5c-4.5,2.8-1.1,9.1,0,13.4c0,0-6.4,8.6-11.4,10.4c0.2-2.2,0-4-0.5-6.4
                c2.1-0.4,2.5-0.2,3.5-2c-5.9,2.3-5.9-4.3-8.9-5.9c0.4,2.7-2.5,1.9-3,6.4c-1-1.2-0.3-0.2-0.5-1.5c-2.4-1.9,0.8-13,3.5-15.4
                c1.3,0.7,2.6,1.3,4,2c-0.5-1.9-0.5-1.1-0.5-3c-2.4,0-2.1-0.5-3,0.5c-2,0.2-3.5,1.7-5.5,2c0.2,2.2,0.8,5.9-0.5,5
                c-0.3,1.2-0.1,0.5-1,2c-1.7-1.1-2-0.9-2.5-3.5c2.1-1,1.3-3.2,3.5-5.9c-1.1-2.8-1.4-3.6-4.5-4.5c0,0.5,0,1,0,1.5
                c1.2,0.1,0.6-0.1,2,0.5c-0.5,1.3-1,2.6-1.5,4c-0.2,0-0.3,0-0.5,0c-1.1-1.4-4.2-3.2-4.5-4.5c0-0.2,0-0.3,0-0.5
                c1.3-1.2,2.6-2.3,4-3.5c1.3-2.6,0.4-3.4,0-5.9c-1.5,0-0.7,0.5-2-0.5c0.2-0.8,0.3-1.7,0.5-2.5c-2.9-0.8-3.4-2.6-5-3.5
                c1-1.5,0.8-1.3,1.5-2.5c-3.2-0.7-6.2-1.8-8.4-2.5c0-0.8,0-1.7,0-2.5c1.2,0.2,2.3,0.3,3.5,0.5c-0.3-1.1-3-4.3-1.5-2.5
                c-1.2-2.3-1.6-4.1-4-5c0.2-0.5,0.3-1,0.5-1.5c2.5,0.3,2.3,0.7,5,1.5c-1.7-3.5-2.9-2.5-3.5-4.5c0-1.7,0.4-1.3,1.5-3
                c-0.2,0-0.3,0-0.5,0c-1.9,1.1-2.1,2-3.5,3.5c0-2.4-0.7-1-1.5-4c2,0.2,1.5,0.5,3-0.5c-0.3-0.5-0.7-1-1-1.5c-0.2,0-0.3,0-0.5,0
                c-1.9,0.9-1,0.2-1.5,1.5c-1.5-0.9-3.1-3.6-2.5-5c-3.7,0.2-6.1,0.9-6.9-2c1.3-0.2,0.3,0.5,1.5-0.5c-3-3.7-6.1-5.9-4-12.4
                c-1,0.5-2,1-3,1.5c0-4.1-1.8-7.1-0.5-9.9c-2.1-0.4-1.4,0.8-2-2c-0.7,0.2-1.3,0.3-2,0.5c0,0.2,0,0.3,0,0.5c1.1,2.8,5.8,22.9,4,24.8
                c-0.8-1.4-2.2-0.3-2-2.5c-0.7-0.5-1.3-1-2-1.5c-1-1.5-1.8-0.3-4.5,0c0-0.3,0-0.7,0-1c2-2-1.3-8-1-10.4c-1-0.4-0.9,0.2-1.5-2
                c-0.5,0.2-1,0.3-1.5,0.5c0.3,1.3,1.1,2.2,0,3c-0.7,0.3-1.3,0.7-2,1c0,1.2,0,2.3,0,3.5c1,0.2,2,0.3,3,0.5c0.1,3.5,0.1,2,1,5
                c-0.4,0.1-1,0-0.5,0.5c-2.9-0.3-2.8,0.1-5.9,0c0.2,0.8,0.3,1.7,0.5,2.5c-11.7-6.8-24.5-26.9-36.7-27.8c-0.1-1.2,0.1-0.6-0.5-2
                c3.2-0.3,2.9-1.7,3.5-4.5c-2.6-1.7-0.4-2.2-3-3.5c-0.2,1.3,0.2,1.3-1,2.5c-1.3-0.2-2.6-0.3-4-0.5c0.5,0.8,1,1.7,1.5,2.5
                c-0.5-0.2-1-0.3-1.5-0.5c-2.7,3.8-12.9-3-12.9-3c-0.7,0-1.3,0-2,0c0.8-1.2,1.7-2.3,2.5-3.5c-0.5-1-1-2-1.5-3c-1.5,1.3-1.4,2.4-1,4
                c-4.5-0.2-7.2-1.1-11.9-3c-5-1.9-13.8,2.3-16.8,3c-0.3-1.6-1.9-2.7-2-4.5c-3.1,0.1-5.2,0.3-6.9-1c-0.1-1.8,0.2-1.1-1-1.5
                c-1.1-1.2-0.3-0.5-2-0.5c0.7-1.5,1.3-3,2-4.5c-2.8-1.5,2-2.2-1-3c-0.8,2.2-3.4,5.5-5.5,6.4c-0.5-3.1-4.6-1.3-6.4-4.5
                c-0.3,0-0.7,0-1,0c0.9-1.2,0.3-0.8,2-1.5c0.4,1.7-0.6,0.6,1,1.5c-0.2-0.3-0.3-0.7-0.5-1c1.4-0.9,0.3-0.1,1-2
                c-3.7,0.7-3.7-0.4-6.4,0.5c-0.4-1.8,0.4-1.7-2-1.5c-1.2,0.2-0.4-0.1-2,0.5c-0.1-1.2,0.1-0.6-0.5-2c2.6-0.2,1.5,1.1,2.5-1.5
                c-2.5,0.3-2.5-1-4-3c1.5-0.7,2.1-2,1-3.5c1.5-0.2,3-0.3,4.5-0.5c-1.5-0.2-3-0.3-4.5-0.5c-3,7-13.8,6.6-14.4,5.9
                c-0.7-0.5-1.3-1-2-1.5c0.8-2.3,1.4-3,2.5-4.5c-0.5,0-1,0-1.5,0c-2,3-4,5.9-5.9,8.9c1.8,0.4,1.1,1,4,1c-0.5,2.8-0.6,2.3-2,4
                c0.7,0.3,1.3,0.7,2,1c0.2-1-0.3-2.5,1.5-2c0.3,1.3,0.7,2.6,1,4c-1,1.3-2,2.6-3,4c1.5,1,0.8,0.1,1.5,2c-0.7,1-1.3,2-2,3
                c-0.8-2.6-0.5-1-2-2c0,1.3,0,2.6,0,4c-4.6,0.2-6.2,0.5-9.4-2c-0.4,1.4,0.2,0.8-1.5,2c-0.4-1.7,0.6-0.7-1-1.5
                c-0.8,3.6-2.7,7.1-5,9.4c-0.4-2.1,0.4-0.5-1.5-1.5c-0.7,1.7-4.4,3.9-4,5.9c-1.7-0.7-1.5-0.9-3-1c-0.2,2.7-0.5,2.1-2.5,3.5
                c-0.3-0.7-3.3,0.3-4.5,1c-0.3,1.5,0.5,0.5-1,1.5c-0.3-2.1-4.6-0.3-5-0.5c-1.9-0.5-0.5-0.4-2.5-0.5c0.3-1.6-0.8-1.2,0.5-2.5
                c0.1-2.9,8.4-3.8,7.4-5.9c1-1.3,2-2.6,3-4c-0.7-0.2-1.3-0.3-2-0.5c-1.1,2.6-3.5,2.8-5,4c-1-0.5-3.9-2-3-2.5
                c-0.4-4.9,2.7-8.1,5.5-11.9c2.4-3.4-0.8-9.9-0.5-11.4c6-1.5,6.5-4.7,12.9-6.4c0.2,0.7,9.6,3.1,13.4,3.5c0-0.5,0-1,0-1.5
                c-4.7-0.2-9.2-2.3-10.4-6.4c2.9-0.6,6.7-5.5,7.9-7.4c-1.7,0.5-2.3,0.2-3,1c-3.3,1.3-2.9,2-3.5,5c-2.3,0-4.6,0-6.9,0
                c-0.2-0.7-0.3-1.3-0.5-2c0,0-13.9,9.9-13.9,9.9c-3.1,5.3-3.7,8.7-6.9,9.9c0.3,1.2,0.7,2.3,1,3.5c-0.9,0.2-3.3,2.4-5,4
                c0.5,1.2,1,2.3,1.5,3.5c-2,0.9-1.4,4.3-5.5,3.5c0.3,3-0.3,3.8-2.5,5.9c-1.2-1.1,0.1-0.3-2-0.5c-0.4-1.7,0.6-0.7-1-1.5
                c-0.1,2.1,0.1,0.8-1.5,1.5c0.3,1.5,0.5,0.6,0,1.5c-9.4,12-7.7,9.6,3.5,14.9c-0.4,1.9,0,1.9-1,3c0.1,2.1-2.8,4.3-3,5
                c-1-0.3-2-0.7-3-1c0.2,5.3-3.4,6.8-4,11.9c-2.8-0.2-6.7,0.9-8.4,2.5c-1.7,0.8-2.3,3.4-4.5,5.9c-1.3-1-0.8-1-3-1.5
                c0.3,5.5-5.3,4.9-9.9,9.4c0,0.2,0,0.3,0,0.5c1.8,0.5,1,0,2,1c-0.7,0.3-1.3,2.6-1.5,4.5c-3.5,1.1-7.1,2.1-7.9,5.9
                c-1.3-0.7-2.6-1.3-4-2c0,1.8,0,3.6,0,5.5c-1.5-0.5-3-1-4.5-1.5c-0.7,1-1.3,2-2,3c1.7-0.1,1.1-0.5,2.5,0.5c-0.5,0.2-1,0.3-1.5,0.5
                c-1.9-0.1-3.9-0.1-5.5,1.5c-1.4,0.9-0.3,0.1-1,2c2.3-0.4,2-0.4,3.5,1c-2,3.2-3.5,5.2-5.9,6.4c-4.8,0.9-8.5,1.7-10.4,7.4
                c-0.3-0.7-0.7-1.3-1-2c1.7-0.4-0.3-0.4,1.5-1.5c-0.2-0.3-0.3-0.7-0.5-1c-1-0.2-2-0.3-3-0.5c-0.5,3.3-6.3,2.8-6.9,7.4
                c-1-0.7-2-1.3-3-2c-0.4,1.6-4.8,4.4-8.9,4.5c0.2-0.3,0.3-0.7,0.5-1c0.3-1.5,0.7-3,1-4.5c-1.2,0.2-2.3,0.3-3.5,0.5
                c0.8,5.6-4.5,9.5-7.9,11.4c-0.7-0.3-1.3-0.7-2-1c1.1-1.9,1.5-2.6-2-3.5c0.7,2.1,0.9,3.6,0.5,5.9c-2.5,0,0,0.5-3,1
                c-0.1-1.8-0.5-3.4-3.5-3.5c0.3-0.5,0.7-1,1-1.5c1.2,0,2.3,0,3.5,0c-0.7-5.3,12.2-16.2,20.8-16.4c0.6,2.6,0.6,3.4,2.5,5
                c0.2,0,0.3,0,0.5,0c0.2-0.8,0.3-1.7,0.5-2.5c2.3-0.1,2,0.7,4.5,1c0-0.7,0-1.3,0-2c-2.1-1-2.1-1.3-2.5-4l6.4-8.4
                c4.1-3.3,8.6-3.4,11.4-7.4c1.4,1,1,1.1,3.5,1.5c-0.2-8.8,6.3-13.7,11.9-16.9c0.2,1.4,0,0,1,1c0.2-0.5,0.3-1,0.5-1.5
                c-0.7-0.5-1.3-1-2-1.5c1.3-5.2,1.9-9.4,4-12.4c-2-1.1-2-1.4-2-4.5c4.1-2.1,5.7-6.4,6.4-10.9c-3.5,1.2-10.9,7.9-13.9,5.9
                c-1.1-0.3-3-2.2-4.5-4c1.8-2.3,1.6-2.4,2.5-5.5c-0.7,0.2-1.3,0.3-2,0.5c-0.5,2.5-2.5,5.6-4.5,8.4c0.8,1.7,1.7,3.3,2.5,5
                c-1.6,0.8-1.2,1-3.5,1.5c-1-5.2-4.9-8.5-6.9-12.4c-2.3,0.5-2.1,1.2-3,3.5c-1.8-1.2-2.5-2.2-5-3c0-1.2,0-2.3,0-3.5
                c-0.7-0.2-1.3-0.3-2-0.5c-4.1,7.2-13.6,7.8-13.9,10.9c-0.5,0.2-1,0.3-1.5,0.5c-0.4-2-1.1,0.3-3-2c0.8-0.5,1.7-1,2.5-1.5
                c-0.1-12.3-2.1-11.2,1.5-18.8c-2-1-7.9-12-5-14.4c-1-0.8-1-0.8-3-1c0.4-4.3,2.6-7.4,5-10.9c-0.7,0-1.3,0-2,0
                c-3.4,3.6-5.2,9-5.9,10.9c0.7,0.5,1.3,1,2,1.5c-0.8,6.2-12.6,11.7-19.8,6.9c0.2-1.3,0.3-2.6,0.5-4c-3.1-0.2-7.2-5.9-11.4-8.4
                c0.2-0.7,0.3-1.3,0.5-2c0.5,0,1,0,1.5,0c-2.2-1.8-2.5-0.6-4.5-1c-0.2-0.5-0.3-1-0.5-1.5c2-0.3,2.9-1.2,4.5-2c-0.2-2-0.3-4-0.5-5.9
                c-1.3-0.4-2.1,0-1.5-2c-0.3,0-0.7,0-1,0c1.1-0.5,2.2-0.7,3-1.5c-2.2-0.3-2-1.8-4-2.5c-0.9,1.8,0,1.7-2,2.5c-1-1.5-0.1,0-1.5-1
                c0.2-1.5,0.3-3,0.5-4.5c-1.8-0.1-2.1-0.2-4-1c0-0.7,0-1.3,0-2c1.4-0.8,0.9,0.2,2-1.5c0-0.5,0-1,0-1.5c-1.7,0.2-3.3,0.3-5,0.5
                c0-1.3,0-2.6,0-4c1.3,0.3,2.6,0.7,4,1c-0.8-1.7-2-3.4-3.5-4c1.8-0.3,3.6-0.7,5.5-1c0.2-0.7,0.3-1.3,0.5-2c-0.8-0.5-2.5-2-1-3
                c0-5.1,6-10.4,11.4-12.9c0-0.7,0-1.3,0-2c-1.6-0.5-1.3,0.4-1.5-2.5c0.5-0.7,1-1.3,1.5-2c-1.5-0.3-0.8,0.5-1.5-1.5
                c0.7-1.3,1.3-2.6,2-4c1.3,0.2,2.6,0.3,4,0.5c0-0.2,0-0.3,0-0.5c-0.8-0.7-1.7-1.3-2.5-2c0.3-0.8,0.7-1.7,1-2.5
                c1.3-0.2,1.9-0.2,2.5-1.5c4.6,0.8,6.4,1.7,8.9,5.5c1.8-0.5,3.8-0.3,4.5-1c4.8-0.9,8.2-7.9,10.9-11.4c0.5,0.3,1,0.7,1.5,1
                c0.2,1.3-0.5,0.3,0.5,1.5c3.3-0.5,6.6-1,9.9-1.5c0.4-1.9,2.3-5,4.5-5.9c-0.7-6.3-2.2-16.2-6.4-17.8l-1.5-1c0.1-3.4,4.8-1,6.9-5
                c3.3-3.2-2.6-8.6-5-7.9c0,2.3-0.6,0.9-2,4c-3.1-0.4-3-1.1-5.5,0c-0.9,3-8,4.9-8.4,10.4c-3.4-1.8-2.4-5.1-6.4-7.9c0,0.3,0,0.7,0,1
                c-2.2,0.1-0.9,1.5-3.5,3c-0.9-4.7-13.2,1.5-15.9,2.5c-4,1.4-21.5-4.7-17.3-11.9c-1.6-0.3-3.5-4.1-5.5-6.9c1,0.3,2,0.7,3,1
                c1-1.8,2-3.6,3-5.5c-1.2-1-0.2-0.3-1.5-0.5c-2.1-3.1-19-3.2-18.8-8.9c-0.2-0.7-0.3-1.3-0.5-2c10.1-4.5,22.8-19.6,32.7-21.3
                c0-0.2,0-0.3,0-0.5c1.3-0.7,16.4-4,16.4-4c0.3,0.7,0.7,1.3,1,2c0.1,1.4-0.4,8.4-2,7.9c1.3,2.8,1.1,3.5,2.5,4.5
                c8.2,3.5,16.3-1.1,22.8,2c0.6-2.9,3.7-4.3,3-6.9c1.9,0.2,3.3,0.3,5.9,1.5c-0.2-0.3-0.3-0.7-0.5-1c-0.3-5-5.3-4.4-9.9-3.5
                c0-0.2,0-0.3,0-0.5c0.5-1,0.6-2.4,1-4.5c0.7,0.8,1.3,1.7,2,2.5c1.5-0.2,3-0.3,4.5-0.5c0-0.5,0-1,0-1.5c-5-1-4.3-5.1-6.9-5.9
                c0.8-3.2,3.5-5.2,3-8.9c-3.5,0.4-6.6-1.3-7.9,1.5c-4.7-3.4-11.1,0.3-16.4-3.5c-0.9-0.7-3.5-14.9-4-15.4
                c-10.1-11.5-21.3-17-31.7-23.8c8.1-2.2,6.8-15.8,7.9-15.4c8.3-2.3,16.9,0.9,23.3-3.5c0.5,0,1,0,1.5,0c5.8-4.6,5.9-9.1,9.4-12.9
                c0.3-4.8,0.7-9.6,1-14.4c1.3-2.9,5.9-6.2,5.5-8.4c-0.5-2.6,5.1-6.7,7.4-8.9c0.3-0.2,0.7-0.3,1-0.5c2.6,0.3,5.3,0.7,7.9,1
                c9-3.5,13.1-16.7,22.8-20.3c1.4,4.8,8.1,1.5,12.4,1.5c8-0.1,13.7-13,16.8-18.3c0.2,0,0.3,0,0.5,0c1.5,1.7,7,2.1,6.4,6.4
                c2.3-1,2.2-1.1,5.5-1.5c0.5,1.5,1,3,1.5,4.5c-2,0.3-2.2,4.7-5.9,3.5c0.2,4.5,1.2,2.8,0,5.9c2.4-0.8,4.5-0.9,7.4-0.5
                c-1.2-2.7-2.1-4.7,1-6.9c1,1.2,0.3,0.2,0.5,1.5c0.5-0.7,1-1.3,1.5-2c-0.5-0.7-1-1.3-1.5-2c1.3,0.2,4.7-0.7,3.5,0
                c1.5,0.2,3.4,2.4,3.5,3c0.3,2.3,0.2,2.3-1.5,4.5c2.6,0.8,5.5,2.9,7.9,4c0.6-1.9,2.4-3.1,2-4.5c9-0.1,10.6,0.8,14.9,0
                c3.1,1.9,2.9,1.4,4.5,4c-1.7,0.8-1.8,2.7-2.5,5c0.8,0.7,1.7,1.3,2.5,2c-1.2,0-2.3,0-3.5,0c0.2,0.5,0.3,1,0.5,1.5
                c4.9-0.4,6.3-1.4,9.4,0c-0.3,0-0.7,0-1,0c-0.3,1.1,0,2.5-2,2c0,0.7,0,1.3,0,2c6.6-0.1,6.3,1.5,9.9,2c-0.1-2.2,3.4-4.2,7.4-4
                c0,0.5,0,1,0,1.5c-1.5,1,0,0.1-1,1.5c8.5-2.9,12.4-5.1,19.3,0.5c2.6-2.1,4.4,0.3,4.5,2.5c5.4-0.5,5-0.4,8.4,1c0,1,0,2,0,3
                c4.4-0.9,21.7,1.1,22.8,1.5c4.3,1.4,4.7,5,10.4,6.4c3.4,0.8,11.1-3.4,12.9-4.5c5.6-3.4,24.4,10.5,27.8,15.4c2-0.6,0.9,0.5,1.5-1.5
                c1.2,0.3,2.3,0.7,3.5,1c0.2,86.7,0.3,173.5,0.5,260.2c1.8,0.3,3.6,0.7,5.5,1c0-0.3-0.2-1.8,0.5-1c0.1,0,7.2,3,5.9,0.5
                c0.8-0.8,1.7-1.7,2.5-2.5c2.3,0,4.6,0,6.9,0c-2.2,14.2,17.3,17.2,18.8,33.2c0.3-0.3,0.7-0.7,1-1c2.1-0.3,9.4-5.5,9.4-5.5
                C424.8,360.1,424.4,358.4,424.2,354.5z"/>
            <path class="st0" d="M241.8,43.8c0.2,0.3,0.3,0.7,0.5,1c-0.3,0-0.7,0-1,0C241.5,44.4,241.7,44.1,241.8,43.8z"/>
            <path class="st0" d="M116.5,158.7c0.3,0,0.7,0,1,0C117.1,158.7,116.8,158.7,116.5,158.7z"/>
            <path class="st0" d="M123.9,161.2c1.5,2.1,3,4.3,4.5,6.4c-1.2,0.4,0.1,0.7-1.5,2.5c-0.1-2-2-3-4.5-3c-0.3-1.7-0.7-3.3-1-5
                c0.3,0.2,0.7,0.3,1,0.5C122.9,162.2,123.4,161.7,123.9,161.2z"/>
            <path class="st0" d="M128.3,170.6c0.2,0.3,0.3,0.7,0.5,1C128.7,171.3,128.5,171,128.3,170.6z"/>
            <path class="st0" d="M46.6,193.4c1.3,1.2,1.3,1.4,1.5,2.5c-0.5-0.2-1-0.3-1.5-0.5C46.6,194.8,46.6,194.1,46.6,193.4z"/>
            <path class="st0" d="M113,228.6c1.2,0.7,2.3,1.3,3.5,2c-0.5,0.5-1,1-1.5,1.5c-0.7-0.5-1.3-1-2-1.5c-0.2-0.3-0.3-0.7-0.5-1
                C112.7,229.3,112.8,229,113,228.6z"/>
            <path class="st0" d="M14.9,250.4c1.6,0.7,8.9,7.6,10.4,3.5c1.8-0.4,3.9-3.9,5.5-2c0.5,0,1,0,1.5,0c0.8,1,1.7,2,2.5,3
                c3.8,7,11.8,2.9,15.9,8.4c-0.3,0-0.7,0-1,0c-0.2,4.5-4.2-0.3-6.4,2.5c-1.6,0.1-2.7,3.9-4,5.5c-0.2,0-0.3,0-0.5,0
                c-0.7-6.6-8-7.1-10.4-12.4c-5.6,0.9-5.9,1.4-10.4,2.5c-0.8-1.7-4.9-2.8-3-5.5C14.9,254.1,14.9,252.2,14.9,250.4z"/>
            <path class="st0" d="M120.9,253.4c2.5,0.4,1.5-0.3,2.5,1.5c-0.3,0-0.7,0-1,0c-0.7,0.2-1.3,0.3-2,0.5c0-0.3,0-0.7,0-1
                c0.3,0,0.7,0,1,0C121.2,254.1,121.1,253.7,120.9,253.4z"/>
            <path class="st0" d="M125.4,255.4c0.2,0.3,0.3,0.7,0.5,1C125.7,256,125.5,255.7,125.4,255.4z"/>
            <path class="st0" d="M246.8,325.3c0.3,0,0.7,0,1,0C247.4,325.3,247.1,325.3,246.8,325.3z"/>
            <path class="st0" d="M328,326.3c-0.2,0.3-0.3,0.7-0.5,1c1.3,0.6,1,0.2,2-0.5c0-0.2,0-0.3,0-0.5C329,326.3,328.5,326.3,328,326.3z"
                />
            <path class="st0" d="M0.5,328.7c0.5,0,1,0,1.5,0c0.2,3.8,3.2,4.1,6.9,5c-0.2,0.5-0.3,1-0.5,1.5c-1.2-0.5-1.7-0.5-4.5-0.5
                c-0.3-1.8-2.2-2.1-4-4C0.2,330.1,0.3,329.4,0.5,328.7z"/>
            <path class="st0" d="M310.7,330.2c2,0.2,1.9,0.2,3,1C311.9,330.9,312,331.3,310.7,330.2z"/>
            <path class="st0" d="M306.7,331.2c2.5,1.1,3.3,0.7,5,3c-2.4,0.3-2.2-0.2-3,1c-2-1.2-2.2-0.7-3-3C307.1,332,305.8,332.2,306.7,331.2
                z"/>
            <path class="st0" d="M311.7,332.7c0.2,0.3,0.3,0.7,0.5,1C312,333.4,311.9,333,311.7,332.7z"/>
            <path class="st0" d="M293.4,333.2c1,1,0.5,0.2,1,2c-0.2,0-0.3,0-0.5,0c-0.3-0.3-0.7-0.7-1-1C293,333.9,293.2,333.5,293.4,333.2z"/>
            <path class="st0" d="M77.8,333.7c2.5,0.5,5,1,7.4,1.5c-0.2,3.9,3,9.5-1,8.9c-0.9,1.5-2.1,1.2-4.5,3.5c-5.3-3.4-9.8-6.4-13.9-7.9
                c0.2-0.5,0.3-1,0.5-1.5C70.7,337,76.9,336.7,77.8,333.7z"/>
            <path class="st0" d="M300.3,333.7c0.2,0.3,0.3,0.7,0.5,1C300.6,334.4,300.5,334,300.3,333.7z"/>
            <path class="st0" d="M299.8,334.7c0.7,0.5,1.3,1,2,1.5c-0.8,1.3-1.7,2.6-2.5,4c-0.5-0.3-1-0.7-1.5-1
                C299.5,336.9,299.6,337.9,299.8,334.7z"/>
            <path class="st0" d="M306.7,334.7c0.2,0.3,0.3,0.7,0.5,1C307.1,335.3,306.9,335,306.7,334.7z"/>
            <path class="st0" d="M302.3,335.2c0.3,0,0.7,0,1,0C302.9,335.2,302.6,335.2,302.3,335.2z"/>
            <path class="st0" d="M378.1,335.2c0.3,0,0.7,0,1,0C378.8,335.2,378.4,335.2,378.1,335.2z"/>
            <path class="st0" d="M292.4,335.7c0.5,0.3,1,0.7,1.5,1c-0.2,0.3-0.3,0.7-0.5,1c-0.3-0.2-0.7-0.3-1-0.5
                C292.4,336.7,292.4,336.2,292.4,335.7z"/>
            <path class="st0" d="M306.2,335.7c0.2,0.3,0.3,0.7,0.5,1C306.6,336.3,306.4,336,306.2,335.7z"/>
            <path class="st0" d="M297.3,340.1c1.5,2.1-1,4.4-1,5.9c-2.5,0.3-2.7,1.9-4.5,0C295.3,344.6,294.9,342.7,297.3,340.1z"/>
            <path class="st0" d="M291.9,340.6c0.9,1.4,0.4,0.1,0.5,2c-0.2,0-0.3,0-0.5,0C291.9,342,291.9,341.3,291.9,340.6z"/>
            <path class="st0" d="M389,342.1c-0.3,0.5-0.7,1-1,1.5c0.5,0,1,0,1.5,0C389.3,343.1,389.2,342.6,389,342.1z"/>
            <path class="st0" d="M333.5,342.6c0.2,0.3,0.3,0.7,0.5,1C333.8,343.3,333.7,342.9,333.5,342.6z"/>
            <path class="st0" d="M289.9,343.1c0.2,0.3,0.3,0.7,0.5,1C290.2,343.8,290.1,343.4,289.9,343.1z"/>
            <path class="st0" d="M332.5,343.1c0.3,0.3,0.7,0.7,1,1c-0.2,0.2-0.3,0.3-0.5,0.5c-0.3-0.2-0.7-0.3-1-0.5c0-0.2,0-0.3,0-0.5
                C332.2,343.4,332.3,343.3,332.5,343.1z"/>
            <path class="st0" d="M390.5,344.1c0.2,2.1,0.3,4.3,0.5,6.4c1-1.5,2-3,3-4.5C392.8,345.4,391.6,344.8,390.5,344.1z"/>
            <path class="st0" d="M330.5,345.1c0.2,0.3,0.3,0.7,0.5,1C330.9,345.8,330.7,345.4,330.5,345.1z"/>
            <path class="st0" d="M79.8,347.6c0.3,0,0.7,0,1,0C80.4,347.6,80.1,347.6,79.8,347.6z"/>
            <path class="st0" d="M383.1,347.6c0.2,0.3,0.3,0.7,0.5,1C383.4,348.2,383.2,347.9,383.1,347.6z"/>
            <path class="st0" d="M227,356c0.5,0.5,1,1,1.5,1.5c-0.7,0.3-1.3,0.7-2,1C226.6,357.6,226.8,356.8,227,356z"/>
            <path class="st0" d="M399.9,361.9c0.2,0.3,0.3,0.7,0.5,1C400.2,362.6,400.1,362.3,399.9,361.9z"/>
            <path class="st0" d="M430.6,366.9c0,0.5,0,1,0,1.5c1.8-0.3,1.5-0.2,2.5-1c0-0.2,0-0.3,0-0.5C432.3,366.9,431.5,366.9,430.6,366.9z"
                />
            <path class="st0" d="M142.2,369.9c0.3,0,0.7,0,1,0c-0.9,1,0,0.3-1.5,1C141.9,370.5,142.1,370.2,142.2,369.9z"/>
            <path class="st0" d="M440.5,369.9c0.2,0.3,0.3,0.7,0.5,1C440.9,370.5,440.7,370.2,440.5,369.9z"/>
            <path class="st0" d="M140.7,370.9c0.7,0.2,1.3,0.3,2,0.5c-0.2,0.3-0.3,0.7-0.5,1c-1.4,0.8-0.6,0.7-1,2c-2.1-0.8-1.2,0.1-2-2
                C139.7,371.9,140.2,371.4,140.7,370.9z"/>
            <path class="st0" d="M239.3,374.3c0.3,0.3,0.7,0.7,1,1C240,375,239.7,374.7,239.3,374.3z"/>
            <path class="st0" d="M238.4,374.8c0.3,1.3,0.9,1.2-1.5,2C237.4,376.1,237.9,375.5,238.4,374.8z"/>
            <path class="st0" d="M235.4,377.8c1,0,2,0,3,0c0,0.8,0,1.7,0,2.5c2.8-0.9,2.4-0.3,4.5-1c-0.2,0.7-0.3,1.3-0.5,2
                c2-0.7,0.9-0.2,1.5-1.5c1.5,1.8,0.3,2.2-0.5,4c-2.7-1,0.2-0.9-3-1c0,1.4,0.3,1.1-0.5,2.5c-1.2-0.5-2.3-1-3.5-1.5
                c-0.1,1.9-0.5,2.3-2,4c-0.2-0.3-0.3-0.7-0.5-1c-1.3-1-2.6-2-4-3c0.3-0.3,0.7-0.7,1-1c0.7,0.2,1.3,0.3,2,0.5c0-2.2-1-2.1,1.5-2
                c-0.2-0.3-0.3-0.7-0.5-1C235.9,379.2,235.4,379.5,235.4,377.8z"/>
            <path class="st0" d="M441.5,378.8c2.8,1.1,3.9,3.7,3.5,5.5c2.8-0.8,4,0.4,4.5,1c2.7,0.7,4.8,6.3,5.5,9.9c-0.2,0-0.3,0-0.5,0
                c-1-1.2-0.3-0.2-0.5-1.5c-0.5,0-1,0-1.5,0c0.2,0.3,0.3,0.7,0.5,1c0.5,2.5,1,5,1.5,7.4c-1.6,0.1-6.2,4.6-8.9,5.9
                C445.6,395.8,444,390.4,441.5,378.8z"/>
            <path class="st0" d="M451.9,379.3c0.8,1.8,0.1,1.2,2,2c-0.2-0.7-0.3-1.3-0.5-2C452.9,379.3,452.4,379.3,451.9,379.3z"/>
            <path class="st0" d="M432.1,381.3c1.9,1,2.1,1.2,3.5,4c-1.8,1.4-1.7,1.6-2.5,3c0.5,0,1,0,1.5,0c0.7-1.2,1.3-2.3,2-3.5
                c2.8,0.8,4.2,1.4,4.5,3c1,1,0.5,0.2,1,2c-0.7-0.2-1.3-0.3-2-0.5c0.2,1.3,0.3,2.6,0.5,4c0.7,0,1.3,0,2,0c0.2,1.8,0.3,3.6,0.5,5.5
                c-0.7,0.2-1.3,0.3-2,0.5c-1.7-3.1-6.1-4.3-8.4-5.5c1.2,2,2.3,4,3.5,5.9c0.2-1.6-0.4-1.3,1.5-2.5c0.1,1.2,4.6,4.8,5.9,6.9
                c3.2,5.1,2.2,13.5,2,21.8c-0.3-0.2-0.7-0.3-1-0.5c-0.5-5-3.9-5.4-5.5-10.4c1.2-0.3,0.4,0.1,1-2.5c-0.8,0.3-1.7,0.7-2.5,1
                c0.2-0.5,0.3-1,0.5-1.5c0.1-1.8,0.1-2.3,1-4c-0.7-0.2-15.3-18.2-15.4-22.3c2.6-0.4,2.1-0.3,3.5-3c0.2,0.8,0.3,1.7,0.5,2.5
                C429.5,382.7,429.9,384,432.1,381.3z"/>
            <path class="st0" d="M425.7,386.7c0.8,1.2,0.3,0.6,1.5,0C426.7,386.7,426.2,386.7,425.7,386.7z"/>
            <path class="st0" d="M229.4,387.2c0.3,0.3,0.7,0.7,1,1C230.1,387.9,229.8,387.5,229.4,387.2z"/>
            <path class="st0" d="M226.5,388.2c0.2,0.3,0.3,0.7,0.5,1C226.8,388.9,226.6,388.5,226.5,388.2z"/>
            <path class="st0" d="M230.4,388.2c0.3,0.3,0.7,0.7,1,1C231.1,388.9,230.8,388.5,230.4,388.2z"/>
            <path class="st0" d="M227.5,388.7c0.5,0.7,1,1.3,1.5,2c2.4-0.8,4.8-2.9,5.9,0c-1,1-0.5,0.2-1,2c0.2,0,0.3,0,0.5,0
                c1.3-1.5,3.2-2.9,3.5-2c1.7,1.6,0.2,3.4,2,5.9c1.7-0.4,1.3-0.6,2.5,0.5c-1.8,0.4-1.4,0.8-2.5,3c-1.5-1.3-3.3-2.6-6.4-1
                c1.3,0.7,2.6,1.3,4,2c-0.5,0.7-1,1.3-1.5,2c-0.2,0-0.3,0-0.5,0c-1.2-0.5-2.3-1-3.5-1.5c0.6,1.7,0.8,2,1,3c-0.8,0.2-1.7,0.3-2.5,0.5
                c1.1,1.1,1.2,0.5,0.5,1.5c-1.2,2.4-0.7,2.4-2.5,1c0.2-0.3,0.3-0.7,0.5-1c-2.1,0.6-1.6,0.7-4,0.5c0.2,0.3,0.3,0.7,0.5,1
                c-0.5,1.7,0.9,0.6-0.5,2c-1,1.5-2,3-3,4.5c-2-0.3-1.5,0-2.5-1c1.6-0.4,1.9-1.3,3-3.5c-1.5-0.2-0.3,0.6-1.5-0.5
                c-1.3,0.8-2.6,1.7-4,2.5c0-0.8-2.8-7.8-3-7.9c-1.5-0.9-1.6-0.3-2.5-2c0.4-0.1,4.7-6,8.4-7.4c0,0.3,0,0.7,0,1c1.1,0.3,3.1,2.1,2,5
                c0.3-0.2,0.7-0.3,1-0.5c0.2-3.5,0.4-4.7,0-5.5c-0.9-2.2-0.9-0.1-1.5-2.5c2.1-0.3,1.4-1.3,3.5-2c0.4,1.7,0.4,0.7,0.5,2
                c1.3-0.3,2.6-0.7,4-1C227.6,390.3,227.4,390.3,227.5,388.7z"/>
            <path class="st0" d="M435.6,390.2c0.7,2.9,1.4,0.7,1,3.5c1.2-0.3,2.3-0.7,3.5-1C438.6,391.8,437.1,391,435.6,390.2z"/>
            <path class="st0" d="M432.1,402.1c0.5,0.2,1,0.3,1.5,0.5c0.2,1,1.4,6.5,0.5,5.5c-0.5,0.5-1,1-1.5,1.5c-0.3-0.3-0.7-0.7-1-1
                c0.9-2.2,0.4-1,1.5-3.5C432,404,431.9,403.2,432.1,402.1z"/>
            <path class="st0" d="M30.7,404.6c1,0,2,0,3,0c-0.8,2.1-1.4,0.3-1,2C32,405.9,31.4,405.2,30.7,404.6z"/>
            <path class="st0" d="M226.5,407.5c0.3,0,0.7,0,1,0C227.1,407.5,226.8,407.5,226.5,407.5z"/>
            <path class="st0" d="M453.4,407.5c3.8,0.3,9.7,1.7,10.9,3.5c0.6,0.4,0.8,2.6,1.5,2c0.9,1.1,1,1.1,2,1c0.3,2.2,1.4,3.1,1,4
                c-1.5,1.9-1.7,0.8-3.5,1.5c-0.8-1.1-0.7-0.2-2.5-1c0.2,0.8,0.3,1.7,0.5,2.5c-2-0.2-4-0.3-5.9-0.5c-0.3-2.6-0.3-1.6-2.5-2.5
                c0,0.3,0,0.7,0,1c-0.3-0.2-0.7-0.3-1-0.5c0.3,1.8,0.7,3.6,1,5.5c-1.9-0.6-1.5,0.1-3.5,0c0,0.8,0,1.7,0,2.5c-0.5-0.3-1-0.7-1.5-1
                c0.8-2.3,1.1-1.2,0-2.5c1.6-1.9,1.3,0.5,3-1.5c-0.2-1-0.3-2-0.5-3c-1.3-0.2-3.1-2.8-4-5c0.5-0.7,1-1.3,1.5-2
                c1.3,0.6,1.8,0.7,3.5,0.5c0.2,1.3,0.3,2.6,0.5,4c0.8-0.3,1.7-0.7,2.5-1c0-2.4,0.5-1.7-0.5-3c-1-1.3-2-2.6-3-4
                c0.3,0.2,0.7,0.3,1,0.5C453.7,408.2,453.6,407.9,453.4,407.5z"/>
            <path class="st0" d="M437.1,410c0.2,0.3,0.3,0.7,0.5,1C437.4,410.7,437.2,410.3,437.1,410z"/>
            <path class="st0" d="M448,412c0.3,0,0.7,0,1,0C448.1,412.5,448.8,412.4,448,412z"/>
            <path class="st0" d="M462.3,415c-0.2,0.8-0.3,1.7-0.5,2.5c1.2,0.1,0.6-0.1,2,0.5c0.2,0,0.3,0,0.5,0c0.2-0.5,0.3-1,0.5-1.5
                C462.9,416.2,463.8,415.7,462.3,415z"/>
            <path class="st0" d="M39.6,417.4c0.3,0,0.7,0,1,0c-0.3,0.7-0.7,1.3-1,2C39.6,418.8,39.6,418.1,39.6,417.4z"/>
            <path class="st0" d="M41.1,417.4c0.2,0.3,0.3,0.7,0.5,1C41.5,418.1,41.3,417.8,41.1,417.4z"/>
            <path class="st0" d="M217.5,417.4c1.7,0.5,3.3,1,5,1.5c-0.3,0-0.7,0-1,0c-1,1-0.9,0.8-3,1c-0.5-0.2-1-0.3-1.5-0.5
                c0.3-0.3,0.7-0.7,1-1C217.9,418.1,217.7,417.8,217.5,417.4z"/>
            <path class="st0" d="M214.1,417.9c0.3,0.7,0.7,1.3,1,2c-0.3-0.2-0.7-0.3-1-0.5c0,0.3,0,0.7,0,1c-0.5-0.5-1-1-1.5-1.5
                c0.3,0,0.7,0,1,0C213.7,418.6,213.9,418.3,214.1,417.9z"/>
            <path class="st0" d="M465.8,420.4c1,0.3,2,0.7,3,1c0,0.8,0,1.7,0,2.5c-0.3,0-0.7,0-1,0c-1.6,0.5-2.6-0.1-3.5-1c0-0.2,0-0.3,0-0.5
                C464.8,421.7,465.3,421.1,465.8,420.4z"/>
            <path class="st0" d="M211.6,420.9c0.3,0.3,0.7,0.7,1,1C212.3,421.6,211.9,421.2,211.6,420.9z"/>
            <path class="st0" d="M470.8,420.9c0.2,0.5,0.3,1,0.5,1.5c-0.2,0-0.3,0-0.5,0c-0.2-0.2-0.3-0.3-0.5-0.5
                C470.4,421.6,470.6,421.2,470.8,420.9z"/>
            <path class="st0" d="M458.4,422.9c2.3,0.5,4.8,1.1,4.5,2.5c0.6,0.5,8.4,11.2,8.9,13.4c-0.7-0.2-1.3-0.3-2-0.5c0,1,0,2,0,3
                c1.4,0.3,2.5,0.7,2,2.5c2.5-0.5,0.7-0.5,3,0c-0.5,1-1,2-1.5,3c1,0,2,0,3,0c-0.3,1.8-0.2,1.5-1,2.5c-0.8,1.1-0.2,1.5-2,1
                c1.4,1.4,1,1.3,3,1c-0.2,0.5-0.3,1-0.5,1.5c0.2,1.3,0.3,2.6,0.5,4c-0.8,0-1.7,0-2.5,0c-0.8-3.8-1.4-6.2-4-5.5c0.7-3-0.4-3.8,0-5.9
                c-0.3,0.5-0.7,1-1,1.5c-2.5-1.2-1.5-1.6-6.4-2c0.5-0.5,1-1,1.5-1.5c0.3,0.2,0.7,0.3,1,0.5c-0.6-3.4-0.8-2.9,0-4
                c-1.1-1.2-4.1-1.2-3.5-2c-0.3,0-0.7,0-1,0c1.5-2.3,4.4-3.9,2-7.4c-0.6-0.8,0.1-0.7-1-0.5c-0.8,0-1.7,0-2.5,0c0-0.7,0-1.3,0-2
                c1.2-0.6,0.5,0.3,1-1C458.5,425.2,457.1,425.7,458.4,422.9z"/>
            <path class="st0" d="M461.8,423.4c0.3,0,0.7,0,1,0C462.5,423.4,462.2,423.4,461.8,423.4z"/>
            <path class="st0" d="M452.4,424.4c0.5,0,1,0,1.5,0c-0.2,1.3-0.3,2.6-0.5,4c-0.2,0-0.3,0-0.5,0C452.8,427,452.6,425.7,452.4,424.4z"
                />
            <path class="st0" d="M450,427.4c0.8,0,1.7,0,2.5,0c-0.2,0.5-0.3,1-0.5,1.5c-0.2,0-0.3,0-0.5,0C450.9,428.4,450.4,427.9,450,427.4z"
                />
            <path class="st0" d="M456.4,428.8c0.7,0,1.3,0,2,0c0,0.2,0,0.3,0,0.5c-0.2,0.5-0.3,1-0.5,1.5c-0.7,0-1.3,0-2,0c0-0.3,0-0.7,0-1
                c0.3,0,0.7,0,1,0C456.7,429.5,456.6,429.2,456.4,428.8z"/>
            <path class="st0" d="M168.5,429.8c0.3,0,0.7,0,1,0C169.1,429.8,168.8,429.8,168.5,429.8z"/>
            <path class="st0" d="M140.2,430.3c0.3,0.3,0.7,0.7,1,1C140.9,431,140.6,430.7,140.2,430.3z"/>
            <path class="st0" d="M453.9,431.3c0.3,0.8,0.7,1.7,1,2.5c-0.3-0.2-0.7-0.3-1-0.5C453.9,432.6,453.9,432,453.9,431.3z"/>
            <path class="st0" d="M455.4,431.3c0.3,0,0.7,0,1,0C456.1,431.3,455.7,431.3,455.4,431.3z"/>
            <path class="st0" d="M202.2,431.8c0.3,0.2,0.7,0.3,1,0.5c-0.2,1-0.3,2-0.5,3c-1-1.2-0.3-0.2-0.5-1.5c-0.3,0.2-0.7,0.3-1,0.5
                C202.1,431.8,202.1,434.5,202.2,431.8z"/>
            <path class="st0" d="M449.5,431.8c0.5,0,1,0,1.5,0c-0.5,0.7-1,1.3-1.5,2C449.5,433.1,449.5,432.5,449.5,431.8z"/>
            <path class="st0" d="M457.4,433.8c0.5,0.2,1,0.3,1.5,0.5c-0.5,0.5-1,1-1.5,1.5C457.4,435.1,457.4,434.5,457.4,433.8z"/>
            <path class="st0" d="M458.4,435.8c0.2,0.3,0.3,0.7,0.5,1C458.7,436.4,458.5,436.1,458.4,435.8z"/>
            <path class="st0" d="M154.6,437.8c0.3,0.3,0.7,0.7,1,1C155.3,438.4,154.9,438.1,154.6,437.8z"/>
            <path class="st0" d="M471.8,438.8c0.2,0.3,0.3,0.7,0.5,1C472.1,439.4,471.9,439.1,471.8,438.8z"/>
            <path class="st0" d="M459.9,439.3c1.3,0.8,0.9,0,1.5,2c-0.3-0.2-0.7-0.3-1-0.5c-1.5,0-0.7,0.5-2-0.5c0.5,0,1,0,1.5,0
                C459.9,439.9,459.9,439.6,459.9,439.3z"/>
            <path class="st0" d="M476.2,439.3c0.2,0.5,0.3,1,0.5,1.5c-0.3-0.2-0.7-0.3-1-0.5C475.9,439.9,476,439.6,476.2,439.3z"/>
            <path class="st0" d="M455.9,440.2c0.3,0,0.7,0,1,0C456.6,440.2,456.2,440.2,455.9,440.2z"/>
            <path class="st0" d="M471.8,440.7c0.2,0.3,0.3,0.7,0.5,1C472.1,441.4,471.9,441.1,471.8,440.7z"/>
            <path class="st0" d="M148.2,441.2c0.5,0.5,1,1,1.5,1.5C147.8,441.8,148.6,442.5,148.2,441.2z"/>
            <path class="st0" d="M458.4,441.2c0.2,0.3,0.3,0.7,0.5,1C458.7,441.9,458.5,441.6,458.4,441.2z"/>
            <path class="st0" d="M143.2,442.7c0.2,0.5,0.3,1,0.5,1.5c1.4-0.6,0.1-1.2,1.5-0.5c0.3,0.3,0.7,0.7,1,1c-1.9,0.3,0.6,1.5-2,3
                c-0.2-2.2-0.4-1-2.5-0.5c0-1.3,0-2.6,0-4C143,443,142,443.7,143.2,442.7z"/>
            <path class="st0" d="M461.8,442.7c0.3,0,0.7,0,1,0c-0.3,0.5-0.7,1-1,1.5C461.8,443.7,461.8,443.2,461.8,442.7z"/>
            <path class="st0" d="M146.2,443.2c1.4,0.8,0.9,0,0.5,1.5C146.5,444.2,146.3,443.7,146.2,443.2z"/>
            <path class="st0" d="M460.4,443.7c0.5,0.5,1,1,1.5,1.5c-0.2,0.3-0.3,0.7-0.5,1c-0.5-0.7-1-1.3-1.5-2c0.3,0.2,0.7,0.3,1,0.5
                C460.7,444.4,460.5,444,460.4,443.7z"/>
            <path class="st0" d="M481.2,445.2c1,0.8,2,1.7,3,2.5c-1,1.7-0.8,1.8-3,2.5c0.2-0.3,0.3-0.7,0.5-1
                C481.5,447.8,481.3,446.5,481.2,445.2z"/>
            <path class="st0" d="M462.8,446.2c0.8,1.7,1.7,3.3,2.5,5c0,0.2,0,0.3,0,0.5c-0.8-0.3-1.7-0.7-2.5-1
                C462.8,449.2,462.8,447.7,462.8,446.2z"/>
            <path class="st0" d="M152.6,446.7c0.7,1.4-0.2,1.1-0.5,1.5c-1,1.5-0.1,0-1.5,1c0-0.5,0-1,0-1.5C152,447.5,151.4,448,152.6,446.7z"
                />
            <path class="st0" d="M129.8,447.2c2.2,0.6,1.6,0,2.5,2C131.5,448.5,130.7,447.8,129.8,447.2z"/>
            <path class="st0" d="M113.5,448.2c0.3,0.7,0.7,1.3,1,2c-0.5-0.2-1-0.3-1.5-0.5C113.1,449.2,113.3,448.7,113.5,448.2z"/>
            <path class="st0" d="M466.8,448.2c0.5,0,1,0,1.5,0c-0.2,0.8-0.3,1.7-0.5,2.5c-0.2,0-0.3,0-0.5,0c-0.2-0.2-0.3-0.3-0.5-0.5
                C466.8,449.5,466.8,448.8,466.8,448.2z"/>
            <path class="st0" d="M108,449.7c2,0.5,3.5,0.7,3,2.5c0.7,0.7,1.3,1.3,2,2c-0.5,1.3-1,2.6-1.5,4c-10,0.3-9.3,4.8-15.9,5
                c-0.3-0.8-1.7-5-0.5-3.5c1.1-2.4,3.5-6.1,5-7.4C102.4,453.3,106.5,451.2,108,449.7z"/>
            <path class="st0" d="M113.5,450.2c0.5,0,1,0,1.5,0c0.3,0.8,0.7,1.7,1,2.5c-1.4,0.2,0,0-1,1c-0.5-0.2-1-0.3-1.5-0.5
                C113.5,452.1,113.5,451.1,113.5,450.2z"/>
            <path class="st0" d="M149.7,450.7c1.5,0.4,0.1,0.7-0.5,1.5C149.3,451.6,149.5,451.1,149.7,450.7z"/>
            <path class="st0" d="M123.9,451.1c0.7,0.3,1.3,0.7,2,1c-0.2,0.5-0.3,1-0.5,1.5c-1.2-0.3-1.6,0.5-2-2
                C123.8,451.6,124.4,451.6,123.9,451.1z"/>
            <path class="st0" d="M484.1,451.1c0.3,0.3,0.7,0.7,1,1c-0.2,0.2-0.3,0.3-0.5,0.5C484.5,452.1,484.3,451.6,484.1,451.1z"/>
            <path class="st0" d="M482.7,451.6c0.3,0,0.7,0,1,0c-0.9,1,0,0.3-1.5,1C482.3,452.3,482.5,452,482.7,451.6z"/>
            <path class="st0" d="M464.3,452.1c0.5,0,1,0,1.5,0C465.3,452.1,464.8,452.1,464.3,452.1z"/>
            <path class="st0" d="M467.3,452.1c0.3,0,0.7,0,1,0C467.4,452.6,468.2,452.6,467.3,452.1z"/>
            <path class="st0" d="M113,453.1c0.2,0.3,0.3,0.7,0.5,1C113.3,453.8,113.1,453.5,113,453.1z"/>
            <path class="st0" d="M464.8,453.1c0.3,0,0.7,0,1,0C465.5,453.1,465.1,453.1,464.8,453.1z"/>
            <path class="st0" d="M465.8,453.6c0.3,0,0.7,0,1,0C466.5,453.6,466.1,453.6,465.8,453.6z"/>
            <path class="st0" d="M466.8,454.1c-0.1,0.7,0.2,0.5-0.5,1.5c-0.2-0.3-0.3-0.7-0.5-1C466.1,454.5,466.5,454.3,466.8,454.1z"/>
            <path class="st0" d="M492.1,454.1c0.3,0.3,0.7,0.7,1,1c-0.5,0-1,0-1.5,0C491.7,454.8,491.9,454.5,492.1,454.1z"/>
            <path class="st0" d="M114.5,455.1c0.3,0.3,0.7,0.7,1,1c-0.2,0.3-0.3,0.7-0.5,1c-0.3-0.2-0.7-0.3-1-0.5
                C114.1,456.1,114.3,455.6,114.5,455.1z"/>
            <path class="st0" d="M466.8,455.6c0.3,0.3,0.7,0.7,1,1C467.5,456.3,467.1,455.9,466.8,455.6z"/>
            <path class="st0" d="M491.6,455.6c0.3,0,0.7,0,1,0C491.7,456.1,492.5,456.1,491.6,455.6z"/>
            <path class="st0" d="M118.4,461.1c1.5,0.6,0.6,0,1.5,1c-0.5,0-1,0-1.5,0C118.4,461.7,118.4,461.4,118.4,461.1z"/>
            <path class="st0" d="M120.4,461.6c0.5,0.3,1,0.7,1.5,1c0,0.2,0,0.3,0,0.5c-0.7,0-1.3,0-2,0c0-0.2,0-0.3,0-0.5
                C120.1,462.2,120.3,461.9,120.4,461.6z"/>
            <path class="st0" d="M85.7,465c2.2,0.7,1.8,0.3,2.5,2.5c-1.2,0.1-0.6-0.1-2,0.5C86.3,465.9,86.6,466.6,85.7,465z"/>
            <path class="st0" d="M82.8,466.5c0.7,0.3,1.3,0.7,2,1c0,0.7,0,1.3,0,2c-1,0-2,0-3,0c-0.2-0.7-0.3-1.3-0.5-2
                C81.8,467.2,82.3,466.8,82.8,466.5z"/>
            <path class="st0" d="M91.2,468c2.1,0.2,2,0,3,1c-1.2,0-2.3,0-3.5,0C90.8,468.7,91,468.3,91.2,468z"/>
            <path class="st0" d="M72.8,470.5c0.7,0,1.3,0,2,0c0,0.5,0,1,0,1.5c-0.3-0.2-0.7-0.3-1-0.5c0.9,1.6,0.2,1.4,2.5,2.5
                c0.1-1.4-0.3-0.4,1-1c-0.2,0.5-0.3,1-0.5,1.5c-1.4,0.5-0.7,0.4-2,0.5c0.7,1,1.3,2,2,3c-1.8,0.8-7.3,7.1-10.9,3.5
                c3.8-0.4,2.8-2.1,5-4.5c0.8,0.2,1.7,0.3,2.5,0.5c-0.3-0.8-0.7-1.7-1-2.5c-2,0.4-2.9,0.1-4-1C69.9,472.8,71.4,471.6,72.8,470.5z"/>
            <path class="st0" d="M77.8,471.5c0.5,0,1,0,1.5,0c-0.3,0.3-0.7,0.7-1,1c0.2,0.3,0.3,0.7,0.5,1c-0.7-0.5-1.3-1-2-1.5
                C77.1,471.8,77.5,471.6,77.8,471.5z"/>
            <path class="st0" d="M80.3,473.5c0.6,1.4,0.4,0.5-0.5,1.5C79.9,474.4,80.1,473.9,80.3,473.5z"/>
            <path class="st0" d="M78.3,474.9c0.5,0,1,0,1.5,0c-0.4,1.3,0.2,1.7-1,1c-1,1.5-0.1,0-1.5,1c-0.2-0.2-0.3-0.3-0.5-0.5
                C77.3,475.9,77.8,475.4,78.3,474.9z"/>
            <path class="st0" d="M57,479.4c1.3,0.2,2.6,0.3,4,0.5c-0.3,0.8-0.7,1.7-1,2.5c-2,0-4,0-5.9,0c0.2-0.5,0.3-1,0.5-1.5
                C55.8,480.7,56.4,480.7,57,479.4z"/>
            <path class="st0" d="M64.4,481.9c0.3,0,0.7,0,1,0C65.1,481.9,64.8,481.9,64.4,481.9z"/>
        </g>
        </svg>
  </div>
</template>

<script>
export default {
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.st0{
    fill:#C8C8C8;
    fill-rule:evenodd;
    clip-rule:evenodd;
}
</style>
