<template>
  <div id="account">
    <h1 class="ui header">Account Overview</h1>

    <div class="ui active centered inline large loader" v-if="loading"></div>

    <div class="ui grid" v-else>
      <div class="sixteen wide column highlighted">
        <div class="ui grid">
          <div class="four wide column"><h3>Email</h3></div>
          <div class="twelve wide column"><p>{{user.email}}</p></div>
        </div>
      </div>
      <div class="sixteen wide column">
        <div class="ui grid">
          <div class="four wide column"><h3>Send Shipment Email to Self?</h3></div>
          <div class="twelve wide column">
            <div class="ui toggle checkbox">
              <input type="checkbox" name="email_user" v-model="emailUser" :checked="emailUser" @change="updateEmailSend($event)">
              <label></label>
            </div>
          </div>
        </div>
      </div>
      <div class="sixteen wide column highlighted">
        <div class="ui grid">
          <div class="four wide column"><h3>Password</h3></div>
          <div class="twelve wide column"><a href="javascript:void(0);" @click="resetPassword()">Reset</a></div>
        </div>
      </div>
      <div class="sixteen wide column">
        <div class="ui grid">
          <div class="four wide column"><h3>Customer Number(s)</h3></div>
          <div class="twelve wide column">
            <p>{{user.customer_numbers.map(function(number) { return number.number }).join(",")}}</p>
          </div>
        </div>
      </div>
      <div class="sixteen wide column highlighted" v-show="false">
        <div class="ui grid">
          <div class="four wide column"><h3>Logo</h3></div>
          <div class="twelve wide column"><a href="javascript:void(0);" @click="changeLogo()">Change Logo </a></div>
        </div>
      </div>
      <div class="sixteen wide column" v-if="user.has_api_access">
        <div class="ui grid">
          <div class="four wide column"><h3>Api Token</h3></div>
          <div class="twelve wide column">
            <p>{{user.api_token}}</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="user.has_api_access" class="ui centered large mt-5">
      <h1 class="ui header">ALG Worldwide Customer HAWB API</h1>
      <h2>Retrieve HAWB Data Endpoint</h2>
      <div class="my-2">
        <div class="font-weight-bold">Endpoint</div>
        <div>{{url}}</div>
      </div>
      <div class="my-2">
        <div class="font-weight-bold">Request Type</div>
        <div>POST</div>
      </div>
      <div class="my-2">
        <div class="font-weight-bold">Required Data</div>
        <div>hawb_number => (String)</div>
      </div>
      <div class="my-2">
        <div class="font-weight-bold">Header Required</div>
        <div>Bearer Token: {{user.api_token}}</div>
      </div>
      <h2 class="mt-5">PHP Code Example - Curl</h2>
      <pre class="overflow-scroll"><code>
          $api_token = "{{user.api_token}}";
          $data = [
              'hawb_number' => 'HAWB1234',
          ];

          $headers = [
              'Authorization: Bearer ' . $api_token
          ];

          $ch = curl_init();
          curl_setopt($ch, CURLOPT_URL, '{{url}}');
          curl_setopt($ch, CURLOPT_POST, 1);
          curl_setopt($ch, CURLOPT_POSTFIELDS, $data);
          curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);
          curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
          $result = curl_exec($ch);
          curl_close($ch);

          return $result;
      </code></pre>

      <h2>Expected Return</h2>
      <pre class="overflow-scroll"><code>
      {
          "data": {
              "hawb_number":"HAWB1234",
              "location_history": [
                  {
                      "city": "WOOD DALE",
                      "state": "IL",
                      "lat": 41.977150,
                      "lng": -87.966990,
                      "miles_to_destination": 100,
                      "timestamp": "2022-05-18 12:25:00"
                  },
              ],
              "pod_data": {
                  "name": "Suzy Q"
                  "date": "04-25-2022"
                  "time": "12:25"
              }
          }
      }
      </code></pre>
    </div>

    <UpdateLogoModal />
    <ResetPasswordModal />
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
import ResetPasswordModal from '@/components/modals/ResetPasswordModal.vue';
import UpdateLogoModal from '@/components/modals/UpdateLogoModal.vue';

export default {
  components: {
    UpdateLogoModal,
    ResetPasswordModal
  },
  data () {
    return {
      loading: true,
      user: [],
      emailUser: null,
      url: ""
    }
  },
  created () {
    this.$http.get('/account')
    .then((response) => {
      this.user = response.data;
      this.emailUser = response.data.email_user;

      this.loading = !this.loading;
    })
    .catch((error) => {
      this.errors = error;
    });
  },
  mounted () {
    this.url = process.env.VUE_APP_API_URL + "/get/hawb";
  },
  methods: {
    updateEmailSend: function() {
      alert('changed');
    },
    resetPassword: function() {
      $('.ui.reset_password.modal').modal('show');
    },
    changeLogo: function() {
      $('.ui.update_logo.modal').modal('show');
    },
    forgotPassword: function () {
      $(".ui.forgot_password.modal").modal("show");
    },
  },
  computed: {
  }
};
</script>

<style lang="scss">
#account {
  margin: $layoutmargin;

  .ui.header {
    margin-bottom: 32px;
  }

  .ui.grid {

    .column {
      padding: 15px 0px 15px 16px;

      a {
        color: $algred;
        text-decoration: underline;
      }
    }

    .highlighted {
      background-color: #F4F4F4;
      border-radius: 8px;
    }

    .ui.toggle.checkbox {

      label {
        margin-bottom: 0px !important;
      }

      input:checked~label:before {
        background-color: $algred !important;
      }
    }
  }
  
}
</style>