// Make sure to call Vue.use(Vuex) first if using a module system
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const state = {
    user: null
};

const getters = {
    returnUser: state => {
        return state.user
    }
};

const mutations = {
    setUser (state, payload) {
        // alert('updating user...');
        state.user = payload.user;
    },
    unsetUser (state) {
        state.user = null;
    }
};

export default new Vuex.Store({
    state,
    plugins: [createPersistedState()],
    mutations,
    getters
});