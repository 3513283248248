var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui hawbs modal"},[_c('div',{staticClass:"header"},[_vm._v("Hawbs "),_c('i',{staticClass:"close icon",on:{"click":function($event){return _vm.close()}}})]),_c('div',{staticClass:"content"},[(_vm.loading)?_c('div',{staticClass:"ui active centered inline large loader"}):_c('v-client-table',{staticClass:"hawbs_table",attrs:{"columns":_vm.columns,"data":_vm.hawbs,"options":_vm.options},scopedSlots:_vm._u([{key:"CheckDate",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"check_date"},[_vm._v(_vm._s(_vm.formatYear(row.CheckDate)))])}},{key:"Amount",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"amount"},[_vm._v("$"+_vm._s(parseFloat(row.Amount).toLocaleString('en-US', {minimumFractionDigits: 2})))])}},{key:"DateInvoiced",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"date_invoiced"},[_vm._v(_vm._s(_vm.formatYear(row.DateInvoiced)))])}},{key:"DatePaid",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"date_paid"},[_vm._v(_vm._s(_vm.formatYear(row.DatePaid)))])}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }