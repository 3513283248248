/* eslint-disable no-console */
<template>
  <div
    :class="'fields detail_fields_' + (detailCount + 1)"
    :id="'detail_fields_' + (detailCount + 1)"
  >
    <div class="one wide field">
      <label><span class="asterik">*</span>Pieces</label>
      <input
        type="text"
        placeholder=""
        @keypress="isNumber($event)"
        :name="'detail_pieces_' + (detailCount + 1)"
        required
      />
    </div>

    <div class="two wide field">
      <label><span class="asterik">*</span>Weight</label>
      <input
        type="text"
        placeholder=""
        @keypress="isNumber($event)"
        :name="'detail_wt_' + (detailCount + 1)"
        required
      />
    </div>

    <div class="one wide field">
      <label>L</label>
      <input
        type="text"
        placeholder=""
        @keypress="isNumber($event)"
        :name="'detail_l_' + (detailCount + 1)"
      />
    </div>

    <div class="one wide field">
      <label>W</label>
      <input
        type="text"
        placeholder=""
        @keypress="isNumber($event)"
        :name="'detail_w_' + (detailCount + 1)"
      />
    </div>

    <div class="one wide field">
      <label>H</label>
      <input
        type="text"
        placeholder=""
        @keypress="isNumber($event)"
        :name="'detail_h_' + (detailCount + 1)"
      />
    </div>

    <div class="two wide field">
      <label><span class="asterik">*</span>Commodity</label>
      <select
        class="ui fluid commodity dropdown"
        :name="'detail_commodity_' + (detailCount + 1)"
        :value="shipmentSettings.detailsCommoditiesDefault"
        :disabled="shipmentSettings.detailsCommoditiesLock"
      >
        <option :value="null" disabled selected></option>
        <option
          v-for="commodity in commodities"
          :key="commodity.Code"
          :value="commodity.Code"
        >
          {{ commodity.Description }}
        </option>
      </select>
    </div>

    <div class="three wide field">
      <label><span class="asterik">*</span>Description</label>
      <input
        type="text"
        :name="'detail_description_' + (detailCount + 1)"
        placeholder=""
        :value="shipmentSettings.detailsDescriptionDefault"
        required
      />
    </div>

    <div class="one wide field">
      <label>Class</label>
      <select
        class="ui fluid dropdown"
        :name="'detail_class_' + (detailCount + 1)"
      >
        <option :value="null" disabled selected></option>
        <option v-for="c in classes" :key="c" :value="c">
          {{ c }}
        </option>
      </select>
    </div>

    <div class="two wide field nmfc1">
      <label>NMFC</label>
      <input
        type="text"
        placeholder=""
        :name="'detail_nmfc_start_' + (detailCount + 1)"
        @blur="checkNmfc"
      />
    </div>
    <div class="seperator">
      -
    </div>
    <div class="one wide field nmfc2">
      <input
        type="text"
        placeholder="00"
        :name="'detail_nmfc_end_' + (detailCount + 1)"
        @blur="checkNmfc2"
      />
    </div>

    <div class="one wide field add_detail">
      <i class="fa fa-plus" @click="addDetail()" v-if="detailCount == 0"></i>
      <i class="fa fa-minus" @click="removeDetail()" v-else></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detailCount: Number,
    details: Array,
    commodities: Object,
    classes: Array,
    shipmentSettings: Object,
    shipment: Object
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    checkNmfc: function($event) {
      if (
        $event.target.value &&
        ($event.target.value.length !== 6 || isNaN($event.target.value))
      ) {
        alert("Must be 6 numerical digits.");
        setTimeout(() => {
          $event.target.focus();
        }, 500);
      }
    },
    checkNmfc2: function($event) {
      if (
        $event.target.value &&
        ($event.target.value.length !== 2 || isNaN($event.target.value))
      ) {
        alert("Must be 2 numerical digits.");
        setTimeout(() => {
          $event.target.focus();
        }, 500);
      }
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    addDetail: function() {
      this.$emit("clicked");
    },
    removeDetail: function() {
      // this.details[this.detailCount].$destroy();
      this.details.splice(this.detailCount, 1);
    }
  },
  computed: {}
};
</script>

<style lang="scss">
h3 {
  font-size: 16px !important;
}

.seperator {
  font-size: 36px;
  display: inline-block;
  position: relative;
  top: 35px;
  left: 15px;
  color: black;
}
</style>
