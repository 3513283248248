<template>
  <div class="ui card">
    <div class="header">
      <h2>Total Late Shipments</h2>
      <div v-cloak v-if="!loading && dateRange.startDate && dateRange.endDate">
        {{ dateRange.startDate }} - {{ dateRange.endDate }}
      </div>
    </div>
    <div class="content" style="min-height: 333px">
      <div class="clearfix pb-2">
        <div style="min-width: 120px" class="ui left floated last_loads menu">
          <select
            class="ui fluid last dropdown"
            v-model="defaults.service"
            @change="$emit('changedService', $event)"
          >
            <option value="all">All Services</option>
            <option
              v-for="{ id, service } in service_options"
              :value="id"
              :key="id"
            >
              {{ service }}
            </option>
          </select>
        </div>

        <div style="min-width: 120px" class="ui right floated last_loads menu">
          <select
            class="ui fluid last dropdown"
            v-model="defaults.date_range"
            @change="$emit('changedDate', $event)"
          >
            <option value="1_week">1 week</option>
            <option value="1_month">1 month</option>
            <option value="3_month">3 months</option>
            <option value="6_month">6 months</option>
            <option value="YTD">YTD</option>
          </select>
        </div>
      </div>

      <div class="ui active centered inline large loader" v-if="loading"></div>

      <Pie
        class="chart"
        style="height: 220px"
        :title="`Total Shipments: ${totals.total}`"
        :labels="Object.values(labels)"
        :datasets="datasets"
        :totals="totals"
        @legendReady="setLegend($event)"
        v-else
      />

      <div v-if="legend" v-html="legend" class="mt-2"></div>
    </div>
  </div>
</template>

<script>
import Pie from "@/components/charts/Pie.vue";
import _ from "lodash";
export default {
  components: {
    Pie,
  },
  data() {
    return {
      colors: {},
      legend: null,
      defaults: {
        service: "all",
        date_range: this.date_range_bounds,
      },
    };
  },
  props: [
    "labels",
    "data",
    "totals",
    "service_filter_options",
    "service_filter_selection",
    "loading",
    "dateRange",
    "date_range_bounds",
  ],
  computed: {
    datasets() {
      return [
        {
          label: Object.values(this.labels),
          backgroundColor: ["#d43452", "#28a745"],
          data: Object.values(this.data),
        },
      ];
    },
    service_options() {
      let options = Object.keys(this.service_filter_options).map((id) => {
        return { id, service: this.service_filter_options[id] };
      });

      return _.sortBy(options, ["service"]);
    },
  },
  methods: {
    setLegend(value) {
      this.legend = value;
    },
  },
};
</script>

<style lang="scss">
ul[class*="legend"] {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    & > span {
      width: 1rem;
      height: 1rem;
      display: inline-block;
      border: 1px solid #e5e5e5;
      margin-right: 2px;
    }
  }
}
</style>