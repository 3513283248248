<template>
  <div id="payment_history">
    
    <div class="ui grid form">
      <div class="four wide column"><h1>Payment History</h1></div>
      <div class="one wide column" id="accounts">Accounts: {{currentAccount}}</div>
      <div class="three wide column">
        <select class="ui fluid accounts dropdown" @change="changeAccount($event)">
          <option value="all">All Accounts</option>
          <option v-for="number in allowedNumbers" :value="number" :key="number">{{number}}</option>
        </select>
      </div>
      <div class="eight wide column button_column">
        <button class="ui right floated button sec" :class="{ loading: downLoading }" @click="downloadExcel()"><i class="fas fa-file-download"></i>Download Excel</button>
        <button class="ui right floated button prim" :class="{ loading: downLoading }" @click="downloadPdf()"><i class="fas fa-download"></i>Download PDF</button>
      </div>
    </div>

    <div class="ui divider"></div>

    <div class="ui active centered inline massive loader" v-if="loading"></div>

    <v-client-table class="payment_history_table table-striped" :data="payments" :columns="columns" :options="options" v-else>
      <div class="formatted_date_paid" slot="FormattedDatePaid" slot-scope="{row}" @click="showHawbs(row.CheckNbr, row.Number)">{{row.FormattedDatePaid}}</div>
      <div class="check_no" slot="CheckNbr" slot-scope="{row}" @click="showHawbs(row.CheckNbr, row.Number)">{{row.CheckNbr}}</div>
      <div class="amount" slot="Amount" slot-scope="{row}" @click="showHawbs(row.CheckNbr, row.Number)">{{row.Amount}}</div>
      <div class="hawb_count" slot="HawbCount" slot-scope="{row}" @click="showHawbs(row.CheckNbr, row.Number)">{{row.HawbCount}}</div>
    </v-client-table>

    <PaymentHistoryModal :hawbs="hawbs" />
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
import TableExport from 'tableexport';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import PaymentHistoryModal from '@/components/modals/PaymentHistoryModal.vue';

export default {
  components: {
    PaymentHistoryModal
  },
  data () {
    return {
      loading: true,
      downLoading: true,
      payments: [],
      hawbs: [],
      allowedNumbers: [],
      columns: [
        'FormattedDatePaid',
        'CheckNbr',
        'Amount',
        'HawbCount',
      ],
      options: {
        headings: {
          FormattedDatePaid: 'Date Paid',
          CheckNbr: 'Check No.',
          Amount: 'Amount',
          HawbCount: '# of Hwbs Paid',
        },
        sortable: ['FormattedDatePaid', 'CheckNbr', 'Amount', 'HawbCount'],
        filterable: ['FormattedDatePaid', 'CheckNbr', 'Amount', 'HawbCount'],
        perPage: 10,
        perPageValues: [10, 25, 50],
        texts: {
            filter: '',
            filterBy: 'Search by {column}',
            count: '',
            limit: ''
        },
        pagination: { chunk: 10, dropdown: false },
        sortIcon: {
          base : 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        },
        customSorting: {
          FormattedDatePaid: function (ascending) {
            return function (a, b) {
              let dateA = new Date(a.FormattedDatePaid);
              let dateB = new Date(b.FormattedDatePaid);

              if (ascending)
                  return dateA >= dateB ? 1 : -1;

              return dateA <= dateB ? 1 : -1;
            }
          },
          Amount: function(ascending) {
            return function (a, b) {
              let amountA = parseFloat(a.Amount.substr(1).replace(/,/g, ''));
              let amountB = parseFloat(b.Amount.substr(1).replace(/,/g, ''));

              if (ascending)
                  return amountA >= amountB ? 1 : -1;

              return amountA <= amountB ? 1 : -1;
            }
          },
          HawbCount: function(ascending) {
            return function (a, b) {
              let countA = parseInt(a.HawbCount);
              let countB = parseInt(b.HawbCount);

              if (ascending)
                  return countA >= countB ? 1 : -1;

              return countA <= countB ? 1 : -1;
            }
          }
        }
      }
    }
  },
  created () {
    let params = {
      customer_numbers: 'all'
    };

    this.$http.get('/billing/paymenthistory', {params})
    .then((response) => {
        this.allowedNumbers = response.data.allowed_numbers;
        this.payments = response.data.payments.map(function(payment) {
          return {
            "FormattedDatePaid": payment.FormattedDatePaid,
            "CheckNbr": payment.CheckNbr,
            "Amount": "$"+parseFloat(payment.Amount).toLocaleString('en-US', {minimumFractionDigits: 2}),
            "HawbCount": payment.HawbCount,
            "Number": payment.Number
          }
        });

        this.initiateDownloadButtons();

        this.loading = !this.loading;
    })
    .catch((error) => {
        this.errors = error;
    });
  },
  mounted () {
    $('.ui.fluid.dropdown').dropdown();
  },
  methods: {
    showHawbs: function(checkNbr, custNbr) {
      this.loading = !this.loading;

      let params = {
        check_nbr: checkNbr,
        cust_nbr: custNbr
      };

      this.$http.get('/billing/hawbs', {params})
      .then((response) => {
        this.hawbs = response.data;

        this.loading = !this.loading;
        $('.ui.hawbs.modal').modal('show');
      })
      .catch((error) => {
          this.errors = error;
      });
    },
    changeAccount: function(e) {
      this.loading = !this.loading;

      this.$http.get('/billing/paymenthistory?customer_number='+e.target.value)
      .then((response) => {
          this.allowedNumbers = response.data.allowed_numbers;
          this.payments = response.data.payments.map(function(payment) {
            return {
              "FormattedDatePaid": payment.FormattedDatePaid,
              "CheckNbr": payment.CheckNbr,
              "Amount": "$"+parseFloat(payment.Amount).toLocaleString('en-US', {minimumFractionDigits: 2}),
              "HawbCount": payment.HawbCount
            }
          });

          this.loading = !this.loading;
      })
      .catch((error) => {
          this.errors = error;
      });
    },
    initiateDownloadButtons: function() {
      // add id to base table to allow download
      // TODO: Change to on table load
      setTimeout(() => {
        $('.VueTables__table').attr("id", "payment_history_table");

        $('.VueTables__limit-field > select').addClass("ui fluid limit dropdown");

        $('.ui.fluid.dropdown').dropdown();

        $('.VueTables__search__input').attr("placeholder", "Search All...");

        this.downLoading = !this.downLoading;
      }, 1000);
    },
    downloadExcel: function() {
      let table = document.getElementById('payment_history_table');

      let excel = new TableExport(table, {
        formats: ["csv"],    // (String[]), filetype(s) for the export, (default: ['xlsx', 'csv', 'txt'])
        filename: "Payment_History",                     // (id, String), filename for the downloaded file, (default: 'id')
        bootstrap: false,                   // (Boolean), style buttons using bootstrap, (default: true)
        exportButtons: false,                // (Boolean), automatically generate the built-in export buttons for each of the specified formats (default: true)
        trimWhitespace: true,               // (Boolean), remove all leading/trailing newlines, spaces, and tabs from cell text in the exported file(s) (default: false)
        sheetname: "payment_history"                     // (id, String), sheet name for the exported spreadsheet, (default: 'id')
      });

      let exportData = excel.getExportData()['payment_history_table']['csv'];

      excel.export2file(exportData.data, exportData.mimeType, exportData.filename, exportData.fileExtension);
    },
    downloadPdf: function() {
      const doc = new jsPDF();

      doc.autoTable({ 
        html: '#payment_history_table',
        styles: { minCellWidth: 19 }
      });

      doc.save('Payment_History.pdf');
    }
  },
  computed: {
    currentAccount: function() {
      return $('.ui.fluid.accounts.dropdown').val();
    }
  }
};
</script>


<style lang="scss">
#payment_history {
  margin: $layoutmargin;

  #accounts {
    padding-top: 22px;
  }

  .ui.fluid.dropdown {
    width: 125px;
    height: 40px;
    border-radius: 20px !important;
    border: $darkblueborder;
    margin-left: 10px;
  }

  .ui.grid.form {
    max-width: unset;
  }

  .ui.button.pdf {
    background-color: $algred;
  }

  .ui.button.excel {
    background-color: $algdarkblue;
  }

  select {
    width: 125px;
    height: 40px;
    border-radius: 20px !important;
  }

  .VueTables__search {
    float: right !important;
  }

  .VueTables__search__input {
    width: 350px;
    height: 40px;
    border: $darkblueborder;
  }

  .ui.fluid.limit.dropdown {
    width: 60px;
    height: 40px;
    border-radius: 20px !important;
    border: $darkblueborder;
    margin-left: 10px;
  }

  .payment_history_table {
    border: none;
    box-shadow: none;

    thead {
      tr {
        th {
            color: $algdarkblue;
            background-color: $algdarkbluetenopac;
            border: none;
        }

        th:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        th:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
      }
    }

    tbody {
      tr {
        td:first-child {
          border-top-left-radius: 8px !important;
          border-bottom-left-radius: 8px !important;
        }
        td:last-child {
          border-top-right-radius: 8px !important;
          border-bottom-right-radius: 8px !important;
        }

        td {
            border: none;
        }
      }
      tr:nth-child(odd) {
          background-color: #fff;
      }

      tr:nth-child(even) {
          background-color: rgba(0,0,0,.05);
      }
    }
  }
}
</style>

