import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";

// auth
import LogIn from "../views/auth/LogIn";
import LogOut from "../views/auth/LogOut";
import ResetPassword from "../views/auth/ResetPassword";

// dashboard
import AdminDashboard from "../views/admin/Dashboard";
import UserDashboard from "../views/Dashboard";

// Account
import Account from "../views/Account";

// tracking
import TrackingReport from "../views/tracking/TrackingReport";
import TrackingDetails from "../views/tracking/TrackingDetails";

// shipment
import ShipmentCreate from "../views/shipments/ShipmentCreate";

// billing
import AccountSummary from "../views/billing/AccountSummary";
import PaymentHistory from "../views/billing/PaymentHistory";
import OpenInvoices from "../views/billing/OpenInvoices";
import OpenInvoiceHawbs from "../views/billing/OpenInvoiceHawbs";

// users
import UserIndex from "../views/admin/users/UserIndex";
import UserCreate from "../views/admin/users/UserCreate";
import UserShow from "../views/admin/users/UserShow";
import UserEdit from "../views/admin/users/UserEdit";

// notificationss
import NotificationIndex from "../views/admin/notifications/NotificationIndex";
import NotificationInactive from "../views/admin/notifications/NotificationInactive";
import NotificationCreate from "../views/admin/notifications/NotificationCreate";

// companies
import CompanyIndex from "../views/admin/companies/CompanyIndex";
import CompanyCreate from "../views/admin/companies/CompanyCreate";
import CompanyEdit from "../views/admin/companies/CompanyEdit";

// customernumbers
import CustomerNumberIndex from "../views/admin/customernumbers/CustomerNumberIndex";
import CustomerNumberCreate from "../views/admin/customernumbers/CustomerNumberCreate";
import CustomerNumberEdit from "../views/admin/customernumbers/CustomerNumberEdit";

// statuses
import StatusIndex from "../views/admin/statuses/StatusIndex";
import StatusCreate from "../views/admin/statuses/StatusCreate";
import StatusEdit from "../views/admin/statuses/StatusEdit";

import ControlsCreate from "../views/admin/controls/ControlsCreate.vue";

import DotStatusCreate from "../views/admin/dotstatuses/DotStatusCreate";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "root",
    component: LogIn, 
    meta: { 
      requiresAuth: false 
    }
  },
  {
    path: "/login",
    name: "login",
    component: LogIn, 
    meta: { 
      requiresAuth: false 
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: LogOut, 
    meta: { 
      requiresAuth: true
    }
  },
  // { 
  //   path: '/reset-password', 
  //   name: 'reset-password', 
  //   component: ForgotPassword, 
  //   meta: { 
  //     auth:false 
  //   } 
  // },
  { 
    path: '/reset-password/:token', 
    name: 'reset-password-form', 
    component: ResetPassword, 
    meta: { 
      requiresAuth: false 
    }
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: UserDashboard, 
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: "/account",
    name: "account",
    component: Account, 
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: "/billing/accountsummary",
    name: "billing_account_summary",
    component: AccountSummary, 
    meta: { 
      requiresAuth: true,
      requiresPermission: 'account_summary'
    }
  },
  {
    path: "/billing/paymenthistory",
    name: "billing_payment_history",
    component: PaymentHistory, 
    meta: { 
      requiresAuth: true,
      requiresPermission: 'payment_history'
    }
  },
  {
    path: "/billing/openinvoices",
    name: "billing_open_invoices",
    component: OpenInvoices, 
    meta: { 
      requiresAuth: true,
      requiresPermission: 'open_invoices'
    }
  },
  {
    path: "/billing/openinvoice/hawbs",
    name: "billing_open_invoice_hawbs",
    component: OpenInvoiceHawbs, 
    meta: { 
      requiresAuth: true,
      requiresPermission: 'open_invoices'
    }
  },
  {
    path: "/tracking/report",
    name: "tracking_report",
    component: TrackingReport, 
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: "/tracking/details",
    name: "tracking_details",
    component: TrackingDetails, 
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: "/shipment/create",
    name: "shipment_create",
    component: ShipmentCreate, 
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: "/admin/dashboard",
    name: "admin_dashboard",
    component: AdminDashboard,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/admin/users",
    name: "admin_user_index",
    component: UserIndex,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/admin/users/create",
    name: "admin_user_create",
    component: UserCreate,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/admin/users/show",
    name: "admin_user_show",
    component: UserShow,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/admin/users/:id/edit",
    name: "admin_user_edit",
    component: UserEdit,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/admin/companies",
    name: "admin_company_index",
    component: CompanyIndex,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/admin/companies/create",
    name: "admin_company_create",
    component: CompanyCreate,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/admin/companies/:id/edit",
    name: "admin_company_edit",
    component: CompanyEdit,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/admin/customernumbers",
    name: "admin_customernumber_index",
    component: CustomerNumberIndex,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/admin/customernumbers/create",
    name: "admin_customernumber_create",
    component: CustomerNumberCreate,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/admin/customernumbers/:id/edit",
    name: "admin_customernumber_edit",
    component: CustomerNumberEdit,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/admin/controlslocations",
    name: "admin_controls_locations_create",
    component: ControlsCreate,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/admin/statuses",
    name: "admin_statuses_index",
    component: StatusIndex,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/admin/statuses/create",
    name: "admin_statuses_create",
    component: StatusCreate,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/admin/statuses/:id/edit",
    name: "admin_statuses_edit",
    component: StatusEdit,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/admin/dotstatuses/",
    name: "dot_statuses",
    component: DotStatusCreate,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/admin/notifications",
    name: "admin_notification_index",
    component: NotificationIndex,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/admin/notifications/inactive",
    name: "admin_notification_inactive",
    component: NotificationInactive,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/admin/notifications/create",
    name: "admin_notification_create",
    component: NotificationCreate,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  // keep for reference
  // {
  //   path: "/reports/tracking",
  //   name: "reports/tracking",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/TrackingReport.vue")
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  
  // // redirect user to dashboard if logged in
  if (to.name == 'root' && store.state.user) next({ name: 'dashboard' })

  // redirect user to root if not logged in 
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.user) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }

  // // redirect user to dash if not admin
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.user.admin) {
      next({
        path: '/dashboard',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }

  if (to.matched.some(record => record.meta.requiresPermission)) {
    // this route requires permission, check if you have it
    // if not, redirect to dashboard page.
    switch(to.meta.requiresPermission) {
      case 'account_summary':
        if (!store.state.user.account_summary) {
          next({
            path: '/dashboard',
            query: { redirect: to.fullPath }
          })
        } else {
          next()
        }
        break;
      case 'payment_history':
        if (!store.state.user.payment_history) {
          next({
            path: '/dashboard',
            query: { redirect: to.fullPath }
          })
        } else {
          next()
        }
        break;
      case 'open_invoices':
        if (!store.state.user.open_invoices) {
          next({
            path: '/dashboard',
            query: { redirect: to.fullPath }
          })
        } else {
          next()
        }
        break;
      default:
        next()
    }

  } else {
    next() // make sure to always call next()!
  }


  

});

export default router;
