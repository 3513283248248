<template>
  <div id="customer_number_create">
    <h1 class="ui dividing header">Add Status Friendly Name</h1>
    <div class="ui form">
      <div class="ui form">
        <div class="inline field">
          <label>Dot 1:</label>
          <input
            type="text"
            placeholder="Value"
            v-model="dot_1_value"
          />
        </div>
        <div class="inline field">
          <label>Dot 2:</label>
          <input
            type="text"
            placeholder="Value"
            v-model="dot_2_value"
          />
        </div>
        <div class="inline field">
          <label>Dot 3:</label>
          <input
            type="text"
            placeholder="Value"
            v-model="dot_3_value"
          />
        </div>
        <div class="inline field">
          <label>Dot 4:</label>
          <input
            type="text"
            placeholder="Value"
            v-model="dot_4_value"
          />
        </div>
      </div>
      <div class="inline field buttons">
        <button class="ui left floated button sec" @click="cancel()">
          <i class="fas fa-times"></i>Cancel
        </button>
        <button class="ui right floated button prim" @click="editStatus">
          <i class="fas fa-check"></i>Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dot_1_value: null,
      dot_2_value: null,
      dot_3_value: null,
      dot_4_value: null,
      errors: []
    };
  },
  created() {
    this.$http
      .get("/dotstatuses/create")
      .then(response => {
        // eslint-disable-next-line no-console
        console.log(response.data);
        this.dot_1_value = response.data.dot_1;
        this.dot_2_value = response.data.dot_2;
        this.dot_3_value = response.data.dot_3;
        this.dot_4_value = response.data.dot_4;

        this.loading = !this.loading;
      })
      .catch(error => {
        this.errors = error;
      });
  },
  mounted() {},
  methods: {
    editStatus: function() {
      let params = {
        dot_number: "all",
        dot_1: this.dot_1_value,
        dot_2: this.dot_2_value,
        dot_3: this.dot_3_value,
        dot_4: this.dot_4_value
      };

      this.$http
        .patch("/dotstatuses/edit", params)
        .then(() => {
          this.router.push("/admin/dashboard");
        })
        .catch(error => {
          this.errors = error;
        });
    },
    cancel: function() {
      this.router.push("/admin/dashboard");
    }
  }
};
</script>

<style lang="scss">
#customer_number_create {
  margin: $layoutmargin;

  .ui.form {
    margin-top: 20px;
    padding-left: 24px;

    label {
      font-size: 16px;
      font-weight: bold;
      color: $algdarkblue;
      width: 130px;
    }

    input {
      width: 300px;
      border: $darkblueborder;
    }

    select {
      width: 300px;
      border: $darkblueborder;
    }

    .inline.field.buttons {
      width: 445px;
      margin-top: 54px;
    }
  }
}
</style>
