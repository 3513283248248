<template>
  <div id="account_summary">
    <div class="ui grid form">
      <div class="five wide column"><h1>Account Summary</h1></div>
      <div class="one wide column" id="accounts">Accounts:</div>
      <div class="three wide column">
        <select class="ui fluid dropdown" @change="changeAccount($event)">
          <option value="all">All Accounts</option>
          <option v-for="number in allowedNumbers" :value="number" :key="number">{{number}}</option>
        </select>
      </div>
      <div class="seven wide column button_column">
        <button class="ui button prim" :class="{ loading: loading }" @click="print()"><i class="fas fa-print"></i>Print</button>
        <button class="ui button sec" :class="{ loading: loading }" @click="downloadPdf()"><i class="fas fa-download"></i>Download PDF</button>
      </div>
    </div>

    <div class="ui active centered inline massive loader" v-if="loading"></div>

    <div class="summary" id="summary" v-else>
      <h2 class="ui header">Billing Statement Summary</h2>
      <div class="ui three column grid">
        <div class="column">
          <label>Date:</label><br>
          <p>{{moment().format('MM/DD/YYYY')}}</p>
        </div>
        <div class="column">
          <label>Customer Number:</label><br>
          <p>{{data.customer_number}}</p>
        </div>
        <div class="column">
          <label>Billing Address:</label><br>
          <p v-if="data.Address1">
            {{data.Address1}} <br>
            {{data.Address2}} <br>
            {{data.City + ', '}} {{data.State}} {{data.Zip}}
          </p>
        </div>
      </div>

      <h2 class="ui header">Amount Due Summary (USD)</h2>
      <div class="ui five column grid">
        <div class="two wide column">
          <label>0 - 30 Days</label><br>
          <p>${{data.Balance0_30.toLocaleString('en-US', {minimumFractionDigits: 2})}}</p>
        </div>
        <div class="two wide column">
          <label>31 - 60 Days</label><br>
          <p>${{data.Balance30_60.toLocaleString('en-US', {minimumFractionDigits: 2})}}</p>
        </div>
        <div class="two wide column">
          <label>61 - 90 Days</label><br>
          <p>${{data.Balance60_90.toLocaleString('en-US', {minimumFractionDigits: 2})}}</p>
        </div>
        <div class="two wide column">
          <label>91+ Days</label><br>
          <p>${{data.Balance90Plus.toLocaleString('en-US', {minimumFractionDigits: 2})}}</p>
        </div>
        <div class="two wide column">
          <label>Total:</label><br>
          <p>${{data.total.toLocaleString('en-US', {minimumFractionDigits: 2})}}</p>
        </div>
      </div>

      <h2 class="ui header">Last Payment</h2>
      <div class="ui three column grid">
        <div class="column">
          <label>Date:</label><br>
          <p>{{data.LastPaidDate}}</p>
        </div>
        <div class="column">
          <label>Check Number:</label><br>
          <p>{{data.LastCheckNbr}}</p>
        </div>
        <div class="column">
          <label>Amount:</label><br>
          <p v-if="data.Amount">${{parseFloat(data.Amount).toLocaleString('en-US', {minimumFractionDigits: 2})}} {{data.Currency}}</p>
        </div>
      </div>
      
      <h2 class="ui header" v-if="invoiceType=='master'">Number of Open Master Invoices: {{Object.keys(openInvoices).length}}</h2>
      <h2 class="ui header" v-else>Number of Open Invoices: {{Object.keys(openInvoices).length}}</h2>
      <div class="ui one column grid">
        <div class="column">
          <label>Questions?</label><br>
          <a href="tel:+17043933888" class="phone_number">{{(data.control) ? data.control.PhoneNbr : '800-323-0289'}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'print-js';

export default {
  components: {
  },
  data () {
    return {
      loading: true,
      data: [],
      allowedNumbers: [],
      openInvoices: [],
      invoiceType: ''
    }
  },
  created () {
    this.$http.get('/billing/accountsummary?customer_numbers=all')
    .then((response) => {
      this.allowedNumbers = response.data.allowed_numbers;
      this.data = response.data.data;

      this.openInvoices = this.data.open_invoices;
      this.invoiceType = this.data.InvoiceType;
      
      this.loading = !this.loading;
    })
    .catch((error) => {
      this.errors = error;
    });
  },
  mounted () {
    $('.ui.fluid.dropdown')
    .dropdown();
  },
  methods: {
    changeAccount: function(e) {
      this.loading = !this.loading;
      
      this.$http.get('/billing/accountsummary?customer_numbers='+e.target.value)
      .then((response) => {
        this.allowedNumbers = response.data.allowed_numbers;
        this.data = response.data.data;

        this.openInvoices = this.data.open_invoices;
        this.invoiceType = this.data.InvoiceType;
        
        this.loading = !this.loading;
      })
      .catch((error) => {
        this.errors = error;
      });
    },
    print: function() {
      html2canvas(document.getElementById('summary')).then(function(canvas) {
        const doc = new jsPDF();
        const img = canvas.toDataURL("image/png", 1);
        printJS(img, 'image');
      });
    },
    downloadPdf: function() {
      this.loading = !this.loading;
      this.$http.get('/billing/accountsummary/pdf', {responseType: 'blob'})
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Account_Summary.pdf');
        document.body.appendChild(link);
        link.click();

        this.loading = !this.loading;
      })
      .catch((error) => {
        this.errors = error;
      });
    }
  },
  computed: {
  }
};
</script>


<style lang="scss">
#account_summary {
  margin: $layoutmargin;

  #accounts {
    padding-top: 22px;
  }

  .summary {
    margin-top: 20px;
  }

  .ui.fluid.dropdown {
    width: 125px;
    height: 40px;
    border-radius: 20px !important;
    border: $darkblueborder;
    margin-left: 10px;
  }

  .ui.header {
    padding: 14px 0px 14px 16px;
    background-color: #F4F4F4;
    border-radius: 8px;
  }

  label {
    color: $algdarkblue;
    padding-left: 15px;
    padding-top: 10px;
  }

  p, a {
    padding-left: 15px;
  }

  .ui.five.column.grid {
    .two.wide.column {
      margin-right: 50px;
    }
  }

  .phone_number {
    color: $algred !important;
    text-decoration: underline;
  }
}
</style>
