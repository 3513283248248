<template>
  <div id="customer_number_create">
    <h1 class="ui dividing header">Add Status Friendly Name</h1>
    <div class="ui form">
      <div class="inline field">
        <label>Status Names</label>
        <select
          class="ui fluid search dropdown status_names"
          v-model="real_status_name"
        >
          <option :value="null"></option>
          <option v-for="num in statusOptions" :key="num" :value="num">
            {{ num }}
          </option>
        </select>
      </div>
      <div class="ui form">
        <div class="inline field">
          <label>Friendly Status Name:</label>
          <input
            type="text"
            placeholder="Name"
            v-model="friendly_status_name"
          />
        </div>
      </div>
      <div class="inline field buttons">
        <button class="ui left floated button sec" @click="cancel()">
          <i class="fas fa-times"></i>Cancel
        </button>
        <button class="ui right floated button prim" @click="createStatus">
          <i class="fas fa-check"></i>Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      statusOptions: [],
      real_status_name: null,
      friendly_status_name: null,
      errors: []
    };
  },
  created() {
    this.$http
      .get("/statuses/create")
      .then(response => {
        // eslint-disable-next-line no-console
        console.log(response.data);
        this.statusOptions = response.data;

        this.loading = !this.loading;
      })
      .catch(error => {
        this.errors = error;
      });
  },
  mounted() {},
  methods: {
    createStatus: function() {
      let params = {
        real_status_name: this.real_status_name,
        friendly_status_name: this.friendly_status_name
      };

      this.$http
        .post("/statuses", params)
        .then(() => {
          this.router.push("/admin/statuses");
        })
        .catch(error => {
          this.errors = error;
        });
    },
    cancel: function() {
      this.router.push("/admin/statuses");
    }
  }
};
</script>

<style lang="scss">
#customer_number_create {
  margin: $layoutmargin;

  .ui.form {
    margin-top: 20px;
    padding-left: 24px;

    label {
      font-size: 16px;
      font-weight: bold;
      color: $algdarkblue;
      width: 130px;
    }

    input {
      width: 300px;
      border: $darkblueborder;
    }

    select {
      width: 300px;
      border: $darkblueborder;
    }

    .inline.field.buttons {
      width: 445px;
      margin-top: 54px;
    }
  }
}
</style>
