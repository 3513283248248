<template>
    <div class="ui forgot_password modal">
        <div class="header">Forgot Your Password? <i class="close icon" @click="close()"></i></div>
        <div class="content">
            <div class="ui two column grid">
                <div class="column">
                    <p>Please enter the email address associated with your account.</p>

                    <div class="ui form">
                        <label for=""></label>
                        <input type="text" name="email" placeholder="Email" v-model="email">

                        <button class="ui button left floated sec" @click="close()"><i class="fa fa-times"></i> Cancel</button>
                        <button class="ui button right floated prim" @click="submit()"><i class="fa fa-check"></i> Submit</button>
                    </div>
                </div>
                <div class="column">
                    <img class="logo" src="../../assets/images/ALG_logo@2x.png" />
                </div>
            </div>
        </div>
    </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
export default {
  data () {
    return {
        email: ''
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    submit: function() {
        let params = {
            email: this.email
        };

        this.$http.post('/auth/reset-password', params)
        .then(() => {
            $('.ui.forgot_password.ui.modal').modal('hide');
        })
        .catch(error => {

        });

    },
    close: function () {
      $('.ui.forgot_password.modal').modal('hide');
    }
  },
  computed: {
  }
};
</script>


<style lang="scss">
.ui.forgot_password.modal {
    margin: 13% auto !important;
    padding-left: 40px;
    padding-top: 40px;

    .header {
        color: $algdarkblue !important;
        border-bottom: none !important;
        

        .close.icon {
            color: $algdarkblue !important;
            padding-right: 40px;
        }
    }

    .logo {
        display: block;
        margin: auto;
    }

    button {
        margin-top: 20px;
        margin-right: 0;
    }
}
</style>