<template>
  <div id="admin_dash">
    <h1 id="header">Dashboard</h1>
    <div class="ui active centered inline massive loader" v-if="loading"></div>
    <div v-else>
      <div class="ui three column grid centered">
        <div class="column">
          <router-link to="/admin/users">
            <div class="ui link card">
              <div class="content">
                <h1>{{ data.users.length }}</h1>
                <h2>USERS</h2>
                <p>Preview and edit details, add or delete users here.</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="column">
          <router-link to="/admin/companies">
            <div class="ui link card">
              <div class="content">
                <h1>{{ data.companies.length }}</h1>
                <h2>COMPANIES</h2>
                <p>Manage all of your companies from here.</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="column">
          <router-link to="/admin/customernumbers">
            <div class="ui link card">
              <div class="content">
                <h1>{{ data.customer_numbers.length }}</h1>
                <h2>CUSTOMER NUMBERS</h2>
                <p>Add, edit, and delete all customer numbers from here.</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="column">
          <router-link to="/admin/statuses">
            <div class="ui link card">
              <div class="content">
                <h1>{{ data.statuses.length }}</h1>
                <h2>STATUSES</h2>
                <p>Add, edit, and delete all friendly status names from here.</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="column">
          <router-link to="/admin/dotstatuses">
            <div class="ui link card">
              <div class="content">
                <h1>4</h1>
                <h2>Dot Statuses</h2>
                <p>Edit dot statuses from here.</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="column">
          <router-link to="/admin/controlslocations">
            <div class="ui link card">
              <div class="content">
                <h1>{{ data.controls.length }}</h1>
                <h2>Controls</h2>
                <p>Disable Location History by Control here.</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <!-- <div class="ui column grid">
        <div class="five wide column">
          <div class="ui link card">
            <div class="content">
              <h1>0</h1>
              <h2>SHIPMENTS CREATED</h2>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      data: [],
      errors: []
    };
  },
  created() {},
  mounted() {
    this.$http
      .get("/dashboard/admin")
      .then(response => {
        this.data = response.data;
        this.loading = !this.loading;
      })
      .catch(error => {
        this.errors = error;
      });
  },
  methods: {}
};
</script>

<style lang="scss">
.ui.inline.loader {
  margin-top: 15% !important;
}

#admin_dash {
  padding: 25px 40px 25px 40px;

  #header {
    padding-bottom: 20px;
  }

  .column {
    a {
      text-decoration: none !important;
    }

    .ui.card {
      width: 100%;
      background-color: #f4f4f4;
      border-radius: 8px;
      box-shadow: none;
      padding: 41px 40px 41px 40px;

      h1 {
        color: $alglightblue !important;
      }

      .content {
        padding: 0;
      }

      &:hover {
        background-color: #fff;
        box-shadow: 0px 3px 20px #00000033;
      }
    }
  }
}
</style>
