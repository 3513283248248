<template>
  <div id="customer_number_create">
    <h1 class="ui dividing header">Add Customer Number</h1>
    <div class="ui form">
      <div class="inline field">
        <label>Number:</label>
        <input type="text" placeholder="Number" v-model="number" />
      </div>
      <div class="inline field buttons">
        <button class="ui left floated button sec" @click="cancel()">
          <i class="fas fa-times"></i>Cancel
        </button>
        <button
          class="ui right floated button prim"
          @click="createCustomerNumber"
        >
          <i class="fas fa-check"></i>Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      number: "",
      errors: []
    };
  },
  mounted() {},
  methods: {
    createCustomerNumber: function() {
      let params = {
        number: this.number
      };

      this.$http
        .post("/customernumbers", params)
        .then(() => {
          this.router.push("/admin/customernumbers");
        })
        .catch(error => {
          this.errors = error;
        });
    },
    cancel: function() {
      this.router.push("/admin/customernumbers");
    }
  }
};
</script>

<style lang="scss">
#customer_number_create {
  margin: $layoutmargin;

  .ui.form {
    margin-top: 20px;
    padding-left: 24px;

    label {
      font-size: 16px;
      font-weight: bold;
      color: $algdarkblue;
      width: 130px;
    }

    input {
      width: 300px;
      border: $darkblueborder;
    }

    select {
      width: 300px;
      border: $darkblueborder;
    }

    .inline.field.buttons {
      width: 445px;
      margin-top: 54px;
    }
  }
}
</style>
