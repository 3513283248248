<template>
  <div id="dashboard">
    <Admin v-if="user && user.admin" />
  </div>
</template>

<script>
import Admin from "@/components/dashboards/Admin.vue";

export default {
  components: {
    Admin
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  }
};
</script>
