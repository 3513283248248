<template>
  <div class="ui columns_modal modal">
    <div class="header">
      Column Chooser <i class="close icon" @click="close()"></i>
    </div>
    <div class="content">
      <div class="ui grid">
        <div
          v-for="col in possibleHeadings"
          :key="col.colId"
        >
          <div v-if="col.colId != 'totalRevenue' || totalRevenue" class="sixteen wide column">
            <div  class="inline field">
              <input
                type="checkbox"
                class="columns"
                :name="col.colId"
                :checked="col.visible"
              />
              <label>{{ col.colDef.headerName }}</label>
            </div>
          </div>
        </div>
      </div>
      <button class="ui button tert" @click="apply()">
        <i class="fas fa-check"></i>Apply
      </button>
    </div>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
export default {
  props: {
    possibleHeadings: Array,
    totalRevenue: Number
  },
  data() {
    return {
      checkedColumns: [],
      allColumns: []
    };
  },
  created() {},
  mounted() {},
  methods: {
    apply: function() {
      this.allColumns = $(".columns")
        .map(function() {
          return this.name;
        })
        .toArray();
      //console.log(this.allColumns);
      // map over checked inputs
      this.checkedColumns = $("input:checked")
        .map(function() {
          return this.name;
        })
        .toArray();

      // emit clicked function on parent component
      this.$emit("clicked", this.allColumns, this.checkedColumns);
      //console.log(this.checkedColumns);
    },
    close: function() {
      $(".ui.columns_modal.modal").modal("hide");
    }
  },
  computed: {}
};
</script>

<style lang="scss">
.columns_modal {
  max-width: 300px;
  overflow: auto;

  .header {
    color: $algdarkblue !important;
    padding-top: 30px !important;
    padding-left: 50px !important;
    border-bottom: none !important;
    margin-bottom: -10px !important;

    .close.icon {
      color: $algdarkblue !important;
    }
  }

  .ui.button.tert {
    margin-top: 30px !important;
    margin: auto;
    display: block;
  }

  input {
    margin-left: 30px;
    margin-right: 30px;
    border: $darkblueborder !important;
  }

  label {
    color: $algdarkblue;
    font-weight: 600;
  }
}
</style>
