<template>
    <div class="ui reset_password modal">
        <div class="header">Reset Password <i class="close icon" @click="close()"></i></div>
        <div class="content">
            <div class="ui two column grid">
                <div class="column">
                    <p>Are you sure you want to reset your password?</p>

                    <div class="ui form">
                        <button class="ui button left floated sec" @click="close()"><i class="fa fa-times"></i> Cancel</button>
                        <button class="ui button right floated prim" @click="submit()"><i class="fa fa-envelope"></i> Send Email</button>
                    </div>
                </div>
                <div class="column">
                    <img class="logo" src="../../assets/images/ALG_logo@2x.png" />
                </div>
            </div>
        </div>
    </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
export default {
  data () {
    return {
      email: '',
      password: '',
      passwordConfirmation: ''
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    submit: function () {
      let params = {
        email: this.$store.state.user.email
      };

      this.$http.post('/auth/reset-password', params)
      .then(() => {
        $('.ui.reset_password.modal').modal('hide');
      })
      .catch(error => {

      });
    },
    close: function () {
      $('.ui.reset_password.modal').modal('hide');
    }
  },
  computed: {
  }
};
</script>


<style lang="scss">
.ui.reset_password.modal {
  margin: 13% auto !important;
  padding-left: 40px;
  padding-top: 40px;

  .header {
        color: $algdarkblue !important;
        border-bottom: none !important;
        

        .close.icon {
            color: $algdarkblue !important;
            padding-right: 40px;
        }
    }

    .logo {
        display: block;
        margin: auto;
    }

    button {
        margin-top: 20px;
        margin-right: 0;
    }
}
</style>