<template>
    <div class="ui update_logo modal">
        <div class="header">Update Logo <i class="close icon" @click="close()"></i></div>
        <div class="content">
          <div class="ui two column grid">
                <div class="column">
                    <div class="ui form">
                        <label for=""></label>
                        <input type="file" name="logo" placeholder="Logo" @change="processLogo($event)" ref="logo">

                        <button class="ui button left floated sec" @click="close()"><i class="fa fa-times"></i> Cancel</button>
                        <button class="ui button right floated prim" @click="submit()"><i class="fa fa-check"></i> Submit</button>
                    </div>
                </div>
                <div class="column">
                    <img class="logo" src="../../assets/images/ALG_logo@2x.png" />
                </div>
            </div>

            {{data}}
        </div>
    </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
export default {
  props: {
  },
  data () {
    return {
      data: [],
      logo: null
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    processLogo(e) {
      // let files = e.target.files;
      // if (files.length > 0) this.logo = files[0];
      this.logo = this.$refs.logo.files[0];
    },
    submit: function () {
      // let params = {
      //   user_id: this.$store.state.user.id,
      //   logo: logo
      // };
      var formData = new FormData();
      formData.append("user_id", this.$store.state.user.id);
      formData.append("logo", this.logo);

      this.$http.post('/users/logo', formData, 
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        this.data = response.data;

      // $('.ui.update_logo.modal').modal('hide');
      })
      .catch(() => {

      });

    },
    close: function () {
      $('.ui.update_logo.modal').modal('hide');
    }
  },
  computed: {
  }
};
</script>


<style lang="scss">
.ui.update_logo.modal {
  margin: 10rem auto !important;

  .header {
    color: $algdarkblue !important;
    border-bottom: none !important;

    .close.icon {
        color: $algdarkblue !important;
        padding-right: 40px;
    }
  }

    .logo {
        display: block;
        margin: auto;
    }

    button {
        margin-top: 20px;
        margin-right: 0;
    }
}
</style>