<template>
  <div id="customernumber_edit">
    <h1 class="ui dividing header">Edit Customer Number</h1>
    <div class="ui form">
      <div class="inline field">
        <label>Number:</label>
        <div class="ui icon input" :class="{ loading: loading }">
          <input type="text" placeholder="Name" v-model="number" />
          <i class="icon"></i>
        </div>
      </div>
      <div class="inline field">
        <label>Hide Status History:</label>
        <input
          type="checkbox"
          name="tracking_history"
          v-model="trackingHistory"
        />
      </div>
      <div class="inline field">
        <label>Show Miles:</label>
        <input type="checkbox" name="tracking_history" v-model="showMiles" />
      </div>
      <div class="inline field">
        <label>Hide Location History:</label>
        <input
          type="checkbox"
          name="location_history"
          v-model="locationHistory"
        />
      </div>
      <div class="inline field buttons">
        <button class="ui left floated button sec" @click="cancel">
          <i class="fas fa-times"></i>Cancel
        </button>
        <button class="ui right floated button prim" @click="update()">
          <i class="fas fa-check"></i>Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      number: "",
      trackingHistory: false,
      showMiles: false,
      locationHistory: false,
      totalRevenue: false,
      errors: []
    };
  },
  created() {
    this.$http
      .get("/customernumbers/" + this.$route.params.id + "/edit")
      .then(response => {
        this.number = response.data.number;
        this.trackingHistory = response.data.tracking_history;
        this.showMiles = response.data.show_miles;
        this.locationHistory = response.data.location_history;

        this.loading = !this.loading;
      })
      .catch(error => {
        this.errors = error;
      });
  },
  methods: {
    update: function() {
      let params = {
        number: this.number,
        tracking_history: this.trackingHistory,
        show_miles: this.showMiles,
        location_history: this.locationHistory,
      };

      this.$http
        .patch("/customernumbers/" + this.$route.params.id, params)
        .then(() => {
          this.router.push("/admin/customernumbers");
        })
        .catch(error => {
          this.errors = error;
        });
    },
    cancel: function() {
      this.router.push("/admin/customernumbers");
    }
  }
};
</script>

<style lang="scss">
#customernumber_edit {
  margin: $layoutmargin;

  .ui.form {
    margin-top: 20px;
    padding-left: 24px;

    label {
      font-size: 16px;
      font-weight: bold;
      color: $algdarkblue;
      width: 145px;
    }

    input {
      width: 300px;
      border: $darkblueborder;
    }

    select {
      width: 300px;
      border: $darkblueborder;
    }

    .inline.field.buttons {
      width: 430px;
      margin-top: 54px;
    }
  }
}
</style>
