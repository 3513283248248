/* eslint-disable no-console */

import 'babel-polyfill';
import "@/assets/scss/styles.scss";

import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from "vuex";
import axios from "axios";
import Multiselect from "vue-multiselect";
import { ClientTable } from "vue-tables-2";
import moment from "moment";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import AsyncComputed from "vue-async-computed";

import VTooltip from 'v-tooltip';

import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/src/components/cluster';
window.Vue = Vue;
Vue.use(VTooltip);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyD6_HNglfB_gkEzp92K_tVsEx8yMQC4rno",
    libraries: 'places,geometry', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
});

Vue.component('GmapCluster', GmapCluster);


// Default production tip
Vue.config.productionTip = false;

// Globalize router
Vue.prototype.router = router;

// Globalize moment
Vue.prototype.moment = moment;

// Globalize env and set api url
let env = process.env.VUE_APP_ENV;
let url = process.env.VUE_APP_API_URL;

// Global axios config
Vue.prototype.$http = axios.create({
  baseURL: url
});

// register VueRouter
Vue.use(VueRouter);

// register Vuex
Vue.use(Vuex);



// register client table for vue-tables-2
Vue.use(ClientTable, {}, false, "bootstrap4");

// register multiselect
Vue.component("multiselect", Multiselect);

Vue.use(AsyncComputed);

// Initiate Vue app
new Vue({
  router,
  store,
  created: function () {
    if (store.state.user) {
      this.$http.defaults.headers.common["Authorization"] =
        "Bearer " + store.state.user.api_token;
    }
  },
  render: h => h(App)
}).$mount("#app");
