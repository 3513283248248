<template>
  <div class="ui shipper_add modal">
    <div class="header">
      Add a Shipper <i class="close icon" @click="close()"></i>
    </div>
    <div class="content">
      <div class="ui active centered inline large loader" v-if="loading"></div>
      <div class="ui two column grid" v-else>
        <div class="nine wide column">
          <div class="ui form grid">
            <div class="four wide column">
              <label for="">Name</label>
            </div>
            <div class="twelve wide column">
              <input type="text" name="shipper_name" v-model="addShipperName" />
            </div>

            <div class="four wide column">
              <label for="">Address:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="shipper_address"
                v-model="addShipperAddress"
              />
            </div>
            <div class="four wide column"></div>
            <div class="twelve wide column">
              <input
                type="text"
                name="shipper_address2"
                v-model="addShipperAddress2"
              />
            </div>

            <div class="four wide column">
              <label for="">City:</label>
            </div>
            <div class="twelve wide column">
              <input type="text" name="shipper_city" v-model="addShipperCity" />
            </div>

            <div class="four wide column">
              <label for="">State:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="shipper_state"
                v-model="addShipperState"
                maxlength="2"
                placeholder="Example: IL"
              />
            </div>

            <div class="four wide column">
              <label for="">Zip Code:</label>
            </div>
            <div class="twelve wide column">
              <input type="text" name="shipper_zip" v-model="addShipperZip" />
            </div>

            <div class="four wide column">
              <label for=""><span class="asterik">*</span>Country:</label>
            </div>
            <div class="twelve wide column">
              <select 
                name="shipper_country" 
                v-model="addShipperCountry" 
                required>
                <option value="UNITED STATES" selected>UNITED STATES</option>
                <option value="CANADA">CANADA</option>
              </select>
            </div>

            <div class="four wide column">
              <label for="">Contact:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="shipper_contact"
                v-model="addShipperContact"
              />
            </div>

            <div class="four wide column">
              <label for="">Phone:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="shipper_phone"
                v-model="addShipperPhone"
              />
            </div>
          </div>

          <br />
          <br />
          <button class="ui button left floated sec" @click="close()">
            <i class="fa fa-times"></i> Cancel
          </button>
          <button class="ui button right floated prim" @click="submit()">
            <i class="fa fa-check"></i> Submit
          </button>
        </div>
        <div class="seven wide column">
          <img class="logo" src="../../assets/images/ALG_logo@2x.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
export default {
  props: {
    shipment: Object
  },
  data() {
    return {
      loading: false,
      addShipperName: "",
      addShipperAddress: "",
      addShipperAddress2: "",
      addShipperCity: "",
      addShipperState: "",
      addShipperZip: "",
      addShipperCountry: "UNITED STATES",
      addShipperContact: "",
      addShipperPhone: ""
    };
  },
  created() {},
  mounted() {},
  methods: {
    submit: function() {
      this.loading = !this.loading;

      let params = {
        state: this.addShipperState,
        zip: this.addShipperZip
      };

      this.$http
        .get("/shipment/verify_zipcode", { params })
        .then(response => {
          if (response.data.length > 0) {
            this.shipment.shipperNumber = "";
            this.shipment.shipperName = this.addShipperName;
            this.shipment.shipperAddress = this.addShipperAddress;
            this.shipment.shipperAddress2 = this.addShipperAddress2;
            this.shipment.shipperCity = this.addShipperCity;
            this.shipment.shipperState = this.addShipperState;
            this.shipment.shipperZip = this.addShipperZip;
            this.shipment.shipperCountry = this.addShipperCountry;
            this.shipment.shipperPhone = this.addShipperPhone;

            $(".ui.shipper_add.modal").modal("hide");
            this.loading = !this.loading;

            this.addShipperName = "";
            this.addShipperAddress = "";
            this.addShipperAddress2 = "";
            this.addShipperCity = "";
            this.addShipperState = "";
            this.addShipperZip = "";
            this.addShipperCountry = "UNITED STATES";
            this.addShipperContact = "";
            this.addShipperPhone = "";
          } else {
            alert("You entered an invalid zip code.");

            this.loading = !this.loading;
          }
        })
        .catch(error => {
          this.errors = error;
        });
    },
    close: function() {
      $(".ui.shipper_add.modal").modal("hide");
    }
  },
  computed: {}
};
</script>

<style lang="scss">
.ui.shipper_add.modal {
  min-height: 675px;
  margin: 8rem auto !important;
  padding-top: 20px;

  .header {
    color: $algdarkblue !important;
    border-bottom: none !important;
    padding-left: 40px;

    .close.icon {
      color: $algdarkblue !important;
      padding-right: 40px;
    }
  }

  label {
    color: $algdarkblue;
    font-weight: 600;
    padding-left: 15px;
  }

  input,select {
    border: 2px solid $algdarkblue25opac;
  }

  .logo {
    display: block;
    margin: auto;
  }

  .twelve.wide.column {
    padding-bottom: 0px;
  }

  .asterik {
    color: $algred;
  }
}
</style>
