<template>
  <div id="reset_password">
    <h1 class="ui dividing header">Reset Password</h1>
    <div class="ui form">
        <div class="inline field">
            <label>Email:</label>
            <input type="text" placeholder="" v-model="email">
        </div>
        <div class="inline field">
          <label>Password:</label>
          <input type="text" name="password" v-model="password">
        </div>
        <div class="inline field">
          <label>Password Confirmation:</label>
          <input type="text" name="password_confirmation" v-model="passwordConfirmation">
        </div>
        <div class="inline field buttons">
            <button class="ui left floated button sec" @click="cancel"><i class="fas fa-times"></i>Cancel</button>
            <button class="ui right floated button prim" @click="submit()"><i class="fas fa-check"></i>Submit</button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data () {
        return {
            loading: true,
            token: null,
            email: null,
            password: null,
            passwordConfirmation: null,
            errors: []
        }
    },
    created () {
    },
    methods: {
        submit: function() {
            let params = {
                token: this.$route.params.token,
                email: this.email,
                password: this.password,
                password_confirmation: this.passwordConfirmation
            };

            this.$http.post('/auth/reset/password', params)
            .then(() => {
                this.router.push('/');
            })
            .catch(error => {
                this.errors = error;
            });
        },
        cancel: function () {
          this.router.push('/');
        }
    }
};
</script>

<style lang="scss">
#reset_password {
  margin: $layoutmargin;

  .ui.form {
    margin-top: 20px;
    padding-left: 24px;

    label {
      font-size: 16px;
      font-weight: bold;
      color: $algdarkblue;
      width: 145px;
    }

    input {
      width: 300px;
      border: $darkblueborder;
    }

    select {
      width: 300px;
      border: $darkblueborder;
    }

    .inline.field.buttons {
      width: 430px;
      margin-top: 54px;
    }
  }
}
</style>