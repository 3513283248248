<template>
  <div>
    <div style="min-width: 120px" class="ui right floated last_loads menu">
      <select
        class="ui fluid last dropdown"
        name="last_loads"
        v-model="defaults.date_range"
        @change="$emit('changedDate', $event)"
      >
        <option value="1_week">1 week</option>
        <option value="1_month">1 month</option>
        <option value="3_month">3 months</option>
        <option value="6_month">6 months</option>
        <option value="YTD">YTD</option>
      </select>
    </div>

    <div v-if="!loading && dateRange.startDate && dateRange.endDate">
      {{ dateRange.startDate }} - {{ dateRange.endDate }}
    </div>

    <div class="ui active centered inline large loader" v-if="loading"></div>
    <div class="ui two column grid" style="margin-top: 20px" v-show="!loading">
      <div class="four wide column">
        <div class="ui top attached block header">
          <h6
            class="text-muted d-flex justify-content-between align-items-center mb-2"
          >
            <strong>Total Number of Shipments:</strong>
            <span v-text="totals.shipments || '...'"></span>
          </h6>

          <h6
            class="text-muted d-flex justify-content-between align-items-center"
          >
            <strong>Total Chargeable Weight:</strong>
            <span v-text="totals.weight.toLocaleString() || '...'"></span>
          </h6>
        </div>
        <div id="state-details" class="ui bottom attached segment">
          Hover over a state to view more detailed information
        </div>
      </div>

      <div class="twelve wide column" style="position: relative">
        <UnitedStates class="map" ref="usa" />

        <div class="ui two column grid">
          <div class="column">
            <Alaska ref="ak" class="map" />
          </div>
          <div ref="hi" class="column">
            <Hawaii class="map" />
          </div>
        </div>

        <div ref="window" class="w-100 rounded border bg-white window">
          <h5 class="px-3 py-2 border-bottom bg-light">
            <strong v-text="windowInfo.state"></strong>
          </h5>

          <div class="px-3 py-2">
            <h6 class="d-flex justify-content-between align-items-center mb-2">
              <strong>Number of Shipments:</strong>
              <span v-text="windowInfo.shipments"></span>
            </h6>

            <h6 class="d-flex justify-content-between align-items-center">
              <strong>Total Chargeable Weight:</strong>
              <span v-if="windowInfo.weight" v-text="windowInfo.weight.toLocaleString()"></span>
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import UnitedStates from "@/components/svgs/UnitedStates.vue";
import Alaska from "@/components/svgs/Alaska.vue";
import Hawaii from "@/components/svgs/Hawaii.vue";

export default {
  components: {
    UnitedStates,
    Alaska,
    Hawaii,
  },
  data() {
    return {
      defaults: {
        date_range: this.date_range_bounds,
      },
      windowInfo: {
        state: null,
        shipments: null,
        weight: null,
      },
      totals: {
        weight: 0,
        shipments: 0,
      },
    };
  },
  props: ["states", "loading", "dateRange", "date_range_bounds"],
  methods: {
    fillMap: function (states) {
      for (let state in states) {
        if (state.length > 2 || state == "") {
          // Removes all international or unknown locations as they are not abbreviated
          continue;
        }

        let path = document.getElementById(state);

        if (!path) {
          continue;
          //console.log(state);
        }

        if (state !== "MI") {
          path.classList.add("blue-state");
        } else {
          path.classList.add("blue-mi");
        }

        let shipments = states[state];
        let info = {
          state,
          shipments: shipments.length,
          weight: 0,
        };

        shipments.forEach((shipment) => {
          info.weight += parseInt(shipment.ChargeableWeight);
        });

        $(path)
          .on("mouseenter", (e) => this.showWindow(e, info))
          .on("mouseleave", (e) => this.hideWindow(e));

        this.totals.shipments += info.shipments;
        this.totals.weight += info.weight;
      }
    },

    hideWindow() {
      let $window = $(this.window);
      $window.removeAttr("style");
    },

    showWindow(e, info) {
      this.$set(this.windowInfo, "state", info.state);
      this.$set(this.windowInfo, "weight", info.weight);
      this.$set(this.windowInfo, "shipments", info.shipments);

      let $window = $(this.window);
      let windowY = $window.height() + 15;
      let windowX = $window.width() / 2;

      let $zone = $(e.target);

      let offset = $zone.offset();
      let zoneY = $zone.get(0).getBBox().height / 4;
      let zoneX = $zone.get(0).getBBox().width / 4;

      let $bounds = $window.parent("div");
      console.log($bounds.offset(), $bounds.position());

      let top = offset.top + zoneY - windowY - $bounds.offset().top;
      let left = offset.left + zoneX - windowX - $bounds.offset().left;

      $window.css({
        top,
        left,
      });
    },
  },

  watch: {
    states(states) {
      this.fillMap(states);
    },
  },

  computed: {
    window() {
      return this.$refs.window;
    },
  },

  created() {},
};
</script>

<style lang="scss">
.window {
  max-width: 260px;
  position: absolute;
  z-index: 20;
  top: 0px;
  left: -2000px;
  &::before,
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
    position: absolute;
  }
  &::before {
    border-left: 11.5px solid transparent;
    border-right: 11.5px solid transparent;
    border-top: 11.5px solid #dee2e6;
  }
  &,
  & * {
    pointer-events: none;
  }
}
#united_states {
  position: relative;
  z-index: 1;
  & ~ .column {
    position: relative;
    z-index: 0;
  }
}
.blue-mi {
  > path {
    fill: #3e95cd !important;
  }
}
</style>