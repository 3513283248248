<!-- Vue component -->
<template>
  <div id="customer_number_create">
    <h1 class="ui dividing header">Edit Controls Locations</h1>
    <div class="ui form">
      <div class="ui form">
        <div>
          <label class="typo__label">Select controls to hide location.</label>
          <multiselect v-model="value" :options="options" :multiple="true">
          </multiselect>
        </div>
      </div>
      <div class="inline field buttons">
        <button class="ui left floated button sec" @click="cancel()">
          <i class="fas fa-times"></i>Cancel
        </button>
        <button class="ui right floated button prim" @click="editStatus">
          <i class="fas fa-check"></i>Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  // OR register locally
  components: { Multiselect },
  data() {
    return {
      value: null,
      options: ["list", "of", "optionskljwelkrf"],
      errors: []
    };
  },
  created() {
    this.$http
      .get("/controls/create")
      .then(response => {
        // eslint-disable-next-line no-console
        console.log(response);
        this.value = response.data.current_controls;
        this.options = response.data.controls;
        this.loading = !this.loading;
      })
      .catch(error => {
        this.errors = error;
      });
  },
  methods: {
    editStatus: function() {
      let params = {
        current_controls: this.value
      };

      this.$http
        .patch("/controls/edit", params)
        .then(() => {
          this.router.push("/admin/dashboard");
        })
        .catch(error => {
          this.errors = error;
        });
    },
    cancel: function() {
      this.router.push("/admin/dashboard");
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
