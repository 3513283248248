<template>
  <div id="customer_numbers_index">
    <h1 class="ui dividing header">
      Customer Numbers
      <router-link
        to="/admin/customernumbers/create"
        class="ui right floated button prim"
        ><i class="fas fa-plus"></i>Add Customer #</router-link
      >
    </h1>

    <div class="ui active centered inline massive loader" v-if="loading"></div>

    <v-client-table
      class="customer_numbers_table table-borderless"
      :data="customerNumbers"
      :columns="columns"
      :options="options"
      v-else
    >

      <div slot="tracking_history" slot-scope="{ row }">
        {{ row.tracking_history ? "Not Showing" : "Showing" }}
      </div>

      <div slot="show_miles" slot-scope="{ row }">
        {{ row.show_miles ? "Showing" : "Not Showing" }}
      </div>

      <div slot="location_history" slot-scope="{ row }">
        {{ row.location_history ? "Not Showing" : "Showing" }}
      </div>

      <div
        class="ui compact menu manage_dropdown"
        slot="manage"
        slot-scope="{ row }"
      >
        <div class="ui simple dropdown item">
          Manage
          <i class="dropdown icon"></i>
          <div class="menu">
            <router-link
              class="item"
              :to="'/admin/customernumbers/' + row.id + '/edit'"
              ><i class="fa fa-edit"></i> Edit</router-link
            >
            <button
              class="item"
              to="javascript:void(0)"
              @click="deleteNumber(row.id)"
            >
              <i class="fa fa-trash-alt"></i> Delete
            </button>
          </div>
        </div>
      </div>
    </v-client-table>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
export default {
  data() {
    return {
      loading: true,
      customerNumbers: [],
      columns: [
        "number",
        "number_of_users",
        "tracking_history",
        "show_miles",
        "location_history",
        "manage"
      ],
      options: {
        headings: {
          number: "Customer #",
          number_of_users: "# of Users",
          tracking_history: "Status History",
          show_miles: "Show Miles",
          location_history: "Location History",
          manage: ""
        },
        sortable: ["number"],
        filterable: ["number"],
        perPage: 10,
        perPageValues: [10, 25, 50],
        texts: {
          filter: "",
          filterBy: "Search by {column}",
          count: ""
        },
        pagination: { chunk: 10, dropdown: true },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort-asc",
          down: "fa-sort-desc"
        }
      },
      errors: []
    };
  },
  mounted() {
    this.$http
      .get("/customernumbers")
      .then(response => {
        this.customerNumbers = response.data;
        console.log(response.data);

        this.loading = !this.loading;

        setTimeout(function() {
          $(".VueTables__limit-field > select").addClass(
            "ui fluid limit dropdown"
          );
          $(".VueTables__search__input").attr("placeholder", "Search All...");
        }, 100);
      })
      .catch(error => {
        this.errors = error;
      });
  },
  methods: {
    deleteNumber: function(id) {
      this.loading = !this.loading;

      this.$http
        .delete("/customernumbers/" + id)
        .then(response => {
          this.customerNumbers = response.data;

          this.loading = !this.loading;
        })
        .catch(error => {
          this.errors = error;
        });
    }
  }
};
</script>

<style lang="scss">
#customer_numbers_index {
  margin: 25px 40px 25px 40px;

  .VueTables__search {
    float: right !important;
  }

  .VueTables__search__input {
    width: 350px;
    height: 40px;
    border: $darkblueborder;
  }

  .ui.fluid.limit.dropdown {
    width: 60px;
    height: 40px;
    border-radius: 20px !important;
    border: $darkblueborder;
    margin-left: 10px;
  }

  .customer_numbers_table {
    .table-responsive {
      padding-bottom: 85px;
    }

    .VueTables__table {
      overflow: unset !important;
    }

    tr:nth-child(2n) {
      background-color: #f4f4f4;
    }

    thead {
      tr {
        th {
          color: $algdarkblue;
          background-color: $algdarkbluetenopac;
          border: none;
          padding-left: 15px;
        }

        th:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        th:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    tbody {
      tr {
        background-color: #fff;

        td {
          border: none;
          padding: 20px 0px 20px 15px;
        }

        td:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        td:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          padding: 10px 0px 10px 15px;
        }
      }
    }
  }
}
</style>
