<template>
  <div id="shipment_create">
    <h1 class="ui dividing header">Create Shipment Entry</h1>

    <form
      class="ui form"
      :class="{ loading: loading }"
      v-on:submit.prevent="submit()"
    >
      <div class="ui grid dropdowns">
        <div class="one wide column hawb_number">Hawb #:</div>
        <div class="four wide column">
          <input
            name="Hwb"
            placeholder="Will be autogenerated if left blank."
            v-model="shipment.Hwb"
            onkeyup="this.value = this.value.toUpperCase();"
          />
        </div>
        <div class="one wide column"></div>
        <div class="one wide column service">
          <span class="asterik">*</span>Service:
        </div>
        <div class="four wide column">
          <select
            class="ui fluid dropdown"
            name="service"
            v-model="shipment.service"
            :disabled="shipmentSettings.servicesLock"
            required
          >
            <option :value="null" disabled selected></option>
            <option
              v-for="service in serviceTypes"
              :key="service.Code"
              :value="service.Code"
            >
              {{ service.Code }} {{ service.Description }}
            </option>
          </select>
        </div>
        <div class="two wide column customer_number">
          <span class="asterik">*</span>Customer #:
        </div>
        <div class="three wide column">
          <select
            class="ui fluid dropdown"
            id="customer_number"
            name="customer_number"
            v-model="shipment.customerNumber"
            @change="customerNumberUpdate"
            :disabled="shipmentSettings.customerNumberLock"
            required
          >
            <option :value="null" disabled selected></option>
            <option
              v-for="number in customerNumbers"
              :key="number.id"
              :value="number.id"
            >
              {{ number.number }}
            </option>
          </select>
        </div>
      </div>

      <div class="ui grid shipment">
        <div class="eight wide column left_side shipper">
          <h2 class="ui header">Shipper Information</h2>
          <div class="ui grid">
            <div v-if="!shipmentSettings.shipperLock" class="four wide column"></div>
            <div
              v-if="!shipmentSettings.shipperLock" 
              class="twelve wide column">
              To fill out the below shipper data please select the magnifying
              glass to search for a shipper or the plus sign to add a
              new shipper.
              </div>
            <div class="four wide column">
              <label for="">Number:</label>
            </div>
            <div class="eleven wide column">
              <div class="ui icon input">
                <input
                  id="shipper_number"
                  name="shipper_number"
                  type="text"
                  placeholder=""
                  v-model="shipment.shipperNumber"
                  class="read-only"
                  readonly
                />
                <i
                  class="search icon"
                  @click="searchShipper"
                  v-if="!shipmentSettings.shipperLock"
                ></i>
                <i class="icon" v-else></i>
              </div>
            </div>
            <div class="one wide column">
              <i
                class="fa fa-plus"
                @click="addShipper"
                v-if="!shipmentSettings.shipperLock"
              ></i>
              <i class="icon" v-else></i>
            </div>
            <div class="four wide column">
              <label for=""><span class="asterik">*</span>Name</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="shipper_name"
                v-model="shipment.shipperName"
                class="read-only"
                readonly
                required
              />
            </div>

            <div class="four wide column">
              <label for=""><span class="asterik">*</span>Address:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="shipper_address"
                v-model="shipment.shipperAddress"
                class="read-only"
                readonly
                required
              />
            </div>
            <div class="four wide column"></div>
            <div class="twelve wide column">
              <input
                type="text"
                name="shipper_address2"
                v-model="shipment.shipperAddress2"
                class="read-only"
                readonly
              />
            </div>

            <div class="four wide column">
              <label for=""><span class="asterik">*</span>City:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="shipper_city"
                v-model="shipment.shipperCity"
                class="read-only"
                readonly
                required
              />
            </div>

            <div class="four wide column">
              <label for=""><span class="asterik">*</span>State:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="shipper_state"
                v-model="shipment.shipperState"
                class="read-only"
                readonly
                required
              />
            </div>

            <div class="four wide column">
              <label for=""><span class="asterik">*</span>Zip Code:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="shipper_zip"
                v-model="shipment.shipperZip"
                class="read-only"
                readonly
                required
              />
            </div>

            <div class="four wide column">
              <label for=""><span class="asterik">*</span>Country:</label>
            </div>
            <div class="twelve wide column">
              <select 
                name="shipper_country" 
                v-model="shipment.shipperCountry" 
                class="read-only" 
                readonly
                disabled
                required>
                <option value="UNITED STATES">UNITED STATES</option>
                <option value="CANADA">CANADA</option>
              </select>
            </div>

            <div class="four wide column">
              <label for="">Contact:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="shipper_contact"
                v-model="shipment.shipperContact"
              />
            </div>

            <div class="four wide column">
              <label for="">Phone:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="shipper_phone"
                v-model="shipment.shipperPhone"
              />
            </div>

            <div class="four wide column">
              <label for=""
                ><span class="asterik">*</span>Shipment Ready:</label
              >
            </div>
            <div class="twelve wide column">
              <input
                type="date"
                id="ship_ready_date"
                name="shipper_ready"
                v-model="shipment.shipperShipmentReady"
                required
              />
            </div>

            <div class="four wide column">
              <label for=""><span class="asterik">*</span>Time from(24h):</label>
            </div>
            <div class="five wide column">
              <input
                type="text"
                name="shipper_time_from"
                @blur="checkAllTimes($event,'shipment.shipperTimeFrom')"
                v-model="shipment.shipperTimeFrom"
                required
              />
            </div>
            <div class="two wide column to">
              <p>to</p>
            </div>
            <div class="five wide column">
              <input
                type="text"
                name="shipper_time_to"
                @blur="checkAllTimes($event,'shipment.shipperTimeTo')"
                v-model="shipment.shipperTimeTo"
                required
              />
            </div>
          </div>

          <h2 class="ui header">Bill To Information</h2>
          <div class="ui grid bill_to">
            <div class="four wide column">
              <label for=""><span class="asterik">*</span>Pay Code:</label>
            </div>
            <div class="twelve wide column">
              <select
                class="ui fluid dropdown"
                name="bill_to_paycode"
                v-model="shipment.billToPaycode"
                :disabled="shipmentSettings.paycodesLock"
              >
                <option :value="null" disabled selected></option>
                <option
                  v-for="code in payCodes"
                  :key="code.ID"
                  :value="code.Code"
                >
                  [{{ code.Code }}] {{ code.Description }}
                </option>
              </select>
            </div>

            <div class="four wide column">
              <label for="">Number</label>
            </div>
            
            <div class="twelve wide column">
              <div class="ui icon input">
                <input
                  id="bill_to_number"
                  name="bill_to_number"
                  type="text"
                  placeholder=""
                  v-model="shipment.billToNumberDefault"
                  :class="{ 'read-only': shipmentSettings.billToLock }"
                  :readonly="shipmentSettings.billToNumberDefault"
                  style="padding-left:30px;"
                />
                <i
                  class="search icon"
                  @click="searchBillTos()"
                  v-if="!shipmentSettings.consigneeLock"
                ></i>
                <i class="search icon" @click="searchBillTos()" v-else></i>
              </div>
            </div>

            <div class="four wide column">
              <label for=""><span class="asterik">*</span>Name</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="bill_to_name"
                v-model="shipment.billToNameDefault"
                :class="{ 'read-only': shipmentSettings.billToLock }"
                :readonly="shipmentSettings.billToNameDefault"
                required
              />
            </div>
            

            <div class="four wide column">
              <label for=""><span class="asterik">*</span>Address:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="bill_to_address"
                v-model="shipment.billToAddressDefault"
                :class="{ 'read-only': shipmentSettings.billToLock }"
                :readonly="shipmentSettings.billToAddressDefault"
                required
              />
            </div>
            <div class="four wide column"></div>
            <div class="twelve wide column">
              <input
                type="text"
                name="bill_to_address2"
                v-model="shipment.billToAddress2Default"
                :class="{ 'read-only': shipmentSettings.billToLock }"
                :readonly="shipmentSettings.billToAddress2Default"
              />
            </div>

            <div class="four wide column">
              <label for=""><span class="asterik">*</span>City:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="bill_to_city"
                v-model="shipment.billToCityDefault"
                :class="{ 'read-only': shipmentSettings.billToLock }"
                :readonly="shipmentSettings.billToCityDefault"
                required
              />
            </div>

            <div class="four wide column">
              <label for=""><span class="asterik">*</span>State:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="bill_to_state"
                v-model="shipment.billToStateDefault"
                :class="{ 'read-only': shipmentSettings.billToLock }"
                :readonly="shipmentSettings.billToStateDefault"
                required
              />
            </div>

            <div class="four wide column">
              <label for=""><span class="asterik">*</span>Zip Code:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="bill_to_zip"
                v-model="shipment.billToZipDefault"
                :class="{ 'read-only': shipmentSettings.billToLock }"
                :readonly="shipmentSettings.billToZipDefault"
                required
              />
            </div>

            <div class="four wide column">
              <label for=""><span class="asterik">*</span>Country:</label>
            </div>
            <div class="twelve wide column">
              <select 
                name="bill_to_country" 
                v-model="shipment.billToCountry" 
                :class="{ 'read-only': shipmentSettings.billToLock }" 
                :readonly="shipmentSettings.billToCountryDefault" 
                required>
                <option value="UNITED STATES">UNITED STATES</option>
                <option value="CANADA">CANADA</option>
              </select>
            </div>
          </div>

          <h2 class="ui header">Pickup</h2>
          <div class="ui grid pickup">
            <div class="four wide column">
              <label for="">Pickup Services:</label>
            </div>
            <div class="twelve wide column">
              <select
                class="ui fluid dropdown pickup-select"
                name="default_pickup_services"
                multiple=""
                v-model="shipment.pickupServices"
                @change="pickupChange()"
              >
                <option :value="null" disabled selected></option>
                <option
                  v-for="type in prevenueTypes"
                  :key="type.Name"
                  :value="type.Name"
                >
                  {{ type.Name }}
                </option>
              </select>
            </div>

            <div class="four wide column">
              <label for="">Pickup Instructions:</label>
            </div>
            <div class="twelve wide column">
              <textarea
                name="pickup_instructions"
                id="pickup_instructions"
                cols="30"
                rows="8"
                placeholder="Add instructions here..."
                v-model="shipment.pickupInstructions"
              ></textarea>
            </div>
          </div>
        </div>

        <div class="eight wide column right_side consignee">
          <h2 class="ui header">Consignee Information</h2>
          <div class="ui grid con_info">
            <div v-if="!shipmentSettings.consigneeLock" class="four wide column"></div>
            <div
              v-if="!shipmentSettings.consigneeLock"
              class="twelve wide column">
              To fill out the below consignee data please select the magnifying glass to search for a consignee or the plus sign to add a new consignee.
            </div>
            <div class="four wide column">
              <label for="">Number:</label>
            </div>
            <div class="eleven wide column">
              <div class="ui icon input">
                <input
                  id="consignee_number"
                  name="consignee_number"
                  type="text"
                  placeholder=""
                  v-model="shipment.consigneeNumber"
                  class="read-only"
                  readonly
                />
                <i
                  class="search icon"
                  @click="searchConsignee()"
                  v-if="!shipmentSettings.consigneeLock"
                ></i>
                <i class="icon" v-else></i>
              </div>
            </div>
            <div class="one wide column">
              <i
                class="fa fa-plus"
                @click="addConsignee()"
                v-if="!shipmentSettings.consigneeLock"
              ></i>
              <i class="" v-else></i>
            </div>

            <div class="four wide column">
              <label for=""><span class="asterik">*</span>Name:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="consignee_name"
                v-model="shipment.consigneeName"
                class="read-only"
                readonly
                required
              />
            </div>

            <div class="four wide column">
              <label for=""><span class="asterik">*</span>Address:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="consignee_address"
                v-model="shipment.consigneeAddress"
                class="read-only"
                readonly
                required
              />
            </div>
            <div class="four wide column"></div>
            <div class="twelve wide column">
              <input
                type="text"
                name="consignee_address2"
                v-model="shipment.consigneeAddress2"
                class="read-only"
                readonly
              />
            </div>

            <div class="four wide column">
              <label for=""><span class="asterik">*</span>City:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="consignee_city"
                v-model="shipment.consigneeCity"
                class="read-only"
                readonly
                required
              />
            </div>

            <div class="four wide column">
              <label for=""><span class="asterik">*</span>State:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="consignee_state"
                v-model="shipment.consigneeState"
                class="read-only"
                readonly
                required
              />
            </div>

            <div class="four wide column">
              <label for=""><span class="asterik">*</span>Zip Code:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="consignee_zip"
                v-model="shipment.consigneeZip"
                class="read-only"
                readonly
                required
              />
            </div>

            <div class="four wide column">
              <label for=""><span class="asterik">*</span>Country:</label>
            </div>
            <div class="twelve wide column">
              <select 
                name="consignee_country" 
                v-model="shipment.consigneeCountry" 
                class="read-only" 
                readonly
                disabled
                required>
                <option value="UNITED STATES">UNITED STATES</option>
                <option value="CANADA">CANADA</option>
              </select>
            </div>

            <div class="four wide column">
              <label for="">Contact:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="consignee_contact"
                v-model="shipment.consigneeContact"
              />
            </div>

            <div class="four wide column">
              <label for="">Phone:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="consignee_phone"
                v-model="shipment.consigneePhone"
              />
            </div>

            <div class="five wide column">
              <label for=""
                ><span class="asterik">*</span>Requested Delivery Date:</label
              >
            </div>
            <div class="eleven wide column">
              <input
                type="date"
                id="req_delivery_date"
                @blur="checkReqDeliveryDate"
                name="requested_delivery_date"
                v-model="shipment.consigneeRequestedDeliveryDate"
                required
              />
            </div>

            <div class="five wide column">
              <label for="">Requested Delivery Time(24h):</label>
            </div>
            <div class="eleven wide column">
              <input
                type="text"
                name="requested_delivery_time"
                @blur="checkAllTimes($event,'shipment.consigneeRequestedDeliveryTime')"
                v-model="shipment.consigneeRequestedDeliveryTime"
              />
            </div>
          </div>

          <h2 class="ui header">Declared Value or Insurance</h2>
          <div class="ui grid">
            <div class="four wide column">
              <label for="">DV or INS:</label>
            </div>
            <div class="twelve wide column">
              <select
                class="ui fluid dv_ins dropdown"
                name="dv_or_ins"
                v-model="shipment.dvOrIns"
                :disabled="shipmentSettings.dvInsLock"
              >
                <option :value="null" disabled selected></option>
                <option value="DV">DV</option>
                <option value="INS">INS</option>
              </select>
            </div>

            <div class="four wide column">
              <label for="">Amount:</label>
            </div>
            <div class="twelve wide column">
              <input
                type="text"
                name="dv_or_ins_amount"
                v-model="shipment.dvOrInsAmount"
                :class="{ 'read-only': shipmentSettings.dvInsLock }"
                :readonly="shipmentSettings.dvOrInsAmount"
              />
            </div>
          </div>

          <h2 class="ui header">Appointment Information</h2>
          <div class="ui checkbox appt_info">
            <input
              type="checkbox"
              name="appt_req"
              v-model="shipment.apptReq"
              :disabled="shipmentSettings.apptLock"
            />
            <label>Delivery Appointment is required</label>
          </div>

          <h2 class="ui header">Reference Numbers</h2>
          <div class="ui grid">
            <div class="four wide column">
              <label for="">Shipper Ref:</label>
            </div>
            <div class="twelve wide column">
              <input type="text" v-model="shipment.shipperRef" />
            </div>

            <div class="four wide column">
              <label for="">Consignee Ref:</label>
            </div>
            <div class="twelve wide column">
              <input type="text" v-model="shipment.consigneeRef" />
            </div>

            <div class="sixteen wide column po_column">
              <div class="field">
                <label>Purchase Order Numbers:</label>

                <template v-for="(po, index) in purchaseOrders">
                  <component
                    :is="po"
                    :key="index"
                    :poCount="index"
                    :purchaseOrders="purchaseOrders"
                    @clicked="addPo"
                  ></component>
                </template>
              </div>
            </div>
          </div>

          <h2 class="ui header" style="margin-top:50px;">Delivery</h2>
          <div class="ui grid">
            <div class="four wide column">
              <label for="">Delivery Services:</label>
            </div>
            <div class="twelve wide column">
              <select
                class="ui fluid dropdown delivery-select"
                name="default_delivery_services"
                multiple=""
                v-model="shipment.deliveryServices"
                @change="deliveryChange()"
              >
                <option :value="null" disabled selected></option>
                <option
                  v-for="type in drevenueTypes"
                  :key="type.Name"
                  :value="type.Name"
                >
                  {{ type.Name }}
                </option>
              </select>
            </div>

            <div class="four wide column">
              <label for="">Delivery Instructions:</label>
            </div>
            <div class="twelve wide column">
              <textarea
                name="delivery_instructions"
                id="delivery_instructions"
                cols="30"
                rows="8"
                placeholder="Add instructions here..."
                v-model="shipment.deliveryInstructions"
              ></textarea>
            </div>
          </div>
        </div>

        <div class="sixteen wide column details">
          <h2 class="ui header">Details</h2>
          <template v-for="(detail, index) in details">
            <component
              :is="detail"
              :key="index"
              :detailCount="index"
              :details="details"
              :shipment="shipment"
              :shipmentSettings="shipmentSettings"
              :commodities="commodities"
              :classes="classes"
              @clicked="addDetail"
            ></component>
          </template>
        </div>

        <div class="sixteen wide column totals">
          <div class="ui header">
            <div class="ui grid">
              <div class="one wide column">Pieces:</div>
              <div class="one wide column">{{ footerPieces }}</div>

              <div class="two wide column">Total Weight:</div>
              <div class="one wide column">{{ footerTotalWeight }}</div>

              <div class="two wide column">Dim Factor:</div>
              <div class="one wide column">{{ footerDimFactor }}</div>

              <div class="two wide column">Dim Weight:</div>
              <div class="one wide column">{{ footerDimWeight }}</div>

              <div class="three wide column">Chargeable Weight:</div>
              <div class="two wide column">{{ footerChargeable }}</div>
            </div>
          </div>
        </div>

        <div class="sixteen wide column terms_agree">
          <div class="ui checkbox">
            <input type="checkbox" name="terms_agree" required />
            <label
              ><p>
                <span class="asterik">*</span> I Agree to ALG Worldwide
                Logistics, LLC’s Terms & Conditions
              </p></label
            >
          </div>
        </div>
        <div class="sixteen wide column">
          <button class="ui right floated button prim">
            <i class="fa fa-check"></i>Submit
          </button>
        </div>
      </div>
    </form>

    <ShipperAddModal id="shipper_add_modal" :shipment="shipment" />
    <ShipperSearchModal
      id="shipper_search_modal"
      :shippers="searchedShippers"
      :shipment="shipment"
    />

    <ConsigneeAddModal id="consignee_add_modal" :shipment="shipment" />
    <ConsigneeSearchModal
      id="consignee_search_modal"
      :consignees="searchedConsignees"
      :shipment="shipment"
    />
    <BillToSearchModal
      id="bill_to_search_modal"
      :consignees="searchedBillTos"
      :shipment="shipment"
    />
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
import ShipperAddModal from "@/components/modals/ShipperAddModal.vue";
import ShipperSearchModal from "@/components/modals/ShipperSearchModal.vue";

import ConsigneeAddModal from "@/components/modals/ConsigneeAddModal.vue";
import ConsigneeSearchModal from "@/components/modals/ConsigneeSearchModal.vue";

import BillToSearchModal from "@/components/modals/BillToSearchModal.vue";

import PurchaseOrder from "@/components/PurchaseOrder.vue";
import Details from "@/components/Details.vue";

export default {
  components: {
    ShipperAddModal,
    ShipperSearchModal,
    ConsigneeAddModal,
    ConsigneeSearchModal,
    BillToSearchModal,
    Details
  },
  data() {
    return {
      data: [],
      loading: true,
      companies: [],
      customerNumbers: [],
      customerProfiles: [],
      serviceTypes: [],
      commodities: {},
      prevenueTypes: [],
      defaultPrevenueTypes: [],
      drevenueTypes: [],
      defaultDrevenueTypes: [],
      payCodes: [],
      countries: [],
      classes: [],
      shipment: {
        Hwb: "",
        service: "",
        customerNumber: "",
        shipperNumber: "",
        shipperName: "",
        shipperAddress: "",
        shipperAddress2: "",
        shipperCity: "",
        shipperState: "",
        shipperZip: "",
        shipperCountry: "UNITED STATES",
        shipperContact: "",
        shipperPhone: "",
        shipperShipmentReady: "",
        shipperTimeFrom: "",
        shipperTimeTo: "",
        consigneeNumber: "",
        consigneeName: "",
        consigneeAddress: "",
        consigneeAddress2: "",
        consigneeCity: "",
        consigneeState: "",
        consigneeZip: "",
        consigneeCountry: "UNITED STATES",
        consigneeContact: "",
        consigneePhone: "",
        consigneeRequestedDeliveryDate: "",
        consigneeRequestedDeliveryTime: "",
        billToPaycode: "",
        billToNumberDefault: "",
        billToNameDefault: "",
        billToAddress2Default: "",
        billToAddressDefault: "",
        billToCityDefault: "",
        billToStateDefault: "",
        billToZipDefault: "",
        billToCountry: "UNITED STATES",
        dvOrIns: "",
        dvOrInsAmount: "",
        apptReq: 0,
        shipperRef: "",
        consigneeRef: "",
        pickupServices: [],
        defaultPickupServices: [],
        pickupInstructions: "",
        deliveryServices: [],
        defaultDeliveryServices: [],
        deliveryInstructions: "",
        detailsAndPos: {},
        commodity: ""
      },
      shipmentSettings: {
        customerNumberDefault: null,
        customerNumberLock: false,
        shipperNumberDefault: "",
        shipperNameDefault: "",
        shipperAddressDefault: "",
        shipperAddress2Default: "",
        shipperCityDefault: "",
        shipperStateDefault: "",
        shipperZipDefault: "",
        shipperCountryDefault: "",
        shipperContactDefault: "",
        shipperPhoneDefault: "",
        shipperLock: false,
        consigneeNumberDefault: "",
        consigneeNameDefault: "",
        consigneeAddressDefault: "",
        consigneeAddress2Default: "",
        consigneeCityDefault: "",
        consigneeStateDefault: "",
        consigneeZipDefault: "",
        consigneeCountryDefault: "",
        consigneeContactDefault: "",
        consigneePhoneDefault: "",
        consigneeLock: false,
        pickupInsDefault: "",
        deliveryInsDefault: "",
        billToNumberDefault: "",
        billToNameDefault: "",
        billToAddressDefault: "",
        billToAddress2Default: "",
        billToCityDefault: "",
        billToStateDefault: "",
        billToZipDefault: "",
        billToCountryDefault: "",
        billToLock: false,
        servicesAllowed: [],
        servicesDefault: "",
        servicesLock: false,
        paycodesAllowed: [],
        paycodesDefault: "",
        paycodesLock: false,
        pickupServicesAllowed: [],
        pickupServicesDefault: [],
        pickupServicesLock: false,
        deliveryServicesAllowed: [],
        deliveryServicesDefault: [],
        deliveryServicesLock: false,
        detailsCommoditiesAllowed: [],
        detailsCommoditiesDefault: "",
        detailsCommoditiesLock: false,
        detailsDescriptionDefault: "",
        dvInsDefault: "",
        dvInsAmountDefault: "",
        dvInsLock: false,
        apptReqLock: false,
        apptLock: false
      },
      searchedShippers: [],
      searchedConsignees: [],
      searchedBillTos: [],
      purchaseOrders: [PurchaseOrder],
      details: [Details],
      footerPieces: 0,
      footerTotalWeight: 0,
      footerDimFactor: 0,
      footerDimWeight: 0,
      footerChargeable: 0
    };
  },
  created() {
    this.$http
      .get("/shipment/create")
      .then(response => {
        this.user = response.data.user;
        this.companies = response.data.companies;
        this.customerNumbers = response.data.customer_numbers;
        this.customerProfiles = response.data.customer_profiles;
        this.serviceTypes = response.data.service_types;
        this.commodities = response.data.commodities;
        this.prevenueTypes = JSON.parse(
          JSON.stringify(response.data.prevenue_types)
        );
        this.defaultPrevenueTypes = JSON.parse(
          JSON.stringify(this.prevenueTypes)
        );
        this.drevenueTypes = JSON.parse(
          JSON.stringify(response.data.drevenue_types)
        );
        this.defaultDrevenueTypes = JSON.parse(
          JSON.stringify(this.drevenueTypes)
        );
        this.payCodes = response.data.pay_codes;
        this.classes = response.data.classes;
        this.countries = response.data.countries;
        // load user shipment settings
        let settings = response.data.shipment_settings;
        if (Object.keys(settings).length > 1) {
          // alert('user hast shipment settings');

          this.shipmentSettings.servicesAllowed = settings.services_allowed;
          if (
            this.shipmentSettings.servicesAllowed !== undefined &&
            this.shipmentSettings.servicesAllowed.length > 1
          ) {
            for (let key in this.serviceTypes) {
              if (this.serviceTypes.hasOwnProperty(key)) {
                if (
                  !this.shipmentSettings.servicesAllowed.includes(
                    this.serviceTypes[key].Code
                  )
                )
                  delete this.serviceTypes[key];
              }
            }
          }

          this.shipmentSettings.servicesDefault = settings.services_default;
          if (this.shipmentSettings.servicesDefault !== undefined)
            this.shipment.service = this.shipmentSettings.servicesDefault;
          if (settings.services_lock) this.shipmentSettings.servicesLock = true;

          this.shipmentSettings.customerNumberDefault =
            settings.customer_number_default;
          if (this.shipmentSettings.customerNumberDefault !== undefined)
            this.shipment.customerNumber = this.shipmentSettings.customerNumberDefault;
          if (settings.customer_number_lock)
            this.shipmentSettings.customerNumberLock = true;

          this.shipmentSettings.shipperNumberDefault =
            settings.shipper_number_default;
          if (this.shipmentSettings.shipperNumberDefault !== undefined)
            this.shipment.shipperNumber = this.shipmentSettings.shipperNumberDefault;
          this.shipmentSettings.shipperNameDefault =
            settings.shipper_name_default;
          if (this.shipmentSettings.shipperNameDefault !== undefined)
            this.shipment.shipperName = this.shipmentSettings.shipperNameDefault;
          this.shipmentSettings.shipperAddressDefault =
            settings.shipper_address_default;
          if (this.shipmentSettings.shipperAddressDefault !== undefined)
            this.shipment.shipperAddress = this.shipmentSettings.shipperAddressDefault;
          this.shipmentSettings.shipperAddress2Default =
            settings.shipper_address2_default;
          if (this.shipmentSettings.shipperAddress2Default !== undefined)
            this.shipment.shipperAddress2 = this.shipmentSettings.shipperAddress2Default;
          this.shipmentSettings.shipperCityDefault =
            settings.shipper_city_default;
          if (this.shipmentSettings.shipperCityDefault !== undefined)
            this.shipment.shipperCity = this.shipmentSettings.shipperCityDefault;
          this.shipmentSettings.shipperStateDefault =
            settings.shipper_state_default;
          if (this.shipmentSettings.shipperStateDefault !== undefined)
            this.shipment.shipperState = this.shipmentSettings.shipperStateDefault;
          this.shipmentSettings.shipperZipDefault =
            settings.shipper_zip_default;
          if (this.shipmentSettings.shipperZipDefault !== undefined)
            this.shipment.shipperZip = this.shipmentSettings.shipperZipDefault;
          this.shipmentSettings.shipperCountryDefault =
            settings.shipper_country_default;
          if (this.shipmentSettings.shipperCountryDefault !== undefined && 
              this.shipmentSettings.shipperCountryDefault !== null)
            this.shipment.shipperCountry = this.shipmentSettings.shipperCountryDefault;
          this.shipmentSettings.shipperContactDefault =
            settings.shipper_contact_default;
          if (this.shipmentSettings.shipperContactDefault !== undefined)
            this.shipment.shipperContact = this.shipmentSettings.shipperContactDefault;
          this.shipmentSettings.shipperPhoneDefault =
            settings.shipper_phone_default;
          if (this.shipmentSettings.shipperPhoneDefault !== undefined)
            this.shipment.shipperPhone = this.shipmentSettings.shipperPhoneDefault;
          this.shipmentSettings.shipperLock = settings.shipper_lock;

          this.shipmentSettings.consigneeNumberDefault =
            settings.consignee_number_default;
          if (this.shipmentSettings.consigneeNumberDefault !== undefined)
            this.shipment.consigneeNumber = this.shipmentSettings.consigneeNumberDefault;
          this.shipmentSettings.consigneeNameDefault =
            settings.consignee_name_default;
          if (this.shipmentSettings.consigneeNameDefault !== undefined)
            this.shipment.consigneeName = this.shipmentSettings.consigneeNameDefault;
          this.shipmentSettings.consigneeAddressDefault =
            settings.consignee_address_default;
          if (this.shipmentSettings.consigneeAddressDefault !== undefined)
            this.shipment.consigneeAddress = this.shipmentSettings.consigneeAddressDefault;
          this.shipmentSettings.consigneeAddress2Default =
            settings.consignee_address2_default;
          if (this.shipmentSettings.consigneeAddress2Default !== undefined)
            this.shipment.consigneeAddress2 = this.shipmentSettings.consigneeAddress2Default;
          this.shipmentSettings.consigneeCityDefault =
            settings.consignee_city_default;
          if (this.shipmentSettings.consigneeCityDefault !== undefined)
            this.shipment.consigneeCity = this.shipmentSettings.consigneeCityDefault;
          this.shipmentSettings.consigneeStateDefault =
            settings.consignee_state_default;
          if (this.shipmentSettings.consigneeStateDefault !== undefined)
            this.shipment.consigneeState = this.shipmentSettings.consigneeStateDefault;
          this.shipmentSettings.consigneeZipDefault =
            settings.consignee_zip_default;
          if (this.shipmentSettings.consigneeZipDefault !== undefined)
            this.shipment.consigneeZip = this.shipmentSettings.consigneeZipDefault;
          this.shipmentSettings.consigneeCountryDefault =
            settings.consignee_country_default;
          if (this.shipmentSettings.consigneeCountryDefault !== undefined && 
              this.shipmentSettings.consigneeCountryDefault !== null)
            this.shipment.consigneeCountry = this.shipmentSettings.consigneeCountryDefault;
          this.shipmentSettings.consigneeContactDefault =
            settings.consignee_contact_default;
          if (this.shipmentSettings.consigneeContactDefault !== undefined)
            this.shipment.consigneeContact = this.shipmentSettings.consigneeContactDefault;
          this.shipmentSettings.consigneePhoneDefault =
            settings.consignee_phone_default;
          if (this.shipmentSettings.consigneePhoneDefault !== undefined)
            this.shipment.consigneePhone = this.shipmentSettings.consigneePhoneDefault;
          this.shipmentSettings.consigneeLock = settings.consignee_lock;

          this.shipmentSettings.billToNumberDefault =
            settings.bill_to_number_default;
          if (this.shipmentSettings.billToNumberDefault !== undefined)
            this.shipment.billToNumberDefault = this.shipmentSettings.billToNumberDefault;
          this.shipmentSettings.billToNameDefault =
            settings.bill_to_name_default;
          if (this.shipmentSettings.billToNameDefault !== undefined)
            this.shipment.billToNameDefault = this.shipmentSettings.billToNameDefault;
          this.shipmentSettings.billToAddress2Default =
            settings.bill_to_name2_default;
          if (this.shipmentSettings.billToAddress2Default !== undefined)
            this.shipment.billToAddress2Default = this.shipmentSettings.billToAddress2Default;
          this.shipmentSettings.billToAddressDefault =
            settings.bill_to_address_default;
          if (this.shipmentSettings.billToAddressDefault !== undefined)
            this.shipment.billToAddressDefault = this.shipmentSettings.billToAddressDefault;
          this.shipmentSettings.billToCityDefault =
            settings.bill_to_city_default;
          if (this.shipmentSettings.billToCityDefault !== undefined)
            this.shipment.billToCityDefault = this.shipmentSettings.billToCityDefault;
          this.shipmentSettings.billToStateDefault =
            settings.bill_to_state_default;
          if (this.shipmentSettings.billToStateDefault !== undefined)
            this.shipment.billToStateDefault = this.shipmentSettings.billToStateDefault;
          this.shipmentSettings.billToZipDefault = settings.bill_to_zip_default;
          if (this.shipmentSettings.billToZipDefault !== undefined)
            this.shipment.billToZipDefault = this.shipmentSettings.billToZipDefault;
          this.shipmentSettings.billToCountryDefault =
            settings.bill_to_country_default;
          if (this.shipmentSettings.billToCountryDefault !== undefined && 
              this.shipmentSettings.billToCountryDefault !== null)
            this.shipment.billToCountry = this.shipmentSettings.billToCountryDefault;
          this.shipmentSettings.billToLock = settings.bill_to_lock;

          this.shipmentSettings.pickupInsDefault = settings.pickup_ins_default;
          if (this.shipmentSettings.pickupInsDefault !== undefined)
            this.shipment.pickupInstructions = this.shipmentSettings.pickupInsDefault;

          this.shipmentSettings.deliveryInsDefault =
            settings.delivery_ins_default;
          if (this.shipmentSettings.deliveryInsDefault !== undefined)
            this.shipment.deliveryInstructions = this.shipmentSettings.deliveryInsDefault;
          if (settings.pickup_services_default)
            this.shipment.pickupServices = settings.pickup_services_default;
          this.shipmentSettings.pickupServicesAllowed =
            settings.pickup_services_allowed;
          this.shipmentSettings.pickupServicesDefault =
            settings.pickup_services_default;
          if (
            this.shipmentSettings.pickupServicesAllowed !== undefined &&
            this.shipmentSettings.pickupServicesAllowed.length > 1
          ) {
            for (let key in this.prevenueTypes) {
              if (this.prevenueTypes.hasOwnProperty(key)) {
                if (
                  !this.shipmentSettings.pickupServicesAllowed.includes(
                    this.prevenueTypes[key].Name
                  )
                ) {
                  delete this.prevenueTypes[key];
                }
              }
            }
          }

          if (settings.pickup_services_lock)
            this.shipmentSettings.pickupServicesLock = true;
          if (settings.delivery_services_default)
            this.shipment.deliveryServices = settings.delivery_services_default;
          this.shipmentSettings.deliveryServicesAllowed =
            settings.delivery_services_allowed;
          this.shipmentSettings.deliveryServicesDefault =
            settings.delivery_services_default;
          if (
            this.shipmentSettings.deliveryServicesAllowed !== undefined &&
            this.shipmentSettings.deliveryServicesAllowed.length > 1
          ) {
            for (let key in this.drevenueTypes) {
              if (this.drevenueTypes.hasOwnProperty(key)) {
                if (
                  !this.shipmentSettings.deliveryServicesAllowed.includes(
                    this.drevenueTypes[key].Name
                  )
                ) {
                  delete this.drevenueTypes[key];
                }
              }
            }
          }

          if (settings.delivery_services_lock)
            this.shipmentSettings.deliveryServicesLock = true;

          this.shipmentSettings.paycodesAllowed = settings.pay_codes_allowed;
          if (
            this.shipmentSettings.paycodesAllowed !== undefined &&
            this.shipmentSettings.paycodesAllowed.length > 1
          ) {
            for (let key in this.payCodes) {
              if (this.payCodes.hasOwnProperty(key)) {
                if (
                  !this.shipmentSettings.paycodesAllowed.includes(
                    this.payCodes[key].Code
                  )
                )
                  delete this.payCodes[key];
              }
            }
          }

          this.shipmentSettings.paycodesDefault = settings.pay_codes_default;
          if (this.shipmentSettings.paycodesDefault !== undefined)
            this.shipment.billToPaycode = this.shipmentSettings.paycodesDefault;

          if (settings.pay_codes_lock)
            this.shipmentSettings.paycodesLock = true;

          this.shipmentSettings.dvInsDefault = settings.dv_ins_default;
          if (this.shipmentSettings.dvInsDefault !== undefined)
            this.shipment.dvOrIns = this.shipmentSettings.dvInsDefault;
          $(".ui.fluid.dv_ins.dropdown").dropdown(
            "set selected",
            this.shipmentSettings.dvInsDefault
          );

          this.shipmentSettings.dvInsAmountDefault =
            settings.dv_ins_amount_default;
          if (this.shipmentSettings.dvInsAmountDefault !== undefined)
            this.shipment.dvOrInsAmount = this.shipmentSettings.dvInsAmountDefault;

          if (settings.dv_ins_lock) this.shipmentSettings.dvInsLock = true;

          this.shipmentSettings.apptReqLock = settings.appt_req_lock;
          if (this.shipmentSettings.apptReqLock !== undefined)
            this.shipment.apptReq = this.shipmentSettings.apptReqLock;

          if (settings.appt_lock) this.shipmentSettings.apptLock = true;

          this.shipmentSettings.detailsCommoditiesAllowed =
            settings.details_commodities_allowed;
          if (
            this.shipmentSettings.detailsCommoditiesAllowed !== undefined &&
            this.shipmentSettings.detailsCommoditiesAllowed.length > 1
          ) {
            for (let key in this.commodities) {
              if (this.commodities.hasOwnProperty(key)) {
                if (
                  !this.shipmentSettings.detailsCommoditiesAllowed.includes(
                    this.commodities[key].Code
                  )
                )
                  delete this.commodities[key];
              }
            }
          }

          this.shipmentSettings.detailsCommoditiesDefault =
            settings.details_commodities_default;
          if (this.shipmentSettings.detailsCommoditiesDefault !== undefined)
            this.shipment.commodity = this.shipmentSettings.detailsCommoditiesDefault;
          $(".ui.fluid.commodity.dropdown").dropdown(
            "set selected",
            this.shipmentSettings.detailsCommoditiesDefault
          );

          if (settings.details_commodities_lock)
            this.shipmentSettings.detailsCommoditiesLock = true;

          this.shipmentSettings.detailsDescriptionDefault =
            settings.details_description_default;
          if (this.shipmentSettings.detailsDescriptionDefault !== undefined)
            this.shipment.detailsDescriptionDefault = this.shipmentSettings.detailsDescriptionDefault;
        }

        this.loading = !this.loading;

        setTimeout(function() {
          $(".ui.fluid.dropdown").dropdown();
        }, 10);

        let vm = this;
        setTimeout(function() {
          // fill bottom sections and activate listeners

          vm.updateChargeable();

          $('[name*="detail_pieces_"]').on("keyup", function() {
            vm.updatePieces();
          });

          $('[name*="detail_wt_"]').on("keyup", function() {
            vm.updateTotalWeight();
          });

          $('[name*="detail_l_"]').on("keyup", function() {
            vm.updateDimWeight();
          });

          $('[name*="detail_w_"]').on("keyup", function() {
            vm.updateDimWeight();
          });

          $('[name*="detail_h_"]').on("keyup", function() {
            vm.updateDimWeight();
          });

          vm.deliveryChange();
          vm.pickupChange();
        }, 1000);
      })
      .catch(error => {
        this.errors = error;
        console.log(this.errors);
      });
  },
  mounted() {},
  updated: function() {},
  watch: {
  },
  methods: {
    deliveryChange() {
      if (this.shipmentSettings.deliveryServicesLock == true) {
        for (let key in this.defaultDrevenueTypes) {
          if (this.defaultDrevenueTypes.hasOwnProperty(key)) {
            if (
              this.shipmentSettings.deliveryServicesDefault.includes(
                this.defaultDrevenueTypes[key].Name
              )
            ) {
              $(
                ".delivery-select a[data-value='" +
                  this.defaultDrevenueTypes[key].Name +
                  "']"
              )
                .find(".delete")
                .addClass("hidden");
            }
          }
        }
      }
    },
    pickupChange() {
      if (this.shipmentSettings.pickupServicesLock == true) {
        for (let key in this.defaultPrevenueTypes) {
          if (this.defaultPrevenueTypes.hasOwnProperty(key)) {
            if (
              this.shipmentSettings.pickupServicesDefault.includes(
                this.defaultPrevenueTypes[key].Name
              )
            ) {
              $(
                ".pickup-select a[data-value='" +
                  this.defaultPrevenueTypes[key].Name +
                  "']"
              )
                .find(".delete")
                .addClass("hidden");
            }
          }
        }
      }
    },
    submit: function() {
      this.loading = !this.loading;

      let vm = this;

      // collect all pos
      $('[name*="po_"]').each(function() {
        let name = $(this).attr("name");
        let value = $(this).val();

        vm.shipment.detailsAndPos[name] = value;
      });

      // collect all detail info
      $('[name*="detail_"]').each(function() {
        let name = $(this).attr("name");
        let value = $(this).val();

        vm.shipment.detailsAndPos[name] = value;
      });

      let params = {
        Hwb: this.shipment.Hwb,
        service: this.shipment.service,
        customer_number: this.shipment.customerNumber,
        shipper_number: this.shipment.shipperNumber,
        shipper_name: this.shipment.shipperName,
        shipper_address: this.shipment.shipperAddress,
        shipper_address2: this.shipment.shipperAddress2,
        shipper_city: this.shipment.shipperCity,
        shipper_state: this.shipment.shipperState,
        shipper_zip: this.shipment.shipperZip,
        shipper_country: this.shipment.shipperCountry,
        shipper_contact: this.shipment.shipperContact,
        shipper_contact_phone_number: this.shipment.shipperPhone,
        shipper_ready_date: this.shipment.shipperShipmentReady,
        shipper_ready_time_to: this.shipment.shipperTimeTo,
        shipper_ready_time_from: this.shipment.shipperTimeFrom,
        consignee_number: this.shipment.consigneeNumber,
        consignee_name: this.shipment.consigneeName,
        consignee_address: this.shipment.consigneeAddress,
        consignee_address2: this.shipment.consigneeAddress2,
        consignee_city: this.shipment.consigneeCity,
        consignee_state: this.shipment.consigneeState,
        consignee_zip: this.shipment.consigneeZip,
        consignee_country: this.shipment.consigneeCountry,
        consignee_contact: this.shipment.consigneeContact,
        consignee_contact_phone_number: this.shipment.consigneePhone,
        req_delivery_date: this.shipment.consigneeRequestedDeliveryDate,
        req_delivery_time: this.shipment.consigneeRequestedDeliveryTime,
        pay_code: this.shipment.billToPaycode,
        bill_to_number: this.shipment.billToNumberDefault,
        bill_to_name: this.shipment.billToNameDefault,
        bill_to_address: this.shipment.billToAddressDefault,
        bill_to_address2: this.shipment.billToAddress2Default,
        bill_to_city: this.shipment.billToCityDefault,
        bill_to_state: this.shipment.billToStateDefault,
        bill_to_zip: this.shipment.billToZipDefault,
        bill_to_country: this.shipment.billToCountry,
        dv_or_ins: this.shipment.dvOrIns,
        dv_ins_amount: this.shipment.dvOrInsAmount,
        appt_required: this.shipment.apptReq,
        shipper_ref: this.shipment.shipperRef,
        consignee_ref: this.shipment.consigneeRef,
        purchase_orders: this.shipment.purchaseOrders,
        pickup_services: this.shipment.pickupServices,
        pickup_instructions: this.shipment.pickupInstructions,
        delivery_services: this.shipment.deliveryServices,
        delivery_instructions: this.shipment.deliveryInstructions,
        details_and_pos: this.shipment.detailsAndPos
      };

      this.$http
        .post("/shipment", params)
        .then(response => {
          this.data = response.data;

          this.loading = !this.loading;
          alert("Your Shipment has been created");
          location.reload();
        })
        .catch(error => {
          this.errors = error;
          console.log(error);
        });
    },
    addPo: function() {
      // display another purchase order input
      this.purchaseOrders.push(PurchaseOrder);
    },
    addDetail: function() {
      // display another row of details
      this.details.push(Details);

      let vm = this;

      // activate listeners
      setTimeout(function() {
        $(".ui.fluid.dropdown").dropdown();

        $('[name*="detail_pieces_"]').on("keyup", function() {
          vm.updatePieces();
        });

        $('[name*="detail_wt_"]').on("keyup", function() {
          vm.updateTotalWeight();
        });

        $('[name*="detail_l_"]').on("keyup", function() {
          vm.updateDimWeight();
        });

        $('[name*="detail_w_"]').on("keyup", function() {
          vm.updateDimWeight();
        });

        $('[name*="detail_h_"]').on("keyup", function() {
          vm.updateDimWeight();
        });
      }, 10);
    },
    addShipper: function() {
      $("#shipper_add_modal").modal("show");
    },
    searchShipper: function() {
      $("#shipper_search_modal").modal("show");
    },
    addConsignee: function() {
      $("#consignee_add_modal").modal("show");
    },
    searchConsignee: function() {
      $("#consignee_search_modal").modal("show");
    },
    searchBillTos: function() {
      $("#bill_to_search_modal").modal("show");
    },
    capitalize(e) {
      return e.value.toUpperCase();
    },
    customerNumberUpdate: function(e) {
      if (e.target.value) {
        var customer = customerProfiles[e.target.value];

        if (customer.Remarks !== null) {
          this.shipmentSettings.pickupInstructions = customer.Remarks;
          this.shipmentSettings.deliveryInstructions = customer.Remarks;
        }

        // set dim factor
        var dimFactor = customer.DimWtValue;
        dimFactor == 0.0 ? (dimFactor = 194) : "";

        var weightDefault = customer.WeightDefault;

        var totalWeight = this.footerTotalWeight;
        var dimWeight = this.footerDimWeight;

        switch (weightDefault) {
          case "G":
            if (totalWeight > dimWeight) {
              weightDefault = totalWeight;
            } else {
              weightDefault = dimWeight;
            }

            break;
          case "A":
            weightDefault = totalWeight;

            break;
          default:
            weightDefault = "";
            break;
        }

        this.footerDimFactor = dimFactor;
        this.footerChargeable = weightDefault;
      }
    },
    checkShipReadyDate: function(e) {
      let currDate = new Date();
      let date = new Date($("#ship_ready_date").val());
      date = new Date(date.setDate(date.getDate() + 1));

      if (date < currDate) {
        alert("Shipment ready date must be today or after.");
        $("#ship_ready_date").val("");
      }
    },
    checkShipReadyTime: function() {
      let from = $("#ship_ready_time_from")
        .val()
        .replace(":", "");
      let to = $("#ship_ready_time_to")
        .val()
        .replace(":", "");

      if (from > to) {
        alert("The Time From must be before To.");
        $("#ship_ready_time_from").val("");
        $("#ship_ready_time_to").val("");
      }
    },
    checkAllTimes: function(e, attr) {
      var value = e.target.value;
      if (value) {
        if (value.indexOf(":") === -1) {
          if (
            value.length < 4 ||
            parseInt(value).toString() < "0000" ||
            parseInt(value) > 2400
          ) {
            alert("Must be between 0000 and 2400.");
            if ($value) {
              value = "";
            }
          } else {
            let splitHour = value.split("");
            if(splitHour[2] + "" + splitHour[3] > 59 ||
              splitHour[2] + "" + splitHour[3] < 0) {
              alert("The minute must be between 00 and 59.");
              value = "";
            } else {
              value = splitHour[0] +
                "" +
                splitHour[1] +
                ":" +
                splitHour[2] +
                "" +
                splitHour[3];
            }
          }
        } else {
          let hour = value.replace(/:/g, "");
          hour = hour.replace(/[^0-9 ]/g, "");
          if (
            hour.length < 4 ||
            hour.length > 4 ||
            parseInt(hour).toString() < "0000" ||
            parseInt(hour) > 2400
          ) {
            alert("Must be between 0000 and 2400.");
            if (hour) {
              value = "";
            }
          } else {
            if (hour.slice(2) > 59 || hour.slice(2) < 0) {
              alert("The minute must be between 00 and 59.");
              value = "";
            } else {
              var result = hour.slice(0, 2) + ":" + hour.slice(2);
              value = result;
            }
          }
        }
      }
      var segments = attr.split(".");
      this.$set(this.$data[segments[0]], segments[1], value);
    },
    checkReqDeliveryDate: function(e) {
      let currDate = new Date();
      let date = new Date($("#req_delivery_date").val());
      date = new Date(date.setDate(date.getDate() + 1));

      let shipDate = new Date($("#ship_ready_date").val());
      shipDate = new Date(shipDate.setDate(shipDate.getDate() + 1));

      if (date < currDate || date < shipDate) {
        alert(
          "Requested delivery date must be today or after and later than Shipment Ready date."
        );
        $("#req_delivery_date").val("");
      }
    },
    checkNmfc: function() {
      if ($(this).val() && $(this).val().length !== 6) {
        alert("Must be 6 digits.");
        if ($(this).val()) {
          $(this).val("");
        }
        setTimeout(() => {
          $(this).focus();
        }, 500);
      }
    },
    updatePieces: function() {
      let count = 0;
      $('[name*="detail_pieces"]').each(function() {
        let value = parseInt($(this).val());

        count += value;
      });

      this.footerPieces = count;

      this.updateChargeable();
    },
    updateTotalWeight: function() {
      let count = 0;
      $('[name*="detail_wt"]').each(function() {
        let value = parseInt($(this).val());

        count += value;
      });

      this.footerTotalWeight = count;

      this.updateChargeable();
    },
    updateDimWeight: function() {
      let count = 0;

      let detailCount;
      let l;
      let w;
      let h;
      $('[id*="detail_fields_"]').each(function() {
        detailCount = $(this)
          .attr("id")
          .slice(-1);

        l =
          parseInt($("[name=detail_l_" + detailCount + "]").val()) > 0
            ? parseInt($("[name=detail_l_" + detailCount + "]").val())
            : 0;
        w =
          parseInt($("[name=detail_w_" + detailCount + "]").val()) > 0
            ? parseInt($("[name=detail_w_" + detailCount + "]").val())
            : 0;
        h =
          parseInt($("[name=detail_h_" + detailCount + "]").val()) > 0
            ? parseInt($("[name=detail_h_" + detailCount + "]").val())
            : 0;

        count += l * w * h;
      });

      this.footerDimWeight = Math.ceil(count / this.footerDimFactor);

      this.updateChargeable();
    },
    updateChargeable: function() {
      let vm = this;
      let number;
      let customer;
      let customerNumber;
      let dimFactor;
      let weightDefault;
      let totalWeight;
      let dimWeight;
      setTimeout(function() {
        // fill bottom sections
        number = $("#customer_number").val();

        customerNumber = vm.customerNumbers.filter(function(num) {
          return num.id == number;
        })[0];

        customer = vm.customerProfiles[customerNumber.number];

        dimFactor = customer.DimWtValue;

        // set dimFactor based on customers DimWtValue
        dimFactor == 0.0
          ? (vm.footerDimFactor = 194)
          : (vm.footerDimFactor = 0);

        weightDefault = customer.WeightDefault;

        totalWeight = vm.footerTotalWeight;
        dimWeight = vm.footerDimWeight;

        // set weight default to Greater or Actual
        switch (weightDefault) {
          case "G":
            if (totalWeight > dimWeight) {
              weightDefault = totalWeight;
            } else {
              weightDefault = dimWeight;
            }

            break;
          case "A":
            weightDefault = totalWeight;

            break;
          default:
            weightDefault = "";
            break;
        }

        vm.footerChargeable = weightDefault;
      }, 0);
    }
  },
  computed: {}
};
</script>

<style lang="scss">
.hidden {
  display: none !important;
}

#shipment_create {
  margin: $layoutmargin;

  .ui.grid.dropdowns {
    input,
    select {
      border-radius: 20px !important;
    }

    .ui.fluid.dropdown {
      width: 200px !important;
      height: 40px;
      border-radius: 20px !important;
      border: 2px solid $algdarkblue25opac;
      margin-left: 10px;
    }

    .one.wide.column.hawb_number,
    .one.wide.column.service,
    .two.wide.column.customer_number {
      text-align: right;
      padding-top: 23px;
      padding-right: 0px;
    }
  }

  .shipment {
    .ui.header {
      padding: 14px 0px 14px 16px;
      background-color: #f4f4f4;
      border-radius: 8px;
    }

    .read-only {
      color: lightgrey !important;
    }

    .ui.grid {
      .column {
        padding-bottom: 0px;

        input,
        select,
        textarea {
          border: 2px solid $algdarkblue25opac;
        }

        .ui.icon.input {
          width: 100%;

          .search.icon {
            left: 0;
            color: $algdarkblue;
            cursor: pointer;
            z-index: 1000;
          }
        }
      }
    }

    .left_side {
      padding-right: 40px;
    }

    .right_side {
      padding-left: 40px;
    }

    .details {
      input,
      div.dropdown {
        margin-left: 15px !important;
      }

      .nmfc2 {
        input {
          margin-top: 28px;
        }
      }

      .add_detail {
        cursor: pointer;

        i {
          display: block !important;
          margin: 0 auto !important;
          padding-top: 40px;
          text-align: center;
          padding-right: 0;
        }

        .fa.fa-plus {
          color: $alglightblue;
        }

        .fa.fa-minus {
          color: $algred;
        }
      }
    }

    .totals {
      .column {
        padding-bottom: 1rem !important;
      }

      .ui.header {
        background-color: #0d4b6a20;

        color: $algdarkblue;
      }
    }

    .bill_to {
      padding-bottom: 60px;
    }

    .appt_info {
      padding-left: 20px;
      label {
        color: $alggrey !important;
        font-weight: 400 !important;
        padding-left: 40px !important;
      }

      input {
        width: 100%;
      }
    }

    .po_column {
      padding-bottom: 40px !important;

      .ui.grid {
        padding-left: 15px;
      }

      i {
        display: block !important;
        margin: 0 auto !important;
        text-align: center;
        padding-right: 20px;
        cursor: pointer;
      }

      .fa.fa-plus {
        color: $alglightblue;
      }

      .fa.fa-minus {
        color: $algred;
        padding-top: 15px;
      }
    }

    .terms_agree {
      text-align: right;

      label {
        padding-left: 35px !important;
      }
    }

    label {
      color: $algdarkblue;
      font-weight: 600;
      padding-left: 15px;
    }

    #shipper_number,
    #consignee_number {
      padding-left: 30px;
    }

    .fa.fa-plus {
      color: $alglightblue;
      padding-top: 14px;
      padding-right: 20px;
      margin-left: -8px;
      cursor: pointer;
    }

    .two.wide.column.to p {
      color: $algdarkblue !important;
      padding-left: 15px;
      padding-top: 7px;
    }
  }

  .ui.fluid.dropdown,
  input {
    // width: 100% !important;
    border: 2px solid $algdarkblue25opac;
  }

  .asterik {
    color: $algred;
  }

  .ui.icon.input > i.icon:not(.link) {
    pointer-events: unset;
  }
}
</style>
