<template>
  <div id="details">
    <div class="details-container">
      <div class="ui grid head">
        <div class="five wide computer five wide tablet twelve wide mobile column">
          <h1>Tracking #{{ hawbNumber }}</h1>
        </div>
        <div class="eleven wide computer eleven wide tablet four wide mobile column print-container">
          <button v-if="!loading" class="ui right floated button prim" @click="printPdf()">
            <i class="fas fa-print"></i>Print PDF
          </button>
          <button v-if="delivered != 10 && !loading && !locationHistory" class="ui right floated button prim" @click="createNotification()">
            <i class="fas fa-map-marker-alt"></i>Get Location Updates
          </button>
        </div>
      </div>

      <div class="ui divider"></div>

      <div
        class="ui active centered inline massive loader"
        v-if="loading"
      ></div>

      <div class="ui grid shipment" id="shipment" v-else>
        <div class="four wide computer five wide tablet sixteen wide mobile column tracker">
          <Tracker
            class="ui sticky"
            id="tracker"
            :status="hawbData.status"
            :lastLocation="lastLocation"
            :lastLocationDateTime="lastLocationDateTime"
            :signedBy="hawbData.hawb.PODName"
            :podDate="podDate"
            :podTime="hawbData.hawb.PODTime"
            :dotStatuses="dotStatuses"
            :consignee="hawbData.consignee"
            :domestic="this.isDomestic"
            :out_for_delivery_data="out_for_delivery_data"
            :pickup_data="pickup_data"
            :oh_data="oh_data"
            :location_button="!locationHistory"
            :location_history_count="locationHistoryCount"
            :miles_to_go="miles_to_go"
          />
        </div>
        <div
          class="twelve wide computer ten wide tablet sixteen wide mobile column details"
          id="shipment-details"
        >
          <h2 class="ui header">Shipment details</h2>
          <p>
            Estimated Delivery Dates are approximate. Some shipments may require
            an appointment or have Specialized Delivery requirements. For
            additional information, please contact the controlling ALG Terminal
            at
            <a
              class="control_number"
              :href="'tel:+' + hawbData.control.PhoneNbr"
              v-if="hawbData.control.PhoneNbr !== undefined"
            >
              {{ hawbData.control.PhoneNbr }}</a
            ><a class="control_number" href="tel:+630-766-3900" v-else
              >630-766-3900</a
            >.
          </p>
          <div class="ui grid">
            <div class="eight wide computer eight wide tablet sixteen wide mobile column">
              <div class="ui grid">
                <div class="sixteen wide column">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>Origin:</h3>
                    </div>
                    <div class="ten wide column">
                      {{ hawbData.shipper.City }}, {{ hawbData.shipper.State }}
                      {{ hawbData.shipper.Zip }}
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>Shipper Ref:</h3>
                    </div>
                    <div class="ten wide column">
                      <p>{{ hawbData.hawb.ShipReference }}</p>
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>Ship Date/Time:</h3>
                    </div>
                    <div class="ten wide column">
                      <p>
                        {{ hawbData.hawb.HAWBDate
                        }}{{
                          hawbData.hawb.ReadyTime !== null &&
                          hawbData.hawb.CloseTime
                            ? " / " +
                              hawbData.hawb.ReadyTime +
                              " - " +
                              hawbData.hawb.CloseTime
                            : ""
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>Service:</h3>
                    </div>
                    <div class="ten wide column">
                      <p>{{ hawbData.service.Description }}</p>
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>DV/INS:</h3>
                    </div>
                    <div class="ten wide column">
                      <p v-if="hawbData.hawb.DeclaredValue > 0">${{ parseFloat(hawbData.hawb.DeclaredValue).toFixed(2) }}</p>
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>Dim Factor:</h3>
                    </div>
                    <div class="ten wide column">
                      <p>{{ parseFloat(hawbData.hawb.DimFactor).toFixed(1) }}</p>
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column" v-show="showMiles">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>Total Miles:</h3>
                    </div>
                    <div class="ten wide column">
                      <p>{{ miles }}</p>
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>Dims:</h3>
                    </div>
                    <div class="ten wide column">
                      <span v-for="(dim, index) in hawbData.dims" :key="index">
                        {{ dim.Pieces }} @ {{ dim.Length }} X {{ dim.Width }} X
                        {{ dim.Height }} <br />
                      </span>
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column description">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>Description:</h3>
                    </div>
                    <div class="ten wide column">
                      <p>{{ hawbData.hawb.Description }}</p>
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column pos">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>PO Number(s):</h3>
                    </div>
                    <div class="ten wide column">
                      {{ formatPos(hawbData.pos) }}
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>Appt. Date/Time:</h3>
                    </div>
                    <div class="ten wide column">
                      <p>
                        {{
                          hawbData.hawb.ApptDate
                            ? moment(hawbData.hawb.ApptDate).format(
                                "MM-DD-YYYY"
                              )
                            : ""
                        }}{{
                          hawbData.hawb.ApptTime
                            ? " / " + hawbData.hawb.ApptTime.slice(0, -3)
                            : ""
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column appt_notes">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>Appt. Notes:</h3>
                    </div>
                    <div class="ten wide column">
                      <p>{{ hawbData.hawb.ApptNotes }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="eight wide computer eight wide tablet sixteen wide mobile column">
              <div class="ui grid">
                <div class="sixteen wide column">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>Destination:</h3>
                    </div>
                    <div class="ten wide column">
                      {{ hawbData.consignee.City }},
                      {{ hawbData.consignee.State }} 
                      {{ hawbData.consignee.Zip }}
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>Consignee Ref:</h3>
                    </div>
                    <div class="ten wide column">
                      {{ hawbData.hawb.ConsReference }}
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>Est. Delivery:</h3>
                    </div>
                    <div class="ten wide column">
                      {{ hawbData.hawb.DueDate }}
                      {{
                        hawbData.hawb.DueTime !== null
                          ? " / " + hawbData.hawb.DueTime
                          : ""
                      }}
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>Pieces:</h3>
                    </div>
                    <div class="ten wide column">
                      {{ hawbData.hawb.Pieces }}
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>Weight:</h3>
                    </div>
                    <div class="ten wide column">
                      {{ hawbData.hawb.Weight }}
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>Chargeable Weight:</h3>
                    </div>
                    <div class="ten wide column">
                      {{ hawbData.hawb.ChargeableWeight }}
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>Commodity:</h3>
                    </div>
                    <div class="ten wide column">
                      {{
                        hawbData.commodity != null
                          ? hawbData.commodity.Description
                          : ""
                      }}
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>Class:</h3>
                    </div>
                    <div class="ten wide column">
                      {{ hawbData.hawb.FrtClass }}
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column">
                  <div class="ui grid">
                    <div class="six wide column">
                      <h3>Estimated CO<sub>2</sub> Emission:</h3>
                    </div>
                    <div class="ten wide column">
                      {{ this.co2 }} Metric Tons <span style="cursor:pointer" v-tooltip="'This is an estimation based on the miles driven, weight/dimensions of shipment and the average CO2 emissions of a standard truck. If the value is N/A, CO2 emission could not be calculated.'" ><i class="fas fa-info-circle"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <h2 v-show="piecesDetail.length > 0" class="ui header">
            Pieces Detail
          </h2>
          <ClientTable
            class="table"
            v-show="piecesDetail.length > 0"
            :columns="piecesDetailTable.columns"
            :tableData="piecesDetail"
            :options="piecesDetailTable.options"
          />

          <h2 class="ui header inter_ocean" v-show="interOcean">
            Ocean Shipment Details
          </h2>
          <div class="ui grid" v-show="interOcean">
            <div class="eight wide column">
              <h3>
                Destination Port:
                <span>{{
                  hawbData.ocean_ship ? hawbData.ocean_ship.dest.PortName : ""
                }}</span>
              </h3>
              <h3>
                Vessel Name:
                <span>{{
                  hawbData.ocean_ship ? hawbData.ocean_ship.VesselName : ""
                }}</span>
              </h3>
              <h3>
                Vessel Number:
                <span>{{
                  hawbData.ocean_ship ? hawbData.ocean_ship.VesselNumber : ""
                }}</span>
              </h3>
              <h3>
                Origin Port:
                <span>{{
                  hawbData.ocean_ship ? hawbData.ocean_ship.origin.PortName : ""
                }}</span>
              </h3>
            </div>
            <div class="eight wide column">
              <h3>
                Departure Port:
                <span>{{
                  hawbData.ocean_ship ? hawbData.ocean_ship.depart.PortName : ""
                }}</span>
              </h3>
              <h3>
                Booking Number:
                <span>{{
                  hawbData.ocean_ship ? hawbData.ocean_ship.BookingNbr : ""
                }}</span>
              </h3>
              <h3>
                Place of Delivery:
                <span>{{
                  hawbData.ocean_ship ? hawbData.ocean_ship.dest.PortName : ""
                }}</span>
              </h3>
              <h3>
                Voyage Info:
                <span>{{
                  hawbData.mawb ? hawbData.mawb.FlightInformation : ""
                }}</span>
              </h3>
              <h3>
                Containers:
                <span>{{ containers ? containers.join(", \n") : "" }}</span>
              </h3>
            </div>
          </div>

          <h2
            class="ui header"
            v-show="hawbData.mawb_air && hawbData.flights.length > 0"
          >
            Flight Details
          </h2>
          <ClientTable
            class="table"
            v-show="hawbData.mawb_air && hawbData.flights.length > 0"
            :columns="flightDetailsTable.columns"
            :tableData="flights"
            :options="flightDetailsTable.options"
          />

          <h2 class="ui header tracking_alert" v-show="trackingAlert">
            Tracking Alert
          </h2>
          <ClientTable
            class="table"
            v-show="trackingAlert"
            :columns="trackingAlertTable.columns"
            :tableData="trackingAlertComments"
            :options="trackingAlertTable.options"
          />

          <Accordion
            class="table"
            v-show="locationHistoryComments.length > 0"
            :columns="locationHistoryTable.columns"
            :tableData="locationHistoryComments"
            :options="locationHistoryTable.options"
            :markers="locationHistoryMarkers"
            :finalMarkers="locationHistoryFinalMarkers"
            :showMap="show_distance_to"
            ><div slot="header">Location History</div>
          </Accordion>
          

          <Accordion
            class="table"
            v-show="!trackingHistory"
            :columns="trackingHistoryTable.columns"
            :tableData="trackingHistoryComments"
            :options="trackingHistoryTable.options"
            :showMap="false"
            ><div slot="header">Status History</div>
          </Accordion>
        </div>
      </div>
    </div>
    <div 
      v-if="!loading"
      class="" 
      style="-webkit-box-shadow: 0px -3px 6px #00000029;box-shadow: 0px -3px 6px #00000029; font-size:10px;padding:20px;line-height:15px" >
        The information and images found on this site are believed to be accurate, to the fullest extent permissible by applicable law, ALG Worldwide disclaims all warranties, expressed or implied, regarding the accuracy, completeness, reliability, or usefulness of the Information and is not liable for any damages arising in any way out of the use of the Information, including, but not limited to direct, indirect, incidental, punitive and consequential damages. The Information is not a representation, recommendation or guaranty of any route taken or to be taken by a carrier, nor is it a commitment by or obligation of any carrier to take a specific route.
    </div>

    <ErrorModal />
    <NotificationModal
      :hawb_number="hawbNumber"
     />
    <MapModal
      :markers="locationHistoryMarkers"
      :initialMarkers="locationHistoryInitialMarkers"
      :finalMarkers="locationHistoryFinalMarkers"
      :center ="mapCenter"
      :bounds ="mapBounds"
      :path="locationPath"
      :paths="route_polys"
      :delivered="parseInt(delivered)"
    >
    </MapModal>
  </div>
</template>
<script src="https://apis.google.com/js/api.js?callback=initialize" type="text/javascript"></script>
<script type="text/javascript">
  gapi.load('auth2', function() {
    // Library loaded.
  });

</script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "print-js";

import ClientTable from "@/components/tables/ClientTable.vue";
import Accordion from "@/components/tables/Accordion.vue";
import Tracker from "@/components/Tracker.vue";
import MapModal from "@/components/modals/MapModal.vue";
import ErrorModal from "@/components/modals/ErrorModal.vue";
import NotificationModal from "@/components/modals/NotificationModal.vue";
import {gmapApi} from 'vue2-google-maps'
export default {
  components: {
    ClientTable,
    Tracker,
    MapModal,
    ErrorModal,
    NotificationModal,
    Accordion
  },
  data() {
    return {
      loading: true,

      shipper_geo:null,
      consignee_geo:null,

      hawbNumber: this.$route.query.hawb_number,
      hawbData: null,
      piecesDetail: [],
      trackingAlert: false,
      trackingAlertComments: [],
      trackingHistory: false,
      trackingHistoryComments: [],
      locationHistory: false,
      locationHistoryCount: false,
      locationHistoryComments: [],
      lastLocationHistoryComments: [],
      locationHistoryMarkers: [],
      locationHistoryInitialMarkers: [],
      isNonDTC: false,
      isDomestic: false,
      mapCenter: {lat: 0,lng: 0},
      mapBounds: {},
      filterOutDupes: [],
      uniqueLocationHistory: [],
      locationHistoryFinalMarkers: [],
      directionsService: {},
      nonbindedPath: {},
      delivered: 0,
      miles_to_go: null,
      co2: 0,
      co2_savings: 0,
      lastLocation: null,
      lastLocationDateTime: null,
      show_distance_to: false,
      out_for_delivery_data: null,
      pickup_data: null,
      route_polys: new Array(),
      oh_data: null,
      dotStatuses: null,
      interOcean: false,
      containers: [],
      flights: [],
      showMiles: false,
      podDate: "",
      arrivalDate: "",
      departDate: "",
      actualDelivery: null,
      piecesDetailTable: {
        columns: [
          "pieces",
          "weight",
          "length",
          "width",
          "height",
          "description",
          "commodity",
          "class",
          "serial_num"
        ],
        options: {
          headings: {
            pieces: "Pieces",
            weight: "Weight",
            length: "Length",
            width: "Width",
            height: "height",
            description: "Description",
            commodity: "Commodity",
            class: "Class",
            serial_num: "Serial #"
          },
          sortable: [
            "hawb_number",
            "ship_date",
            "due_date",
            "pcs_wt",
            "service",
            "shipper",
            "shipper_ref",
            "consignee",
            "consignee_ref",
            "status"
          ],
          filterable: false,
          perPage: 10,
          perPageValues: [10, 25, 50],
          texts: {
            filter: "",
            filterBy: "Search by {column}",
            count: ""
          },
          pagination: { chunk: 10, dropdown: false }
        }
      },
      flightDetailsTable: {
        columns: ["carrier", "flt", "origin", "departure", "dest", "arrival"],
        options: {
          headings: {
            carrier: "Carrier",
            flt: "Flt",
            origin: "Origin",
            departure: "Departure",
            dest: "Dest",
            arrival: "Arrival"
          },
          sortable: [],
          filterable: false,
          perPage: 100,
          perPageValues: [10, 25, 50],
          texts: {
            filter: "",
            filterBy: "Search by {column}",
            count: ""
          },
          pagination: { chunk: 10, dropdown: false }
        }
      },
      trackingAlertTable: {
        columns: ["date", "time", "message"],
        options: {
          headings: {
            date: "Date",
            time: "Time (PST)",
            message: "Message"
          },
          sortable: [],
          filterable: false,
          perPage: 100,
          perPageValues: [10, 25, 50],
          texts: {
            filter: "",
            filterBy: "Search by {column}",
            count: ""
          },
          pagination: { chunk: 10, dropdown: false }
        }
      },
      trackingHistoryTable: {
        columns: ["date", "time", "message"],
        options: {
          headings: {
            date: "Date",
            time: "Time (PST)",
            message: "Message"
          },
          sortable: [],
          filterable: false,
          perPage: 100,
          perPageValues: [10, 25, 50],
          texts: {
            filter: "",
            filterBy: "Search by {column}",
            count: ""
          },
          pagination: { chunk: 10, dropdown: true }
        }
      },
      locationHistoryTable: Array
    };
  },
  created() {
    this.$http
      .get("/track/quick/details?hawb_number=" + this.hawbNumber)
      .then(response => {
        this.hawbData = response.data.hawb_data;

        this.interOcean = response.data.inter_ocean;
        this.arrivalDate = response.data.arrival_date;
        this.departDate = response.data.depart_date;
        this.podDate = response.data.pod_date;
        this.showMiles = response.data.show_miles;
        this.show_distance_to = response.data.show_distance_to;
        this.out_for_delivery_data =
          response.data.hawb_data.out_for_delivery_data;
        this.pickup_data = response.data.hawb_data.pickup_data;
        this.shipper_geo = response.data.shipper_geo;
        this.consignee_geo = response.data.consignee_geo;
        // Calculate if this is a Non-DTC hawb
        if(!Object.keys(response.data.hawb_data.mawb).length || response.data.hawb_data.mawb.DTCAgent == null){
          this.isNonDTC = true;
        }
        //Calculate if the hawb is Domestic
        if(response.data.hawb_data.hawbtype.HawbTypeCode == 'Domestic'){
          this.isDomestic = true;
        }
        //Table if we want to show distance
        if (response.data.show_distance_to) {
          this.locationHistoryTable = {
            columns: ["date", "time", "message", "distance"],
            options: {
              headings: {
                date: "Date",
                time: "Time (Local)",
                message: "Location",
                distance: "Distance to Destination"
              },
              sortable: [],
              filterable: false,
              perPage: 100,
              perPageValues: [10, 25, 50],
              texts: {
                filter: "",
                filterBy: "Search by {column}",
                count: ""
              },
              pagination: { chunk: 10, dropdown: false }
            }
          };
        } else {
          this.locationHistoryTable = {
            columns: ["date", "time", "message"],
            options: {
              headings: {
                date: "Date",
                time: "Time (Local)",
                message: "Location"
              },
              sortable: [],
              filterable: false,
              perPage: 100,
              perPageValues: [10, 25, 50],
              texts: {
                filter: "",
                filterBy: "Search by {column}",
                count: ""
              },
              pagination: { chunk: 10, dropdown: false }
            }
          };
        }

        if (response.data.hawb_data.pieces_detail != null) {
          let detailKeys = Object.keys(response.data.hawb_data.pieces_detail);
          if (detailKeys.length > 1) {
            this.piecesDetail = detailKeys.map(function(key) {
              let detail = response.data.hawb_data.pieces_detail[key];

              return {
                pieces: detail.Pieces,
                weight: detail.Weight,
                length: detail.dim != null ? detail.dim.Length : "",
                width: detail.dim != null ? detail.dim.Width : "",
                height: detail.dim != null ? detail.dim.Height : "",
                description:
                  detail.Description != null ? detail.Description : "",
                commodity:
                  detail.commodity != null ? detail.commodity.Code : "",
                class: detail.FrtClass != null ? detail.FrtClass : "",
                serial_num:
                  detail.SerialNumber != null ? detail.SerialNumber : ""
              };
            });
          }
        }
        

        this.delivered = response.data.hawb_data.status.OperationOrder;

        this.trackingAlert = response.data.tracking_alert;
        if (
          this.trackingAlert &&
          response.data.hawb_data.hawb_comments !== undefined
        ) {
          this.trackingAlertComments = response.data.hawb_data.hawb_comments
            .map(function(comment) {
              if (comment != null && comment.Code == "CUST") {
                return {
                  date: comment.CommentDate,
                  time: comment.CommentTime.slice(0, 5),
                  message: comment.Comments
                };
              }
            })
            .filter(function(el) {
              return el != null;
            });
        }
        this.trackingHistory = response.data.tracking_history;
        this.mapBounds = { ne: { lat: 0, lng: 0 }, sw: { lat: 0, lng: 0 } };
        
        if (
          !this.trackingHistory &&
          response.data.hawb_data.hawb_comments !== undefined
        ) {
          this.trackingHistoryComments = response.data.hawb_data.hawb_comments.map(
            function(comment) {

              if (comment.Code == "STS") {
                return {
                  date: comment.CommentDate,
                  time: comment.CommentTime.slice(0, 5),
                  message: comment.Comments
                };
              }
            }
          );
        }
        this.locationHistory = response.data.location_history;
        this.locationHistoryCount = 0;
        if (
          this.locationHistory == false &&
          response.data.hawb_data.location_history !== undefined
        ) {
          //Start Filtering out Duplicates, Transoft experienced an issue in which records were being duplicated producing
          //a large amount of records with the same timestamp/city/state
          response.data.hawb_data.location_history.forEach(element => {
            if(!this.filterOutDupes.includes(element.StatusTimestampLocal.split('.')[0] + ' ' + element.City + ' ' + element.State)) {
              this.filterOutDupes.push(element.StatusTimestampLocal.split('.')[0] + ' ' + element.City + ' ' + element.State);
              this.uniqueLocationHistory.push(element);
              //Lets reuse this loop to find the center of the map
              this.mapCenter = {};
              //Calculate the NorthEast bounds of the map
              //Northwest bound is greatest point possitive
              if(this.mapBounds.ne.lat == 0 && this.mapBounds.ne.lng == 0){
                this.mapBounds.ne.lat = element.lat;
                this.mapBounds.ne.lng = element.lng;
              }
              else{
                if(element.lat > this.mapBounds.ne.lat)
                  this.mapBounds.ne.lat = element.lat;
                if(element.lng > this.mapBounds.ne.lng)
                  this.mapBounds.ne.lng = element.lng;
              }

              //Calculate the SouthWest bounds of map
              //sw bound is greatest point negative
              if(this.mapBounds.sw.lat == 0 && this.mapBounds.sw.lng == 0){
                this.mapBounds.sw.lat = element.lat;
                this.mapBounds.sw.lng = element.lng;
              }
              else{
                if(element.lat < this.mapBounds.sw.lat)
                  this.mapBounds.sw.lat = element.lat;
                if(element.lng < this.mapBounds.sw.lng)
                  this.mapBounds.sw.lng = element.lng;
              }
              if(element.lat && element.lng){
                this.locationHistoryCount = this.locationHistoryCount + 1;
              }
            }
            
          });
          //this.locationHistoryCount = response.data.hawb_data.location_history.length;

          //Set Map center after location markers have been calculated
          //Lat and Lng of bounds are added together than multiplied by 0.5 to find the mid point (multiplcation by .5 is more efficient than division by 2)
          this.mapCenter = {lat: (this.mapBounds.sw.lat + this.mapBounds.ne.lat)*0.5, lng: ((this.mapBounds.sw.lng + this.mapBounds.ne.lng)*0.5)};
          //Set this filtered list to be the only records shown
          this.locationHistoryComments = this.uniqueLocationHistory
            .filter(location => {
              return location.State;
            })
            .map(function(comment) {
              var split_date = comment.StatusTimestampLocal.split(" ")[0].split(
                "-"
              );
              var formatted_date =
                split_date[1] + "-" + split_date[2] + "-" + split_date[0];

              if (comment.City == null || comment.State == null) {
              } else {
                var date_full = comment.StatusTimestampLocal.split(" ")[1]
                    .split(".")[0];
                return {
                  id: comment.ID,
                  date: formatted_date,
                  time: date_full.length > 5 ? date_full.slice(0,-3) : date_full,
                  // Message is used to define the location in the accordian as we only want city/state
                  message: comment.City + ", " + comment.State,
                  // Not all Location history items have a full address, so we only add the address segments if we have them, this is used to differentiate uniques when we populate the polyline route on the map
                  full_address: (comment.Address1 == null ? '' : comment.Address1 + ' ') + comment.City + ", " + comment.State + (comment.Zip == null ? '' : ' ' + comment.Zip),
                  // If lat/lng don't exist it means the data coming from the transoft system does not validly translate to a lat/lng object so we pass in the city/state to let google maps try to decipher it
                  latlng: (comment.lat != null && comment.lng != null ? new google.maps.LatLng(comment.lat, comment.lng) : comment.City + ", " + comment.State),
                  distance: comment.miles_to_destination + " miles"
                };
              }

              
            });
          var allowedOperationOrders = [5, 6, 9];
          if (!allowedOperationOrders.includes(parseInt(this.hawbData.status.OperationOrder))) {
            this.lastLocationHistoryComments = this.locationHistoryComments.filter(
              location => {
                return location.id > -1;
              }
            );
          } else {
            this.lastLocationHistoryComments = this.locationHistoryComments;
          }

          if (this.lastLocationHistoryComments[0] && this.lastLocationHistoryComments[0].message != null) 
          {
            this.lastLocation = this.lastLocationHistoryComments[0].message;
            this.lastLocationDateTime =
              this.lastLocationHistoryComments[0].date +
              " " +
              this.lastLocationHistoryComments[0].time;
          }

          
          //We need to push the position object for the pin location and the address text for the infowindow
          var shipper_full_address = response.data.hawb_data.shipper.Address1 + (response.data.hawb_data.shipper.Address2 ? ' ' + response.data.hawb_data.shipper.Address2 + ' ' : ' ') + response.data.hawb_data.shipper.City + ', ' + response.data.hawb_data.shipper.State + ' ' + response.data.hawb_data.shipper.Zip;
          this.locationHistoryInitialMarkers.push({address: shipper_full_address,position: {lat: response.data.hawb_data.shipper.lat, lng: response.data.hawb_data.shipper.lng}});
          if (response.data.show_distance_to) {
            if (this.hawbData.status.OperationOrder == 10) // Operation 10 = Delivered
            {
              this.locationHistoryMarkers = response.data.hawb_data.location_history
                .filter(location => {
                  return (
                    location.City != response.data.hawb_data.consignee.City
                  );
                }).filter(location => {
                  return (
                    // We want to remove the pin of the city of the shipment for Non-DTC shipments only if the shippers's city
                    // and state are equal to that of the pin
                    this.isNonDTC ? location.City != response.data.hawb_data.shipper.City : true
                  );
                })
                .map(function(comment) {
                  return {
                    position: {
                      lat: comment.lat,
                      lng: comment.lng
                    }
                  };
                })
                .filter(location => {
                  return location.position.lat != undefined;
                });
              var consignee_full_address = this.hawbData.consignee.Address1 + (this.hawbData.consignee.Address2 ? ' ' + this.hawbData.consignee.Address2 + ' ' : ' ') + this.hawbData.consignee.City + ', ' + this.hawbData.consignee.State + ' ' + this.hawbData.consignee.Zip;
              this.locationHistoryFinalMarkers[0] = {address: consignee_full_address, position: {lat: this.hawbData.consignee.lat, lng: this.hawbData.consignee.lng}};
             
             // this.locationPath = response.data.hawb_data.location_history.map(function(position) {
              //   return {
              //     lat: position.position.lat,
              //     lng: position.position.lng
              //   }
              // });
              // this.locationPath.unshift(this.locationHistoryFinalMarkers[0].position);
            } else {
              this.locationHistoryMarkers = response.data.hawb_data.location_history
                .map(function(comment) {
                  return {
                    position: {
                      lat: comment.lat,
                      lng: comment.lng
                    },
                    City: comment.City,
                    State: comment.State,
                  };
                })
                .filter(location => {
                  return location.position.lat != undefined;
                })
                .filter(location => {
                  // We want to remove the pin of the city of the shipment for Non-DTC shipments only if the shippers's city
                  // and state are equal to that of the pin
                  return (
                    this.isNonDTC ? location.City != response.data.hawb_data.shipper.City : true
                  );
                });
              var consignee_full_address = this.hawbData.consignee.Address1 + (this.hawbData.consignee.Address2 ? ' ' + this.hawbData.consignee.Address2 + ' ' : ' ') + this.hawbData.consignee.City + ', ' + this.hawbData.consignee.State + ' ' + this.hawbData.consignee.Zip;
              this.locationHistoryFinalMarkers[0] = {address: consignee_full_address, position: {lat: this.hawbData.consignee.lat, lng: this.hawbData.consignee.lng}};
            }
          }
        }
        this.directionsService = new google.maps.DirectionsService();
        if(this.locationHistoryComments[0]) {
          this.miles_to_go = this.locationHistoryComments[0].distance + " to Destination";
        }
        var allowedOHOrders = [7, 8];
        if (
          allowedOHOrders.includes(
            parseInt(this.hawbData.status.OperationOrder)
          )
        ) {
          this.oh_data = this.hawbData.oh_location_data;
        }

        if (response.data.dot_statuses) {
          this.dotStatuses = response.data.dot_statuses;
        }

        if (response.data.hawb_data.flights !== undefined) {
          if(typeof response.data.hawb_data.flights === 'object' && response.data.hawb_data.flights !== null){
            //Sometimes this data is returned as an object instead of an array, here we covert to array
            response.data.hawb_data.flights = Object.values(response.data.hawb_data.flights);
          }
          this.flights = response.data.hawb_data.flights.map(function(flight) {
            return {
              carrier: flight.carrier,
              flt: flight.flt,
              origin: flight.origin,
              departure: flight.departure_time,
              dest: flight.destination,
              arrival: flight.arrival_time
            };
          });
        }

        if(this.interOcean) {
          if(response.data.hawb_data.mawb.containers != undefined) {
          this.containers = Object.keys(response.data.hawb_data.mawb.containers).map(function(key) {
            let current = response.data.hawb_data.mawb.containers[key];

            let container = current.ContainerNbr + " - " + current.type.ContainerName;

            return container;
          });
          } else {
            return "No - Container";
          }
        }

        // setTimeout(function() {
        //   $(".ui.sticky").sticky({
        //     context: "#shipment"
        //   });
        // }, 1000);

        this.loading = !this.loading;
      })
      .catch(error => {
        this.errors = error;
        $(".ui.error_modal.modal").modal("show");
        //this.router.push('/');
      });
  },
  mounted() {

  },
  methods: {
    printPdf: function() {
      html2canvas(document.getElementById("details")).then(function(canvas) {
        const doc = new jsPDF();
        const img = canvas.toDataURL("image/png", 1);
        printJS(img, "image");
      });
    },
    createNotification: function(){
        $(".ui.notification_modal.modal").modal("show");
    },
    formatPos: function(pos) {
      let purchaseOrderNumbers = Object.keys(pos).map(function(key) {
        let current = pos[key];

        return current.PurchaseOrder;
      });

      let formattedPos = purchaseOrderNumbers.join(", ");

      return formattedPos;
    },
  },
  asyncComputed: {
    async miles() {

      let result = 0;
      if (this.showMiles) {


        result = "Loading...";
       
        let origin_lat = this.shipper_geo ? this.shipper_geo.lat : null;
        let origin_lng = this.shipper_geo ? this.shipper_geo.lng : null;
        let destination_lat = this.consignee_geo ? this.consignee_geo.lat : null;
        let destination_lng = this.consignee_geo ? this.consignee_geo.lng : null;


        if(origin_lat != null && origin_lng != null && destination_lat != null && destination_lng != null){
          let origin_coords = new google.maps.LatLng(origin_lat, origin_lng);
          let destination_coords = new google.maps.LatLng(destination_lat, destination_lng);
          let distance =
            google.maps.geometry.spherical.computeDistanceBetween(
              origin_coords,
              destination_coords
            ) * 0.000621371;
          result = parseInt(distance);
          // Calculate co2 Emission & Savings
          let weight = this.hawbData.hawb.ChargeableWeight;
          let miles = result;
          if(weight != null){
            if(parseInt(weight) >= 15000){
              let co2_estimated = ((miles * (weight/2000)) * 161.8 / 1000000).toFixed(3);
              this.co2 = (co2_estimated - (.1276 * co2_estimated)).toFixed(3);
              this.co2_savings = (co2_estimated - this.co2).toFixed(3);
            }
            else{
              let co2_estimated = (((( 1.1 * miles / 5.9 ) * 22.38) * (weight/25000) + (6.07/6.3) * 2 * 22.38)/2000).toFixed(3);

              this.co2 = (co2_estimated - (.1276 * co2_estimated)).toFixed(3);
              this.co2_savings = (co2_estimated - this.co2).toFixed(3);
            }
          }
        }
        else{
          this.co2 = 0;
          this.co2_savings = 0;
          return 0;
        }
        

        
        
        return result;
      }
    },
    async locationPath() {
      var locations =  this.locationHistoryComments;
      if(locations.length == 0){
        return;
      }
        var origin = this.hawbData.shipper.Address1 + " " + this.hawbData.shipper.Address2 + "," + this.hawbData.shipper.City + "," + this.hawbData.shipper.State + " " + this.hawbData.shipper.Zip;
        
        var wayPoints = [];
        var addedLocations = [];
        
        locations.forEach(element => {
          //Since there is a chance that the location could fail the google maps api call (Unrecognized city/state) usually
          //caused by a misspelling. We will check if the latlng of the element is an object. If it is then it has passed
          //the check for the google maps api. Therefore if it fails indicated by the bang (!), we will not attempt to add it
          //to the route.
          if(!(typeof element.latlng === 'object')){
            return;
          }

          // We are using locations full address to calculate unique waypoints for the route 
          if(!addedLocations.includes(element.full_address)) {
            if(this.isNonDTC && element.full_address == (this.hawbData.shipper.City + ', ' + this.hawbData.shipper.State)){
              //Skip if it is Non DTC and the address = shippers address (city&state)
            }
            else{
              wayPoints.push(
                {
                  location: element.latlng,
                  stopover: true
                }
              )
              addedLocations.push(element.full_address)

            }
            

          }

        });

        wayPoints.reverse();

        if(this.delivered == 10) {
          var destination = this.hawbData.consignee.Address1 + (this.hawbData.consignee.Address2 ? ' ' + this.hawbData.consignee.Address2 + ' ' : ' ') + this.hawbData.consignee.City + ', ' + this.hawbData.consignee.State + ' ' + this.hawbData.consignee.Zip;
        } else {
          if(wayPoints.length > 0)
            var destination = wayPoints.pop();
          else
            return false;
          
        }
        //Because the directions service has a limit on waypoint calculations (25 waypoints + origin + destination)
        //We have to split the waypoints into multiple calls

        var all_map_points = wayPoints;
        all_map_points.push(destination);
        all_map_points.unshift(origin);
        var routes = new Array();

        var x = 0,y = 0;

        all_map_points.forEach(function(point,index){
          if(routes[y] == undefined){
            routes[y] = new Array();
          }
          routes[y].push(point);
          if(x == 26){
            
            y++;
            if(routes[y] == undefined){
              routes[y] = new Array();
            }
            //Since we want the routes to connect, we need to have the start of the next route
            //be the end of the previous route
            routes[y].push(point);
            //Since we are duplicating the last point in the route, we start at 1 instead of 0
            //for the next route
            x = 1;
          }
          else{
            x++;
          }
        });
        //Routes define the seperate route lines
        this.route_polys = new Array();

        routes.forEach(function(map_points,index ){
          var org = map_points.shift();
          var dest = map_points.pop();
          

          var location = this.directionsService.route({
                  origin: typeof org === 'object' ? org.location : org,
                  destination: typeof dest === 'object' ? dest.location : dest,
                  waypoints: map_points,
                    travelMode: 'DRIVING'
          }, (response, status) => {
              if (status === 'OK') {
                //Google Directions Route was too inacurate with their provided approximation, we are now calculating our
                //our own path data by iterating each leg/step/segment
                var path = [];
                var legs = response.routes[0].legs;
                for (var x = 0; x < legs.length; x++) {
                  var steps = legs[x].steps;
                  for (var y = 0; y < steps.length; y++) {
                    var nextSegment = steps[y].path;
                    for (var z = 0; z < nextSegment.length; z++) {
                      path.push(nextSegment[z]);
                    }
                  }
                }
                // Pass data to the map
                this.locationPath = path;
                this.route_polys.push(path);
                
                
                
              } else {
                  return error;
              }
          });
        }.bind(this));
        

    }
  }
};
</script>

<style lang="scss">
#details {
  .ui.menu {
    border-top: none;
    border-right: none;
    border-left: none;
  }
  .details-container {
    margin: 25px 40px 0px 0px;
  }

  .header.item:before {
    display: none;
  }

  .ui.grid.head {
    padding-left: 40px !important;
  }

  .ui.divider {
    margin-left: 40px !important;
    border-top: 1px solid $alggrey;
  }

  .shipment {
    #shipment-details {
      .column {
        padding-top: 0.8em;
        padding-bottom: 0.2em;

        h3 {
          span {
            color: $alggrey !important;
            font-weight: 400;
            font-size: 16px !important;
          }
        }
      }
    }

    .inter_ocean {
      margin-top: 40px;
    }

    .sixteen.wide.column.pos {
      margin-top: 30px;
    }

    .sixteen.wide.column.appt_notes {
      margin-bottom: 10px;
    }

    .five.wide.column {
      padding-right: 0px !important;
    }

    .eleven.wide.column {
      padding-left: 0px;
    }

    h3 {
      font-weight: 600 !important;
    }

    .details {
      margin-top: 25px;
      margin-bottom: 50vh;

      .control_number {
        color: $algred;
        text-decoration: underline;
      }

      .ui.header {
        padding: 14px 0px 14px 16px;
        background-color: #f4f4f4;
        border-radius: 8px;
      }

      .ui.header.tracking_alert {
        background-color: $algred;
        color: #fff !important;
      }

      .table {
        border: none;
        box-shadow: none;

        thead {
          tr {
            th {
              color: $algdarkblue;
              border: none;
              border-bottom: 1px $algdarkblue solid;
            }
          }
        }

        tbody {
          tr {
            background-color: #fff;

            td {
              border: none;
            }
          }
        }
      }
    }
  }

  .VueTables__search,
  .VueTables__limit {
    display: none;
  }

  .tracker {
    padding-top: 0;
    height: 81vh;
    max-height: 81vh;
  }
}

#tracker .is-active .progress-title {
  color: black !important;
  font-weight: 700 !important;
}

@import url("https://fonts.googleapis.com/css?family=Lato");

.accordion {
  font-family: Lato;
  margin-bottom: 20px;
  margin-top: 20px;
}

.accordion .header {
  position: relative;
  cursor: pointer;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  color: #0d4b6a !important;
}

.accordion .header-icon {
  position: absolute;
  top: 5px;
  right: 8px;
  transform: rotate(0deg);
  transition-duration: 0.3s;
}

.accordion .body {
  /*   display: none; */
  overflow: hidden;
  background-color: #fff;
  border-top: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  transition: 150ms ease-out;
}

.accordion .body-inner {
  padding: 8px;
  overflow-wrap: break-word;
  /*   white-space: pre-wrap; */
}

.accordion .header-icon.rotate {
  transform: rotate(180deg);
  transition-duration: 0.3s;
}

#tracker .progress-step:last-child .progress-title {
  min-height: 0;
  margin-bottom: 0;
}
.ui.location_modal.modal.scrolling.transition.visible.active{
  width:62%;
  // transition: width 4s;
}
@media only screen and (max-width: 600px) {
  #tracker {
    position:relative;
  }
  .shipment {
    margin-left:0px;
  }
  
  #shipment-details {
    padding-left:40px;
    padding-right:40px;
  }
  #details .tracker {
    padding-top: 0;
    height: 100%; 
    max-height: none; 
  }
  #details .details-container {
    margin: 25px 0px 0px 0px;
  }
  #details .ui.divider{
    margin-left:0px!important;
  }
  #details .ui.grid.head {
    padding-right: 30px;
  }
  #details .print-container{
    display:none;
  }
  

}

</style>
