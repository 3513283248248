<template>
  <div id="hawaii">
    <svg version="1.1" id="_x2014_ÎÓÈ_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 2126.1 1427.8" style="enable-background:new 0 0 2126.1 1427.8;" xml:space="preserve">
        <g>
            <path class="st0" d="M204.9,262.6c0.4-0.3,0.8-0.7,0.6-1.3c-0.2-0.5-0.9-0.5-1.3-0.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-0.4,0.3-0.8,0.8-0.6,1.4
                C203.7,263.1,204.5,262.9,204.9,262.6z"/>
            <path class="st0" d="M201.7,266.3c0.9-0.2,2.6-2.6,1-2.7c-0.1,0-0.2,0.1-0.2,0.2c-0.8,0.5-1.1,1.5-0.9,2.4C201.6,266.3,201.6,266.3,201.7,266.3
                z"/>
            <path class="st0" d="M258.4,228.5c-0.6-0.3-1.3-0.6-1.9-0.9c-0.2-0.1-0.6-0.6-0.9-0.6c-1-0.2-2.1,0.7-3.1,0.7c-1.1,0-2.8,0.5-3.1-0.9
                c-0.1-0.6-1.3,0-1.5,0.1c-0.4,0.2-0.8,0.4-1.1,0.8c-0.3,0.4-0.4,0.6-1,0.8c-0.5,0.1-0.9,0.2-1.4,0.4c-0.4,0.1-0.6,0.4-1,0.5
                c-0.6,0.2-1.7,0.5-1.9,1.2c-0.1,0.4,0,0.9,0,1.3c0,0.4-0.4,0.4-0.6,0.7c-0.1,0.2-0.1,0.4,0,0.7c0.3,0.8,0.2,1.8,0,2.6
                c-0.3,1.1-1.5,1.5-1.7,2.7c0,0.1,0,0.1,0,0.1c-0.4,1-1.1,2.1-1.7,3c-0.5,0.8-0.8,1.6-1.6,2.3c-0.8,0.6-1.5,1.2-2.4,1.6
                c-1.5,0.8-3.3,1.7-5,1.6c-0.6,0-1.3,0.1-1.6,0.6c-0.9,0.4-1.5,1.4-2.3,2c-0.2,0.2-0.5,0.3-0.7,0.5c-0.2,0.2-0.2,0.4-0.4,0.5
                c-0.7,0.4-1.6,0.3-2.4,0.6c-0.7,0.3-1.7,0.1-2.4,0.4c-0.5,0.2-1.1,0-1.3,0.6c-0.1,0.2-0.8,1.9-0.9,2c-0.9,0.1-1.2,0.3-1.9,0.8
                c-0.6,0.5-1.1,1-1.7,1.5c-0.3,0.2-0.6,0.6-0.6,1c0,0.4,0.1,1.2-0.3,1.5c-0.2,0.2-0.6,0.4-0.8,0.5c-0.3,0.2-0.6,0.3-0.8,0.6
                c-0.5,0.5-0.9,0.7-1.5,1.2c-0.3,0.2-0.4,0.7-0.8,0.9c-0.7,0.5-1.4,1-2,1.6c-0.5,0.5-1.1,1.2-1.5,1.8c-0.2,0.4-0.6,0.7-0.8,1.1
                c-0.1,0-0.1,0.1-0.1,0.1c-0.4,1.2-0.8,2.4-1,3.7c-0.2,1.5-0.1,3-0.7,4.5c-0.9,2-3.5,3-3.3,5.6c0.1,0.6,0.2,1.2,0.3,1.8
                c0.2,0.9,0,1.7-0.4,2.5c-0.4,1-0.9,1.8-1,2.9c-0.1,0.8,0.2,1.5,0.8,2c0.4,0.4,0.7,0.7,1.1,1.1c0.2,0.2,0.3,0.5,0.4,0.7
                c-0.1,0.6-0.2,1.3,0.1,1.8c0.3,0.5,0.9,0.7,1.5,0.8c0.3,0.2,0.5,0.4,0.7,0.7c0,0,0,0,0,0.1c0,0.7,0.2,1.7,0.7,2.2
                c0.3,0.3,0.6,0.7,1,0.8c0.5,0.1,1,0.1,1.6,0.1c1.1,0.3,1.7,1,2.6,1.8c0.9,0.8,2.1,1,3.2,1.2c0.7,0.1,0.9,0.6,1.6,0
                c0.5-0.5,0.9-1.1,0.8-1.9c-0.1-1-1.5-1-0.9-2.1c0.1-0.1,0.1-0.1,0.2-0.2c0.7-0.5,1.3-1.3,1.7-1.9c0.2-0.4,0.8-0.7,0.9-1.1
                c0.1-0.5,0.1-1,0.1-1.5c0-0.6,0-1.1,0.4-1.7c0.2-0.3,0.5-0.5,0.6-0.8c0.7-1.3,1.6-2.4,2.5-3.6c0.7-1,1.3-2,1.9-3.1
                c0.6-1.2,1.2-2.5,2.1-3.6c0.5-0.7,0.6-0.7,1.4-0.7c0.2,0,0.5-0.1,0.6-0.3c0.1-0.3,0.5-0.7,0.5-1c0-0.3,0-0.6,0-0.8
                c0-0.7,0.2-1.2,0.6-1.7c0.3-0.3,0.5-0.7,0.8-1c0.9-0.9,1.8-1.6,2.9-2.2c1-0.5,2.3-0.4,3.4-0.4c0.6,0,1.4,0.2,2-0.2c0,0,0,0,0,0
                c0,0,0,0,0.1,0c0.2,0,0.3-0.4,0.1-0.4c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c-0.3-0.2-0.4-0.4-0.7-0.4c-0.2-0.6-0.1-0.8,0.4-0.6
                c0.2,0,0.4,0.1,0.6,0.1c0.8,0.1,1.5,0.3,2.3,0.3c0.6,0,1.2-0.1,1.8-0.2c0.4-0.1,1.2-0.3,0.8,0.6c-0.1,0.1,0,0.2,0.1,0.3
                c0,0,0.1,0,0.1,0c0.5-0.1,0.9-0.5,1.2-0.9c0.5-0.5,1.1-1,1.6-1.5c0.9-0.9,1.7-1.4,2.9-1.5c0.6,0,1.1-0.5,1.4-1
                c0.6-0.7,1.5-1.2,2.3-1.7c0.7-0.4,1.4-0.8,2.2-0.8c0.5,0,1.4-0.2,1.7-0.7c0,0,0,0,0,0c0.3-0.1,0.5-0.3,0.5-0.6c0,0,0.1-0.1,0.1-0.1
                c0.1-0.5-0.4-0.9-0.7-1.3c-0.5-0.8-1-1.8-1.2-2.7c-0.4-1.8-0.7-3.6-1-5.4c-0.1-0.4,0-0.9,0-1.3c0-0.6-0.2-0.8,0-1.4
                c0.2-0.8,0.5-1.5,0.7-2.3c0.2-0.9,0.4-1.8,0.7-2.7c0,0,0.1-0.1,0.1-0.1c0.4-1.8,1-3.6,1.9-5.2c0,0,0.1-0.1,0.1-0.1
                c0.2-0.4,0.6-0.7,0.8-1.2c0.2-0.3,0.5-0.6,0.7-0.9c0.2-0.3,0.2-0.6,0.5-0.8c0.5-0.4,1.1,0.3,1.6,0.4c0.4,0.1,0.8,0.1,1.1-0.1
                c0.8-0.4,0.8-1.3,1.1-2.1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.4,0.1-1-0.1-1.3C260.5,230.8,260,229.2,258.4,228.5z"/>
            <path class="st0" d="M245.1,223.1c0.6,0.3,1.2,0.1,1.8-0.2c0.6-0.2,1.2-0.7,1.6-1.2c0.6-0.7,0.5-1.7,0.6-2.5c0.1-0.4,0.7-1.2,0-1.3
                c-0.5-0.1-1,0.8-1.1,1.1c-0.5,1.1-1.4,0.4-1.8-0.3c-0.2-0.3-0.4-0.4-0.8-0.3c0,0,0,0,0,0c0,0-0.1,0-0.2,0.1c-0.5,0.5-0.4,1.3-0.4,2
                c-0.1,0.6,0,1.1,0.1,1.7C244.9,222.3,244.9,223,245.1,223.1z"/>
            <path class="st0" d="M502.3,193.2c-0.6-0.4-1-1.3-1.1-2c-0.2-1,0.6-1.6,0.6-2.6c0-1.2,0-2.4,0-3.6l0,0c0,0,0-0.1,0-0.1v-0.1c0,0,0-0.1,0-0.1
                c-0.1-0.4-0.3-0.8-0.2-0.9c0.2-0.2,0.2-0.4,0.2-0.7c0-0.4,0.5-2.1-0.4-2c-0.8,0-1.4,0.1-2.1-0.3c-0.3-0.1-0.5-0.2-0.8-0.3
                c-0.5-0.3,0.2-0.9,0.4-1c0.2-0.3,0.2-0.5,0.1-0.8c-0.1-0.4-0.2-0.8-0.4-1.1c-0.3-0.4-0.6-0.6-0.5-1.2c0-0.2-0.1-0.4-0.2-0.6
                c-0.6-0.6-0.9-0.8-0.9-1.8c0-0.3-0.1-0.7-0.3-0.9c-0.3-0.3-1.3-1.4-0.7-1.8c0.7-0.5,0.7-1,0.2-1.6c-0.1-0.2-0.4-0.4-0.6-0.6
                c-0.1-0.1,0-0.2-0.1-0.3c-0.3-0.3-0.4-0.9-0.8-1.1c-0.3-0.2-0.5-0.3-0.6-0.6c-0.1-0.3-0.1-0.2-0.3-0.4c-0.4-0.3-0.9-0.5-1.4-0.6
                c-0.6-0.1-1.2-0.1-1.8-0.2c-0.2-0.1-0.5-0.1-0.7-0.2c-0.4-0.1-0.5-0.1-0.2-0.7c0.4-0.6-0.4-1.2-0.4-1.9c0-0.3-0.2-0.6-0.4-0.8
                c-0.4-0.4-0.6-0.7-0.9-1.2c-0.2-0.3-0.5-0.5-0.8-0.7c-0.5-0.5-1.1-0.8-1.6-1.3c-0.8-0.7-1.7-0.9-2.7-0.5c-0.7,0.3-1.4,0.7-2.2,0.4
                c-0.6-0.3-1.2-0.3-1.8-0.5c-0.7-0.2-1.3-0.9-2-0.9h-3c0.3-0.4,0.5-0.8,0.8-1.3c0.2-0.3,0.2-0.5-0.1-0.7c-0.4-0.3-1.3-0.2-1.7-0.3
                c-0.3-0.1-1.2,0-1.4-0.1c-0.2-0.1-0.2-0.4-0.3-0.6c0,0-0.3-0.4-0.4-0.4c-0.5-0.1-0.9-0.4-1.3-0.7c-0.4-0.3-0.7-0.5-0.8-1.1
                c0-0.1-0.1-0.4-0.3-0.4c-0.6-0.2-0.4,0.2-0.8,0.3c0,0,0,0-0.1,0c-0.1,0-0.3,0.1-0.4,0.2c-0.4,0.3-0.7,0.7-1.1,1.1
                c-0.1,0.1-0.1,0.2-0.1,0.2c-0.3,0.2-0.9,0.3-1.4,0.6c-0.4,0.3,0.6,0.3-0.9,0.6c-0.5,0.1-1.1,0-1.6,0c-0.4,0.1-1,0-1.4,0.3
                c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.7-0.7,0.8-1.3,0.6c-0.8-0.2-1.3-0.6-1.8-1.3c-0.7-0.9-2-0.6-3.1-0.5c-0.4-0.2-1-0.1-1.5-0.1
                c-0.2,0-0.5,0-0.7,0c-0.3,0-0.7-0.1-1-0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.6,0-1.2-0.1-1.8,0
                c-0.2,0-0.7,0.4-0.8,0.5c-0.3,0.2-0.7-0.6-0.9-0.7c-0.7-0.4-1.4-0.6-2.3-0.7c-0.4,0-0.9-0.5-1.4-0.6c-0.5-0.2-1-0.1-1.4,0
                c-0.8,0.1-1.6,0.3-2.3,0.3c-0.5,0-0.9,0.3-1.2,0.8c-0.2,0.4-0.2,0.8-0.7,1c-0.2,0.1-0.4,0.4-0.5,0.5c-0.3,0.4-1,1.3-0.9,1.9
                c0.1,0.7,0.2,1.1-0.3,1.7c-0.2,0.3-0.3,0.6-0.5,1c-0.4,0.9-1,1.7-2.2,1.6c-0.5,0-1-0.4-1.5-0.5c-0.6-0.2,0-1.8,0.1-2
                c0.3-0.3-0.1-0.7-0.3-0.8c-0.5-0.3-0.8-0.1-1.2-0.6c-0.1-0.1-0.4-0.3-0.5-0.4c-0.7-0.1-1.4-0.3-2.1-0.2c-0.2,0.1-0.3,0.1-0.5,0.1
                c-0.6,0.1-0.8-0.2-1.2-0.5c-0.4-0.2-0.8-0.3-1.3-0.4c-0.7,0-0.9,1.3-1.7,0.4c0,0,0-0.1,0-0.1c-0.4-0.3-0.4-0.7-0.6-1.1
                c-0.2-0.4-0.4-0.8-0.9-1c-0.5-0.2-1-0.1-1.6-0.5c-0.5-0.3-1.1-0.6-1.7-0.8c-0.4-0.1-0.8-0.2-1.2,0.1c-0.2,0.2-0.4,0.4-0.6,0.6
                c-0.6,0.5-1.5,0.2-2.2,0.1c-0.9-0.2-1.8-1-2.7-0.3c0,0,0,0-0.1,0.1c-0.8,0.3-2.1-0.6-2.6,0.5c-0.1,0.2-0.1,0.5-0.2,0.7
                c-0.3,0.6-0.7,1-1.3,1.4c-0.5,0.4-1.1,0.9-1.5,1.3c-0.6,0.6-1.2,0.6-1.9,1c-0.5,0.3-0.9,1.1-1.3,1.5c-0.2,0.2-0.8,0.2-1.1,0.3
                c-0.3,0.1-0.5,0.3-0.8,0.4c-1.4,0.5-2.7,1-4.1,1.6c-0.4,0.2-0.7,0.5-1.1,0.7c-0.3,0.1-0.5,0.4-0.8,0.6c-0.3,0.1-0.6,0.2-0.8,0.4
                c-1.1,0.9-2.4,1.7-3.7,2.5c-0.4,0.3-0.8,0.5-1.2,0.8c-0.4,0.3-0.6,0.6-1.1,0.9c-0.3,0.2-0.6,0-0.9,0.3c-0.1,0.1-0.2,0.2-0.4,0.3
                c-0.8,0.3-1.7,0.6-2.3,1.2c-0.8,0.7-1.6,1-2.7,1.3c-0.7,0.2-1.4,0.5-2,0.9c-0.6,0.4-1.9,0.6-2.7,0.7c-1,0.1-1.9,0.5-2.9,0.6
                c-0.7,0.1-1.5,0.1-2.1,0.4c-0.3,0.2-0.6,0.5-1,0.6c-0.5,0.2-1,0.4-1.4,0.6c-2.9,1.2-5.7,3-7.4,5.8c-0.4,0.7-1,1.3-1.2,2.1
                c-0.2,0.8,0,1.9,0,2.8c0,0.6-0.9,1.7-1.2,2.2c-0.3,0.5-0.6,0.9-0.7,1.4c0,0,0,0-0.1,0c-0.6,0.5-0.7,1.2-0.8,1.9
                c-0.3,1-0.5,2.2-1,3.1c-0.6,0.9-1.3,2.3-2.3,2.9c-0.8,0.4-1.5,1.4-2.1,2.2c-0.6,0.8-1.2,1.6-2,2.2c-0.6,0.4-1.9,0.1-2.1,0.9
                c-0.1,0.5,0.1,0.8-0.6,1.1c-0.3,0.1-0.5,0.4-0.7,0.7c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.8-0.1-1.1,0c-0.7,0.3-0.7,1.5-1,2.1
                c-0.1,0.3-0.4,0.5-0.5,0.9c-0.1,0.6,0,1.2-0.1,1.7c-0.1,0.6-0.4,0.8-0.6,1.2c-0.2,0.3-0.1,0.8-0.2,1.2c-0.2,0.6-0.1,1.2-0.2,1.7
                c-0.1,0.4-0.1,0.8-0.2,1.3c-0.1,0.5-0.1,1.1-0.2,1.6c-0.1,0.7-0.3,1.2-0.2,1.9c0,0.6,0.3,1.2,0.4,1.8c0,0.4,0.1,1,0.3,1.4
                c0.2,0.6,0.2,1,0.6,1.5c0.2,0.4,0.6,0.7,0.9,1c0.2,0.3,0.8,0.6,1.2,0.7c0.5,0.2,1,0.5,1.5,0.7c0.3,0.4,0.4,1.1,0.5,1.5
                c0.1,0.6,0.6,1.2,0.9,1.8c0.3,0.7,0.2,1.1,0.4,1.8c0.1,0.7,0.6,1.2,0.9,1.8c0.3,0.6,0.6,1.2,1,1.8c0.2,0.3,0.6,1,1,1.1
                c1.1,0.3,2.2,0.4,3.3,0.5c0.6,0.1,1.1,0,1.7,0.2c0.6,0.1,1.5,0.5,2,0.8c0.3,0.2,0.7,0.2,1,0.4c0.4,0.1,0.8,0,1.2,0.2
                c0.3,0.1,0.6,0.2,1,0.3c0.5,0.1,1,0.8,1.5,1.1c0.4,0.3,0.7,0.2,1.2,0.3c0.9,0.1,1.1,1.1,1.6,1.6c0.4,0.5,1.1,0.7,1.6,1c0,0,0,0,0,0
                c0.4,0.3,0.8,0.6,1.3,0.7c0.9,0.2,1.8,0.6,2.7,0.7c1,0.1,2.1,0.1,3.1,0.1c0.5,0,1,0.2,1.4,0.2c0.7,0.1,1.2,0.3,1.9,0.5
                c0.5,0.1,0.7,0.5,1.1,0.7c0.7,0.2,1.1,0.3,1.3,1.2c0.1,0.4,0,0.6,0.4,0.8c0.2,0.1,0.5,0.3,0.6,0.5c0.1,0.2,0.1,0.6,0.3,0.7
                c0.5,0.6,1,0.4,1.7,0.3c0.5-0.1,0.8,0.4,1.2,0.6c0.1,0,0.2,0,0.3,0c0.6,0,0.7,0.3,0.7,0.8c0,1.7,0.3,3.1,1.6,4.3
                c0.4,0.3,0.8,0.8,1.2,1.1c0.3,0.3,1.2,0.8,1.2,1.2c0,0.3,0.3,0.6,0.4,0.8c0.2,0.4,0.6,0.8,1,1.1c0.1,0.1,0.9,0.5,1,0.6
                c0.2,0.3,0.6,0.6,0.9,0.9c0.8,0.7,1.1,1.7,2.2,2c0.5,0.2,1.2-0.1,1.5,0.4c0.2,0.3,0.2,0.3,0.5,0.4c0.6,0.1,1.1,0.4,1.4,0.9
                c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.5,0.4,1,0.8,1.5c0.4,0.5,1,0.3,1.4,0.7c0.4,0.5,1.5-0.4,1.7-0.8c0.2-0.3,0.4-0.8,0.3-1.2
                c-0.2-0.5-0.7-0.8-0.2-1.2c0.3-0.3,1.2-1.3,1.7-0.5c0.3,0.5,0.6,1,1,1.3c0.7,0.5,1.5,1.1,2.4,1.1c0.6,0,1.1-0.2,1.4,0.4
                c0.1,0.2,0.6,0.7,0.8,0.7c0.6,0.2,2-0.2,2.2,0.5c0.1,0.4,0.5,0.5,0.9,0.6c0.6,0.1,1.2,0.2,1.8,0.3c0.5,0.1,1,0.2,1.5,0.2
                c0.1,0,0.1-0.1,0.2-0.1c1.2,0.1,2.5,0,3.6,0.6c0.5,0.2,1.1,0.2,1.6,0.4c0.3,0.1,0.6,0,0.9,0.1c0.3,0.1,0.7,0.4,1,0.4
                c0.5,0,0.7,0.6,1.1,0.6c0.5,0,0.8-0.2,1.2-0.5c0.8-0.6,1.4-0.8,2.3-0.8c0.6,0,1.1,0.1,1.6,0.2c1.2,0,2.2-0.8,3.2,0
                c0.3,0.2,0.7,0.3,1,0.3c0.9,0,1.6,1.1,2,1.8c0.1,0.2,0.4,0.2,0.6,0.1c0.9-0.6,1.4-0.6,2.4-0.1c0.5,0.3,1.1,0.2,1.6,0.5
                c0.6,0.3,1.2-0.2,1.8,0.2c0.1,0.1,0.2,0,0.3-0.2c0,0,0.1,0,0.1,0c0,0.1,0,0.1,0,0.2c0.3,0.7,1,0.7,1.5,1c0.7,0.4,1.5,0.6,2.3,0.9
                c1,0.4,2.1,0.5,3,1c0.3,0.2,1.1,0,1.2,0.6c0,0.2,0.1,0.2,0.3,0.3c0,0,0.1,0,0.1,0c0.4-0.3,0.8-0.6,1.1-0.9c0.5-0.4,0.9-0.8,1.6-0.7
                c0.1,0,0.4,0,0.5-0.1c0.4-0.2,0.9-0.5,1.1-0.8c0.4-0.6,1-0.3,1.4-0.8c0.6-0.9,1.1-0.9,2-1.1c0.4-0.1,0.8-0.3,1-0.7
                c0.2-0.3,0.9-1.2,1.2-1.3c0.3-0.1,0.5,0,0.7-0.1c0.3-0.1,0.5-0.4,0.6-0.7c0.2-0.6,0.4-0.9,0.9-1.2c0.5-0.3,0.3-0.7,0.7-1.1
                c0.4-0.5,1.1-0.8,1.5-1.4c0.6-0.7,2-0.4,2.8-0.1c0.6,0.2,1.4-0.7,1.4-1.3c0-0.5,0.4-0.8,0.2-1.3c-0.2-0.8,0.2-1.6,0.8-2.1
                c0,0,0.1-0.1,0.1-0.2c0.4-0.4,0.9-0.7,1.4-1c0.4-0.3,0.7-0.8,1.1-1.1c0.1-0.1,0.2-0.4,0.4-0.5c0.2-0.2,1.1,0.2,1.5,0.1
                c0.5-0.2,0.6-0.6,0.8-1c0.4-1,1.3-0.7,2.1-1.1c0.1,0,0.1-0.2,0.2-0.2c0-0.1,0.9-0.3,0.9-0.3c0.3-0.1,0.8-0.1,0.9-0.5
                c0.1-0.2,0.1-0.4,0.1-0.7c0-0.6,0.6-0.3,0.9-0.2c0.4,0,1.1,0.1,1.5-0.1c1-0.4,0-1.8-0.1-2.3c0-0.6,0.3-1.3-0.1-1.9
                c-0.2-0.3-1.4-0.1-1.7-0.2c-0.4-0.1-0.6-0.3-1-0.3c-0.3-0.1-0.8,0.1-1.1,0.2c-0.3,0.1-0.6,0-0.8,0.2c0,0,0,0,0,0
                c-1.1,0.6-0.5-0.6,0-0.9c0.2-0.1,0.4-0.2,0.7-0.4c0.2-0.1,0.4-0.3,0.5-0.5c0.2-0.6,0.6-0.5,1.1-0.5c0.6,0,0.9-0.5,1.1-1
                c0.5-1.5,1.7,0,2.6,0.2c0.2,0,0.5-0.1,0.6-0.2c0.7-0.6,1.2,0.7,1.6,0.9c0.5,0.2,1,0.1,1.2-0.5c0.2-0.5,0-1.1,0.1-1.6
                c0.1-0.6,0.1-1.1,0.1-1.7c0-0.7,0.1-1.3,0.1-2c0-0.6-0.2-1.3-0.2-1.9c0-0.5,0-1.2-0.1-1.6c0-0.1,0-0.6,0-0.7
                c-0.1-0.2-0.2-0.6,0-0.4c0.5,0.4,0.9,0.2,1.2-0.2c0.3-0.4,1.2-0.9,0.6-1.5c-0.2-0.2-0.6-0.1-0.9-0.1c-0.8,0.2-1.5,0-2.2-0.3
                c0,0,0,0,0,0c-0.5,0-0.5-0.3-0.1-0.6c0.3,0.1,0.2,0.1,0.5,0.2c0.3,0.1,0.7-0.2,0.9-0.4c0.5-0.5,1-0.9,0.7-1.6
                c-0.4-0.9-0.7-1.9-1.1-2.9c-0.3-0.8-0.3-1.6-0.3-2.4c0-0.7-0.3-1.2-0.4-1.9c0-0.1-0.1-0.1-0.1-0.1c0-1.3,0.4-2.5,0.5-3.8
                c0-0.1,0.1-0.2,0.1-0.3c0.2-0.8,0.2-1.7,0.2-2.6c0-1.2,0.6-1.9,1.4-2.7c0.4-0.4,0.9-0.8,1.5-1.1c0.5-0.3,0.9-0.7,1.3-1
                c1.1-0.8,2.3-2,2.6-3.5c0.2-0.9-0.1-1.9-0.1-2.7c0-1.5,1.2-3,2.3-3.9c0.3-0.3,0.8-0.6,0.8-1.1c0-0.5,0-0.9,0-1.4
                c0-0.9,0.5-1.4,1.2-1.8c0.4-0.3,1.1-1.1,1.4-1.5c0.6-0.8,1.1-1.7,1.8-2.4C502.3,193.7,502.5,193.4,502.3,193.2z"/>
            <path class="st0" d="M1020.8,464.9c0.2,0.1,0.5,0.2,0.7,0.1c0.3-0.2,0.3-0.5,0.4-0.8c0.1-0.2,0.1-0.5-0.1-0.7c-0.2-0.2-0.4-0.3-0.7-0.2
                c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0.1c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.1,0,0.2,0.1,0.3C1020.6,464.6,1020.7,464.8,1020.8,464.9z
                "/>
            <path class="st0" d="M974.3,413.7C974.3,413.7,974.3,413.8,974.3,413.7c0.1,0.2,0.3,0.2,0.4,0.1c0.2-0.1,0.4-0.4,0.2-0.6
                c-0.1-0.2-0.5-0.1-0.6,0C974.2,413.4,974.1,413.6,974.3,413.7z"/>
            <path class="st0" d="M965.8,395.3c0.1,0.1,0.3,0.1,0.4,0c0.2-0.2,0.4-0.8-0.1-0.8c-0.1,0-0.1,0-0.2,0.1c0,0,0,0-0.1,0c-0.1,0.1-0.1,0.2-0.2,0.3
                c0,0,0,0.1,0,0.1C965.8,395.1,965.8,395.2,965.8,395.3z"/>
            <path class="st0" d="M932.8,346c0.2,0,0.4-0.3,0.4-0.5c0-0.2-0.1-0.6-0.3-0.4c-0.1,0.1-0.2,0.2-0.3,0.3c0,0-0.1,0.1-0.1,0.1
                C932.6,345.6,932.5,346,932.8,346z"/>
            <path class="st0" d="M1023.2,470.1c-0.3,0-0.5,0.1-0.8,0.2c-0.3,0.1-0.7,0-1,0.2c-0.4,0.3-0.8-0.3-1-0.5c-0.5-0.4-0.6-0.6-0.7-1.3
                c-0.1-0.7-0.4-1-1.1-1c-0.5,0-0.9,0.1-1.3-0.3c-0.4-0.4-0.4-0.5-0.9-0.8c-0.3-0.2-0.7-0.3-1-0.5c-0.4-0.2-0.7-0.5-1.1-0.6
                c-0.8-0.2-1.6-0.3-2.4-0.7c0.1-0.1,0.1-0.2,0-0.2c-0.1,0-0.3-0.3-0.4-0.3c-0.2-0.1-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.6-0.6-0.8
                c-0.3-0.3-0.7-0.4-1-0.8c-0.4-0.5,0.1-1-0.5-1.5c-0.3-0.2-0.5-0.6-0.8-0.8c-0.4-0.4-0.9-0.7-1.2-1.3c-0.4-0.7-0.5-1.6-1-2.3
                c-0.2-0.3-0.4-0.5-0.6-0.8c-0.4-0.5-0.5-1.1-0.7-1.7c-0.3-1.1-0.5-2-0.1-3.1c0.3-0.6,0.5-1.3,0.7-2c0.4-0.3,0.3-1.4,0.3-1.8
                c0-0.5,0-1.3-0.2-1.7c0-0.1-0.2-0.4-0.3-0.4c-0.3-0.1-0.5,0.4-0.9-0.3c-0.2-0.3-0.3-0.6-0.4-0.9c-0.3-0.5-0.7-0.5-0.7-1.2
                c0-0.2,0-1-0.2-1.1c0.1,0-0.2-0.2-0.3-0.2c-0.6-0.1-0.9-0.2-1.2-0.7c-0.2-0.2-0.5-0.3-0.8-0.4c0,0,0-0.1,0-0.1
                c-0.1-0.1-0.3-0.2-0.4-0.2c-0.7,0-1-0.2-1.5-0.7c-0.2-0.2-0.5-0.2-0.7-0.2c-0.5,0-1.1-0.5-1.6-0.7c-2-0.9-2.7-3.2-3.2-5.2
                c-0.1-0.5-0.2-0.9-0.2-1.4c0-0.8,1-1.6,1.5-2.1c0.4-0.3,1.1-0.7,1.3-1.2c0.2-0.6,0.5-1,0.5-1.7c0-0.9-0.2-1.6-0.5-2.5
                c-0.1-0.4-0.2-0.7-0.2-1.1c-0.2-0.9,0.4-0.6,0.9-0.7c0.4,0,0.8-0.1,0.9-0.5c0.4-1.5,2.4-2.3,2.8-3.8c0.3-1.4-2.2-1-2.9-0.7
                c-0.7,0.4-1.7,1.5-2.5,1.5c-0.5,0-1,0-1.4-0.1c-0.3,0-0.9,0.1-1.2-0.1c0,0-0.1,0-0.2,0c-0.9-0.2-1.9-0.3-2.8-0.7
                c-0.9-0.4-1.9-0.5-2.5-1.2c-0.3-0.4-0.5-1-1.1-0.7c-0.2,0.1-0.4,0.2-0.6,0.4c-0.1,0.1-0.4,0.7-0.6,0.8c-0.9,0.1-0.8,0.7-1.7,1.1
                c-0.6,0.3-0.9,0.6-1.2,1.2c-0.1,0.3,0,0.7,0,1c0,0.6,0,0.8-0.5,1.2c-0.4,0.4-0.2,0.8,0,1.2c0,0.1,0.1,0,0.2,0c0.6,0,1.2,0,1.6,0.6
                c0.2,0.2,0.2,0.5,0.4,0.6c0.5,0.4,1.1-0.3,1.7,0.1c0.3,0.2,0.4,0,0.6,0.3c0.1,0.1,0.2,0.3,0.4,0.3c0.3,0,0.7,0,1,0.1
                c0.2,0.1,0.3,0.1,0.5,0.2c0.3,0.1,0.4,0.4,0.6,0.6c0.5,0.3,1.1,0.7,1.6,0.8c0.3,0.1,0.6,0.1,0.9,0.1c0.3,0,0.5,0,0.8,0
                c0.4,0,0.5,0.1,0.4,0.3c-0.7,0.2-1.8-0.3-2.4,0.1c-0.6,0.3-1.3,0.4-1.8,0.4c-0.1,0-0.3,0.2-0.2,0.3c0.1,0.4-1,1.3-1.2,1.7
                c-0.4,0.9-0.5,1.5-0.5,2.5c0,1.2-0.6,1.4-1.5,1.4c-0.6,0-1,0.1-1.6,0.3c-0.9,0.3-1.9,0.5-2.9,0.3c-0.5-0.3-1-0.8-1.2-1.1
                c-0.4-0.6-0.3-1.2-0.3-1.9c0-0.1,0.1-0.6,0-0.7c-0.3-0.6-0.3-0.7-1-0.8c-0.3,0-0.6,0-0.9,0c-0.7,0.1-1.4-0.6-1.7-1.1
                c-0.3-0.5-0.9-0.8-1.1-1.3c-0.3-0.6-0.5-2-0.4-2.7c0.1-0.7-0.2-0.6-0.7-0.9c-0.9-0.4-1.6-1.1-2.2-1.8c-1-1.1-0.7-3-2.6-3
                c-0.5,0-0.9-0.3-1.3-0.3c-0.3,0-0.8-0.1-1.1,0c-0.7,0.2-1.4,0.3-1.9-0.3c-0.3-0.4-0.6-0.8-0.9-1.2c-0.6-0.8-1-1.6-1.4-2.6
                c-0.4-0.9-0.2-1.8-0.5-2.7c-0.3-1.1,0.3-1.2,0.6-1.9c0.1-0.1,0.1-0.3,0.1-0.5c-0.3-0.7-0.9-1.4-1.5-1.8c0,0,0,0-0.1,0
                c-0.3-0.4-0.7-0.8-0.8-1.2c-0.1-0.4,0-1.1-0.1-1.5c-0.1-0.4-0.4-0.8-0.6-1.1c-0.6-0.8-0.3-1.3-0.3-2.2c0-0.5-0.2-1.2,0.7-1.2
                c0.4,0,0.8,0.1,1.1-0.1c0.1,0,0.2-0.2,0.3-0.3c0.2-0.3,1.3-0.4,1.6-0.5c0.7-0.2,1.4-0.3,1.9-0.9c0.6-0.6,0.2-1.5,0.2-2.2
                c0-1-0.1-1.9,0-2.9c0-0.3,0-0.7-0.1-1c-0.3-0.6-0.3-1.1-0.3-1.8c0-0.8-0.1-1.4-0.7-1.9c-0.4-0.3-0.7-0.6-1-1.1
                c-0.3-0.6-0.8-0.9-1.2-1.4c-0.6-0.7-0.8-1.8-1.4-2.6c-0.7-0.9-1.4-1.5-2.3-2.2c-0.5-0.4-1.6-0.5-2.2-0.4c-0.5,0.1-0.9,0.2-1.4,0.2
                c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.3,0.3-0.4,0.5c-0.2,0.3-1,0.8-1.2,0c-0.1-0.3,0-0.3-0.2-0.5c-0.3-0.5-0.3-1-0.2-1.6
                c0.1-0.6,0.1-1.1,0.1-1.8c0,0,0,0,0,0c0-0.5-0.1-0.9-0.2-1.4c-0.1-0.5,0.1-1-0.2-1.5c-0.1-0.2-0.6-0.4-0.8-0.4
                c-0.5,0-0.7,0-0.9-0.5c0-0.1-0.5-0.3-0.5-0.3c-0.9-0.5-0.9-1.2-0.9-2.1c0-0.4,0.1-0.8-0.3-1c-0.2-0.1-0.6,0-0.7,0.2
                c-0.1-0.1-0.3-0.3-0.4-0.4c-0.5-0.4-1.1-0.8-1.5-1.2c-0.5-0.5,0-1.1-0.2-1.7c-0.2-0.4-0.5-0.7-0.8-1.2c-0.2-0.4-0.5-0.7-0.9-1
                c-0.4-0.4-0.9-0.6-1.4-0.9c-0.2-0.1-0.5-0.1-0.8-0.2c-0.4-0.2-0.5-0.5-0.7-0.8c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.2c0,0,0,0,0,0
                c0,0,0,0,0,0c-0.3-0.4-0.6-0.8-0.8-1.2c-0.3-0.6-0.5-1.3-1-1.7c-0.4-0.4-0.9-0.9-1.4-1.5c-0.1-0.5-0.1-1-0.1-1.4
                c0-0.7-0.2-1.3-0.2-2c0.2-0.3,0.3-0.5,0.3-0.9c0-0.9,0.3-0.8,0.4-1.4c0-0.4,0.1-0.8,0.1-1.1c0-0.6-0.4-1.4,0.8-1.2
                c0.3,0,0.4-0.2,0.5-0.4c0,0,0-0.1,0-0.2c-0.3-0.4-1-0.4-1.4-0.6c-0.8-0.4-1.5-0.5-2.2-1.1c-0.3-0.3-0.7-0.2-1-0.6
                c-0.3-0.4-0.1-1.2-0.2-1.7c-0.1-0.6-0.1-1.5-0.6-1.9c0,0-0.1,0-0.1,0c-1.1,0.4-1.3-0.3-1.4-1.3c-0.1-0.8-0.9-0.8-0.9-1.6
                c0.3-0.4,0.8-1,0.6-1.4c-0.2-0.4-0.6-0.8-0.9-1.2c-0.3-0.4-0.6-0.9-1-1.3c-0.8-0.9-1.8-1.7-2.5-2.6c-0.3-0.5-0.6-1-1-1.4
                c-0.4-0.4-0.7-0.9-1.1-1.2c0,0-0.1,0-0.1,0c-0.3-0.4-0.4-0.8-0.9-1.1c-0.2-0.1-0.4-0.2-0.7-0.3c-0.5-0.1-0.8-0.4-1.1-0.8
                c-0.4-0.5-0.2-0.7-0.8-1c-0.5-0.3-0.9-0.8-1.5-1c-0.7-0.2-1-0.3-1.8-0.2c-0.9,0.1-2.1,0.3-2.5-0.7c-0.3-0.6-1.4,0.1-1.6,0.4
                c-0.3,0.4-0.8,0.7-1,1.2c-0.3,0.5-1.8,0.1-2.3,0.1c-0.7,0-1.2,0.1-1.1,0.8c0.1,1.3-1.1,0.9-1.8,1c-0.5,0.1-0.8,0.6-1.2,0.8
                c0,0-0.1,0.1-0.1,0.1c-0.7,0.1-1.5,0-2,0.3c-0.2,0.1-0.5,0.2-0.7,0.4c-0.3,0.4-0.4,1-1,1.1c-0.6,0.1-1,0.4-1.4,0.9
                c-0.4,0.5-0.5,1.1-1.1,1.4c-0.4,0.3-0.9,0.5-1.3,0.8c-0.2,0.2-0.4,0.5-0.6,0.7c-0.3,0.3-0.8,0.6-1.1,0.9c-0.6,0.7-1.5,1.1-2,1.9
                c-0.5,0.7-0.9,1.8-1.7,2.1c-0.5,0.2-0.6,0.7-0.9,1.1c-0.6,0.3-1.1,0.8-1.5,1.2c-0.8,0.8-1.4,1.9-2.4,2.4c-0.4,0.2-0.5,0.5-0.6,0.9
                c0,0.4-0.2,0.7-0.3,1.1c-0.1,0.5,0,1,0,1.5c0,0.4,0.3,0.5,0.3,0.9c0,0.6-0.4,1.2-0.8,1.6c-0.4,0.4-1.2,1-1.4,1.6
                c-0.4,1.6-2.5,2.2-3.6,3.4c-0.4,0.4-1,0.6-1.5,0.9c-0.1,0.1-0.3,0.2-0.4,0.4c-0.4,0.8-0.7,1.3-1.6,1.5c-0.4,0.1-0.9,0.4-0.9,0.9
                c0,0.4-1.1,0.6-1.2,1.1c-0.1,0.4-0.5,0.7-0.8,0.9c-0.3,0.4-0.4,1-0.9,1.2c-0.5,0.3-1.3,0-1.7,0.6c-0.3,0.4-0.3,0.9,0,1.4
                c0.2,0.4,0.6,0.6,0.9,0.8c0.7,0.5-0.8,1-0.9,1.1c0,0-0.1,0-0.1,0.1c-0.2,0-0.4,0.1-0.5,0.3c-0.2,0.3-0.3,0.5-0.6,0.7
                c-0.3,0.3-0.7,1.5-1.4,0.9c-0.4-0.3-0.9-0.5-1.3-0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0.1,0.4,0.2,0.6,0.3,1c0,0.2,0.1,0.3,0.2,0.4
                c0.3,0.4,0.2,0.8,0.1,1.3c-0.2,0.8-1.3-0.4-1.3-0.5c-0.4-0.2-0.7,0.1-1,0.3c0,0,0,0,0,0c-0.6,0-1.2-0.1-1.4-0.2
                c-0.4-0.2-1.1-0.1-1.5-0.1c-1.7,0-3.3,0.5-4.9,0.5c-0.8,0-1.6,0-2.3,0.1c-0.6,0.1-0.9,0.5-1.4,0.6c-0.8,0.2-1.6-0.4-2.4-0.5
                c-0.9-0.1-1.8,0-2.6,0c0,0-0.1,0-0.1,0.1c-0.7-0.1-1.4-0.2-2.1-0.5c-0.3-0.1-0.7-0.1-1-0.1c-0.4,0-0.6-0.4-1-0.4
                c-0.5-0.1-1-0.1-1.5,0.1c-0.8,0.3-1.9,0.1-2.8,0.1c-1.9,0-3.8,0-5.7,0.1c-0.9,0-1.9,0.1-2.8,0.3c-0.7,0.2-1.5,0.1-2.2,0.3
                c-0.8,0.1-1.6,0-2.4,0c-1,0.1-2,0-3,0c-1.7,0-3.3,0.2-4.9,0.3c-0.7,0.1-1.4,0.1-2.1,0.4c-0.6,0.3,0,0.5-1.1,0.6
                c-0.4,0.1-0.7,0.1-1.1,0.2c-0.5,0.1-1.1,0-1.5,0.4c-0.2,0.2-0.1,0.4,0,0.6c0,0.9,0.6,0.7,1.4,0.9c0.6,0.2,1.3,0.3,1.8,0.7
                c0.5,0.4,0.9,0.9,1.4,1.4c0.5,0.5,1.1,0.7,1.7,1c0.6,0.3,1,0.7,1.5,1.2c0.4,0.4,0.9,0.9,1.3,1.3c0.5,0.5,1.5,0.2,1.9,0.9
                c0.5,0.8,1,1.7,1.7,2.3c0.5,0.4,1.1,0.7,1.7,1.1c0.4,0.3,0.8,0.6,1,1.2c0.1,0.3,0.2,0.5,0.3,0.8c0.3,0.5,0.3,1,0.7,1.4
                c0.3,0.5,0.7,0.7,0.7,1.4c0,0.5,0,0.9,0.1,1.4c0,0.3,0,0.7,0,1c0.1,0.3,0.2,0.6,0.2,1c0,0.6,0.1,1.2,0.1,1.7c0,0.7-0.3,1.1-0.5,1.7
                c-0.2,0.6-0.4,1.2-0.6,1.8c-0.1,0.7,0,1.5,0,2.2c0,0.6-0.2,1.1-0.2,1.7c0.1,0.7,0.4,1.3,0.4,2c0,1.2,0.3,2,1.3,2.8
                c0.4,0.3,0.7,0.6,1.2,0.8c0.7,0.3,0.8,0.5,1.2,1c0.3,0.4,0.7,1,0.3,1.4c-0.3,0.3-0.4,0.5-0.4,0.9c0,0.4,0.3,1,0.3,1.5
                c0.1,0.6,1.1-0.1,1.1,0.8c0,1,0.5,1.3,1.5,1.3c0.8,0,2.3,1.3,2.7,2c0.2,0.3,0.5,0.6,0.8,0.8c0.3,0.2,0.7,0.9,1,1.2c0,0,0,0,0,0
                c0.2,0.4,0.6,0.8,1,1.1c0.7,0.5,1.1,1.5,1.6,2.2c1.3,1.7,1.8,3.8,2.8,5.6c0.5,1,1,1.9,1.2,3c0.2,0.6,0.1,1.2,0.3,1.8
                c0.2,0.6,0.4,1.1,0.5,1.8c0.1,0.8,0.2,1.5,0.7,2.2c0.4,0.6,1.2,1.3,1.9,1.6c0.9,0.3,2,0.6,2.8,1.2c0.8,0.5,1.6,1.2,2.4,1.8
                c0.4,0.3,0.8,0.7,1.2,1.1c0.5,0.4,0.7,0.6,1.1,1.2c0.2,0.3,0.7,0.4,0.9,0.5c0.1,0.1,0.2,0.7,0.3,0.8c0.4,0.8,0.3,1.8,0.6,2.6
                c0.1,0.3,0.7,0.7,0.8,0.9c0.3,0.4,0.4,1,0.7,1.4c0.5,1,0.9,2.1,1.5,3c0.3,0.4,0.4,0.9,0.7,1.4c0.3,0.4,0.8,0.9,0.8,1.5
                c0,0.9-0.2,1.9,0.1,2.8c0.3,0.7,0.4,1.4,0.4,2.2c0,0.3-0.1,0.8,0.1,1.1c0.3,0.6,0.8,1.1,0.8,1.8v1.7c0,0.3,0.4,0.6,0.5,0.7
                c0.1,0.1,0.3,0.2,0.4,0.3c0.5,0.3,0.4,0.8,0.4,1.3c0,0.3,0.1,0.6,0.3,0.8c0.3,0.5,0.4,0.9,0.5,1.4c0.1,0.9,1.1,1.5,1.6,2.3
                c0.2,0.2,0.3,0.7,0.6,0.8c0.4,0.2,1.1,0.1,1.4,0.5c0.2,0.3,0.6,0.7,1.1,0.7c0.6,0.2,1.2,0.1,1.8,0.4c0.5,0.2,1,0.1,1.5-0.1
                c1.2,0,2.4,0.1,3.6,0c0.5-0.1,1-0.4,1.3-0.8c0.5-0.1,0.9-0.3,1.3-0.4c0.8-0.3,1.6-0.3,2.4-0.3c0.6,0,1.1-0.1,1.6-0.4
                c0.7-0.4,1.9-0.1,2.7-0.3c1.2-0.2,2.6,0.3,3.8-0.2c1.3-0.5,2.4-1.6,3.8-1.8c0.6,0.1,1.2,0.3,1.7,0c0.5-0.2,0.9-0.2,1.5-0.2
                c0.4,0,0.9,0,1.3,0.2c0.2,0.1,0.4,0.2,0.6,0.1c0.5-0.1,0.6-0.8,0.9-0.8c0.5-0.1,1,0.1,1.4-0.1c0.5-0.2,0.2-0.8,1.1-0.8
                c0.8,0,1.2,0.4,2,0.2c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.2,0.5-0.5,0.8-0.7c0.8-0.4,0.5,0,1,0c0.4,0,1.1,0.1,1.5-0.1
                c0.5-0.2,0.9-0.4,1.5-0.4c0.5-0.1,0.9,0.2,1.2,0.2c0,0.1,0,0.2,0.1,0.3c0,0,0.1,0,0.1,0c0.3-0.2,0.5-0.5,0.3-0.8
                c0.4-0.4,1-1.1,0.8-1c0.3-0.3,0.6-0.7,0.9-1c0.3-0.2,0.3-0.5,0.3-0.9c0.3,0,0.7-0.2,0.8-0.3c0.3-0.1,0.5-0.2,0.7-0.5
                c0.1-0.2,0.2-0.5,0-0.6c-0.3-0.2-0.6-0.2-0.9,0c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0-0.5-0.2-0.7-0.3c-0.2-0.5,0.1-1.1,0.2-1.5
                c0.1-0.3,0.1-0.5,0-0.8c0-0.1-0.3-0.4-0.4-0.4c0,0,0,0,0,0c-0.4-0.4-0.1-0.4-0.2-0.7c-0.1-0.3-0.3-0.4-0.6-0.6
                c-0.5-0.3-0.7-0.7-1.1-1.1c-0.4-0.6-1-1.5-1.8-1.7c-0.4-0.1-0.6-0.3-1-0.2c-0.9,0-1.5,0-1.7-1c-0.1-0.3-0.3-0.6-0.4-0.9
                c-0.2-0.3-0.2-0.7-0.5-0.9c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.2c0,0.3-0.5,0.6-0.8,0.8
                c-0.4,0.3-1.3,0.1-1.9,0.1c-1,0-1.6-0.7-2.5-1c-1-0.3-1.8-0.7-2.6-1.3c-0.1-0.1-1-1.5,0-1.3c0.3,0.1,0.3,0,0.5-0.1
                c0.4-0.2,0.2-1,0.2-1.3c-0.1-1.4,0.1-2.4,1.2-3.3c0.1-0.1,1-1,1-0.2c0,0.2,0.2,0.5,0.3,0.7c0.3,0.7,1.2,1.1,1.8,1
                c0.9,0,1,0.8,0.4,1.2c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.7,0,1.5,0.4,2c0.4,0.5,0.6,1.4,1.1,1.7c0.1,0.1,0.3,0.1,0.4,0
                c0.2-0.1,0.5-0.2,0.7-0.3c0.9-0.5,1.2,0.1,1.2,0.9c0,0.7,0.5,1.3,1,1.8c0.5,0.4,1.1,0,1.6-0.2c1.9-0.9,2.7,3.2,4.6,2.6
                c0.1,0,0.2-0.1,0.2-0.2c0-0.2,0-0.2,0-0.4c-0.3-0.7-0.3-1,0-1.7c0.3-0.8-0.1-1.2-0.3-1.9c-0.2-1-0.2-1.8-0.2-2.8
                c0-0.5,0-0.9-0.6-0.9c-0.9,0-1.5,0.5-2.5-0.1c-0.5-0.3-1-1.3-1.4-1.8c-0.5-0.7-0.7-1.6-1.1-2.3c-0.1-0.1-0.2-0.1-0.3-0.2
                c-0.2-0.4-0.3-0.9-0.3-1.3c0-1,0.2-2.2,1.5-2.2c0,0.1,0,0.1,0,0.1c0.2,0.4,0.1,1.2,0.6,1.5c0.6,0.4,1.2,0.7,1.7,1.1
                c0.3,0.2,0.4,0.5,0.7,0.7c0.5,0.4,0.5,0.8,0.7,1.3c0.2,0.7,0.4,1,1.1,1.3c0.8,0.3,1.3,0.7,1.7-0.3c0.2-0.6,0.6-0.9,0.7-1.5
                c0.2-0.7-0.4-0.9-0.4-1.5c-0.1-0.6-0.5-1.1-0.5-1.8c0-0.4,2.1-0.3,2.4-0.3h1c1.2,0,0.5,0.6,0.8,1c0.5,0.5,1.1,0.5,1.8,0.7
                c0.3,0.1,0.7,0.1,1,0.1c0.8,0,0.8,0.6,1,1.1c0.1,0.4,0.7,0.8,1.2,0.7c0.3-0.1,0.3-0.1,0.6-0.2c1.2-0.5,1.7,1.3,1.2,1.9
                c-0.3,0.3-0.3,0.8-0.4,1.2c0-0.1-0.1-0.1-0.2,0c-0.8,0.7-1.5,1.4-2.1,2.3c-0.5,0.8-0.5,2-0.5,2.8c0,0.1,0,0.1,0.1,0.1
                c0,0.3,0.1,1-0.4,1c-0.5,0-0.9-0.3-1.3-0.6c-0.3-0.2-0.8-0.9-1.2-0.8c-1.3,0.3-2.1,1.7-3.5,1.8c-1.3,0.1-0.5,1.5-0.9,2.2
                c-0.2,0.4-0.2,0.9-0.2,1.4c0,0.2,0.2,1.7-0.6,1.3c-0.3-0.2-0.3-0.3-0.7-0.3c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0-0.1,0
                c-0.1,0-0.2,0.2-0.2,0.3c0.1,0.5,0.8,0.7,1.2,1c0.3,0.3,0.4,0.6,0.8,0.7c0.7,0.3,0.6,1.1,0.6,1.7c0,0.6,0.2,1.4,0.6,1.8
                c0.4,0.4,0.8,0.7,1.2,1.1c0.2,0.2,0.5,0.7,0.8,0.9c0.2,0.2,0.5,0.4,0.8,0.4c0.8,0,1.3,0.1,1.8,0.8c0.5,0.7,0.2,1.3-0.4,1.7
                c-0.2,0.1-0.3,0.3-0.4,0.5c-0.6,1.1,0.3,2.1,1.5,1.7c1.2-0.1,2.3-0.1,3.5-0.2c0.6,0,1.2-0.1,1.8-0.1c0.8,0,1.6,0.3,2.4,0.3
                c1.3,0,2.5,0.2,3.8,0.2c0.7,0,1.7-0.4,2-1.1c0.3-0.6-0.1-1.6-0.6-1.9c-0.4-0.2-0.9-0.3-1.3-0.3c-0.4,0-1.4,0.3-0.9-0.5
                c0.5-0.8,1.1-1.5,1.8-2.1c1-0.8,1.5-2,2.4-2.9c0.1-0.1,0.2-0.3,0.2-0.4c0.5-0.9,0.8-0.9,1.5-1c1-0.3,0.7,1.4,0.7,1.8
                c0,1.2,1.1,1.9,1.6,2.9c0.2,0.3,0.7,0.3,1,0.3c0.5,0,1.1-0.3,1.6-0.4c0.4,0,1.1,0.1,1.5-0.1c0.2-0.1,0.5,1.2,0.5,1.3
                c0.3,0.9,1.1,0.6,1.9,0.6c0.8,0,1.7-0.2,1.7,1c0,0.6,0.2,1.9-0.3,2.3c-0.5,0.4-0.5,1.2-0.4,1.8c0.1,0.5,0.4,1,0.5,1.5
                c0.1,0.4,0.6,0.3,0.9,0.4c0,0,0,0,0.1,0c0,0,0,0,0,0c0.6,0,1.7,0.1,2,0.6c0.3,0.5,0.8,0.6,1.4,0.7c0.7,0.1,1.3,0,2,0.1
                c0.4,0,0.5,0.4,0.8,0.7c0.3,0.2,0.6,0.5,0.9,0.7c0.5,0.3,0.9,0.9,1.4,1.4c0.6,0.6,1.4,0.8,2.2,1.1c0.2,0.1,0.4,0.3,0.6,0.4
                c0.3,0.2,0.4-0.3,0.8,0.2c0.3,0.4,0.6,0.8,1.1,1.1c0,0,0,0,0,0.1c0.6,0.9,0.5,2,0.8,3c0.3,0.8,0.5,1.3,1,1.9
                c0.5,0.7,1.4,0.6,2.1,0.9c0.3,0.1,0.7,0,1,0c0.6,0,1.2,0.1,1.8,0.2c1,0.1,1.9,0.1,2.9,0.1c0.3,0,1,0.1,1.1-0.3
                c0.3-0.9,0.3-1.8,1.2-2.4c0.9-0.6,2-0.9,2.9-1.5c0.8-0.5,1.8-1.3,2.7-1.7c0.4-0.2,0.7-0.3,1-0.5c0.4-0.2,0.6-0.6,0.9-0.8
                c1.2-0.9,3.3-0.4,4.7-0.4c0.9,0,1.6-0.6,2.4-0.9c1-0.4,2.1-0.8,3.2-1c0.5-0.1,1,0,1.5,0c0.4,0,0.7-0.2,1.1-0.2c1-0.2,2,0.1,2.9-0.2
                c0.6,0.2,1.2,0.4,1.6-0.2c0.3-0.6,0.3-1.5,0.3-2.1c0-0.5,0.2-1.3,0.4-1.8c0.1-0.2,0.1-0.4,0.2-0.5c0.4-0.9,0.7,0.4,0.7,0.7
                c0,0.6-0.7,1.8,0.1,2.2c0.5,0.2,0.4,0.6,0.7,1c0.1,0.1,0.4,0.4,0.6,0.3c0.6-0.2,0.8-0.9,1.2-1.4c0.2-0.3,0.2-0.6,0.4-0.9
                c0.1-0.2,0.5-0.7,0.6-0.7c0.9-0.2,0.4,1.3,0.1,1.5c-0.4,0.4-0.6,0.9-0.9,1.4c-0.3,0.5-1.3,1.2-1.8,1.5c-0.7,0.4-1.2,0.7-1.4,1.5
                c-0.1,0.3,0,0.6-0.1,0.8c-0.1,0.3-0.3,0.4-0.4,0.7c-0.2,0.4-0.2,0.8-0.2,1.3c-0.2,0.9-0.1,1.9,0.1,2.8c0.2,0.8,1.4,0.9,2,0.6
                c1-0.5,2.1-0.8,3.3-0.8c0.5,0,1.1-0.4,1.2-0.9c0-0.2-0.1-0.3-0.2-0.4c-0.6-0.6-0.3,0.1-0.9-0.6c-0.2-0.3-0.4-0.6-0.6-0.9
                c-0.4-0.6-0.1-1.5,0.6-1.7c0.5-0.1,0.8,0.3,1.1,0.5c0.5,0.3,0.8,0.6,1.2,1c0,0,0.1,0,0.1,0c0.2-0.1,0.5-0.4,0.5-0.7
                c0-0.4,0-0.7-0.1-1.1c-0.2-0.8,0.7-0.9,1.2-1.1c0.6-0.2,0.7-0.5,1.2-0.9c0.8-0.7,1.6-1.3,2.4-2c0.7-0.6,1.4-1.1,2.2-1.6
                c0.5-0.4,1-0.8,1.6-1.1c0.4-0.2,0.8-0.4,1.1-0.7c0.6-0.4,1.5-0.8,1.9-1.4c0.2-0.4,0.2-0.8,0.4-1.2c0.2-0.2,0.5-0.5,0.6-0.8
                c0.3-0.6,0.4-1.1,0.5-1.8C1024.4,470.2,1023.8,470.1,1023.2,470.1z"/>
            <path class="st0" d="M948.6,471.8c-0.6-0.2-1.2-0.3-1.7-0.8c-0.4-0.3-0.7-0.5-1.2-0.7c-0.3-0.1-0.5-0.1-0.8-0.1c0,0,0,0-0.1,0
                c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.5,0.1-0.8,0.1c-0.1,0-0.2,0.2-0.2,0.3c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0
                c0.3,0.7,0.7,1.4,1,2c0.2,0.3,0.4,0.7,0.7,0.9c0.3,0.3,0.8,0.3,1.1,0.5c0.8,0.5,1.7,0.4,2.5,0.7c0.3,0.1,0.9,0.2,1-0.2
                C950.3,473.6,949.8,472.3,948.6,471.8z"/>
            <path class="st0" d="M919.7,455.1c0.2-0.2,0.4-0.5,0.5-0.7c0.1-0.1,0.2-0.5,0.4-0.7c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0.1-0.1,0.1-0.1,0.1-0.2
                c0.4-0.8,1.4-1.2,2-1.9c0.3-0.3,0.4-0.7,0.3-1c-0.2-0.5-1.8-0.5-2.2-0.3c-0.4,0.1-0.6,0.3-0.9,0.5c-0.4,0.3-0.8,0.6-1.2,0.9
                c-0.4,0.4-1.1,1.2-1.1,1.9c-0.1,0.7,0.3,1.1,0.6,1.7C918.6,455.5,919.4,455.3,919.7,455.1z"/>
            <path class="st0" d="M998.3,437.1c0.3-0.1,0.4-0.6,0.4-0.9c0-0.2-0.2-0.2-0.3-0.1c-0.1,0-0.1,0.1-0.1,0.2c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0.1
                c0,0.1-0.1,0.1-0.1,0.2C997.9,436.9,998,437.1,998.3,437.1z"/>
            <path class="st0" d="M998,438.2c0.2,0,0.5,0,0.7-0.2c0,0,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.1,0.2-0.3c0.1-0.2,0-0.4-0.1-0.5c0,0,0-0.1,0-0.1
                c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1,0-0.1-0.1-0.1c-0.1-0.1-0.3,0.2-0.3,0.2v0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
                c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c-0.1,0-0.3,0-0.4,0.1c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2,0.1
                C997.8,437.9,997.8,438.1,998,438.2z"/>
            <path class="st0" d="M1315.7,518.9c-0.1-0.6,0-1.5-0.6-1.9c0,0-0.1,0-0.1,0c-0.4,0.2-0.5,0.5-1,0.5c-1.1,0-2.1,0.2-3.2,0.3
                c-0.5,0-0.9,0.1-1.3,0.2c-0.7,0.1-1.6,0.2-1.6-0.7c0-0.8,0.1-1.8-0.1-2.6c-0.1-0.4-0.4-0.6-0.5-1c-0.1-0.3-0.2-0.4-0.4-0.6
                c-0.6-0.4-1.6-0.4-2.3-0.4c-2,0-3.9-0.5-5.8-0.5c-0.5-0.5-1.2-0.5-1.9-0.4c-0.2,0-0.4,0.1-0.5,0.3c-0.6,0.6-1.1,0.4-1.9,0.4
                c-1.2,0-2.4,0-3.6,0c-0.4,0-0.6,0.2-0.7,0.6c0,0.2,0.2,0.5,0.4,0.7c-0.2,0.4-0.4,0.6-0.8,0.6c-0.6,0.2-0.8-0.3-0.9-0.7
                c-0.1-0.3-0.3-0.3-0.6-0.4c-0.5-0.2-1-0.6-1.6-0.6c-1.1-0.1-2.2,0.4-3.3,0.6c-0.8,0.2-1.7,0.3-2.5,0.5c-0.3,0.1-0.4,0.3-0.7,0.5
                c-0.5,0.4-0.9,0.8-1.4,1.1c-0.6,0.3-1,0-1.5,0.2c-0.8,0.3-1.5,0.4-2.4,0.4c-0.6,0-1.2-0.1-1.8-0.3c-0.4-0.2-0.9-0.1-1.3-0.1
                c-1.2,0-2.4,0.1-3.5,0.4c-0.4,0.1-0.7,0.2-1.1,0.3c-0.5,0.2-0.7,0.6-1.2,0.1c-0.5-0.5-1-1.1-1.6-1.6c-0.4-0.3-0.7,0.5-0.9,0.7
                c-0.2,0.3-0.3,0.5-0.4,0.9c-0.1,0.2,0,0.5,0,0.7c0,0.2-0.1,0.4-0.2,0.5c-0.2,0-0.5,0-0.7,0.1c0,0,0,0-0.1,0
                c-0.3-0.3-0.6-0.8-0.8-1.1c-0.3-0.4-0.3-1.2-0.3-1.6c0.1-1-0.6-1-1.3-0.8c-1.2,0.4-1.8,0.6-2.4,1.7c-0.3,0.5-1.6,2.7-2.1,0.9
                c-0.2-0.6,0-0.9-0.5-1.3c-0.4-0.3-1-0.4-1.4-0.6c-0.6-0.3-1.6,0-2.3-0.1c-0.5,0-0.9-0.2-1.3-0.4c-0.1-0.1-0.2-0.2-0.4-0.3
                c-0.4-0.1-0.5-0.4-0.9-0.6c-0.2-0.1-0.6,0.2-0.8,0.2c-0.3,0-0.6,0-0.9,0c-0.2,0-0.9,0.4-0.9,0.4c-0.8,0.2-1.4-0.2-2-0.6
                c-0.4-0.2-0.7-0.2-1.1-0.4c0,0,0-0.1,0-0.1c-0.4-0.5-1-1-1.1-1.7c0-0.3-0.1-0.5-0.4-0.6c-0.8-0.3-0.9-1.9-1.1-2.7
                c-0.1-0.5-0.4-0.8-0.5-1.3c0-0.2-0.7-0.9-0.8-0.9c-0.8-0.5-0.4-1.2-0.6-2c-0.1-0.6-0.6-1-0.9-1.4c-0.4-0.5-0.5-0.8-1.1-1.2
                c-0.5-0.3-0.9-1-1.1-1.5c-0.5-0.9-1.5-0.9-2.4-0.6c-1,0.4-1.6,0.8-2.3,1.5c-0.6,0.6-0.6,1.1-0.2,1.8c0.4,0.7-0.5,1.7-0.9,2.2
                c-0.3,0.4-0.5,1-0.9,1.3c-0.4,0.4-0.9,1-0.4,1.6c0.5,0.5,0.4,1.7-0.3,2c-0.2,0.1-0.5,0.2-0.7,0.3c-0.6,0.2-1.1,0.4-1.7,0.6
                c-0.8,0.2-1.6-0.2-2.2-0.7c-0.4-0.3-1.2-0.4-1.6-0.4c-1.2-0.1-2.3,0-3.5-0.3c-0.7-0.1-1.3-0.3-2-0.3c-0.4,0-0.6,0-1,0
                c-0.4-0.1-0.4-0.5-0.6-0.8c-0.3-0.6-1,0.1-1.2,0.4c-0.3,0.5-1-0.6-1.2-0.7c-0.3-0.2-0.7,0-1,0.1c-0.4,0.2-0.6,0.7-1.2,0.6
                c-0.5-0.1-1,0.2-1.5,0.2c-0.2,0-0.7,0.2-0.8,0.5c-0.1,0.2-0.1,0.3-0.1,0.5c-0.1,0.4-1.1,0.1-1.3,0.1c-0.7-0.1-1.4-0.5-2.1-0.7
                c-0.4-0.1-0.6-0.3-1-0.4c-0.4-0.1-0.9-0.2-1.3-0.4c-0.6-0.3-1.2-0.4-1.9-0.7c-0.4-0.1-1.3-0.4-1.9-0.8c0.1-0.1,0.1-0.2,0-0.2
                c-0.4-0.3-0.8-0.5-1.2-0.8c-0.4-0.3-1-0.3-1.5-0.3c-0.4,0-0.8,0-1.2,0.1c0,0-0.1,0.1-0.1,0.1c-0.3,0.4-0.9,0.4-1.3,0.2
                c-0.5-0.2-1.2,0-1.8,0c-0.6,0-1.2-0.1-1.8,0c-1.5,0.1-3-0.9-4.5-1.3c-1.4-0.3-2.8-0.1-4.2-0.1c-1.6,0-3.1,0.3-4.7,0.3
                c-0.6,0-1.1-0.6-1.6-0.9c-0.6-0.3-1.1-0.3-1.8-0.3h-4.6c0,0,0-0.1-0.1-0.1c-1.6,0-3,0.1-4.3-0.9c-0.5-0.4-0.7-1-1.2-1.4
                c-0.4-0.5-1-1.2-1.2-1.8c-0.4-1-1.8-0.6-2.7-0.6c-0.7,0-1.2-0.5-1.8-0.8c-0.4-0.2-1,0-1.4,0.1c-0.6,0.2-1.3,0.1-1.9-0.1
                c-0.7-0.2-1.6-0.1-2.4-0.2c-1.5-0.2-3.1,0.2-4.6-0.1c-0.5-0.1-1.3-0.3-1.7-0.6c-0.7-0.5-1.5-0.7-2.2-1.1c-0.5-0.3-0.9-0.3-1.1,0.1
                c0,0-0.1,0-0.1,0.1c-0.4,0.6-0.8,1.2-0.9,2c-0.1,1.1,1.1,1.2,1.7,1.9c0.4,0.5,1,0.8,1.3,1.4c0.4,0.6,0.5,1.5,0.5,2.1
                c0.1,0.6,0.2,1.2,0.3,1.8c0.2,0.8-0.2,1.1-0.7,1.5c-0.3,0.2-0.3,0.7,0,0.9c0.7,0.6,0.8,1.2,0.6,2.2c-0.2,0.7-0.7,1.5-1.3,2
                c-0.8,0.7-1.7,1.5-2.3,2.4c-0.4,0.5-1,0.7-1.5,1.1c-0.4,0.4-0.6,0.9-1.1,1.3c-1,0.8-1.8,1.8-2.9,2.4c-0.9,0.5-2,1.3-2.6,2.2
                c-0.6,0.7-1.1,1.6-1.1,2.5c0,0.4,0,0.8,0,1.2c-0.1,0.5-0.7,1-1,1.3c-0.4,0.4-0.6,0.9-0.8,1.4c-0.6,1.3-0.8,2.8-1.6,4
                c-0.7,1.1-0.8,2.5-1.6,3.5c-0.5,0.6-0.8,1.1-1.4,1.6c-0.2,0.2-0.5,0.4-0.6,0.7c-0.4,0.7-0.1,1.2,0.7,1.4c0.1,0,0.4,0,0.6,0
                c0.7,0,1,0.3,1.4,0.7c0.4,0.4,0.7,0.8,1.3,0.9c0.6,0.1,0.9-0.1,1.4-0.3c1.2-0.2,2.5,0.3,3.7,0.2c1.4-0.1,2.4,0.7,3.7,1
                c0.5,0.1,1,0.1,1.4,0.4c0.4,0.3,1,0.5,1.4,0.7c0.1,0,0.2,0,0.3,0c0.4,0,0.7,0.2,1,0.2c0.3,0.1,0.6,0.1,1,0c1.2,0.2,2.6-0.2,3.8,0
                c0.5,0.1,0.9,0.2,1.5,0.2c0.8,0,1.6-0.1,2.4-0.2c1.1-0.1,2.5-0.1,3.5-0.4c0.5-0.2,1.1,0,1.6-0.2c0.9-0.4,1.8-0.3,2.7-0.3
                c3.1,0.1,6.3,0.2,9.4,0.2c0.1,0,0.2,0,0.4,0c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.3-0.1,0.4-0.2c0.7-0.5,2-0.2,2.8-0.3
                c0.3,0,0.6-0.1,0.9-0.1c0.5,0,0.8,0.2,1.2,0.4c0,0-0.1,0-0.1,0h0.2c0.3,0.1,0.6,0.1,0.9,0c0.1,0,0.1-0.2,0.1-0.3
                c0.1,0,0.1-0.1,0.2-0.1c0.4,0.1,0.8,0.1,1.2,0.1c0.6,0,1.2,0,1.8,0c0,0,0.1,0,0.1-0.1c0.2,0.2,0.7,0,1-0.1c0.7-0.2,1.6-0.6,2.3-0.7
                c0.1,0,0.2,0,0.3,0c0.3-0.2,0.7-0.4,1-0.6c0.5-0.2,1-0.2,1.5-0.3c0.7-0.1,1.4-0.1,2.2-0.3c0.9-0.2,1.8-0.6,2.8-0.7
                c0.8-0.1,1.5-0.2,2.4-0.4c0.6-0.2,1-0.8,1.6-1.1c0.1,0,0.1-0.1,0.1-0.2c0.2,0,0.6,0,0.7,0c0.6,0,1.2-0.2,1.3,0.7
                c0.1,0.6,0.4,0.7,1,0.7c0.7,0,1.4-0.2,2.2-0.2c0.3,0,0.7-0.1,1-0.2c0.6-0.2,1.5-0.1,2.2-0.1c0.3,0,0.7,0.3,1,0.4
                c0.6,0.1,1.1-0.1,1.6,0.4c0.4,0.5,1.2,0.1,1.6,0c0.5-0.1,1.1-0.2,1.4,0.3c0.2,0.3,0.2,0.5,0.5,0.7c0.6,0.4,2.3,0.1,2.9,0
                c0.6-0.1,1.2,0,1.8,0.1c0.6,0,0.9,0.6,1.3,0.9c0.6,0.6,1.2,1.2,1.8,1.8c0.6,0.6,1,1.3,1.8,1.4c0.9,0.2,1.9-0.1,2.9,0.1
                c0.4,0,0.8,0.1,1.2,0.1c0.4,0,0.6,0.4,1,0.4c1,0,1.4,0.9,2.4,1c0.3,0,0.7,0,1,0c0.4,0,0.7,0.3,1,0.3c0.3,0,0.8,0.1,1.1,0
                c0.4,0.3,0.8,0.5,1.1,0.8c0.6,0.6,1,0.9,1.8,1.2c0.7,0.3,1.2,0.9,1.9,1.2c0.4,0.2,0.6,0.6,1.1,0.5c0.3-0.1,0.6-0.4,0.8-0.6
                c0.5-0.6,1.2-0.4,1.5,0.2c0,0,0.2,0.8,0.2,0.9c0.6,0.9,1.7,0.6,2.5,0.2c0.9-0.4,1.9-0.4,2.8,0.1c0.4,0.2,0.9,0.4,1.4,0.5
                c0.9,0.1,1.8,0,2.7,0c0.1,0,0.3,0,0.4,0c0,0.6,0.6,0.9,1.1,1c0.3,0.1,0.7,0.1,1.1,0.1c0.3,0,0.5,0,0.8,0c0.4,0.2,0.9,0.4,1.4,0.5
                c0.1,0.2,0.5,0.4,0.7,0.4c0.3,0.1,0.5,0,0.7-0.2c0.2,0,0.4,0,0.6-0.2c0.3-0.2,0.5-0.2,0.7,0c0.1,0.6,0.3,1.2,0.9,1.4
                c0.6,0.2,1.1-0.4,1.2-0.9c0.2-0.7,1.3-0.3,1.7-0.1c0.6,0.4,0.2,1.1,0.6,1.5c0.5,0.6,1.2,0.2,1.6-0.2c0.4-0.4,0.7,0.5,1.1,0.5
                c0.3,0,0.2,0,0.5,0c0.2,0,0.7,0.3,0.9,0.5c0.3,0.2,0.7,0.2,1,0.1c0.9-0.4,0.8,0.8,1.5,0.9c0.5,0,1.2,0,1.3-0.6
                c0.3-0.9,2.3-0.2,3-0.9c0.2-0.2,0.3-0.4,0.3-0.6c0-0.3,1.5-1.2,1.7-1.3c1-0.5,0.6,1.1,0.8,1.3c0.2,0.3,0.5,0.4,0.8,0.6
                c0.5,0.4,1.3,0.9,1.9,0.4c0.4-0.3,0.7-0.4,1-0.8c0.1-0.1,0.5-0.5,0.7-0.7c0.6-0.6,1.1-0.8,1.9-0.8c0.6,0,1.3-0.1,1.6-0.7
                c0.2-0.3,0.5-0.4,0.6-0.7c0.3-0.8,2.3-1.8,3-1.3c0.3,0.2,0.9,0.7,1.2,0.1c0.3-0.7,1.4-0.5,1.9-0.3c0.6,0.2,1.2,0.6,1.8,0.9
                c0.3,0.1,0.7-0.1,0.8-0.3c0.6-0.8-0.4-1.9,0.7-2.5c0.4-0.2,0.5-0.4,0.9-0.6c0.1-0.1,0.2-0.2,0.3-0.3c0.4-0.6,1-0.4,1.5-0.7
                c0.5-0.2,0.7-1,1.1-1.3c0.3-0.2,0.6-0.5,0.9-0.7c0.6-0.4,1-0.1,1.6-0.2c0.3-0.1,0.7-0.3,1-0.5c0.6-0.5,0.8-1.3,1.7-1.4
                c0,0,0.1,0,0.1-0.1c0.4,0,0.7-0.1,1-0.4c0.4-0.3,0.5-0.7,0.8-1.1c0.3-0.4,1.1-0.1,1.6-0.1c0.3,0,0.5-0.3,0.6-0.5c0,0,0,0,0,0
                c0.2-0.2,0.2-0.2,0.2-0.5c0-0.1,0.7,0,0.8-0.1c0.3,0,0.8-0.3,0.9-0.6c0.2-0.5,0.3-1,0.4-1.6c0.1-0.8,0.8-0.6,1.4-1
                c0.3-0.2,0.4-0.6,0.4-0.9c0-0.3,0-0.8,0-1.1c0-0.1,0.5-0.2,0.6-0.3c0.6-0.2,1.1-0.9,1.6-1.3c0.5-0.5,1-0.9,1.6-1.3
                c0.3-0.2,0.4-0.8,0.5-1.2c0.2-0.6,0.5-0.7,0.9-1.1c0.3-0.4,0.6-0.8,0.9-1.2c0.4-0.5,0.9-1.1,1.2-1.7c0.1-0.3,0.3-0.8,0.6-1
                c0.2-0.1,0.4-0.1,0.5-0.3c0.3-0.4,0.7-0.5,0.9-1.1c0.2-0.4,0.5-0.7,0.6-1.1c0.1-0.3,0-0.8,0-1.1
                C1315.9,520.8,1315.9,519.9,1315.7,518.9z"/>
            <path class="st0" d="M1318,527.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0,0,0,0,0,0
                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.4,0-0.5
                C1318.4,527,1318.1,527,1318,527.1z"/>
            <path class="st0" d="M1286.1,629.2c-0.3-0.6-0.5-1.1-0.8-1.6c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1,0-0.1,0
                c-0.3-0.4-0.6-0.9-0.9-1.3c-0.3-0.5-0.4-1.4-0.8-1.8c-0.2-0.2-0.7-0.8-0.9-1.1c-0.2-0.3-0.4-0.6-0.6-0.8c-0.1-0.1-0.2-0.5-0.4-0.6
                c-0.5-0.4-1.3-0.6-1.7-1.1c-0.2-0.2-0.1-0.5-0.3-0.7c-0.3-0.2-0.6-0.3-0.9-0.5c-1.1-0.6-1.6-2-2.6-2.7c0,0,0,0,0,0
                c-0.5-0.6-1.1-1-1.7-1.5c-0.4-0.3-0.7-0.7-1.1-1c-0.4-0.3-0.9-0.4-1.1-0.9c-0.2-0.7-1-1.3-1.6-1.6c-0.5-0.3-0.3-1.2-0.9-1.2
                c-0.8,0-0.4-0.5-0.8-0.9c-0.2-0.2-0.6-0.4-0.8-0.7c-0.4-0.4-0.7-0.7-1.1-1c-0.7-0.8-1.6-1.3-2.3-2c-0.6-0.7-1.5-1.2-2.3-1.6
                c-0.3-0.2-0.7-0.2-1.1-0.4c-0.6-0.3-1.1-0.7-1.6-1c-0.7-0.4-0.8-1.4-1.7-1.6c-0.3-0.1-0.7-0.1-1-0.1c-0.4,0-1.1-0.4-1.5-0.5
                c-0.9-0.3-1.7-0.6-2.6-0.9c-1.6-0.6-3.2-1-4.9-1.4c-0.5-0.1-1-0.1-1.5-0.2c-0.4-0.1-0.6-0.8-1-0.9c-0.3-0.1-0.6,0.1-0.8,0.1
                c-0.5,0-1,0-1.5,0c0,0,0,0-0.1,0c-1.6,0-3.2,0-4.8,0c-1.3,0-2.7,0.1-4,0c0,0,0,0,0,0c-0.8-0.2-1.6-0.2-2.3-0.4
                c-0.6-0.2-1.1-0.3-1.7-0.5c-0.5-0.1-0.9-0.3-1.4-0.4c-0.8,0-1.6-0.2-2.4,0c-0.4,0.1-0.8,0.2-1.2,0.4c-0.2,0.1-0.4,0.2-0.5,0.3
                c-0.5,0.2-0.7,0.1-1.2,0.1c-2.1,0.2-4.2,0-6.2,0c-0.9,0-1.8,0.1-2.8,0.1c-0.8,0-1.1,0.2-1.7,0.7c0,0,0,0,0,0c0,0,0,0,0,0
                c0,0,0,0-0.1,0.1c-0.2,0.2-0.4,0.5-0.6,0.7c-0.2,0.3-0.3,0.7-0.6,0.9c-0.5,0.4-1.2,0.4-1.8,0.6c-1.4,0.4-2.4,1.1-3.5,2
                c-0.4,0.3-0.5,0.8-0.6,1.3c0,0.3,0.1,0.5,0.1,0.8c0.1,0.5-0.2,0.7-0.4,1.1c-0.2,0.4-0.2,0.5,0.2,0.8c0.7,0.5-0.3,1-0.3,1.5
                c0,0.4,0.4,0.6,0,1c-0.3,0.3-0.3,0.5-0.3,0.9c0,0.2,0.3,0.3,0.4,0.4c0.5,0.4,0.5,1.1,0.5,1.6c0,0.4-0.1,1.4,0.4,1.6
                c1.1,0.3,1.5,1.4,1.9,2.4c0.2,0.5,0.7,0.6,1.2,0.6c0.3,0,0.8-0.3,0.9,0.2c0.1,0.3-0.1,0.7,0.1,1c0.2,0.3,0.7,0.2,1.2,0.1
                c0.4,0.5,0.2,1.4,1,1.7c0.4,0.1,0.8,0,1.2,0.1c0.1,0,0.1,0,0.2,0.1c0,0.1,0,0.1,0.1,0.1c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0c0,0,0,0,0,0
                c0.7,0,1.3,0.1,2,0.5c0.6,0.4,1.2,0.7,1.7,1.1c0.2,0.1,0.4,0.2,0.7,0.2c0.3,0,0.7,0.4,1,0.6c0.3,0.1,0.4,0,0.5-0.1h0.1
                c0.6,0,1.3,1,1.5,1.5c0.2,0.5,0.6,0.8,0.9,1.2c0.3,0.5,0.6,0.9,1.1,1.2c0.5,0.4,0.9,1,1.2,1.6c0.5,1,1,1.9,1.8,2.8
                c0.6,0.7,2,1.6,1.4,2.8c0,0.1,0,0.1,0,0.2c-0.2,1.1,0,2.3,0.1,3.4c0,0.3-0.1,1,0.1,1.3c0.2,0.4-0.3,0.7-0.4,1.1
                c0,0.3,0.2,1.2,0,1.3c-0.3,0.2-0.5,0.4-0.5,0.7c0,0.4,0.6,0.6,0.7,1c0.1,0.5-0.5,1-0.4,1.5c0.1,0.8,0,0.4-0.3,0.8
                c-0.2,0.3-0.5,0.6-0.5,1c0,0.5,0.4,1.1,0.6,1.6c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.3,0.5,0.4,0.6,0.7c0.2,0.5,0.6,0.9,0.6,1.5
                c0,0.6,0,1.4-0.1,2c-0.1,0.6,0.5,0.9,0.5,1.5c0,0.5-0.1,0.8,0.1,1.2c0,0.1,0,0.1,0,0.2c0.3,0.4,0.6,0.9,0.9,1.2
                c0.4,0.4,1,0.4,1.5,0.8c0.5,0.4,0.8,1.2,1.2,1.7c0.4,0.7,0.9,1.3,1.4,2c0.3,0.4,0.3,1,0.5,1.4c0.2,0.3,0.5,0.2,0.6,0.7
                c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.7,0.1,0.8,0.3c0,0.1,0.1,0.2,0.2,0.2c0.3,0,0.6-0.2,1-0.3c0.1,0,0.1-0.1,0.2-0.1
                c0.5-0.3,1.1-0.2,1.7-0.1c0.7,0.1,1-0.4,1.7-0.4c0.7,0,1.2-0.1,1.9-0.2c0.5-0.1,0.9-0.6,1.4-0.7c0.6-0.1,1.1,0.4,1.6,0.5
                c0.8,0.2,1.7,0.1,2.5,0.3c0.5,0.1,1.4,0.2,1.9,0c0.5-0.2,0.9-0.8,1.4-1.1c1,0.3,2,0.4,3.1,0.4c0.7,0,1.3-0.2,1.8-0.6
                c1.2-1,1.5,0.7,2.3,1.2c0.7,0.4,1.3-0.1,1.5-0.7c0.1-0.3,0.2-0.6,0.2-1c0.1-0.7,0.7-1.5,1.2-0.5c0.1,0.2,0.3,0.6,0.5,0.8
                c0,0,0.1,0,0.1,0c0.4-0.2,0.7-0.1,0.8-0.6c0.1-0.5,0.4-0.8,0.8-1c0.7-0.4,1.7-0.6,2.3-1.1c0.6-0.5,1.2-0.9,1.7-1.4
                c0.5-0.5,1.4-0.8,2-1.2c0.9-0.6,1.8-1.6,2.9-1.6c0.8,0,1.5,0.3,2.3,0.5c0.6,0.2,1.2-0.4,1.5-0.8c0.1-0.1,0.1-0.1,0-0.2
                c0.5-0.9,1-1.9,1.7-2.8c0.7-0.9,1.4-1.6,2-2.6c0.2-0.3,0.5-0.6,0.5-1c0-0.6,1-1.6,1.4-2.1c0.9-0.9,1.4-2.1,2.4-3
                c0.8-0.7,1-1.8,1.3-2.8c0.2-0.6,0.2-1.3,0.5-1.9c0.1-0.3,0.3-0.5,0.4-0.8c0.1-0.5-0.1-0.7,0.2-1.3c0.2-0.5,0.2-0.9,0.2-1.4
                c0-0.6-0.5-1-0.8-1.4C1286.6,631,1286.4,629.9,1286.1,629.2z"/>
            <path class="st0" d="M1349.8,737C1349.8,736.9,1349.8,736.9,1349.8,737C1349.8,736.9,1349.8,736.9,1349.8,737c-0.1-0.1-0.2-0.2-0.3-0.1
                c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0c0-0.2-0.3,0-0.3,0.1c0,0.2,0.1,0.6,0.3,0.7c0.1,0,0.2,0,0.2,0c0.1,0,0.1-0.1,0.2-0.1
                c0-0.1,0.1-0.2,0.1-0.3C1349.8,737.2,1349.8,737.1,1349.8,737C1349.8,737,1349.8,737,1349.8,737z"/>
            <path class="st0" d="M1373.3,727.8c-0.8,0.1-1.4,0.2-2.2-0.2c-0.9-0.4-1.7-0.3-2.5,0.1c-0.4,0.2-0.8,0.2-1.2,0.1c-0.3,0-1.7-0.4-0.7-1.1
                c0.4-0.3,0.8-0.5,1.1-0.8c0.3-0.2,0.4-0.5,0.4-0.9c0-0.2,0-0.6,0-1c0,0,0.1,0,0.1,0c0.2-0.1,0.4-0.4,0.5-0.6c0.3-0.4,0.4-1,0.6-1.4
                c0.3-0.6,0.5-1.4,0.3-2.1c0,0,0,0,0,0c0.1-0.4,0.1-1.1,0-1.7c0-0.3,0.1-0.6,0.2-0.9c0.3-0.8,0.6-0.2,1-0.3c0.3,0,0.6-0.2,0.6-0.6
                c0-0.3,0.1-0.7,0-0.9c-0.1-0.5,0-1.1-0.1-1.6c-0.1-0.6-0.6-0.3-0.6-1.2c0-0.4-0.4-0.7-0.6-1.1c-0.7-0.9-1.5-1.5-2.1-2.5
                c-1.2-2-4-2.5-5.6-3.9c-0.4-0.3-1.6-1.2-2.2-1c-0.3,0.1-0.8,0.2-1.1,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c-0.3,0.4-0.8,0.4-1.3,0.3
                c-0.4,0-0.7,0-1,0.2c-0.5,0.3-0.7,0.8-1.3,0.8c-0.6,0-1.4,0-1.9,0.6c-0.2,0.3-0.3,0.7-0.8,0.7c-0.4,0-1.1-0.3-1.4-0.1
                c-0.7,0.3-0.2,1.1-0.7,1.5c0,0,0,0,0,0c-0.7-0.1-1.3-0.1-1.9,0.4c-0.2,0.2-0.2,0.4-0.2,0.7c0,0.8-0.7,1-1.2,1.4
                c-0.6,0.5-1.4,0.2-2,0.8c-0.6,0.5-0.9,1.2-1.7,1.5c-0.4,0.1-0.8,0.5-1.1,0.7c-0.4,0.2-0.6,0.4-0.9,0.7c-0.2,0.2-0.3,0.4-0.4,0.6
                c-0.3,0.5-1,0.3-1.4,0.3c-0.5,0-0.8,0.7-1.1,1c-0.2,0.2-0.3,0.4-0.4,0.7c-0.2,0.5-0.4,0.4-0.8,0.6c-0.3,0.1-0.9,0.3-1.2,0.4
                c-0.4,0.2-0.7,0.5-1.2,0.4c-0.9-0.2-1.6,0-2.3,0.5c-0.9,0.6-1.6,0.7-2.6,0.7c-0.5,0-1,0-1.4,0.2c-0.7,0.3-1.1,0.5-1.2,1.3
                c-0.2,1-0.7,1.4-1.7,1.5c-0.3,0-0.5,0.1-0.7,0.3c-0.1,0.1-0.2,0.3-0.2,0.4c-0.3,0.8-0.6,1.2-1.3,1.6c-0.8,0.5-1.7,1-2.1,2
                c-0.1,0.3-0.5,1.2-0.7,1.4c-0.1,0.2-0.3,0.3-0.5,0.5c-0.2,0.2-0.1,0.7-0.1,0.9c0,0.5,0.1,1,0.1,1.5c0,0.1-0.1,0.1-0.1,0.2
                c0.2,1.9,2.9,2.3,4,3.4c0.3,0.3,0.7,0.4,1.1,0.6c1,0.4,0.6,0.7,0.2,1.3c-0.4,0.7,0.2,1,0.7,1.3c0.5,0.3,0.9,0.2,1.4,0.4
                c0.5,0.3,1.1,0.5,1.6,0.7c0.6,0.3,1.6,0.2,2.2-0.1c0.9-0.4,1.7-0.8,2.6-1.2c0.9-0.5,1.7-0.9,2.4-1.6c0.2-0.2,0.4-0.5,0.6-0.8
                c0,0,1-1.3,1.1-0.5c0,0.8-0.1,1.4,0.5,2.1c1.3,1.6,3.3,0,4.6-0.5c0.2-0.1,0.4-0.5,0.6-0.6c0.6-0.3,1.1-0.7,1.9-0.9
                c1.4-0.2,2.7-0.2,4.1-0.3c0.4,0,0.9-0.1,1.3-0.2c0.4,0.1,0.9-0.1,1.1-0.4c0.5-0.6,0.5-1.3,0.4-2c0-0.1,0-0.1,0.1-0.2
                c0.1-0.2,0.3-0.3,0.5-0.2c0.4,0,0.5,0.7,0.6,1c0.1,0.5,0.7,1.1,0.9,1.5c0.3,0.6,0.7,1.2,0.8,1.9c0.1,0.4,0,0.8,0.3,1.1
                c0.6,0.5,1.4,0.5,2.1,0.2c0.4-0.2,0.6-0.6,0.9-0.9c0.5-0.4,1-0.6,1.6-0.6c0.6,0,0.9-0.4,1.1-0.9c0.3-0.9,1.4-0.6,2-0.7
                c0.4,0,1-0.2,1.4-0.4c0.5-0.2,1.2-0.3,1.7-0.3c0.7,0,1.1,0.2,1.7,0.5c0.5,0.3,0.5,0.8,1,1.1c0.3,0.1,0.7,0.1,1.1,0.1
                c0.5-0.1,0.8-0.9,0.9-1.3c0.1-0.3,0.3-0.7,0.4-1c0-0.7-0.2-1.4,0.6-1.7c0.7-0.3,1-1.2,1.3-1.9c0.4-0.7,0.6-1.6,0.2-2.4
                C1373.7,727.8,1373.4,727.8,1373.3,727.8z"/>
            <path class="st0" d="M1527.3,685.6c0-0.9,0.3-1.5,1.4-1.3c0.3,0,0.6,0,0.8-0.2c0.2-0.1,0.3-0.4,0.3-0.6c0.1-0.4,0.6-0.3,0.9-0.6
                c0.4-0.4,0.3-0.7-0.1-0.9c-1.9-0.6,0.5-2.7,1.4-3.6c0,0,0,0,0,0c0.1,0.2,0.4-0.1,0.3-0.2c-0.2-0.7,1.6-1.3,2-1.6
                c0.3-0.2,0.6-0.1,0.9-0.3c0.2-0.1,0.3-0.3,0.4-0.5c0.4-0.4,0.4,0.3,0.5,0.4c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.8-0.2,1-0.3
                c0.4-0.2,0.3-0.6,0.9-0.6c0.5,0,0.8-0.8,1.2-0.9c0.3,0,0.8-0.5,0.7-0.8c0-0.1-0.1-0.1-0.1-0.1c0,0,0,0,0.1-0.1
                c0.2-0.2,0.5-0.3,0.7-0.5c0.4-0.3,0.7-0.9,0.8-1.4c0.1-0.4,0.8-0.5,0.9-0.9c0.1-0.2,0.1-0.3,0.1-0.6c0-0.3-0.2-0.9,0.1-1.1
                c0.1-0.1,0.3-0.2,0.4-0.3c0.4-0.4,0-1.4,0.9-1.3c0.8,0.1,1.1,0.1,1.6-0.6c0.2-0.4,0.3-0.9,0.1-1.3c-0.2-0.3-0.7-1,0-1.2
                c0.2-0.1,0.3-0.2,0.3-0.4c0.2-0.6,0.3-0.8,0.7-1.3c0.2-0.2,0.3-0.5,0.4-0.7c0.1-0.5,0-1.1,0.1-1.6c0.1-0.3,0-0.5-0.1-0.8
                c0,0-0.1,0-0.1,0c-1.3,0.3,0.4-2,0.6-2.2c0.2-0.3,0.4-1.2,0.2-1.5c-0.5-0.8-1-1.6-0.3-2.5c0.3-0.4,0.2-0.9,0.1-1.4c0,0,0,0,0,0
                c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.1-0.1c-0.2-0.4-0.4-0.9-0.8-1.2c-0.4-0.3-0.7-0.6-0.2-0.9c0.2-0.1,0.4-0.4,0.6-0.6
                c0.3-0.3,0.6-0.5,0.7-0.9c0-0.5-0.6-1-0.8-1.4c-0.8-1.1-1.8-2.1-2.5-3.3c-0.2-0.4-0.3-0.5-0.6-0.8c-0.4-0.3-0.7-0.5-1-0.9
                c-0.4-0.5-1.1-1.1-1.7-1.4c-0.6-0.7-0.2-1.7-1-2.4c-0.3-0.3-0.6-0.3-1-0.4c-0.6-0.1-1.1-0.6-1.7-0.7c-1-0.2-1.9-0.7-2.8-1.1
                c-0.5-0.2-1-0.2-1.5-0.4c-0.5-0.2-1-0.7-1.5-0.8c-0.8-0.2-1.1-0.9-1.8-0.8c-0.3,0-0.6,0.2-0.8,0.4c-0.6,0.4-0.8,0.6-1.5,0.4
                c0,0-0.1,0-0.1,0c-0.6,0.2-0.5-0.2-0.9-0.5c-0.5-0.3-1,0-1.4-0.7c-0.3-0.5-1.7-0.2-2.3-0.1c-0.3-0.1-0.5-0.3-0.6-0.7
                c0-0.3-0.4-0.5-0.6-0.7c-0.3-0.4-0.6-0.7-1-1c-0.7-0.6-1.6-0.8-2.3-1.3c-0.5-0.3-0.6-0.4-1.2-0.4c-0.7,0-1.3-0.4-1.9-0.8
                c-0.1,0-0.2-0.1-0.3-0.1c-0.4-0.1-0.2-0.2-0.6-0.5c-0.3-0.2-0.5-0.4-0.9-0.5c-1-0.1-1.6,0-2.5,0.3c-0.7,0.2-1.5,0.5-2,1.1
                c-0.4,0.5-1.4,0.2-1.9,0c-0.5-0.2-0.9-0.2-1.4-0.2c-0.5-0.6-0.7-1.4-1.2-2.1c-0.5-0.8-1.3-1.5-2-2.1c-0.2-0.2-0.3-0.3-0.6-0.4
                c-0.7-0.2-0.7-0.8-0.7-1.4c0-0.5,0.4-0.6,0.5-0.9c0.1-0.3,0.1-0.6,0.1-0.9c0-0.8-0.9-0.5-0.8-1.4c0.1-0.7-0.9-1.1-1.4-1.3
                c-0.6-0.3-1-0.6-1.7-0.6c-0.5,0-1,0-1.5,0c-0.4,0-0.3-1.1-0.3-1.3c0-0.5-0.5-0.8-1-0.7c-0.1,0-0.3,0.1-0.4,0.2
                c-0.3,0.4-0.7,0.7-1.1,1c-0.6,0.6-1.2-0.2-1.9-0.2c-1.2,0-2.9-0.3-3.3-1.6c-0.1-0.4,0.3-1.5-0.2-1.8c-0.7-0.5-1.6-1-2.5-1.2
                c-0.9-0.2-1.7-1-2.3-1.7c-0.5-0.5-1.2-1.3-1.8-1.6c0,0-0.1,0-0.1,0c-0.2-0.2-0.2-0.6-0.5-0.9c-0.2-0.2-0.3-0.1-0.5-0.1
                c-0.2,0-0.4-0.4-0.5-0.6c-0.2-0.3-0.3-0.6-0.6-0.9c-0.2-0.2-0.6-0.1-0.9-0.1c0-0.3-0.1-0.6-0.3-0.7c-0.4-0.2-0.6-0.1-0.7-0.7
                c0-0.1-0.1-0.4-0.2-0.4c-0.3-0.1-0.9-0.2-0.9-0.6c0-0.3,0-0.2-0.2-0.4c-0.1-0.1-0.3-0.1-0.4-0.1c-0.4,0-0.7-0.2-1-0.4
                c-0.4-0.4-0.9-0.4-1.4-0.6c-0.8-0.2-0.7-0.7-0.5-1.2c0.4-1.5-0.7-2.2-2-2.6c-0.6-0.2-1.2,0-1.8,0.3c-0.7,0.3-1.1-1-0.9-1.3
                c0.2-0.3,0.5-0.5,0.8-0.8c0.6-0.6-0.1-2-0.7-2.3c-0.5-0.3-0.9-0.8-1.6-0.9c-0.8,0-1.7-0.5-1.9-1.2c0-0.1-0.6-0.8-0.8-0.8
                c-0.9-0.2-1.9-0.1-2.7-0.1c-0.2-0.2-0.5-0.3-0.7-0.3c-0.4,0.1-0.8,0.9-1.3,0.4c-0.2-0.2-0.6-0.8-1-0.6c-0.5,0.2-0.9-0.4-1.3-0.5
                c-0.3-0.1-0.7,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.5,0.5c-0.7,0.7-1.3-0.1-1.6-0.5c-0.7-0.7-1.1-2.4-2.3-2.4c-0.7,0-1.9-0.1-2.4,0.5
                c-0.6,0.7-1.7,0.7-2.5,1.1c-0.2,0.1-0.2,0.1-0.3,0.3c-0.3,0.3-1.1,0-1.4,0c-0.6-0.1-1.4-0.1-2-0.2c-0.5,0-1.2-0.6-1.6-0.8
                c-0.1,0-0.2,0-0.3,0c-0.4,0-0.7-0.1-1.1-0.2c-0.4-0.3-0.9-0.7-1.3-0.3c-0.7,0.6-1.4-0.2-2.1,0.1c-0.6,0.2-1.2,0.4-1.6,0.9
                c-0.2,0.2-0.1,0.7-0.6,0.6c-0.2,0-0.6,0-0.9,0.1c-0.1,0-0.2,0.1-0.2,0.2c-0.4,0.5-0.6,0.7-1.2,0.4c-0.7-0.3-1.5-0.1-2.3-0.3
                c-0.3,0-0.9,0.1-1.2,0.4c-0.7,0.7-1.8,1.7-2.8,2c0,0-0.1,0-0.1,0c-1.3,0.6-2.8,0.7-3.7,2c-0.4,0.6,0.1,0.6-0.8,1.3
                c-0.2,0.2-0.5,0.3-0.7,0.5c-0.2,0.2-0.6,0.1-0.9,0.2c-0.2,0.1-0.5,0.5-0.6,0.7c-0.5,0.6-1.2,0.6-1.9,0.6c-0.6,0-1.1,0.6-1.6,0.6
                c-0.6,0-1,0.5-1.6,0.6c-0.8,0-1.7,0.3-2.4,0.1c-0.6-0.2-1.3,0.4-1.9,0.5c-0.4,0.1-0.8,0.3-1.2,0.3c-0.5,0.1-1.2,0-1.7,0
                c-0.8,0-1.7-0.1-2.5,0.2c-0.5,0.2-0.9,0.5-1.3,0.8c-0.9,0.6-1.8,1.6-2.5,2.4c-0.5,0.7-1.3,0-1.8,0.5c-0.3,0.2-0.8,0.1-1.1,0.2
                c-0.4,0.1-0.7,0.1-1.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.7-0.2-1.6-0.1-2.4-0.1c-0.5,0-1,0.1-1.4,0.5c-0.1,0-0.3,0.2-0.2,0.3
                c0,0-0.6,0.5-0.6,0.6c-0.3,0.4-0.4,1-1,0.8c-0.5-0.2-0.8-0.3-1.3-0.3c-0.8,0-1-1.8-1-2.3c0-1-0.3-1.8-1.1-2.5
                c-0.4-0.4-0.9-0.8-1.5-0.9c-0.4-0.1-1-1.7-1.2-2c-0.4-0.6-0.3-1.3-0.5-1.9c-0.3-0.6-0.6-1.1-0.8-1.7c-0.2-0.5-0.4-1.5-0.9-1.8
                c-0.3-0.2-0.7-0.5-0.9-0.8c-0.4-0.4-0.8-0.5-1.1-1.1c-0.1-0.2-0.2-0.2-0.3-0.4c-0.4-0.4-0.5-0.8-0.6-1.4c-0.1-0.4-0.3-0.8-0.6-1.1
                c-0.3-0.2-0.1-0.2-0.5-0.2c-0.6-0.1-0.6-0.5-0.6-0.9c0.1-0.1,0.1-0.2,0.1-0.3c-0.4-1.2-1.8-1-2.3-2c-0.6-1.1-1.1-2.2-1.7-3.3
                c-0.4-0.8-0.5-1.5-0.4-2.3c0.2-1.3,0.3-0.7,0.6-1.2c0-0.1,0.1-0.2,0.2-0.3c0-0.3-0.1-0.5-0.3-0.7c-0.4-0.3-0.4-0.2-0.7-1
                c0-0.2-0.1-0.3-0.2-0.4c-0.2-0.1-0.5-0.1-0.8,0c-0.9,0.1-1.9,0.4-2.3-0.6c-0.2-0.5-0.3-1.1-0.5-1.6c-0.3-0.7-0.7-1.1-0.7-2
                c0-0.9-0.5-1.6-1.4-1.6c-1,0-1.3,0.8-2.2,0.9c-0.1-0.2-0.1-0.5-0.2-0.6c-0.6-0.8-0.3-1.5-0.5-2.3c-0.1-0.4-0.2-0.7-0.5-1
                c-0.5-0.5-1.3,0.3-1.7-0.5c-0.6-1.1-1.7,0.5-2.3-0.6c-0.2-0.4-0.4-0.9-0.9-1.1c-0.6-0.2-1.1,0.2-1.6,0.3c-0.7,0.2-0.8-0.5-1.4-0.6
                c-0.7-0.1-1.6-0.9-2-1.6c-0.4-0.6-0.2-1.3-0.5-1.9c-0.2-0.4-0.8-0.6-1.2-0.7c-0.6-0.1-1.2-0.1-1.8-0.3c-0.4-0.1-0.8-0.2-1.2-0.5
                c-0.2-0.2-0.4-0.2-0.7-0.3c-0.6-0.1-0.7,0.9-0.6,1.2c-0.1,0.4-0.1,1.2-0.6,1.2c-0.3,0-0.7,0.2-0.8,0.5c-0.2,0.9-0.8,0.1-1.3-0.1
                c-0.3-0.1-0.9,0.1-1.1,0.4c-0.2,0.4-1.2,0-1.5,0.3c-0.2,0.2-0.9,0.1-1.2,0.1c-0.6,0-0.9-0.3-1.3-0.7c-0.5-0.4-1.3-0.6-2-0.8
                c-0.7-0.2-0.7,0-1.3,0.3c-0.1,0-0.1,0.1-0.1,0.2c-0.4,0.3-0.8,0.7-0.7,1.2c0.1,0.5,0.4,0.9,0.6,1.4c0.1,0.3,0.6,1.2,0.1,1.4
                c-0.2,0.1-0.3,0.2-0.5,0.4c-0.6,0.6-1,1.2-1.8,1.6c-0.4,0.2-0.6,0.3-0.8,0.7c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.5-0.8-0.3-1-0.4
                c-0.4-0.3-0.2,0.4-0.8-0.4c-0.2-0.2-0.2-0.3-0.4-0.5c-0.1-0.1-0.2,0.1-0.2,0.1c-0.3,0.6-0.7,0.9-1.4,0.7c-0.2-0.1-0.7-0.1-1,0
                c-0.2,0.1-0.3,0.4-0.3,0.6c0,0.3,0,0.5,0,0.8c0.1,0.7-0.4,0.6-0.8,0.8c-0.2,0.2-0.4,0.4-0.5,0.7c0,0.2,0,0.6,0.1,0.8
                c0.1,0.8,0,1.4-0.5,2c-0.3,0.4-0.3,1-0.3,1.4c0,0.6,0.1,0.8-0.6,1.3c-0.1,0.1-0.4,0.2-0.5,0.4c-0.2,0.3-0.3,0.6-0.3,0.9
                c0,0.2-0.7,0.6-0.8,0.9c-0.3,0.6-0.4,1.1-0.8,1.7c-0.4,0.5-0.7,1.2-1,1.8c-0.3,0.8,0,1.5-0.8,2c-0.5,0.3-0.4,1.1-0.6,1.6
                c-0.1,0.3,0,0.6,0.1,0.9c0,0.1,0.1,0.2,0.2,0.3c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.8-0.4,1.5-0.5,2.3c-0.1,1.2-0.1,2.1-1,3
                c-1,0.9-1.3,1.8-1.3,3.2c0,1.8,1.1,4.7,3.3,4c0,0.2,0,0.5,0,0.8c0,0.3,0,0.6,0.2,0.8c0.8,0.8,0.4,2.2,0.4,3.2v1.3
                c0,0.7,0.3,1.4,0.1,2c-0.1,0.6-0.7,0.7-0.7,1.3c0,0.7,0.2,1.1,0.7,1.4c0.9,0.5,1.8,1.7,2.1,2.6c0.3,0.8,0.6,1.4,1.2,2
                c0.8,0.9,1.3,1.8,1.8,2.9c0.1,0.2,0.4,0.5,0.6,0.6c0.5,0.4,0.9,0.8,1.3,1.4c0.5,0.8,1.2,1.5,1.8,2.2c0.2,0.2,0.4,0.5,0.6,0.7
                c0.5,0.4,0.7,0.8,1,1.3c0.3,0.6,1.2,1.2,1.8,1.7c0.2,0.2,0.5,0.4,0.8,0.5c0.6,0.2,1.2,0,1.8,0.4c0.3,0.2,0.5,0.6,0.8,0.8
                c0.3,0.3,0.2,0.8,0.5,1.1c0.2,0.2,0.3,0.2,0.5,0.3c0.5,0.2,0.6,0.3,0.7,0.9c0,0.2,0.1,0.4,0.2,0.5c0.1,0.2,0.2,0.3,0.4,0.4
                c0.1,0,0.3,0,0.4,0c0.3,0.2,0.3,0.5,0.2,0.8c-0.1,0.4-0.1,0.8,0,1.3c0.1,0.4,0.6,1.1,1,1.2c0.6,0.2,1.5,0.6,2.1,0.5
                c1.5-0.1,3,0,4.5,0.4c0.7,0.2,1.3,0.6,1.8,1.1c0.3,0.3,0.5,0.5,0.9,0.7c0.1,0,0.2,0.1,0.3,0c0.5,0.1,0.8,0.2,1.1,0.6
                c0.5,0.6,1.2,0.6,1.9,1c0.6,0.3,0.8,0.9,1.5,1.1c0.9,0.2,1.8-0.1,2.7,0.4c0.6,0.3,1.4,0.3,2,0.4c0.3,0.1,0.6,0,0.9,0
                s1.3,0.6,1.6,0.8c0.2,0.1,0.3,0.3,0.6,0.3c0.8,0,0.9,0.3,1.3,0.9c1,1.3,2.7,2.2,4,3.1c0,0,0.1,0,0.1,0c0.3,0.2,0.6,0.3,1,0.3
                c0.5,0,1-0.2,1.5-0.2c0.3,0,0.5-0.1,0.7-0.2c0.7-0.3,1.3-0.2,2-0.6c0.6-0.3,1-0.9,1.5-1.4c0.3-0.3,0.8-0.8,1-1.2
                c0.3-0.6,0.4-1.2,0.7-1.8c0.2-0.5,0.3-0.7,0.9-0.8c0.3-0.1,0.7,0,1-0.1c0.5-0.1,0.9-0.5,1.4-0.7c0.1,0,0.1-0.1,0.2-0.2
                c1.1-0.3,2.2-0.1,3.2,0c0.8,0.1,1.7,0.2,2.4,0.7c0.7,0.5,1.3,1,2.1,1.3c0.4,0.2,1.8,0.5,2.2,0.2c0.3-0.2,0.4-0.4,0.3-0.7
                c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.5,0.5,0.8,0.8,1.2c0.4,0.4,0.7,0.8,0.8,1.4c0.1,0.4,0.2,0.6,0.5,0.9c0.4,0.4,0.1,1.6,0,2
                c0,0.8,0,1.5,0,2.3c0,0.6,0.1,1.3-0.1,1.9c-0.1,0.5-0.2,1.1-0.2,1.6c0,0.8,0.5,1.7,0.8,2.4c0.2,0.4,0.4,0.9,0.6,1.3
                c0.3,0.7,0.2,1.6,0.2,2.4c0,0.3-0.1,1.1,0.1,1.4c0.3,0.4,0.5,0.8,0.8,1.2c0.5,0.7,0.9,1.5,1.4,2.3c0.7,0.9,0.8,2.2,1.2,3.2
                c-0.2,0.9,0.1,1.9,0.1,2.9c0,1.3-0.1,2.4,0.4,3.6c0.1,0.4,0.3,1,0.6,1.3c0.7,0.6,0,1.9-0.1,2.6c-0.1,0.3-0.1,1.1,0,1.4
                c0.1,0.5-0.1,1.1,0.1,1.7c0,0.1,0.1,0.1,0.1,0.1c-0.1,0.8,0,1.7,0.2,2.4c0.3,0.7,0.6,1.3,0.5,2.1c0,0.5-0.2,1.3,0,1.7
                c0.3,0.7,0.6,0.8,0,1.6c-0.1,0.2-0.4,0.6-0.4,0.9c0,0.6-0.4,0.8-0.8,1.2c-0.2,0.2-0.2,0.2-0.4,0.5c-0.2,0.4-1.1,0.6-1.5,0.8
                c-0.5,0.3-0.8,0.6-0.7,1.2c0.1,0.4,0.5,0.7,0.8,0.9c0.8,0.5,1.7,0.9,1.9,1.9c0.1,0.4,0.2,0.8,0.5,1.1c0.5,0.5,0.7,0.9,1,1.6
                c0.3,0.6,0.7,1,0.9,1.6c0.3,0.9-0.4,1.1-0.8,1.7c-0.3,0.5-0.9,1.3-0.4,1.9c0.5,0.6,0.9,0.8,1.2,1.5c0.1,0.4,0.5,0.7,0.8,0.9
                c0.5,0.5,1.7,1.4,2.4,1.3c1.2-0.2,1.9-1.6,3.1-0.5c0.6,0.5,1,1,0.4,1.7c-0.2,0.2-0.1,0.4,0,0.7c0.2,0.8,1.6,1.5,2.3,1.7
                c0.8,0.2,1.1,0.1,1.7,0.8c0.3,0.3,0.5,0.4,1,0.4c1,0,2,0.1,2.9,0.1c0.3,0,0.6,0.2,0.8,0.2c0.6-0.2,1.1-0.3,1.8-0.3
                c0.3,0,0.8,0.6,1.1,0.7c0.2,0.1,0.4,0.2,0.7,0.2c0,0,0,0.1,0.1,0.1c0.7,0.3,1.5,0.2,2.3,0.2c0.7,0,1.5-0.1,2.1-0.4
                c0.4-0.2,0.5-0.5,0.9-0.6c0.4-0.2,0.9-0.3,1.3-0.4c0.9-0.2,1.8-0.4,2.6-0.9c1.7-1,3.1-0.3,4.9-0.3c0.8,0,1.3-0.6,2-0.6
                c0.9,0,1.7,0,2.6-0.1c0,0,0,0,0.1,0c0.9,0.1,1.7,0.3,2.7,0.3h3.3c0.8,0,1.5-0.1,2.2-0.2c0.6,0,1.3-0.1,1.8-0.6
                c0.5-0.5,0.8-0.9,1.2-1.5c0.2-0.3,0.5-0.4,0.7-0.6c0.5-0.7,1-0.5,1.6-0.5c0.6,0,1.5-0.1,1.9-0.6c0.6-0.7,1.4-1.5,2.4-1.5
                c1.1,0,2.3-0.2,2.9-1.3c0.3-0.4,1.1-0.6,1.6-1c0.6-0.5,1.5-0.3,2.2-0.7c0.6-0.4,0.8-1,1.7-1.3c0.4-0.1,0.8-0.2,1.2-0.3
                c0.5-0.1,0.9-0.5,1.4-0.5c1.5-0.1,2.1-1.9,2.7-3c0.4,0.2,0.7,0.5,1.1,0.5c0.5,0,1.3,0.1,1.8-0.1c0.8-0.3,1.2-0.4,1.9-0.9
                c0.6-0.4,1-0.6,1.7-0.6c0.4,0,0.8-0.3,1.3-0.3c0.8-0.1,1.6-0.3,2.3-0.1c0.3,0.1,0.6,0.3,0.9,0.3c0.4,0,0.8,0,1.3,0
                c0.8,0,1.9,0,2.3,0.8c0.2,0.3,0.8,0.6,1.2,0.6c0.5,0,2.1-1.1,2.2-0.2c0,0.3,0.3,0.4,0.6,0.3c0.6-0.2,1.2-0.5,1.8-0.7
                c0.4-0.2,0.6-0.5,1.1-0.5c0.5,0,1,0,1.6,0c0.9-0.1,1.8,0,2.3,0.8c0.2,0.2,0.6,0.5,0.9,0.6c0.3,0.2,0.7,0.3,1,0.2
                c0.5-0.3,0.2-0.8,0.6-1.2c0,0,0.1-0.1,0.1-0.2c0.1-0.1,1.6,0.1,1.8,0c0.1,0,0.2-0.2,0.3-0.3c0.4-0.7,0.6-1.5,1.6-1.5
                c0.9,0,1.2-0.6,1.7-1.2c0.7-0.9,2.1-0.4,2.4-1.7c0.1-0.3,0.3-2.2,1-1.2c0.1,0.2,0.5,0.4,0.7,0.3c0.4-0.1,0.6-0.2,0.8-0.5
                c0.8-1,1.3-0.2,2.1,0c1.1,0.2,1.9-1,2.9-1.3c0.4-0.1,0.6-0.3,0.8-0.6c0.5-0.7,1-0.8,1.9-0.8c0.4,0.1,0.8,0.2,1.1,0.2
                c0.6,0.1,0.9,0.5,1.5,0.5c0.5,0,1,0.1,1.5,0c0.6-0.1,0.7-0.3,1.2-0.6c0.8-0.5,1.2-0.5,2-0.5c0.3,0,0.9-0.1,1.2-0.3
                C1527.2,687.1,1527.4,686.3,1527.3,685.6z M1389.1,639.1c-0.5-0.2-0.5-0.4-0.2-0.6c0.5,0,0.8,0.3,1.2,0.6c0.4,0.3,0.7,0.7,1.2,1
                c0.3,0.2,0.4,0.4,0.5,0.6C1391.4,640.1,1389.6,639.4,1389.1,639.1z"/>
            <path class="st0" d="M1374,574.9c0,0.1,0,0.1,0,0.2c0.1,0.2,0.3,0.2,0.5,0c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.1-0.3,0.1-0.5
                c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.1-0.3-0.2-0.4,0C1374,574.4,1373.9,574.7,1374,574.9C1374,574.9,1374,574.9,1374,574.9z"/>
            <path class="st0" d="M1377.7,584.6c0.3-0.1,0.5-0.6,0.2-0.8c-0.1-0.1-0.4-0.1-0.5-0.1c-0.2,0-0.3,0.2-0.4,0.3c0,0.1,0,0.1,0,0.1
                c0,0,0,0.1,0,0.1C1377,584.6,1377.5,584.7,1377.7,584.6z"/>
            <path class="st0" d="M1928.5,1066.3c-0.1-0.6-0.1-1.4-0.7-1.7c-0.7-0.4-1.5-0.8-2.2-1.3c-0.6-0.5-0.9-1.1-1.7-1.5c-0.7-0.3-1.5-0.5-2.2-0.8
                c-0.2-0.1-0.6-0.4-0.8-0.4c-0.2,0-0.4-0.1-0.7-0.1c-0.9-0.6-1.8-1.3-2.8-1.8c-0.6-0.2-1.1-0.6-1.7-0.8c-0.6-0.2-1.3-0.3-2-0.4
                c-0.5-0.1-0.9-0.3-1.4-0.3c-0.6,0-1-0.5-1.5-0.7c-0.4-0.1-0.7-0.1-0.9,0.2c-0.3-0.3-0.8-0.6-1-0.9c-0.4-0.5-1.4-0.9-1.9-1.3
                c-0.7-0.4-1.4-0.5-2-1.1c-0.7-0.6-1.6-1-2.3-1.6c-0.4-0.3-0.6-0.6-1-0.9c-0.5-0.3-1-0.4-1.5-0.9c-0.4-0.5-1.5-0.7-2.1-0.8
                c-0.4-0.1-0.8-0.3-1.2-0.4c-0.6-0.2-1.3-0.2-1.6-1c-0.2-0.5-0.5-1.2-1-1.4c-0.4-0.1-0.7-0.1-1.1-0.2c-0.5-0.1-0.8-0.6-1.1-1
                c-0.2-0.4-0.4-0.7-0.7-1.1c-0.2-0.2-0.4-0.8-0.6-0.9c-0.9-0.5-1.8-0.8-2.4-1.7c-0.2-0.3-0.3-0.3-0.5-0.5c-0.4-0.3-0.7-1-0.9-1.3
                c-0.1-0.1-0.9-0.4-1.1-0.4c-0.5,0-1.1,0.1-1.6-0.2c-0.6-0.4-0.8-1.1-0.7-1.7c0-0.3,0.2-0.6,0.3-0.9c0.1-0.5,0-1.1,0-1.5
                c0-0.8-0.1-1.5-0.4-2.2c0-0.1-0.1-0.1-0.1-0.1c-0.4-0.5-0.7-0.4-1.4-0.5c-0.6,0-1.1-0.1-1.7-0.2c-0.4,0-1.5,0.2-1.8-0.1
                c-0.4-0.5-1.4-0.6-1.9-0.9c-1-0.6-1.7-1.6-2.5-2.4c-0.9-0.8-1.6-1.7-2.1-2.7c-0.3-0.7-0.7-1.2-0.3-2c0.2-0.5,0.1-1,0.2-1.5
                c0.2-0.9,0.2-1.8-0.3-2.6c-0.4-0.6-0.3-1.5-0.1-2.2c0.2-0.5,0.1-0.9,0.3-1.3c0.2-0.9,0.5-1.9,0.5-2.8c0-1.3,0-2.5,0-3.8
                c-0.1-2.2-1.3-4.2-2.2-6.1c-0.6-1.2-1.4-2.4-1.6-3.7c-0.2-1-0.2-2-0.4-3c-0.2-0.9-1.1-1.3-1.7-2c-0.3-0.4-0.5-1-1-1.1
                c0-0.1,0-0.1-0.1-0.1c-0.3-0.1-0.5-0.1-0.8,0.1c-0.6,0.4-0.8,0.7-1.5,0.1c-0.3-0.2-0.7-0.2-1,0c-0.8,0.5-1.5,1.4-2.5,0.8
                c-0.5-0.3-1-0.2-1.2,0.4c0,0.2,0,0.3,0,0.5c0,0.7-0.7,0.1-0.8,0c-0.5-0.5-0.7-1.1-1.1-1.7c-0.3-0.3-0.6-0.4-1-0.4
                c-0.5,0-0.4,0.3-0.9,0.1c-0.3-0.1-0.7-0.3-1-0.4c-0.8-0.2-1.4,0-1.4,0.9c0,0.5-1.6,0.3-2,0.4c-0.9,0.1-1.8,1.4-2.5,1.9
                c0,0,0,0-0.1,0.1c-0.8,0-1.7,0.1-2.5,0.3c-0.5,0.1-0.8,0.6-1.1,0.9c-0.7,0.9-1.8,0.8-2.9,0.9c-0.4,0.1-1.1-0.3-1.5-0.5
                c-0.8-0.3-0.9-0.7-1.3-1.4c-0.1-0.2-0.1-0.9-0.3-1.2c-0.2-0.4-0.3-0.8-0.4-1.2c-0.1-0.3-0.3-0.5-0.5-0.8c-0.4-0.5-0.3-0.8-0.5-1.4
                c-0.2-0.9-0.1-1.9-0.1-2.8c0-0.9-1.1-1.7-0.4-2.5c0.4-0.6,0.3-1.3,0.4-2c0.1-0.9,0.3-1.8,0.3-2.7c0-0.4,0-0.9,0.1-1.3
                c0.1-0.4,0.3-0.8,0.3-1.2c0-0.5-0.3-0.6,0-1.2c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.6-0.5-1.1-0.9-1.3c-1-0.7-0.2-2.3,0.4-3.1
                c0.3-0.4,0.3-0.8,0.1-1.2c-0.1-0.3-0.6-0.7-0.8-1c-0.4-0.5-0.5-0.8-0.5-1.4v0c0-0.5,0.1-0.9,0.1-1.4v-0.2c0.5-1.5,1.2-2.9,1.5-4.3
                c0.1-0.7,0.4-1.4,0.6-2.1c0.1-0.6-0.1-1-0.3-1.5c0.1-0.1,0.1-0.2,0.1-0.3c-0.4-0.9-0.1-1.4,0.2-2.2c0.2-0.6,0.7-0.5,1-0.9
                c0.5-0.7-0.2-1.5-0.6-2c-0.3-0.4-0.5-0.9-1-1.1c-0.3-0.1-0.2-0.4-0.4-0.6c-0.5-0.5-0.5-1.2-0.7-1.8c-0.1-0.4-0.1-0.7-0.4-1
                c-0.4-0.5-1.2-0.4-1.5-1c-0.4-0.9-1.8-1-2.6-1.4c-1.2-0.5-2.3-1.3-2.9-2.4c-0.3-0.5-0.4-1-0.7-1.5c-0.2-0.3-0.6-0.4-0.7-0.8
                c0-0.1-0.2-0.3-0.3-0.4c-0.4-0.3-0.4-0.9-0.8-1.2c-0.5-0.4-0.6-1.1-1-1.6c-0.1-0.8-0.6-1.3-1-2c-0.6-1.1-1.8-1.4-2.5-2.3
                c-0.6-0.8-1.7-1.3-2.4-1.9c-1-0.8-1.9-1.5-2.8-2.5c-0.7-0.8-1.4-1.8-2-2.7c-0.3-0.4-0.6-0.8-0.9-1.2c-0.2-0.2-0.5-0.6-0.7-0.7
                c-0.4-0.2-0.8-0.4-1.1-0.7c-0.4-0.4-1-0.9-1.5-1.2c-0.4-0.3-0.9-0.8-1.3-1.1c-0.2-0.2-0.6-0.3-0.8-0.4c-0.3-0.1-0.5-0.4-0.8-0.5
                c-0.3-0.2-0.7-0.3-1.1-0.4c-0.6-0.1-1.1-0.6-1.7-0.8c-0.4-0.2-0.9-0.3-1.3-0.3c-0.7-0.1-1.1-0.2-1.2-1c-0.2-1-1.2-1.8-1.8-2.5
                c-0.3-0.3-0.8-0.7-1.2-0.9c-0.6-0.3-1-0.8-1.6-1.1c-0.3-0.2-0.5-0.8-0.8-0.9c-0.3-0.2-1-0.1-1.2-0.4c-0.1-0.3-0.5-0.4-0.8-0.6
                c-0.4-0.4-0.8-0.8-1.2-1c-0.6-0.2-0.9-0.7-1.4-1.1c-0.3-0.2-0.7-0.8-1.1-0.9c-0.7-0.1-1.1-0.2-1.6-0.5c-0.4-0.2-0.6-0.5-1-0.6
                c-0.6-0.8-1.1-1.5-2.2-1.8c-0.7-0.2-1.7,0.1-1.9-0.8c-0.1-0.2-0.1-0.6-0.3-0.8c-1-0.7-1.9-1.6-2.8-2.4c-0.4-0.3-0.7-0.4-1.2-0.4
                c-0.9,0-1.8-0.2-2.3-1c-0.3-0.5-0.3-0.4-0.9-0.7c-0.5-0.2-0.9-0.6-1.3-0.9c-0.6-0.4-0.9-0.8-1.2-1.4c-0.1-0.2-0.3-0.2-0.5-0.1
                c-1.1,0.4-0.7-2-1.6-1.8c-0.6,0.1-0.5,0.8-0.5,1.3c-0.3-0.4-0.3-0.8-1-0.7c-1,0.1-1.7,0.5-2.7,0.1c-0.3-0.2-0.5-0.2-0.7-0.5
                c-0.1-0.2-0.2-0.6-0.4-0.8c-0.5-0.5-1.2-0.1-1.8-0.5c-0.6-0.4-1-1.1-1.6-1.5c-0.1-0.1-0.3,0-0.4,0.1c-0.4,0.2-0.8,0.1-1.2,0.1
                c-0.8,0-1.5-0.9-2.2-1.2c-0.3-0.2-0.5-0.5-0.9-0.6c-0.4-0.4-1.3-0.5-1.8-0.9c-0.8-0.6-1.6-0.9-2.4-1.4c-0.3-0.2-0.6-0.3-1-0.4
                c-0.5-0.2-1.1,0.2-1.7,0.1c-1-0.1-2.1-1-2.9-1.5c-2.2-1.2-4.1-2.7-6.1-4.2c-0.7-0.6-2.1-0.5-2.9-0.5c-1.1,0-2.4,0.5-3.2-0.6
                c-0.2-0.3-0.4-0.7-0.6-1c-0.4-0.4-1.1-1-1.4-1.5c-0.2-0.2-0.6-1.5-1.1-1.3c-0.1,0-0.5,0.4-0.6,0.1c-0.1-0.2,0.1-0.6-0.2-0.8
                c-0.2-0.1-0.4-0.1-0.6,0c-0.6,0.1-1.1,0.1-1.7,0.1c-0.6,0-1-0.5-1.5-0.8c-0.5-0.3-1-0.4-1.4-0.6c-0.9-0.4-2-0.4-2.8-0.8
                c-0.2-0.1-0.4-0.4-0.7-0.4c-0.7,0.1-1.3,0.1-2-0.2c-0.4-0.2-0.7-0.4-1.1-0.5c-0.3-0.1-0.6,0-0.9-0.2c-0.1,0-0.1-0.1-0.2-0.2
                c0,0,0-0.1,0-0.1v-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1-0.2-0.2-0.4-0.4-0.6c-0.5-0.4-1.5-0.4-1.5-1.2c0-0.5-1.2-0.3-1.5-0.3
                c-0.7,0-1.4,0.1-2.1-0.2c-0.4-0.2-0.8-0.6-1.2-0.7c-1-0.3-2.3,0.2-3.2-0.4c-0.3-0.2-0.8-0.2-1.2-0.3c-0.5-0.2-0.8-0.5-1.2-0.9
                c-0.3-0.2-0.6-0.2-0.9-0.3c-0.5-0.1-1-0.5-1.4-0.8c-0.5-0.3-1.1-1.3-1.8-1c-0.4,0.1-1,0.5-1.4,0.5c-0.7,0-1.3,0-2,0.1
                c-0.6,0.1-1.1,0.3-1.7,0c-0.4-0.2-0.8-0.6-1.2-0.9c-0.5-0.3-1-0.7-1.5-1c-0.4-0.2-0.7-0.6-1-0.8c-0.5-0.3-1-1-1.6-0.5
                c-0.7,0.5-1.4,0.7-1.9-0.1c-0.3-0.5-0.6-1-0.9-1.4c-0.4-0.7-1.3-0.6-2-0.5c-0.7,0-2.1-0.4-2.7,0c-0.1,0.1-0.2,0.1-0.2,0.3
                c-0.1,0.5-1.4,0.3-1.8,0.2c-0.6,0-1,0-1.3,0.5c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.4,0.1-0.7,0.4-1,0.7c-0.5,0.4-0.9,0.8-1.4,1
                c-0.3,0.1-0.7,0.2-0.9,0.4c0,0-0.1,0-0.1,0c-0.6,0.1-1.3,0.1-1.9,0.3c-0.7,0.2-1.7,0-2.4,0c0,0,0,0-0.1,0c-0.3-0.4-0.5-0.8-1-1.2
                c-0.3-0.3-0.5-0.7-0.7-1c-0.3-0.3-0.6-0.6-1-0.8c-0.8-0.6-1.3-1.3-1.8-2.2c-0.3-0.6-1-0.7-1.6-0.7c-0.7,0-1.1,0-1.5-0.6
                c-0.2-0.2-0.4-0.4-0.7-0.5c-0.7-0.4-1.1,0-1.6-0.8c-0.2-0.3-0.6-0.1-0.9-0.1c-0.3,0-0.3,0-0.5,0.2c-0.5,0.3-1.3,0.1-1.9,0
                c-0.2-0.7-0.5-1.3-0.7-2c-0.2-0.6-0.7-1.3-1.1-1.8c-0.4-0.5-1.1-0.9-1.6-1.3c-0.8-0.7-1.4-1.3-2.4-1.9c-1-0.6-2.2-1.2-3.2-1.7
                c-0.5-0.2-1-0.2-1.5-0.5c-0.5-0.2-0.8-0.6-1.3-0.8c-0.8-0.5-1.6-0.8-2.4-1.3c-0.2-0.1-0.6-0.1-0.8-0.1h-2.1c-0.4-0.8-0.5-1.8-1.5-2
                c-0.8-0.1-1.6-0.3-2.3-0.7c-0.6-0.4-0.9-1.2-1.6-1.4c-0.6-0.2-1.2-0.3-1.8-0.5c-0.8-0.2-1.4,0-2.1-0.5c-0.6-0.5-0.3-1.1-1-1.5
                c-0.4-0.3-1.3-0.4-1.8-0.5c-2.1-0.4-2.1-2.9-1.7-4.5c0.1-0.4,0.2-0.9,0-1.3c-0.2-0.4-0.5-1.1-0.9-1.5c-0.5-0.5-1.2-0.6-1.8-0.9
                c-0.5-0.3-1-1.1-1.3-1.6c-0.2-0.5-0.7-0.5-1.1-0.5c-0.5,0-1.1-0.2-1.6-0.1c0-0.3-0.1-0.6-0.3-0.9c-0.2-0.4-0.5-0.7-0.6-1.2
                c-0.1-0.3-0.3-0.5-0.6-0.6c-0.5-0.3-0.8,0.2-1.3-0.4c-0.2-0.2-0.3-0.5-0.6-0.6c-0.3-0.1-0.7-0.1-1-0.1c-0.5-0.1-0.8-0.5-1.1-0.9
                c-0.2-0.2-0.4-0.3-0.6-0.4c-0.4-0.2-0.6-0.5-1-0.6c-0.3-0.2-0.7,0.3-0.9,0.5c-0.5,0.7-0.9,0-1.3-0.1c-0.5-0.1-0.9-0.1-1.4-0.1
                c-1.2,0-1.9-0.7-2.9-1.3c-0.8-0.4-1.7-0.5-2.5-1c-0.9-0.6-1.8-0.9-2.5-1.8c-0.5-0.6-1.1-0.6-1.8-0.6c-0.7,0.1-1.1-0.4-1.6-0.7
                c-0.4-0.2-0.9-0.4-1.4-0.5c-0.4-0.1-0.7-0.3-1.2-0.4c-0.5-0.1-0.8-0.1-1.2-0.5c-0.4-0.3-1.1-1.2-1.6-1.2c-1.2,0-2.4,0.1-3.6,0.1
                c0,0-0.1,0-0.1,0.1c-1.2,0-2.5-0.1-3.7-0.1c-0.5,0-0.8,0.2-1.2,0.4c-0.5,0.3-1-0.2-1.4,0.1c-0.4,0.3-0.6,0.7-1.1,0.9
                c-0.4,0.1-0.7,0.2-1,0.6c-0.5,0.5-1.7,0.6-2.2,0.7c-0.6,0.1-1,1.1-1.3,1.4c-0.3,0.3-0.5,0.5-0.7,0.9c-0.1,0.4-0.5,0.6-0.7,1
                c-0.2,0.7-0.8,0.8-0.9,1.5c-0.1,0.8,0.1,0.6-0.2,1.3c-0.1,0.4-0.4,0.8-0.6,1.1c-0.4,0.7-0.8,1.4-1.3,1.9c-0.7,0.8-0.7,1.5-1,2.6
                c-0.2,0.6-0.8,1.2-0.6,1.9c0.3,1.2,0,2.5,0.1,3.8c0,0.7,0,1.3-0.2,2c-0.2,0.6-0.1,1.2-0.3,1.9c-0.3,1-0.4,1.7,0.1,2.6
                c0.4,0.8,0.2,1.7,0.2,2.6c0,0.5,0.3,1,0.4,1.5c0.1,0.5,0.2,1,0.4,1.4c0.2,0.5,0.1,1.1,0.3,1.6c0.4,1.3-0.3,2.7,0,4.1
                c0.1,0.4,0.4,0.8,0.6,1.1c0.4,0.8,0.6,1.4,0.6,2.3c0,0.4-0.1,1.1,0.3,1.4c0,1.1-0.2,2.2-0.2,3.3c0,0.4,0.3,0.6,0.4,1
                c0.1,0.6,0,0.8,0.4,1.4c0.4,0.6,0.1,1.1,0.3,1.6c0.3,0.5,0.6,0.8,1.1,1.2c0.5,0.5,1.1,0.7,1.6,1.3c0.5,0.8,0.2,1.4,0.1,2.2
                c-0.1,0.8,0.1,1.9,0.5,2.6c0.2,0.4,0.7,0.7,1.1,0.9c0.6,0.3,0.3,0.9,0.5,1.3c0.5,0.9,1.5,1.4,2.1,2.2c0.6,0.7,1.2,1.6,1.9,2.2
                c0,0,0,0,0,0c0.1,0.5,0.3,1.3,0.6,1.6c0.4,0.3,0.8,0.5,1.2,0.8c0.5,0.3,0.8,1.1,1.2,1.5c0.6,0.8,1.4,1.4,2.2,1.9
                c0.4,0.3,0.4,0.5,0.6,0.9c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.2,0.2,0.5,0.2,0.7c0.1,0.3,0,0.6,0.2,0.9c0.3,0.4,0.8,0.6,1.2,0.8
                c1,0.6,0.5,1.7,1.2,2.5c0.2,0.3,0.7,0.5,1,0.7c0.6,0.5,0.7,1.1,1.2,1.7c0.3,0.3,0.5-0.1,0.9,0.5c0.3,0.4,0.4,0.9,0.8,1.3
                c0.5,0.6,0.2,1.5,0.9,2c0.8,0.6-0.6,1.2,0,1.8c0.4,0.4-0.6,1.7-0.7,2.3c0,0.4-0.1,1.1-0.1,1.5c0,0.4,0.1,0.6,0.4,0.9
                c0.5,0.6-1.1,1.8-1.2,2.4c-0.2,0.7,0.6,1.4,0.4,2.1c-0.1,0.6-0.4,0.9-0.4,1.5c0,0.8,0.3,1.8-0.2,2.5c-0.5,0.2-1,0.2-1.3,0.8
                c-0.2,0.4-0.3,0.9-0.5,1.3c-0.2,0.5-1.3,0.2-1.8,0.4c-0.2,0.1-0.5,0.3-0.6,0.5c-0.3,0.5-0.6,0.2-0.9,0.5c-0.2,0.1-0.3,0.2-0.4,0.4
                c-0.4,0.8-1.1,0.3-1.7,0.3c-0.7,0-1.3,0.7-1.6,1.2c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.5-0.6,1-0.7,1.5c-0.3,1-1.2,1.5-1.7,2.4
                c-0.3,0.6-1.4,2.5-2.2,2.4c-0.7-0.1-1.4,0-2,0.5c-0.5,0.4-0.2,1.5-0.3,2.1c-0.1,1.3-1.4,1-2.1,1c-0.3,0-0.4,0.2-0.6,0.4
                c-0.1,0.1-0.2,0.4-0.3,0.6c-0.2,0.5-0.6,1-0.7,1.6c-0.2,0.8-0.3,1.8,0.2,2.5c0.5,0.7,0,1.6-0.7,1.9c-0.3,0.1-0.6,0.3-0.8,0.6
                c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.7-0.2,1.2-0.4,1.9c0,0.1,0,0.3,0,0.5c0,0.5-0.4,0.4-0.7,0.5c-0.4,0.2-0.6,0.4-0.9,0.6
                c-0.1,0-0.2,0.1-0.3,0.2c0,0,0,0,0,0.1c-0.1,0.1-0.1,0.2,0,0.3c-0.2,0.8-0.3,1.5-0.9,2.2c-0.5,0.6-0.9,1.4-1.5,1.9
                c-0.4,0.4-0.7,1-0.9,1.5c-0.3,0.8-1,1-1.4,1.6c-0.6,0.9-2,1.2-2.1,2.3c-0.1,0.7,0.5,1.1,0.7,1.7c0.2,0.7,0.6,1.5,0.7,2.3
                c-0.5,0.5-1,1-1.7,1.3c-0.7,0.3-1.4,0.4-2.2,0.5c-0.7,0-1.4-0.1-2.1,0c-0.3,0-0.7,0-1,0c-0.3,0-0.7,0.2-1.1,0.2
                c-1.3,0-2.6-0.5-3.8,0.1c-1.1,0.5-2.3,0.8-3.5,1.2c-0.4,0.1-0.9,0.6-1.4,0.9c-0.4,0.3-0.9,0.6-1.2,1c-0.4,0.3-0.6,0.9-0.9,1.3
                c-0.4,0.6-1.2,1.1-1.5,1.7c-0.4,0.7-1,0.8-1.6,1.3c-0.5,0.4-0.8,0.9-1.1,1.4c-0.3,0.5-0.4,1-0.6,1.5c-0.2,0.6-0.7,1.1-1,1.7
                c0,0,0,0,0,0c-0.7,0.8-1.5,1.6-2.4,2.4c-0.3,0.2-0.7,0.3-1,0.6c-0.4,0.5-0.8,0.9-1.3,1.2c-0.7,0.4-1.1,1.3-1.6,2
                c-0.4,0.6-1,1-1.5,1.4c-0.2,0.2-0.3,0.3-0.5,0.5c-0.3,0.3-0.9,0.7-1.3,0.7c-0.3,0-0.6-0.1-0.9,0.1c-0.4,0.1-0.7,0.7-0.8,1.1
                c-0.1,0.4,0.1,0.8-0.3,1.1c-0.3,0.2-0.6,0.5-1,0.8c-0.6,0.4-1,1.2-1.4,1.8c-1.1,2-1.6,4-2.2,6.2c-0.3,1-0.2,2-0.2,3
                c0,0.7-0.4,1.1-0.7,1.6c-0.1,0.3-0.1,0.7-0.1,1.1c0,0.7,0.5,1.9-0.1,2.5c-0.2,0.2-0.4,0.5-0.5,0.7c-0.2,0.4-0.6,0.6-0.8,0.9
                c-0.4,0.5-0.2,1,0.1,1.5c0.1,0.1,0.3,0.9,0.5,1c0.5,0.3,0.8,0.6,1.2,1c0.1,0.1,0.1,0,0.2-0.1c0.3,0.3,0.4,0.8,0.4,1.1
                c0.1,0.4,0.5,0.6,0.7,0.9c1,1.2,0.7,3.5,0.7,4.9c0,0.4-0.1,0.9,0,1.3c0.1,0.4,0.6,0.5,0.8,0.8c0.1,0.1,0.6,0.5,0.6,0.5
                c0.2,0.2,0.3,0.5,0.5,0.7c0.2,0.2,1,0.3,1.3,0.3c0.5,0.1,1.1,0,1.6-0.1c1-0.2,0.6,0.8,0.5,1.1c-0.2,0.9,0,2.3,0.8,2.9
                c0.5,0.4,0.8,0.9,1.2,1.5c0.2,0.3-0.3,1.2-0.4,1.3c-0.3,0.6-0.4,1.2-0.7,1.8c-0.2,0.4-0.6,0.8-0.6,1.3c0,0.6,0,1.2,0.1,1.7
                c0.1,0.4,0.4,0.7,0.6,1.1c0.5,0.9,1,2.2,2.1,2.5c0.2,0.1,0.6-0.1,0.7-0.1c0.3-0.1,0.7,0,1,0c0.6,0,1.2,0.4,1.8,0.5
                c1.1,0.2,1.8,1.3,2.8,1.8c0.9,0.5,1.8,0.6,2.9,0.6c0.2,0,1.1-0.1,1.2,0.2c0,0.1,0,0.4,0.1,0.5c0.4,0.5,0.9,0.9,1.1,1.5
                c0.2,0.6,0,1.3,0.1,1.9c0.1,0.6,0.6,1,0.6,1.6c0,1.2,0.7,2,1.3,2.9c0.5,0.7,0.8,1.4,1.2,2.1c0,0,0,0,0,0.1s0,0,0,0.1
                c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0c0.6,0.9,0.7,2.3,0.8,3.2c0.1,0.6,0.4,1.2,0.4,1.8c0,0.3,0.2,0.9,0.4,1.2
                c0.4,1.1,0.7,2.2,0.9,3.4c0.1,0.6,0.3,1.1,0.5,1.7c0.2,0.7,0.3,1.3,0.5,1.9c0.1,0.6,0.1,1.2,0.4,1.9c0.2,0.6,0.2,1.2,0.2,1.9
                c-0.1,0.9,0.8,1.1,1.4,1.6c0.5,0.5,0.9,0.9,0.5,1.5c-0.2,0.4-0.1,0.6,0,1c0.1,0.4,0.3,0.7,0.6,1c0.5,0.5,0.1,1.3,0.1,1.9
                c0,0,0,0,0,0.1c0,0,0,0,0,0c-0.1,0.6-0.2,1.2-0.2,1.8c0,0.7-0.3,1.2-0.5,1.9c-0.4,1-0.3,1.8,0.3,2.6c0.4,0.5,0.6,1,1,1.5
                c0.4,0.5,0.8,1,1.1,1.5c0.4,0.7,0.3,1.6,0.3,2.4c0,0.7-0.3,1.3-0.5,2c-0.1,0.6,0.3,1.4,0.9,1.5c0.7,0.1,1.1,0.1,1.5,0.7
                c0.3,0.4,0.5,0.7,1,0.8c0.6,0.2,1.1,0.7,1.5,1.3c0.3,0.3,0.3,0.6,0.7,0.7c0.2,0.1,0.4-0.1,0.5-0.2c0.4-0.4,1.6-2.3,2-0.6
                c0.2,1,0.8,0.2,1.4,0.2c0.3,0,0.6,0,0.9,0c0.8,0.1,0.4,1.1,0,1.4c-0.3,0.2-0.5,0.4-0.6,0.8c-0.1,0.7,0,1.4-0.2,2
                c-0.2,0.4-0.8,1.1-1,1.5c-0.6,1.1,0.3,1.2,0.7,2.2c0.5,1.1,0,2.6,0.3,3.8c0.3,1.2,0.6,2.5,0.6,3.8c0,0.6-0.2,1.6,0.6,1.8
                c0.5,0.1,0.9,0,1.3,0.4c0.2,0.2,0.5,0.4,0.8,0.2c0.9-0.6,0.6,2.4,0.7,2.5c0.1,0.2,0.1,0.4,0.3,0.5c0.6,0.2,0.4,0.7,0.5,1.2
                c0,0.3,0.3,0.5,0.5,0.6c0.1,0.1,0.3,0.1,0.4,0.2c0.5,0.3,0,1.1-0.1,1.5c-0.1,0.6-0.7,0.7-0.9,1.1c-0.2,0.3-0.2,0.6-0.2,1
                c0,0.8-0.4,1.1,0.3,1.7c0.4,0.4,0.7,1,1.1,1.3c0.3,0.2,0.6,0.3,0.9,0.4c0.7,0.4,0.8,1.8,1.2,2.5c0.2,0.3,0.4,1,0.9,1.1
                c0,0,0,0.1,0.1,0.1c0.9,0.3,1,1.5,0.7,2.3c-0.2,0.5,0,1.4,0.1,1.9c0.1,0.7,0.5,1.2,0.7,1.9c0.1,0.3,0,0.7,0.1,1.1
                c0.1,0.4,0.5,0.1,0.4,0.7c-0.1,0.3-0.1,0.8-0.1,1.2c0.1,0.6,0.7,0.8,1,1.3c0.4,0.5,0.5,1.1,0.5,1.7c0,0.2,0.2,0.4,0.2,0.6
                c0,0.9,0.3,1.8,0.1,2.8c-0.2,0.7-0.7,1.3-0.9,2c-0.3,0.8-0.5,1.6-0.6,2.4c-0.1,0.7,0.3,1.5,0.3,2.2v3.2c0,1.7-0.3,3.4-0.3,5.1
                c0,0.3,0,0.7,0,1c0,0.4-0.4,0.7-0.6,1c-0.3,0.6-0.2,1.1-0.2,1.8c-0.3,0.5-0.4,1.1-0.4,1.7c-0.1,0.6-0.2,1.3-0.3,1.9
                c-0.1,0.5-0.5,0.7-0.6,1.1c-0.3,0.9-0.7,1.7-0.9,2.6c-0.1,0.4-0.2,1-0.2,1.4c0,0.5-0.3,1-0.4,1.5c0,0.4,0.2,0.7,0.4,1
                c0.4,0.6,0.4,1.4,0.4,2.1c0,0.6-0.9,1.5-1.2,2.1c-0.2,0.4-0.4,0.7-0.6,1.2c-0.3,0.9,0.3,2,0.7,2.8c0.3,0.6,0.5,1.1,0.6,1.7
                c0.2,1-0.4,1.7-1,2.4c-0.1,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.2,0.1-0.2,0.3c-0.4,0.5-0.8,1.2-0.9,1.7c0,0.5,0,0.9,0.1,1.4
                c0,0.1,0.1,0.5,0.1,0.6c0.3,0.7,0,1.1-0.4,1.7c-0.3,0.4-0.3,0.8-0.4,1.2c-0.2,0.6-0.3,1-0.3,1.7c0,0.5-0.2,0.9-0.1,1.4
                c0.1,0.3,0.3,1,0.5,1.3c0.5,0.8,0.1,1.9-0.4,2.5c-0.2,0.4-0.6,0.7-0.8,1.1c-0.5,0.8-1.1,1.5-1.4,2.4c-0.3,0.7,0.7,1,1.2,1.3
                c0.8,0.6,0,1.8,0.1,2.5c0,0.8,0.2,1.6-0.3,2.3c-0.5,0.8-1,1.5-1.1,2.5c0,0.7,0.1,1.3,0.1,2c0,0.8-0.4,1.5-0.4,2.3
                c-0.1,1.1,0.5,1.9,0.6,3c0.1,1.3,0.7,2.6,1.4,3.6c0.7,1,1,2.4,1.1,3.5c0.1,1.2,1,2.4,1.7,3.3c0.3,0.4,0.7,0.6,1.1,0.8
                c0.7,0.3,1.1,1.3,1.2,2c0.3,1.3,1.7,1.7,2.2,2.8c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.5,0.6,1,0.8,1.5c0.5,1.2,1,2.1,1.2,3.4
                c0,0.5,0.2,0.9,0.3,1.4c0.1,0.6,0,1.2,0.2,1.8c0.2,0.9,1.5,1.6,2.1,2.3c0.6,0.8,1.4,0.5,2.2,0.4c0.2,0,0.4-0.1,0.6-0.1
                c0.8-0.1,0.7,0.2,1.2,0.6c0.5,0.3,0.8,0.7,1.2,1.1c0.3,0.3,0.6,0.7,1,1c0.4,0.3,0.9,0.1,1.3,0.3c0.8,0.2,1.7,0.3,2.5,0
                c0.8,0.3,1.4,0.6,1.7,1.4c0.4,0.7,2.4-0.2,2.8-0.2c0.6,0,1.2,0.3,1.8,0.5c0.3,0.2,0.4,0.5,0.8,0.6c0.4,0.1,0.9,0,1.3-0.1
                c0.9-0.1,1.2,0.4,1.8,0.8c1.7,1.2,3.9,1,5.8,1c0.5-0.2,0.9,0,1.2,0.5c0.1,0.2,0,0.6,0,0.9c0,0.3,0,0.4,0.2,0.6
                c0.2,0.2,0.6,0.5,0.8,0.7c0.2,0.2,0.9,0.6,1.2,0.7c0.7,0.2,1.2,0.2,1.6,1c0.2,0.4,0.5,0.7,0.8,0.9c0.9,0.8,2.3,0.9,3.3,1.6
                c0.3,0.2,0.6,0.6,0.9,0.9c0.4,0.4,0.8-0.4,1,0.8c0.1,0.7,1,1.6,1.7,2c0.2,0.1,0.6,0.1,0.8,0.1c0.6,0,1,0.1,1.5,0.3
                c0.9,0.2,1.7,0.9,2.7,1.1c0.9,0.2,1.8,0.5,2.7,0.5c0.4,0,0.8,0.2,1.2,0.3c0.7,0.7,2.2,0.9,3,1.3c1.4,0.8,2.6,2,3.7,3.1
                c0.5,0.5,1.1,0.8,1.6,1.4c0.5,0.5,0.7,1.3,1.4,1.6c0.4,0.2,0.8,0.2,1.1,0.5c0.4,0.3,0.7,0.6,1.1,0.9c0.6,0.5,1.3,1,1.8,1.5
                c0.4,0.5,0.8,1,1.3,1.5c0.6,0.6,1,1.6,1.3,2.4c0.1,0.4-0.3,0.9-0.4,1.3c-0.1,0.6-0.6,0.9-0.6,1.5c0,0.2,0.1,0.4,0.2,0.6
                c0.2,0.3,0.1,0.6,0.2,1c0.1,0.6,0.8,0.7,1.2,0.9c0.4,0.2,1.3-0.1,1.7-0.1c0.2,0,0.4-0.1,0.5-0.2c0.1,0,0.2,0,0.2-0.1
                c0.1-0.4,1.4-1.1,1.8-1.4c0.7-0.5,1.7-0.7,2.5-0.9c1.2-0.3,2.2-0.9,2.8-2c0.2-0.3,0.6-0.6,0.8-0.9c0.3-0.3,0.5-0.7,0.8-1
                c0.7-0.7,0.7-1.4,1.9-1.7c0.5-0.1,1.1-0.1,1.5-0.5c0.6-0.5,1-1.2,1.6-1.8c1.2-1.2,1.9-2.2,2.7-3.6c0.2-0.4,0.5-1.1,0.5-1.6
                c0-0.7,0.4-1.3,0.5-2c0.1-0.4,0.3-0.8,0.4-1.2c0.1-0.4,0-1.5,1-0.9c0.3,0.1,0.7,0.2,1-0.1c0.2-0.2,0.2-0.5,0.5-0.5
                c0.2-0.1,0.6,0.5,0.8,0.6c0.2,0.2,1.9,0.5,1.9,0.3c0,0.1,0.1,0.1,0.2,0.1c0.3,0.1,0.9-0.2,1.1-0.4c0.6-0.4,0.8-0.8,0.8-1.5
                c0-0.9,0.4-1.3,1-1.9c0.5-0.5,0.7-1.2,0.8-1.9c0.2-1.2-0.3-1.9-0.6-3c-0.1-0.6-0.1-1.8,0.4-2.2c0.3-0.2,0.6-0.4,0.7-0.7
                c0.4-0.9,1.2-1.6,1.5-2.5c0.1-0.3,0.1-0.6,0.2-1c0.1-1.1,0.6-1.7,1.3-2.4c0.8-0.8,1.6-1.6,2.7-2.1c0.9-0.4,1.7-1.1,2.3-1.9
                c0.3-0.4,0.6-0.8,0.8-1.2c0.2-0.4,0.7-0.8,0.8-1.2c0.4-1,1-1.9,1.4-2.8c0.8-1.7-0.2-3.9-0.7-5.6c-0.4-1.2,0.4-2.1,0.6-3.3
                c0.1-0.9-0.2-1.6-0.4-2.4c0-0.1-0.1-0.1-0.1-0.1c-0.5-1.1,0.2-1.6,1.1-1.9c0.7-0.3,1.1-0.1,1.7-0.2c0.9-0.1,1.2-1.1,1.6-1.8
                c0.4-0.7,0.8-1.7,1.4-2.3c0.9-0.9,1.3-2.2,2.3-3c0.2-0.2,0.4-0.4,0.5-0.7c0.1-0.1,0.1-0.5,0.1-0.5c0.2-0.8,0.7-0.8,1.3-0.8
                c0.9,0,1.4-0.2,1.9-1c0.2-0.3,0-1.1,0.6-1c0.2,0,0.4,0,0.6-0.2c0.4-0.4,0.4-1.3,0.8-1.9c0.2-0.4,0.4-0.8,0.7-1
                c0.1-0.1,0.2-0.3,0.2-0.5c0-0.3-0.3-0.6-0.4-0.8c-0.1-0.1,0.6-0.9,0.7-1.1c0.5-1.1,1.6-0.2,2.4-0.7c0.3-0.2,0.7-0.5,0.9-0.6
                c0.3-0.1,0.9,0,1.3,0c0.4,0,1,0,1.4-0.2c0.4-0.2,0.8-0.2,1.2-0.3c0.5-0.2,1-0.4,1.5-0.6c0.7-0.2,1.2-0.5,1.9-0.6
                c0.3,0,0.8-0.2,1-0.4c1-0.8,2.3-0.9,3.4-1.5c1-0.5,2.2-0.7,3-1.4c0,0,0.1-0.1,0.1-0.1c0.7-0.2,0.9-0.3,1.3-1c0.3-0.5,1-1.2,1.4-1.6
                c0.5-0.5,1-1,1.4-1.6c0.8-1,1.8-1.8,2.6-2.9c0.3-0.4,0.4-0.9,0.6-1.4c0.3-0.6,1.7-1,2.3-1.3c0.4-0.2,0.7-0.3,1-0.6
                c0.4-0.4,1.2-0.5,1.7-0.9c0.6-0.4,1.3-0.7,2-1c0.3-0.1,0.6-0.2,0.9-0.3c0.4-0.2,0.7-0.6,1-0.8c1.1-0.6,2-1.7,3.3-2
                c0.6-0.1,1.3,0,1.9,0c0.4,0,0.9-0.2,1.3-0.3c0.5,0.3,0.7,0.3,1.4,0.3c0.3,0,0.6-0.3,0.8-0.3c0.4-0.1,0.8-0.2,1.2-0.4
                c1.4-0.6,2.9-0.8,4-1.9c0.5-0.5,0.8-1.2,1.2-1.7c0.6-0.8,1.3-1.3,1.8-2.3c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.2,0.3-0.6,0.4-0.9
                c0.3-0.7,0.2-1.5,0.5-2.2c0.1-0.4,0.9-1.9,1.4-2c0.5-0.1,1-0.1,1.4-0.4c0.1-0.2,0.2-0.2,0.3-0.4c0.2-0.4,1.1-0.3,1.4-0.4
                c0.7-0.2,0.9-0.5,1.3-1c0.1-0.2,0.9-0.2,1.1-0.3c0.2-0.1,0.3-0.2,0.5-0.3c0.6-0.5,1.2-1.2,1.7-1.8c0.3-0.3,0.6-0.6,0.8-0.9
                c0.3-0.3,0.7-0.4,1-0.7c0.3-0.4,0.5-0.8,0.7-1.2c0.2-0.1,0.3-0.2,0.4-0.3c0.4-0.4,0.7-0.9,1.4-1.1c0.5-0.2,1-0.6,1.5-0.9
                c1.1-0.8,2.6-1.3,3.9-1.2c0.4,0,0.8,0.5,1.3,0.4c0.3-0.1,0.5-0.3,0.7-0.6c0.6-0.7,1.8-1,2.6-1.1c0.5-0.1,1,0,1.5-0.2
                c0.6-0.3,1.1-0.4,1.8-0.4c0.8,0,1.4,0.7,2.1,1c0.5,0.3,1.1,0.2,1.7,0.5c0.4,0.2,0.7,0.3,1.1,0.3c0.7,0,0.9,0.5,1.4,1
                c0.4,0.4,0.7,0.7,1.3,0.9c1,0.4,2.1-0.2,3.1,0.1c0.7,0.2,1.3,0.2,2,0.6c0.4,0.2,0.9,0.3,1.3,0.3h2.2c0.7,0,1.3,0.3,2,0.3
                c0.9,0,1.9,0.9,2.8,0.2c0.6-0.4,0.7-1.3,1.3-1.6c1-0.6,2.1-0.9,3.2-1c0.9,0,2.1-0.3,2.8-0.8c0.3-0.2,0.8-0.6,1.1,0
                c0.2,0.3,0.4,0.3,0.7,0.3c0.6,0,1.2-0.4,1.7-0.8c0.4-0.3,0.6-0.8,1.1-1c0.6-0.3,1.5-0.6,2.2-0.8c0.4-0.1,0.8-0.7,1.1-1
                c1-0.2,2.3,0.4,3.1,0.8c0.6,0.3,1.8-0.6,2.2-0.9c1-0.6,1.8-1.6,2.6-2.4c0.3-0.3,0.6-0.8,0.9-1.1c0.4-0.5,0.8-1,1.2-1.6
                c0,0,0-0.1,0-0.1c0.5-0.2,0.8-0.5,1.3-0.8c0.4-0.2,0.9-0.4,1.4-0.6c0.8-0.5,1.5-0.9,2.2-1.4c1.1-0.8,1.9-1.8,3.1-2.4
                c0.5-0.3,0.9-0.5,1.3-0.9c0.3-0.3,0.4-0.8,0.7-1.1c0.4,0.1,0.8,0.4,1.1,0.2c1-0.4,1.9-0.9,2.9-1.1c0.6-0.1,1.2,0,1.8-0.3
                c0.8-0.5,1.7-0.9,2.7-1.1c0.7-0.2,1.4-0.5,2.1-0.6c0.8-0.1,1.1,0,1.7-0.4c0.6-0.5,0.4-1.4,1.5-1.6c0.4-0.1,0.9,0.1,1.3,0.1
                c0.4,0,0.8,0,1.2-0.1c1-0.2,2-0.8,2.6-1.7c0.3-0.5,0.2-0.9,1-1.1c0.1,0,0.3-0.1,0.4-0.2c0.2-0.2,0.2-0.2,0.4-0.5
                c0.3-0.5,0.7-0.4,1.2-0.4c0.4,0,1.3,0.1,1.6-0.2c0.5-0.4,0.9-1.1,1.6-1.3c0.4-0.1,0.8,0,1.2-0.1c0.5-0.2,1-0.3,1.5-0.5
                c0.9-0.3,1.8-0.6,2.6-1c0.1,0,0.1-0.1,0.1-0.2c0,0,0.1,0,0.1-0.1c0.4-0.2,0.9-0.2,1.2-0.4c0.6-0.5,1.2-1.1,1.6-1.8
                c0.2-0.3,0.2-0.6,0.2-0.9c0.1-0.8,0.7-1,1.2-1.5c0.3-0.3,0.6-0.7,1-0.9c0.2-0.2,0.5-0.6,0.7-0.7c0.3-0.1,0.6-0.6,0.9-0.8
                c0.5-0.4,1-0.7,1.5-1.1c1-0.6,1.8-1,2.6-1.9c0.3-0.3,0.6-0.6,0.9-1c0.4-0.5,0.7-1.1,1.1-1.6c0.6-0.7,1.8-0.8,2.5-1.6
                c0.4-0.4,0.7-1.1,0.8-1.7c0-0.6-0.2-1.2,0.2-1.7c0.4-0.4,1.2-0.5,1.7-0.6c1.6-0.1,3-1.2,4.2-2.3c1.8-1.7,4-2.5,6.1-3.8
                c0.8-0.5,1.5-0.9,2.1-1.7c0.5-0.8,0.2-1.9,0.8-2.6c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0-0.3,1.6-0.3,1.9-0.4c0.7-0.2,1.3-0.9,1.8-1.4
                c1.1-1.3,3.1-1.8,4.2-3.2c0.3-0.3,0.6-0.8,1-0.9c0.1,0,0.2-0.2,0.3-0.3c0.4-0.5,0.8-1.1,1.3-1.5c0.3-0.3,0.7-0.4,1.1-0.7
                c0.4-0.3,0.7-0.8,1.1-1.1c0.6-0.5,1.5-1.3,1.8-2c0.3-0.7,0.9-1.4,1.4-2c0.3-0.4,0.5-0.9,1-1.2c0.3-0.1,0.6-0.2,0.9-0.4
                c0.7-0.4,0.4-1.5,1.1-2c0.6-0.4,0.8-1.1,1.2-1.7c0.8-1.2,1.1-2.8,0.5-4.1c-0.4-0.8-0.6-1.7,0.3-2.3c0.7-0.4,1.4-1,2-1.5
                c0.1-0.1,0.1-0.1,0.1-0.2c0.2-0.2,0.3-0.3,0.4-0.6c0.2-0.6,0.3-1.2,0.5-1.8C1928.3,1067.7,1928.6,1067.2,1928.5,1066.3z"/>
        </g>
    </svg>
  </div>
</template>

<script>
export default {
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.st0{
    fill:#C8C8C8;
    fill-rule:evenodd;
    clip-rule:evenodd;
}
</style>
