<template>
  <div id="user_dashboard">
    <h1>Dashboard</h1>
    <div class="ui" v-cloak v-if="userCantViewAny()">
      Visibility for dashboard all displays are disabled. Please contact an
      administrator if this is an error.
    </div>

    <div class="ui" v-cloak v-else>
      <div v-if="userCanView('hl')">
        <h2>Last Locations</h2>
        <div class="clearfix">
          <div
            style="min-width: 120px"
            class="ui right floated last_loads menu"
          >
            <date-range-picker
              ref="picker"
              opens="left"
              :locale-data="{ firstDay: 1, format: 'mm/dd/yyyy' }"
              :showWeekNumbers="false"
              :showDropdowns="true"
              :autoApply="false"
              v-model="dateRange.hl"
              :linkedCalendars="true"
              @update="onChange($event, 'hl')"
              :ranges="daterange_picker_ranges"
              :min-date="disabledBefore"
              :max-date="disabledAfter"
            >
              >
              <template v-slot:input="picker" style="min-width: 350px">
                {{
                  moment(String(picker.startDate)).format("MM/DD/YYYY") | date
                }}
                -
                {{ moment(String(picker.endDate)).format("MM/DD/YYYY") | date }}
              </template>
            </date-range-picker>
            <!-- <select
              class="ui fluid last dropdown"
              name="last_loads"
              v-model="date_range_bounds"
              @change="onChange($event, 'hl')"
            >
              <option value="1_day">1 day</option>
              <option value="1_week">1 week</option>
              <option value="1_month">1 month</option>
              <option value="3_month">3 months</option>
              <option value="6_month">6 months</option>
              <option value="YTD">YTD</option>
            </select> -->
          </div>
          <div
            style="min-width: 120px"
            class="ui right floated last_loads menu"
          >
            <select
              class="ui fluid last dropdown"
              name="last_loads"
              v-model="search_by_status"
            >
              <option value="Delivered">Delivered</option>
              <option value="On Time">On Time</option>
              <option value="Caution">Caution</option>
              <option value="Delayed">Delayed</option>
              <option value="Late">Late</option>
            </select>
          </div>
          <div
            style="min-width: 120px"
            class="ui right floated last_loads menu"
          >
            <select
              class="ui fluid last dropdown"
              name="last_loads"
              v-model="search_hl_by"
              @change="onChange($event, 'hl')"
            >
              <option value="HAWBDate">Hawb Date</option>
              <option value="DueDate">Due Date</option>
            </select>
          </div>
          
          <div
            class="float-left"
            style="padding: 8px 0"
            v-cloak
            v-if="
              !loading.hl &&
              (deliveredMarkers.length ||
                lateMarkers.length ||
                onTimeMarkers.length) &&
              dateRange.hl.startDate &&
              dateRange.hl.endDate
            "
          >
            {{
              moment(String(dateRange.hl.startDate)).format("MMMM Do, YYYY")
            }}
            - {{ moment(String(dateRange.hl.endDate)).format("MMMM Do, YYYY") }}
          </div>
        </div>
        <div
          class="ui active centered inline large loader"
          v-if="loading.hl"
        ></div>

        <GmapMap
          v-show="!loading.hl"
          :center="mapCenter"
          ref="mapRef"
          :zoom="4"
          map-type-id="roadmap"
          style="width: 100%; height: 500px; margin-top: 20px"
          :options="{
            disableDefaultUi: false,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
          }"
        >
          <GmapCluster v-if="search_by_status == 'Delivered'"
            :calculator="clusterCalculator"
          >
            <GmapMarker
              :key="'d' + index"
              v-for="(m, index) in deliveredMarkers"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              :title="m.hawb_numbers.length + ''"
              @click="openWindow(m.position, m.hawb_numbers)"
              :icon="deliveredIcon"
            />
          </GmapCluster>

          <GmapCluster v-if="search_by_status == 'Late'"
            :calculator="clusterCalculator"
          >
            <GmapMarker
              :key="'l' + index"
              v-for="(m, index) in lateMarkers"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              :title="m.hawb_numbers.length + ''"
              @click="openWindow(m.position, m.hawb_numbers)"
              :icon="lateIcon"
            />
          </GmapCluster>

          <GmapCluster v-if="search_by_status == 'On Time'"
            :calculator="clusterCalculator"
          >
            <GmapMarker
              :key="'o' + index"
              v-for="(m, index) in onTimeMarkers"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              :title="m.hawb_numbers.length + ''"
              @click="openWindow(m.position, m.hawb_numbers)"
              :icon="onTimeIcon"
            />
          </GmapCluster>

          <GmapCluster v-if="search_by_status == 'Caution'"
            :calculator="clusterCalculator"
          >
            <GmapMarker
              :key="'o' + index"
              v-for="(m, index) in cautionMarkers"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              :title="m.hawb_numbers.length + ''"
              @click="openWindow(m.position, m.hawb_numbers)"
              :icon="cautionIcon"
            />
          </GmapCluster>
          <GmapCluster v-if="search_by_status == 'Delayed'"
            :calculator="clusterCalculator"
          >
            <GmapMarker
              :key="'o' + index"
              v-for="(m, index) in delayedMarkers"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              :title="m.hawb_numbers.length + ''"
              @click="openWindow(m.position, m.hawb_numbers)"
              :icon="delayedIcon"
            />
          </GmapCluster>

          <gmap-info-window
            @closeclick="window_open = false"
            :opened="window_open"
            :position="infowindow"
            :options="{
              pixelOffset: {
                width: 0,
                height: -35,
              },
            }"
          >
            <div
              v-for="(hawb, i) in infowindow_hawbs"
              :key="i"
              style="
                padding-bottom: 10px;
                border-bottom: 2px #17506d solid;
                margin-bottom: 10px;
              "
            >
              <a
                style="display: block"
                target="_blank"
                v-bind:href="
                  '/tracking/details?hawb_number=' + hawb.hawb_number
                "
              >
                <h3 style="margin-bottom: 0px">
                  <strong>Hawb: </strong><span v-text="hawb.hawb_number"></span>
                </h3>
              </a>

              <br />
              <div>
                <strong style="color: #17506d">Dest: </strong
                ><span v-text="hawb.consignee_address"></span>
              </div>
              <div>
                <strong style="color: #17506d">Status: </strong
                ><span v-text="hawb.status"></span>
              </div>
              <div>
                <strong style="color: #17506d">Due Date: </strong
                ><span v-text="hawb.duedate"></span>
              </div>
            </div>
          </gmap-info-window>
        </GmapMap>
      </div>
    </div>

    <!-- Tab Navigation -->
    <div class="ui top attached tabular menu tabs">
      <div
        v-if="analyticsVisible"
        class="item"
        :class="{ active: tabs.isAnalytics }"
        @click="switchTab('analytics')"
      >
        Analytics
      </div>
      <!-- Summary indicates the service overview -->
      <div
        v-if="userCanView('so')"
        class="item"
        :class="{ active: tabs.isSummary }"
        @click="switchTab('summary')"
      >
        Summary
      </div>
      <div
        v-if="userCanView('t5o')"
        class="item"
        :class="{ active: tabs.isTopFiveOrigins }"
        @click="switchTab('topFiveOrigins')"
      >
        Top 5 Origins
      </div>
      <div
        v-if="userCanView('t5d')"
        class="item"
        :class="{ active: tabs.isTopFiveDest }"
        @click="switchTab('topFiveDest')"
      >
        Top 5 Destination
      </div>
      <div
        v-if="userCanView('tbds')"
        class="item"
        :class="{ active: tabs.isTransByState }"
        @click="switchTab('transByState')"
      >
        Shipments by Destination State
      </div>
    </div>

    <!-- Tab Content -->
    <div
      v-if="analyticsVisible"
      class="ui bottom attached tab segment"
      :class="{ active: tabs.isAnalytics }"
      id="anaylics"
    >
      <div
        class="ui grid analytics"
        :class="analyticsVisible > 1 ? 'equal width' : ''"
      >
        <div
          class="column"
          :class="analyticsVisible == 1 ? 'four wide' : ''"
          v-if="userCanView('tsbs')"
        >
          <total-spend-by-service
            :labels="revKeys"
            :data="revData"
            :date-range="dateRange.tsbs"
            :date_range_bounds="date_range_bounds"
            :loading="loading.tsbs"
            @changedDate="onChange($event, 'tsbs')"
          />
        </div>
        <div
          class="column"
          :class="analyticsVisible == 1 ? 'four wide' : ''"
          v-if="userCanView('tvbs')"
        >
          <total-volume-by-service
            :labels="volKeys"
            :data="volData"
            :date-range="dateRange.tvbs"
            :date_range_bounds="date_range_bounds"
            :loading="loading.tvbs"
            @changedDate="onChange($event, 'tvbs')"
          />
        </div>
        <div
          class="column"
          :class="analyticsVisible == 1 ? 'four wide' : ''"
          v-if="userCanView('cct')"
        >
          <customer-claim-totals
            :labels="claim_keys"
            :data="claim_data"
            :totals="claim_totals"
            :date-range="dateRange.cct"
            :date_range_bounds="date_range_bounds"
            :loading="loading.cct"
            @changedDate="onChange($event, 'cct')"
          />
        </div>
        <div
          class="column"
          :class="analyticsVisible == 1 ? 'four wide' : ''"
          v-if="userCanView('sdt')"
        >
          <shipment-delay-totals
            :labels="shipment_delay_keys"
            :data="shipment_delay_data"
            :totals="shipment_delay_totals"
            :date-range="dateRange.sdt"
            :date_range_bounds="date_range_bounds"
            :loading="loading.sdt"
            :service_filter_options="filter_options.sdt.service"
            :service_filter_selection="filter_selection.sdt.service"
            @changedDate="onChange($event, 'sdt')"
            @changedService="onChangedService($event, 'sdt')"
          />
        </div>
      </div>
    </div>

    <div
      v-if="userCanView('so')"
      class="ui bottom attached tab segment"
      :class="{ active: tabs.isSummary }"
      id="summary"
    >
      <div style="min-width: 120px" class="ui right floated last_loads menu">
        <select
          class="ui fluid last dropdown"
          name="last_loads"
          v-model="date_range_bounds"
          @change="onChange($event, 'so')"
        >
          <option value="1_week">1 week</option>
          <option value="1_month">1 month</option>
          <option value="3_month">3 months</option>
          <option value="6_month">6 months</option>
          <option value="YTD">YTD</option>
        </select>
      </div>
      <div
        v-if="!loading.t5o && dateRange.so.startDate && dateRange.so.endDate"
      >
        {{ dateRange.so.startDate }} - {{ dateRange.so.endDate }}
      </div>
      <div
        class="ui active centered inline large loader"
        v-if="loading.so"
      ></div>

      <ClientTable
        :columns="servOverviewTable.columns"
        :tableData="servOverview"
        :options="servOverviewTable.options"
        v-else
      />
    </div>

    <div
      v-if="userCanView('t5o')"
      class="ui bottom attached tab segment"
      :class="{ active: tabs.isTopFiveOrigins }"
      id="top_five_origins"
    >
      <div style="min-width: 120px" class="ui right floated last_loads menu">
        <select
          class="ui fluid last dropdown"
          name="last_loads"
          v-model="date_range_bounds"
          @change="onChange($event, 't5o')"
        >
          <option value="1_week">1 week</option>
          <option value="1_month">1 month</option>
          <option value="3_month">3 months</option>
          <option value="6_month">6 months</option>
          <option value="YTD">YTD</option>
        </select>
      </div>
      <div
        v-if="!loading.t5o && dateRange.t5o.startDate && dateRange.t5o.endDate"
      >
        {{ dateRange.t5o.startDate }} - {{ dateRange.t5o.endDate }}
      </div>
      <div
        class="ui active centered inline large loader"
        v-if="loading.t5o"
      ></div>
      <ClientTable
        :columns="topFiveOriginsTable.columns"
        :tableData="topFiveOrigins"
        :options="topFiveOriginsTable.options"
        v-else
      />
    </div>

    <div
      v-if="userCanView('t5d')"
      class="ui bottom attached tab segment"
      :class="{ active: tabs.isTopFiveDest }"
      id="top_five_dest"
    >
      <div style="min-width: 120px" class="ui right floated last_loads menu">
        <select
          class="ui fluid last dropdown"
          name="last_loads"
          v-model="date_range_bounds"
          @change="onChange($event, 't5d')"
        >
          <option value="1_week">1 week</option>
          <option value="1_month">1 month</option>
          <option value="3_month">3 months</option>
          <option value="6_month">6 months</option>
          <option value="YTD">YTD</option>
        </select>
      </div>
      <div
        v-if="!loading.t5d && dateRange.t5d.startDate && dateRange.t5d.endDate"
      >
        {{ dateRange.t5d.startDate }} - {{ dateRange.t5d.endDate }}
      </div>
      <div
        class="ui active centered inline large loader"
        v-if="loading.t5d"
      ></div>

      <ClientTable
        :columns="topFiveDestTable.columns"
        :tableData="topFiveDest"
        :options="topFiveDestTable.options"
        v-else
      />
    </div>

    <div
      v-if="userCanView('tbds')"
      class="ui bottom attached tab segment"
      :class="{ active: tabs.isTransByState }"
      id="trans_by_state"
    >
      <TransactionsByDestinationState
        v-if="userCanView('tbds')"
        :states="states"
        :date-range="dateRange.tbds"
        :date_range_bounds="date_range_bounds"
        :loading="loading.tbds"
        @changedDate="onChange($event, 'tbds')"
      />
    </div>
    <div 
      v-if="!loading.hl"
      class="" 
      style="-webkit-box-shadow: 0px -3px 6px #00000029;box-shadow: 0px -3px 6px #00000029; font-size:10px;padding:20px;line-height:15px" >
        The information and images found on this site are believed to be accurate, to the fullest extent permissible by applicable law, ALG Worldwide disclaims all warranties, expressed or implied, regarding the accuracy, completeness, reliability, or usefulness of the Information and is not liable for any damages arising in any way out of the use of the Information, including, but not limited to direct, indirect, incidental, punitive and consequential damages. The Information is not a representation, recommendation or guaranty of any route taken or to be taken by a carrier, nor is it a commitment by or obligation of any carrier to take a specific route.
    </div>
  </div>
  
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>


<script>
import ClientTable from "../tables/ClientTable.vue";
import TotalSpendByService from "./Charts/TotalSpendByService.vue";
import TotalVolumeByService from "./Charts/TotalVolumeByService.vue";
import CustomerClaimTotals from "./Charts/CustomerClaimTotals.vue";
import ShipmentDelayTotals from "./Charts/ShipmentDelayTotals.vue";
import TransactionsByDestinationState from "./Tabs/TransactionsByDestinationState.vue";
import DateRangePicker from "vue2-daterange-picker";

export default {
  components: {
    ClientTable,
    TotalSpendByService,
    TotalVolumeByService,
    CustomerClaimTotals,
    ShipmentDelayTotals,
    TransactionsByDestinationState,
    DateRangePicker,
  },
  data() {
    //Default Calculation
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() - 7);

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);
    let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    let disabledBefore = new Date();
    disabledBefore.setDate(today.getDate() - 30);
    disabledBefore.setHours(0, 0, 0, 0);
    let disabledAfter = new Date();
    disabledAfter.setDate(today.getDate() + 30);
    disabledAfter.setHours(0, 0, 0, 0);

    return {
      loading: {
        tsbs: true,
        tvbs: true,
        cct: true,
        sdt: true,
        so: true,
        t5o: true,
        t5d: true,
        tbds: true,
        hl: true,
      },

      uris: {
        all: "user_dashboard",
        tsbs: "spendByService",
        tvbs: "volumeByService",
        cct: "customerClaimTotals",
        sdt: "shipmentDelayTotals",
        so: "serviceOverview",
        t5o: "topOrigins",
        t5d: "topDestinations",
        tbds: "transactionsByDestination",
        //hl: "hawbLocationsMap",
        hl: "hawbLastLocationMapData",
      },

      filter_options: {
        sdt: {
          service: ["All"],
        },
      },

      filter_selection: {
        sdt: {
          service: null,
        },
      },

      mapCenter: { lat: 0, lng: 0 },

      tabs: {
        isAnalytics: false,
        isSummary: false,
        isTopFiveOrigins: false,
        isTopFiveDest: false,
        isTransByState: false,
        isLocations: false,
      },

      dateRange: {
        tsbs: { startDate, endDate },
        tvbs: { startDate, endDate },
        cct: { startDate, endDate },
        sdt: { startDate, endDate },
        so: { startDate, endDate },
        t5o: { startDate, endDate },
        t5d: { startDate, endDate },
        tbds: { startDate, endDate },
        hl: { startDate, endDate },
      },

      disabledBefore: disabledBefore,
      disabledAfter: disabledAfter,

      daterange_picker_ranges: {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        // 'This month': [thisMonthStart, thisMonthEnd],
        // 'This year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
        // 'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
      },

      locationMarkers: [],
      deliveredMarkers: [],
      lateMarkers: [],
      onTimeMarkers: [],
      cautionMarkers: [],
      delayedMarkers: [],

      deliveredIcon: require("../../assets/images/pins/flag-green.png"),
      lateIcon: require("../../assets/images/pins/pin-red.png"),
      onTimeIcon: require("../../assets/images/pins/pin-blue.png"),
      cautionIcon: require("../../assets/images/pins/pin-yellow.png"),
      delayedIcon: require("../../assets/images/pins/pin-orange.png"),


      infowindow: { lat: 10, lng: 10.0 }, // This is arbitrary it gets reset on click

      window_open: false,

      infowindow_text: "",
      infowindow_hawbs: [],

      search_hl_by: "DueDate",
      search_by_status: "Delivered",
      //Change this value to set default for the graphs select options are
      //['today','1_day','1_week','3_month','6_month','YTD']
      last_location_map_default_range: "today",
      date_range_bounds: "1_week",
      revKeys: [],
      revData: [],
      volKeys: [],
      volData: [],
      claim_keys: [],
      claim_data: [],
      claim_totals: [],
      shipment_delay_keys: [],
      shipment_delay_data: [],
      shipment_delay_totals: [],
      states: [],
      servOverview: [],
      servOverviewTable: {
        columns: ["service", "number_of_shipments", "total_spend", "weight"],
        options: {
          headings: {
            service: "Service",
            number_of_shipments: "# of Shipments",
            total_spend: "Total Spend",
            weight: "Weight (Lbs)",
          },
          sortable: [],
          filterable: false,
          perPage: 10,
          perPageValues: [10, 25, 50],
          texts: {
            filter: "",
            filterBy: "Search by {column}",
            count: "",
          },
          pagination: { chunk: 10, dropdown: false },
        },
      },
      topFiveOrigins: [],
      topFiveOriginsTable: {
        columns: ["location", "number_of_shipments", "total_spend"],
        options: {
          headings: {
            location: "Location",
            number_of_shipments: "# of Shipments",
            total_spend: "Total Spend",
          },
          sortable: [],
          filterable: false,
          perPage: 10,
          perPageValues: [10, 25, 50],
          texts: {
            filter: "",
            filterBy: "Search by {column}",
            count: "",
          },
          pagination: { chunk: 10, dropdown: false },
        },
      },
      topFiveDest: [],
      topFiveDestTable: {
        columns: ["location", "number_of_shipments", "total_spend"],
        options: {
          headings: {
            location: "Location",
            number_of_shipments: "# of Shipments",
            total_spend: "Total Spend",
          },
          sortable: [],
          filterable: false,
          perPage: 10,
          perPageValues: [10, 25, 50],
          texts: {
            filter: "",
            filterBy: "Search by {column}",
            count: "",
          },
          pagination: { chunk: 10, dropdown: false },
        },
      },
    };
  },

  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },

  created() {
    //Set Default Time Range for Hawb Locations Map
    let hl_range = this.calculateDateRange(this.last_location_map_default_range);
    //get visible tables only
    let hl_tables = [
      "hl",
    ].filter((table) => this.userCanView(table));
    // load  HL table seperately
    this.getGraphData(hl_range["startDate"], hl_range["endDate"], hl_tables);


    //Set Default Time Range
    let range = this.calculateDateRange();
    //get visible tables only
    let tables = [
      "tsbs",
      "tvbs",
      "cct",
      "sdt",
      "so",
      "t5o",
      "t5d",
      "tbds",
    ].filter((table) => this.userCanView(table));
    
    this.getGraphData(range["startDate"], range["endDate"], tables);

    if (!this.userCantViewAny()) {
      this.loadFirstTabAvailable(tables);
    }
  },

  mounted() {
    $(".ui.fluid.dropdown").dropdown({});
  },

  methods: {
    openWindow(position, hawb_data) {
      this.infowindow = position;
      this.window_open = true;
      this.infowindow_hawbs = hawb_data;
    },
    clusterCalculator(markers, numStyles){
      var val=0,//this will be the text you see in the cluster-icon
      index=0,
      dv;
      for(var m=0;m<markers.length;++m){
        //add the value of the markers prop-property to val
        //val++;
        val+=Number(markers[m].title);
      }
      dv = val;
      while (dv !== 0) {
        dv = parseInt(dv / 10, 10);
        index++;
      }

      index = Math.min(index, numStyles);
      return {
        text: val,
        index: index*.00001 //This is an arbitrary low number to keep the cluster from changing colors at higher increments
      };
    },
    onChange(event, table) {
      let range = [];
      switch (table) {
        case "hl":
          range = this.dateRange[table]; //Calculated by datepicker
          break;
        default:
          range = this.calculateDateRange(event.target.value);
      }
      this.getGraphData(range["startDate"], range["endDate"], table);
    },

    onChangedService(event, table) {
      this.filter_selection[table].service = event.target.value;
      this.getGraphData(
        this.dateRange[table]["startDate"],
        this.dateRange[table]["endDate"],
        table
      );
    },

    // this is dumb, why is nothing consistent in the app
    loadFirstTabAvailable(tables) {
      // clone to not modify original array
      let _tables = [...tables];

      switch (_tables.shift()) {
        case "hl":
          // ignore map and recurse
          this.loadFirstTabAvailable(_tables);
          break;
        case "tsbs":
        case "tvbs":
        case "cct":
        case "sdt":
          this.switchTab("analytics");
          break;
        case "so":
          this.switchTab("serviceOverview");
          break;
        case "t5o":
          this.switchTab("topFiveOrigins");
          break;
        case "t5d":
          this.switchTab("topFiveDest");
          break;
        case "tbds":
          this.switchTab("transByState");
          break;
      }
    },

    switchTab: function (tab) {
      switch (tab) {
        case "analytics":
          this.tabs.isAnalytics = true;

          this.tabs.isSummary = false;
          this.tabs.isTopFiveOrigins = false;
          this.tabs.isTopFiveDest = false;
          this.tabs.isTransByState = false;
          this.tabs.isLocations = false;
          break;
        case "summary":
          this.tabs.isSummary = true;

          this.tabs.isAnalytics = false;
          this.tabs.isTopFiveOrigins = false;
          this.tabs.isTopFiveDest = false;
          this.tabs.isTransByState = false;
          this.tabs.isLocations = false;
          break;
        case "topFiveOrigins":
          this.tabs.isTopFiveOrigins = true;

          this.tabs.isAnalytics = false;
          this.tabs.isSummary = false;
          this.tabs.isTopFiveDest = false;
          this.tabs.isTransByState = false;
          this.tabs.isLocations = false;
          break;
        case "topFiveDest":
          this.tabs.isTopFiveDest = true;

          this.tabs.isAnalytics = false;
          this.tabs.isSummary = false;
          this.tabs.isTopFiveOrigins = false;
          this.tabs.isTransByState = false;
          this.tabs.isLocations = false;
          break;
        case "transByState":
          this.tabs.isTransByState = true;

          this.tabs.isAnalytics = false;
          this.tabs.isSummary = false;
          this.tabs.isTopFiveOrigins = false;
          this.tabs.isTopFiveDest = false;
          this.tabs.isLocations = false;
          break;
        case "locations":
          this.tabs.isLocations = true;

          this.tabs.isAnalytics = false;
          this.tabs.isTransByState = false;
          this.tabs.isSummary = false;
          this.tabs.isTopFiveOrigins = false;
          this.tabs.isTopFiveDest = false;
          //google.maps.event.trigger(map, "resize");
          break;
      }
    },

    calculateDateRange(range_type = this.date_range_bounds) {
      let range = [];
      let startDate = new Date();
      let endDate = new Date();

      switch (range_type) {
        case "today":
          startDate.setDate(startDate.getDate());
          break;
        case "1_day":
          startDate.setDate(startDate.getDate() - 1);
          break;
        case "1_week":
          startDate.setDate(startDate.getDate() - 7);
          break;
        case "1_month":
          startDate.setDate(startDate.getDate() - 30);
          break;
        case "3_month":
          startDate.setDate(startDate.getDate() - 90);
          break;
        case "6_month":
          startDate.setDate(startDate.getDate() - 180);
          break;
        case "YTD":
          startDate = new Date(new Date().getFullYear(), 0, 1);
          break;
        default:
          startDate.setDate(startDate.getDate() - 7);
      }
      range["startDate"] = startDate;
      range["endDate"] = endDate;

      return range;
    },

    setSpendByServiceData(data) {
      this.revKeys = data.rev_keys;
      this.revData = data.rev_vals;
      this.dateRange.tsbs = data.date_range;
    },

    setVolumeByServiceData(data) {
      this.volKeys = data.vol_keys;
      this.volData = data.vol_vals;
      this.dateRange.tvbs = data.date_range;
    },

    setCustomerClaimTotalsData(data) {
      this.claim_keys = data.claim_keys;
      this.claim_data = data.claim_data;
      this.claim_totals = data.claim_totals;
      this.dateRange.cct = data.date_range;
    },

    setShipmentDelayTotalsData(data) {
      this.filter_selection.sdt.service = data.service_filter;
      this.filter_options.sdt.service = data.service_options;
      
      this.shipment_delay_keys = data.shipment_delay_keys;
      this.shipment_delay_data = data.shipment_delay_data;
      this.shipment_delay_totals = data.shipment_delay_totals;
      this.dateRange.sdt = data.date_range;
    },

    setOverviewData(data) {
      let serv = data.serv_overview;
      this.servOverview = Object.keys(data.serv_overview).map(function (key) {
        return {
          service: serv[key].service,
          number_of_shipments: serv[key].volume,
          total_spend:
            "$" +
            serv[key].revenue.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            }),
          weight: serv[key].weight,
        };
      });
      this.dateRange.so = data.date_range;
    },

    setTopOriginsData(data) {
      let ship_data = data.shipper_data;
      this.topFiveOrigins = Object.keys(data.shipper_data).map(function (key) {
        let location = ship_data[key].shipper.Address1;
        let address2 = ship_data[key].shipper.Address2
          ? " " + ship_data[key].shipper.Address2
          : "";
        location += address2;
        location += " ";
        location += ship_data[key].shipper.City;
        location += ", " + ship_data[key].shipper.State;
        location += " " + ship_data[key].shipper.Zip;

        return {
          location: location,
          number_of_shipments: ship_data[key].volume,
          total_spend:
            "$" +
            ship_data[key].total.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            }),
        };
      });
      this.dateRange.t5o = data.date_range;
    },

    setTopDestinationsData(data) {
      let cons_data = data.consignee_data;
      this.topFiveDest = Object.keys(data.consignee_data).map(function (key) {
        let location = cons_data[key].consignee.Address1;
        let address2 = cons_data[key].consignee.Address2
          ? " " + cons_data[key].consignee.Address2
          : "";
        location += address2;
        location += " ";
        location += cons_data[key].consignee.City;
        location += ", " + cons_data[key].consignee.State;
        location += " " + cons_data[key].consignee.Zip;

        return {
          location: location,
          number_of_shipments: cons_data[key].volume,
          total_spend:
            "$" +
            cons_data[key].total.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            }),
        };
      });
      this.dateRange.t5d = data.date_range;
    },

    setTransactionsByDestinationData(data) {
      this.states = data.hawbs_data_by_state;
      this.dateRange.tbds = data.date_range;
    },

    setHawbLocationsData(data) {
      //this.locationMarkers = this.setMarkerData(data.hawb_location_data);
      this.deliveredMarkers = this.setMarkerData(data.delivered_hawbs);
      this.lateMarkers = this.setMarkerData(data.late_hawbs);
      this.onTimeMarkers = this.setMarkerData(data.on_time_hawbs);
      this.cautionMarkers = this.setMarkerData(data.caution_hawbs);
      this.delayedMarkers = this.setMarkerData(data.delayed_hawbs);

      this.dateRange.hl = data.date_range;

      //This is currently set for US, Change to Dynamic
      this.mapCenter.lat = 37.0902;
      this.mapCenter.lng = -95.7129;
    },
    setMarkerData(markers) {
      var count = 0;
      var unique = 0;
      var lat = 0;
      var lng = 0;
      var location_markers_sort = [];
      var latlng_str = "lat:0,lng:0";
      var location_markers_keys = [];
      var hawb_details = [];
      var key = 0;
      console.log(markers);
      for (const [hawb_number, hawb_data] of Object.entries(markers)) {
        if (typeof hawb_data.last_location.latlng !== "undefined") {
          count++;
          lat += hawb_data.last_location.latlng.lat;
          lng += hawb_data.last_location.latlng.lng;
          latlng_str =
            hawb_data.last_location.latlng.lat +
            "," +
            hawb_data.last_location.latlng.lng;
          //We store keys to make sure that we congregate all pins in the same lat/lng
          if (typeof location_markers_keys[latlng_str] === "undefined") {
            unique++;
            location_markers_keys[latlng_str] = unique;
            key = unique;
          } else {
            key = location_markers_keys[latlng_str];
          }

          if (typeof location_markers_sort[key] === "undefined") {
            //Initialize new fields
            location_markers_sort[key] = {};
            location_markers_sort[key].hawb_numbers = [];
            location_markers_sort[key].position = {};
            //Send Data through
            location_markers_sort[key].position.lat =
              hawb_data.last_location.latlng.lat;
            location_markers_sort[key].position.lng =
              hawb_data.last_location.latlng.lng;
            location_markers_sort[key].hawb_numbers.push({
              hawb_number: hawb_number,
              consignee_address: hawb_data.last_location.consignee_address,
              status: hawb_data.last_location.status,
              duedate: hawb_data.last_location.DueDate,
            });
          } else {
            location_markers_sort[key].hawb_numbers.push({
              hawb_number: hawb_number,
              consignee_address: hawb_data.last_location.consignee_address,
              status: hawb_data.last_location.status,
              duedate: hawb_data.last_location.DueDate,
            });
          }
        }
      }
      //This line filters out any potentially empty array elements
      markers = location_markers_sort.filter(Boolean);
      return markers;
    },

    getUri(table) {
      return `/dashboard/${this.uris[table]}`;
    },

    fetchGraphData: function (startDate, endDate, table) {
      let service_filter = this.filter_options[table]
        ? this.filter_selection[table].service
        : null;

      return this.$http
        .get(this.getUri(table), {
          params: {
            startDate: startDate,
            endDate: endDate,
            search_hl_by: this.search_hl_by,
            service_filter,
          },
        })
        .then(({ data }) => {
          if (table == "all" || table == "tsbs") {
            this.setSpendByServiceData(data);
          }

          if (table == "all" || table == "tvbs") {
            this.setVolumeByServiceData(data);
          }

          if (table == "all" || table == "cct") {
            this.setCustomerClaimTotalsData(data);
          }

          if (table == "all" || table == "sdt") {
            this.setShipmentDelayTotalsData(data);
          }

          if (table == "all" || table == "so") {
            this.setOverviewData(data);
          }

          if (table == "all" || table == "t5o") {
            this.setTopOriginsData(data);
          }

          if (table == "all" || table == "t5d") {
            this.setTopDestinationsData(data);
          }

          if (table == "all" || table == "tbds") {
            this.setTransactionsByDestinationData(data);
          }

          if (table == "all" || table == "hl") {
            this.setHawbLocationsData(data);
          }
        })
        .catch((error) => {
          this.errors = error;
        });
    },

    getGraphData: function (startDate, endDate, tables = "all") {
      if (tables == "all") {
        for (const [key, value] of Object.entries(this.loading)) {
          this.loading[key] = true;
        }
        this.fetchGraphData(startDate, endDate, tables).then(() => {
          for (const [key, value] of Object.entries(this.loading)) {
            this.loading[key] = false;
          }
        });
      } else if (Array.isArray(tables)) {
        for (const table of tables) {
          this.loading[table] = true;
          this.fetchGraphData(startDate, endDate, table).then(() => {
            this.loading[table] = false;
          });
        }
      } else {
        this.loading[tables] = true;
        this.fetchGraphData(startDate, endDate, tables).then(() => {
          for (const [key, value] of Object.entries(this.loading)) {
            this.loading[tables] = false;
          }
        });
      }
    },

    userCanView(table) {
      return Boolean(this.$parent.user.dash_settings[table]);
    },

    userCantViewAny() {
      let tables = Object.values(this.$parent.user.dash_settings);
      let visible = tables.filter((table) => table);
      return !visible.length;
    },
  },

  computed: {
    analyticsVisible() {
      let count = 0;
      count += this.userCanView("tsbs") ? 1 : 0;
      count += this.userCanView("tvbs") ? 1 : 0;
      count += this.userCanView("cct") ? 1 : 0;
      count += this.userCanView("sdt") ? 1 : 0;
      return count;
    },
  },
};
</script>

<style lang="scss">
@import "../../../node_modules/vue2-daterange-picker/dist/vue2-daterange-picker.css";
#user_dashboard {
  margin: $layoutmargin;
  [v-cloak] {
    display: none;
  }

  .ui.active.centered.inline.large.loader {
    padding-bottom: 20%;
  }

  .analytics {
    margin-top: 24px;
    margin-bottom:8px;

    .chart {
      height: 300px;
    }
  }

  .tabs {
    margin-top: 48px;
    border-bottom: none !important;

    .item {
      color: $algdarkblue;
      background-color: #f4f4f4;
      border: none;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      margin-right: 5px;
      cursor: pointer;
    }

    .active {
      background-color: #fff !important;
      box-shadow: 0px 3px 20px #00000033;
    }
  }

  .ui.bottom.attached.tab.segment {
    box-shadow: 0px 20px 20px #00000033;
    border: none;
  }

  .column {
    .ui.card {
      width: 100%;
      box-shadow: 0px 3px 20px #00000033;

      .header {
        background-color: #f4f4f4;
        padding: 16px 0px 16px 32px;
      }
    }
  }

  table {
    tr:nth-child(2n) {
      background-color: #f4f4f4;
    }

    thead {
      tr {
        th {
          color: $algdarkblue;
          background-color: $algdarkbluetenopac;
          border: none;
          padding-left: 15px;
        }

        th:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        th:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    tbody {
      tr {
        background-color: #fff;

        td {
          border: none;
          // padding: 20px 0px 0px 15px;
        }

        td:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        td:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    td,
    th {
      cursor: unset;
    }
  }

  .VueTables__search,
  .VueTables__limit {
    display: none;
  }
}
.reportrange-text {
  height: 38px;
  line-height: 28px;
}
// path:hover {
//     fill: #fce57e;
// }
</style>
