<template>
  <div class="ui consignee_search modal">
    <div class="header">
      Search for a Consignee <i class="close icon" @click="close()"></i>
    </div>
    <div class="content">
      <div class="col-md-12">
        <div class="form-group form-inline float-left VueTables__search">
          <div class="VueTables__search-field">
            <label for="VueTables__search_RzUbx" class=""></label>
            <input
              type="text"
              id="consignee-filter-text-box"
              class="VueTables__search__input form-control "
              placeholder="Search All..."
              v-on:keyup="onFilterTextBoxChanged()"
            />
          </div>
        </div>
        <!-- <div class="form-group form-inline float-right VueTables__limit">
          <div class="VueTables__limit-field">
            <label for="VueTables__limit_RzUbx"></label>
            <div
              class="form-control ui fluid limit dropdown selection"
              tabindex="0"
            >
              <select id="VueTables__limit_RzUbx"
                ><option value="10">10</option
                ><option value="25">25</option
                ><option value="50">50</option></select
              ><i class="dropdown icon"></i>
              <div class="text">10</div>
              <div class="menu transition hidden" tabindex="-1">
                <div class="item active selected" data-value="10">10</div>
                <div class="item" data-value="25">25</div>
                <div class="item" data-value="50">50</div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div>
        <!-- <v-client-table
          class="consignee_search_table"
          :columns="columns"
          :data="shippers"
          :options="options"
        >
          <span
            class="number"
            slot="number"
            slot-scope="{ row }"
            @click="updateShipper(row)"
            >{{ row.number }}</span
          >
          <span class="address" slot="address" slot-scope="{ row }">{{
            row.address +
              " " +
              row.address2 +
              " " +
              row.city +
              ", " +
              row.state +
              " " +
              row.zip
          }}</span>
        </v-client-table> -->
        <template>
          <ag-grid-vue
            style="width: 100%; height: 80vh;clear:both;"
            class="ag-theme-balham"
            id="consignee_search_table"
            :columnDefs="columnDefs"
            :rowData="consignees"
            :gridOptions="consigneesGridOptions"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :paginationNumberFormatter="paginationNumberFormatter"
            :sideBar="sideBar"
            @grid-ready="onGridReady"
            @first-data-rendered="onFirstDataRendered"
            @cell-clicked="updateConsignee"
            :modules="modules"
          >
          </ag-grid-vue>
        </template>
      </div>
    </div>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
import { AgGridVue } from "ag-grid-vue";
import { LicenseManager } from "@ag-grid-enterprise/core";
LicenseManager.setLicenseKey(
  "CompanyName=Build This, LLC,LicensedApplication=ALG Interface,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-026375,ExpiryDate=19_April_2024_[v2]_MTcxMzQ4MTIwMDAwMA==ee7c1dd1960a418478b92ab9ed66c256"
);
import { AllModules } from "@ag-grid-enterprise/all-modules";

export default {
  props: {
    shipment: Object
  },
  components: {
    AgGridVue,
    AllModules
  },
  data() {
    return {
      consignees: null,
      columnDefs: null,
      currentAccount: null,
      consigneesGridOptions: null,
      consigneesGridApi: null,
      columnApi: null,
      columnDefs: null,
      autoGroupColumnDef: null,
      defaultColDef: null,
      rowSelection: null,
      rowGroupPanelShow: null,
      pivotPanelShow: null,
      paginationPageSize: 10,
      paginationNumberFormatter: null,
      modules: AllModules,
      rowData: null,
      sideBar: null,
      possibleHeadings: null,
      columnState: null,
      allowedNumbers: null
    };
  },
  beforeMount() {
    this.consigneesGridOptions = {};
    this.consigneesGridApi = {};
    this.columnDefs = [
      {
        headerName: "Number",
        field: "number",
        sortable: true,
        resizable: true,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Name",
        field: "name",
        sortable: true,
        resizable: true,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Address",
        field: "fullAddress",
        sortable: true,
        resizable: true,
        filter: "agTextColumnFilter"
      }
    ];
  },
  created() {},
  mounted() {
    this.consigneesGridApi = this.consigneesGridOptions.api;
    this.gridColumnApi = this.consigneesGridOptions.columnApi;
    this.consigneesGridApi.setDomLayout("autoHeight");
    this.possibleHeadings = this.gridColumnApi.getPrimaryColumns();
    this.columnState = this.gridColumnApi.getColumnState();
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.consigneesGridApi.sizeColumnsToFit();
    },
    onFilterTextBoxChanged() {
      clearTimeout(this.shipperTimeout);

      // Make a new timeout set to go off in 800ms
      this.shipperTimeout = setTimeout(() => {
        let params = {
          search: document.getElementById("consignee-filter-text-box").value
        };
        this.$http
          .get("/shipment/consigneesearch", { params })
          .then(response => {
            this.consignees = Object.keys(response.data.consignees).map(
              function(index) {
                let consignee = response.data.consignees[index];

                return {
                  number: consignee.Number,
                  name: consignee.Name,
                  address: consignee.Address1,
                  address2: consignee.Address2,
                  countryName: consignee.CountryName,
                  city: consignee.City,
                  state: consignee.State,
                  zip: consignee.Zip,
                  fullAddress:
                    consignee.Address1 +
                    " " +
                    consignee.Address2 +
                    " " +
                    consignee.City +
                    " " +
                    consignee.State +
                    " " +
                    consignee.Zip,
                  contact: consignee.Contact,
                  phone: consignee.PhoneNbr
                };
              }
            );
            this.loading = !this.loading;
          })
          .catch(error => {
            console.log(error);
            this.errors = error;
          });
      }, 800);
      // this.gridOptions.api.setQuickFilter(
      //   document.getElementById("filter-text-box").value
      // );
    },
    onFirstDataRendered() {
      this.consigneesGridApi.sizeColumnsToFit();
    },
    onPageSizeChanged(newPageSize) {
      var value = document.getElementById("page-size").value;
      this.consigneesGridApi.paginationSetPageSize(Number(value));
    },
    onGridReady(params) {
      this.consigneesGridApi.setRowData([]);
    },
    updateConsignee: function(consignee) {
      this.shipment.consigneeNumber = consignee.data.number;
      this.shipment.consigneeName = consignee.data.name;
      this.shipment.consigneeAddress = consignee.data.address;
      this.shipment.consigneeAddress2 = consignee.data.address2;
      this.shipment.consigneeCity = consignee.data.city;
      this.shipment.consigneeState = consignee.data.state;
      this.shipment.consigneeZip = consignee.data.zip;
      this.shipment.consigneeContact = consignee.data.contact;
      this.shipment.consigneeCountry = consignee.data.countryName;
      this.shipment.consigneePhone = consignee.data.phone;
      this.shipment.consigneeNumberDefault = consignee.data.number;
      this.shipment.consigneeNameDefault = consignee.data.name;
      this.shipment.consigneeAddressDefault = consignee.data.address;
      this.shipment.consigneeAddress2Default = consignee.data.address2;
      this.shipment.consigneeCityDefault = consignee.data.city;
      this.shipment.consigneeStateDefault = consignee.data.state;
      this.shipment.consigneeZipDefault = consignee.data.zip;
      this.shipment.consigneeContactDefault = consignee.data.contact;
      this.shipment.consigneePhoneDefault = consignee.data.phone;
      this.shipment.consigneeCountryDefault = consignee.data.countryName;


      $(".ui.consignee_search.modal").modal("hide");
    },
    close: function() {
      $(".ui.consignee_search.modal").modal("hide");
    }
  },
  computed: {}
};
</script>

<style lang="scss">
.VueTables__search__input.form-control {
  color: black;
}

.ui.consignee_search.modal {
  min-height: 650px;
  margin: 7rem auto !important;
  overflow-y: auto;

  .header {
    color: $algdarkblue !important;
    border-bottom: none !important;

    .close.icon {
      color: $algdarkblue !important;
    }
  }

  select {
    width: 75px;
    height: 40px;
    border-radius: 20px !important;
  }

  .VueTables__search {
    float: right !important;
  }

  .VueTables__search__input {
    width: 350px;
    height: 40px;
    border: $darkblueborder;
    color: black;
  }

  .ui.fluid.limit.dropdown {
    width: 60px;
    height: 40px;
    border-radius: 20px !important;
    border: $darkblueborder;
    margin-left: 10px;
  }

  .consignee_search_table {
    border: none;
    box-shadow: none;

    .fa,
    .fa-sort,
    .fa-sort-asc,
    .fa-sort-desc {
      float: unset !important;
      padding-left: 10px;
    }

    .number {
      color: $algdarkblue;
      text-decoration: underline;
    }

    thead {
      tr {
        th {
          color: $algdarkblue;
          background-color: $algdarkbluetenopac;
          border: none;
        }

        th:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        th:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    tbody {
      tr {
        td:first-child {
          border-top-left-radius: 8px !important;
          border-bottom-left-radius: 8px !important;
        }
        td:last-child {
          border-top-right-radius: 8px !important;
          border-bottom-right-radius: 8px !important;
        }

        td {
          border: none;
        }
      }
      tr:nth-child(odd) {
        background-color: #fff;
      }

      tr:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}
</style>
