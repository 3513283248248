<template>
    <div></div>
</template>

<script>
export default {
    created: function() {
        this.$http.defaults.headers.common["Authorization"] = undefined;
        this.$store.commit("unsetUser");
        this.router.push("/");
    }
}
</script>