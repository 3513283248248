<template>
  <div class="ui consignee_add modal">
    <div class="header">Add a Consignee <i class="close icon" @click="close()"></i></div>
    <div class="content">
      <div class="ui active centered inline large loader" v-if="loading"></div>
      <div class="ui two column grid" v-else>
        <div class="nine wide column">
            <div class="ui form grid">
              <div class="four wide column">
                <label for="">Name</label>
              </div>
              <div class="twelve wide column">
                <input type="text" name="consignee_name" v-model="addConsigneeName">
              </div>

              <div class="four wide column">
                <label for="">Address:</label>
              </div>
              <div class="twelve wide column">
                <input type="text" name="consignee_address" v-model="addConsigneeAddress">
              </div>
              <div class="four wide column">
              </div>
              <div class="twelve wide column">
                <input type="text" name="consignee_address2" v-model="addConsigneeAddress2">
              </div>

              <div class="four wide column">
                <label for="">City:</label>
              </div>
              <div class="twelve wide column">
                <input type="text" name="consignee_city" v-model="addConsigneeCity">
              </div>

              <div class="four wide column">
                <label for="">State:</label>
              </div>
              <div class="twelve wide column">
                <input type="text" name="consignee_state" v-model="addConsigneeState" maxlength="2" placeholder="Example: IL">
              </div>

              <div class="four wide column">
                <label for="">Zip Code:</label>
              </div>
              <div class="twelve wide column">
                <input type="text" name="consignee_zip" v-model="addConsigneeZip">
              </div>

              <div class="four wide column">
                <label for="">Country:</label>
              </div>
              <div class="twelve wide column">
                <select name="consignee_country" v-model="addConsigneeCountry" >
                  <option value="UNITED STATES">UNITED STATES</option>
                  <option value="CANADA">CANADA</option>
                </select>
              </div>

              <div class="four wide column">
                <label for="">Contact:</label>
              </div>
              <div class="twelve wide column">
                <input type="text" name="consignee_contact" v-model="addConsigneeContact">
              </div>

              <div class="four wide column">
                <label for="">Phone:</label>
              </div>
              <div class="twelve wide column">
                <input type="text" name="consignee_phone" v-model="addConsigneePhone">
              </div>
            </div>

            <br>
            <br>
            <button class="ui button left floated sec" @click="close()"><i class="fa fa-times"></i> Cancel</button>
            <button class="ui button right floated prim" @click="submit()"><i class="fa fa-check"></i> Submit</button>
        </div>
        <div class="seven wide column">
            <img class="logo" src="../../assets/images/ALG_logo@2x.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
export default {
  props: {
    shipment: Object
  },
  data () {
    return {
      loading: false,
      addConsigneeName: '',
      addConsigneeAddress: '',
      addConsigneeAddress2: '',
      addConsigneeCity: '',
      addConsigneeState: '',
      addConsigneeZip: '',
      addConsigneeCountry: 'UNITED STATES',
      addConsigneeContact: '',
      addConsigneePhone: ''
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    submit: function() {
      this.loading = !this.loading;

      let params = {
        state: this.addConsigneeState,
        zip: this.addConsigneeZip
      };

      this.$http.get('/shipment/verify_zipcode', {params})
      .then((response) => {

        if (response.data.length > 0) {
          this.shipment.consigneeNumber = "";
          this.shipment.consigneeName = this.addConsigneeName;
          this.shipment.consigneeAddress = this.addConsigneeAddress;
          this.shipment.consigneeAddress2 = this.addConsigneeAddress2;
          this.shipment.consigneeCity = this.addConsigneeCity;
          this.shipment.consigneeState = this.addConsigneeState;
          this.shipment.consigneeZip = this.addConsigneeZip;
          this.shipment.consigneeCountry = this.addConsigneeCountry;
          this.shipment.consigneePhone = this.addConsigneePhone;

          $('.ui.consignee_add.modal').modal('hide');
          this.loading = !this.loading;

          this.addConsigneeName = '';
          this.addConsigneeAddress = '';
          this.addConsigneeAddress2 = '';
          this.addConsigneeCity = '';
          this.addConsigneeState = '';
          this.addConsigneeZip = '';
          this.addConsigneeCountry = 'UNITED STATES';
          this.addConsigneeContact = '';
          this.addConsigneePhone = '';

        } else {
          alert('You entered an invalid zip code.');

          this.loading = !this.loading;
        }

      })
      .catch((error) => {
        this.errors = error;
      });
    },
    close: function () {
      $('.ui.consignee_add.modal').modal('hide');
    }
  },
  computed: {
  }
};
</script>


<style lang="scss">
.ui.consignee_add.modal {
  min-height: 675px;
  margin: 8rem auto !important;
  padding-top: 20px;

  .header {
      color: $algdarkblue !important;
      border-bottom: none !important;
      padding-left: 40px;

      .close.icon {
          color: $algdarkblue !important;
          padding-right: 40px;
      }
  }

  label {
    color: $algdarkblue;
    font-weight: 600;
    padding-left: 15px;
  }

  input {
    border: 2px solid $algdarkblue25opac;
  }

  .logo {
    display: block;
    margin: auto;
  }

  .twelve.wide.column {
    padding-bottom: 0px;
  }

  .asterik {
    color: $algred;
  }
}
</style>