<template>
  <div id="user_index">
    <h1 class="ui dividing header">
      Users
      <router-link to="/admin/users/create" class="ui right floated button prim"
        ><i class="fas fa-user-plus"></i>Add User</router-link
      >
    </h1>

    <div class="ui active centered inline massive loader" v-if="loading"></div>

    <v-client-table
      class="users_table table-borderless"
      :data="users"
      :columns="columns"
      :options="options"
      v-else
    >
      <div slot="companies" slot-scope="{ row }">
        {{ row.companies.join(", ") }}
      </div>
      <div slot="customer_numbers" slot-scope="{ row }">
        {{ row.customer_numbers.join(", ") }}
      </div>
      <div
        class="ui compact menu"
        slot="manage"
        slot-scope="{ row }"
        v-if="!row.deleted_at"
      >
        <div class="ui simple dropdown item">
          Manage
          <i class="dropdown icon"></i>
          <div class="menu">
            <router-link class="item" :to="'/admin/users/' + row.id + '/edit'"
              ><i class="fa fa-edit"></i> Edit</router-link
            >
            <a
              class="item"
              href="javascript:void(0);"
              @click="deleteUser(row.id)"
              ><i class="fa fa-trash-alt"></i> Delete</a
            >
          </div>
        </div>
      </div>
      <button
        class="ui left floated button tert enable"
        @click="enableUser(row.id)"
        slot="manage"
        slot-scope="{ row }"
        v-else
      >
        <i class="fas fa-check"></i>Enable
      </button>
    </v-client-table>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
export default {
  data() {
    return {
      loading: true,
      users: [],
      columns: [
        "name",
        "email",
        "companies",
        "customer_numbers",
        "last_login",
        "manage",
      ],
      options: {
        headings: {
          name: "Name",
          email: "Email",
          companies: "Companies",
          customer_numbers: "Customer #'s",
          last_login: "Last Login",
          manage: "",
        },
        sortable: ["name", "email"],
        filterable: ["name", "email"],
        perPage: 10,
        perPageValues: [10, 25, 50],
        texts: {
          filter: "",
          filterBy: "Search by {column}",
          count: "",
        },
        pagination: { chunk: 10, dropdown: false },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
        },
      },
      errors: [],
    };
  },
  mounted() {
    this.$http
      .get("/users")
      .then((response) => {
        this.users = response.data;

        this.loading = !this.loading;

        setTimeout(function () {
          $(".VueTables__limit-field > select").addClass(
            "ui fluid limit dropdown"
          );
          $(".VueTables__search__input").attr("placeholder", "Search All...");
        }, 100);
      })
      .catch((error) => {
        this.errors = error;
      });
  },
  methods: {
    enableUser: function (id) {
      let params = {
        user_id: id,
      };

      this.$http
        .patch("/user/enable", params)
        .then(() => {
          this.reloadUsers();
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    deleteUser: function (id) {
      let params = {
        user_id: id,
      };

      this.$http
        .delete("/user/force_delete", { params })
        .then(() => {
          this.reloadUsers();
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    reloadUsers: function () {
      this.loading = !this.loading;

      this.$http
        .get("/users")
        .then((response) => {
          this.users = response.data;

          this.loading = !this.loading;
        })
        .catch((error) => {
          this.errors = error;
        });
    },
  },
};
</script>


<style lang="scss">
#user_index {
  margin: $layoutmargin;

  .VueTables__search {
    float: right !important;
  }

  .VueTables__search__input {
    width: 350px;
    height: 40px;
    border: $darkblueborder;
  }

  .ui.fluid.limit.dropdown {
    width: 60px;
    height: 40px;
    border-radius: 20px !important;
    border: $darkblueborder;
    margin-left: 10px;
  }

  .users_table {
    .table-responsive {
      padding-bottom: 85px;
    }

    .VueTables__table {
      overflow: unset !important;
    }

    tr:nth-child(2n) {
      background-color: #f4f4f4;
    }

    thead {
      tr {
        th {
          color: $algdarkblue;
          background-color: $algdarkbluetenopac;
          border: none;
          padding-left: 15px;
        }

        th:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        th:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    tbody {
      tr {
        background-color: #fff;

        td {
          border: none;
          padding: 20px 0px 0px 15px;
        }

        td:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        td:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          padding: 10px 0px 10px 15px;
        }
      }
    }
  }

  .enable {
    padding: 10px 19px 10px 19px;
  }
}
</style>