<template>
  <div id="user_index">
    <h1 class="ui dividing header">
      Active Notifications
      <router-link to="/admin/notifications/create" class="ui right floated button prim"
        ><i class="fas fa-bell"></i>Add Notification</router-link
      >
      <router-link to="/admin/notifications/inactive" class="ui right floated button prim"
        ><i class="fas fa-bell"></i>View Inactive</router-link
      >
    </h1>

    <div class="ui active centered inline massive loader" v-if="loading"></div>

    <v-client-table
      class="notifications_table table-borderless"
      :data="notifications"
      :columns="columns"
      :options="options"
      v-else
    >
      
      <div
        class="ui compact menu"
        slot="manage"
        slot-scope="{ row }"
      >
        <div class="ui simple dropdown item">
          Manage
          <i class="dropdown icon"></i>
          <div class="menu">
            <a
              class="item"
              href="javascript:void(0);"
              @click="disableNotification(row.token)"
              ><i class="fa fa-trash-alt"></i> Delete</a
            >
          </div>
        </div>
      </div>
    </v-client-table>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
export default {
  data() {
    return {
      loading: true,
      notifications: [],
      columns: [
        "hawb_number",
        "notification_type",
        "notification_contact",
        "interval",
        "created_at",
        "manage"
      ],
      options: {
        headings: {
          hawb_number: "HAWB #",
          notification_type: "Notification Type",
          notification_contact: "Notification Contact",
          interval: "Interval",
          created_at: "Requested At",
        },
        sortable: ["hawb_number", "notification_type","notification_contact","interval","created_at"],
        filterable: ["hawb_number", "notification_type","notification_contact","interval","created_at"],
        perPage: 10,
        perPageValues: [10, 25, 50],
        texts: {
          filter: "",
          filterBy: "Search by {column}",
          count: "",
        },
        pagination: { chunk: 10, dropdown: false },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
        },
      },
      errors: [],
    };
  },
  mounted() {
    this.$http
      .get("/notifications/get")
      .then((response) => {
        this.notifications = response.data;

        this.loading = !this.loading;

        setTimeout(function () {
          $(".VueTables__limit-field > select").addClass(
            "ui fluid limit dropdown"
          );
          $(".VueTables__search__input").attr("placeholder", "Search All...");
        }, 100);
      })
      .catch((error) => {
        this.errors = error;
      });
  },
  methods: {
    disableNotification: function (token) {
      let params = {
        token: token,
      };

      this.$http
        .delete("/notification/disable", { params })
        .then(() => {
          this.reloadNotifications();
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    reloadNotifications: function () {
      this.loading = !this.loading;

      this.$http
        .get("/notifications/get")
        .then((response) => {
          this.notifications = response.data;

          this.loading = !this.loading;
        })
        .catch((error) => {
          this.errors = error;
        });
    },
  },
};
</script>


<style lang="scss">
#user_index {
  margin: $layoutmargin;

  .VueTables__search {
    float: right !important;
  }

  .VueTables__search__input {
    width: 350px;
    height: 40px;
    border: $darkblueborder;
  }

  .ui.fluid.limit.dropdown {
    width: 60px;
    height: 40px;
    border-radius: 20px !important;
    border: $darkblueborder;
    margin-left: 10px;
  }

  .notifications_table {
    .table-responsive {
      padding-bottom: 85px;
    }

    .VueTables__table {
      overflow: unset !important;
    }

    tr:nth-child(2n) {
      background-color: #f4f4f4;
    }

    thead {
      tr {
        th {
          color: $algdarkblue;
          background-color: $algdarkbluetenopac;
          border: none;
          padding-left: 15px;
        }

        th:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        th:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    tbody {
      tr {
        background-color: #fff;

        td {
          border: none;
          padding: 20px 0px 0px 15px;
        }

        td:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        td:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          padding: 10px 0px 10px 15px;
        }
      }
    }
  }

  .enable {
    padding: 10px 19px 10px 19px;
  }
}
</style>