<template>
  <div id="user_create">
    <h1 class="ui dividing header">Add Notification</h1>

    <form class="ui form" v-on:submit.prevent="create()">
      <div class="inline field">
        <label>Hawb Number:</label>
        <input type="text" v-model="hawb_number" />
      </div>
      <div class="inline field">
        <label>Notification Type:</label>
        <select type="checkbox" class="ui fluid search dropdown" v-model="notification_type" >
          <option value="email">Email</option>
          <option value="phone">Text</option>
        </select>
      </div>
      <div class="inline field" v-if="notification_type == 'email'">
        <label>Email:</label>
        <input type="text" v-model="email" />
      </div>
      <div class="inline field" v-if="notification_type == 'phone'">
        <label>Phone:</label>
        <input type="text" v-model="phone" />
      </div>
      
      <div class="inline field">
        <label>Interval:</label>
        <select type="checkbox" class="ui fluid search dropdown" v-model="interval">
          <option value="1_hr">1 Hour</option>
          <option value="4_hr">4 Hour</option>
          <option value="1_day">1 Day</option>
        </select>
      </div>
      <div class="inline field buttons">
        <button class="ui left floated button sec" @click="cancel()">
          <i class="fas fa-times"></i>Cancel
        </button>
        <button class="ui right floated button prim">
          <i class="fas fa-check"></i>Submit
        </button>
      </div>
    </form>

    <ErrorModal :errors="errors" />
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
import ErrorModal from "@/components/modals/ErrorModal";

export default {
  components: {
    ErrorModal,
  },
  data() {
    return {
      hawb_number: "",
      notification_type: "email",
      email: "",
      phone: "",
      interval: "1_hr",
      errors: {},
    };
  },
  mounted() {
    $(".ui.fluid.dropdown").dropdown();
  },
  methods: {
    create: function () {
      let params = {
        hawb_number: this.hawb_number,
        notification_type: this.notification_type,
        email: this.email,
        phone: this.phone,
        interval: this.interval,
      };

      this.$http
        .post("/notification/store", params)
        .then((response) => {
          this.router.push("/admin/notifications/");
        })
        .catch((error) => {
          this.errors = error;
          //$('.ui.error_modal.modal').modal('show');
        });
    },
    
    cancel: function () {
      this.router.push("/admin/notifications");
    },
  },
};
</script>

<style lang="scss">
#user_create {
  margin: $layoutmargin;

  .ui.form {
    margin-top: 20px;
    padding-left: 24px;

    label {
      font-size: 16px;
      font-weight: bold;
      color: $algdarkblue;
      width: 130px;
    }

    input {
      width: 300px;
      border: $darkblueborder;
    }

    select {
      // width: 300px;
      // height: 40px;
      // border: $darkblueborder;
      // border-radius: 4px;
      // font-size: 16px;
      // padding: 0px 0px 0px 10px !important;
    }

    .inline.field.buttons {
      width: 430px;
      margin-top: 54px;
      padding-bottom: 50px;
    }

    .single-select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      background-color: #fff;
      color: $alggrey;
    }

    .ui.fluid.dropdown {
      display: inline-block !important;
      width: 100% !important;
      max-width: 300px !important;
      border: $darkblueborder;
    }
  }
}
</style>