<template>
  <div id="admin_nav">
    <div class="ui menu">
      <div class="header item">
        <router-link to="/admin/dashboard"
          ><img id="logo" src="../../assets/images/ALG_logo@2x.png"
        /></router-link>
      </div>
      <router-link class="item right" to="/admin/dashboard"
        ><span>Dashboard</span></router-link
      >
      <router-link class="item" to="/admin/users"
        ><span>Users</span></router-link
      >
      <router-link class="item" to="/admin/companies"
        ><span>Companies</span></router-link
      >
      <router-link class="item" to="/admin/customernumbers"
        ><span>Customer Numbers</span></router-link
      >
      <router-link class="item" to="/admin/notifications"
        ><span>Notifications</span></router-link
      >
      <router-link class="item" to="/shipment/create"
        ><span>Shipment Entry</span></router-link
      >
      <router-link class="item" to="/logout"
        ><i class="fa fa-sign-out-alt"></i
      ></router-link>
      <!-- <div class="ui compact menu">
          <div class="ui simple dropdown item">
            
            <div class="menu">
              <router-link class="item" v-if="user" to="/account">Account</router-link>
              <router-link class="item" v-if="user" to="/logout">Logout</router-link>
              <router-link class="item" v-if="!user" to="/login">Login</router-link>
            </div>
          </div>
        </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  }
};
</script>

<style lang="scss">
#admin_nav {
  .ui.menu {
    min-height: 65px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0;
    border-top: 0px;

    .header {
      border-right: none;
    }

    .ui.compact.menu {
      border: 0 !important;
      box-shadow: none !important;
    }

    .header.item:before {
      display: none;
    }

    .item {
      font-size: 18px;
    }

    .item:before {
      display: none;
    }
  }

  .ui.dropdown {
    border: none !important;

    .menu {
      right: 0 !important;
      left: unset;
    }
  }

  #logo {
    width: 95px !important;
    padding-left: 20px;
  }
}
</style>
