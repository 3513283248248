<template>
  <div id="user_nav">
    <div class="ui menu">
      <div class="header item">
        <router-link to="/dashboard"
          ><img id="logo" src="../../assets/images/ALG_logo@2x.png"
        /></router-link>
      </div>
      <router-link class="item right padding-right" to="/dashboard">
        <span>Dashboard</span>
      </router-link>
      <div class="ui compact billing menu" v-if="user.account_summary || user.payment_history || user.open_invoices">
        <div
          class="ui simple dropdown item padding-right"
          :class="{ 'router-link-active': billingRoute }"
        >
          <span>Billing<i class="dropdown icon"></i></span>

          <div class="menu">
            <router-link v-if="user.account_summary" class="item" to="/billing/accountsummary"
              >Account Summary</router-link
            >
            <router-link v-if="user.payment_history" class="item" to="/billing/paymenthistory"
              >Payment History</router-link
            >
            <router-link v-if="user.open_invoices" class="item" to="/billing/openinvoices"
              >Open Invoices</router-link
            >
          </div>
        </div>
      </div>
      <router-link v-if="user.tracking_report" class="item  padding-right" to="/tracking/report"
        ><span>Tracking Report</span></router-link
      >
      <router-link v-if="user.shipment_entry" class="item" to="/shipment/create"
        ><span>Shipment Entry</span></router-link
      >
      <div class="ui compact account menu">
        <div class="ui simple dropdown item">
          <router-link class="item" to="/account"
            ><span><i class="fa fa-user"></i></span
          ></router-link>
          <div class="menu">
            <router-link class="item" to="/account">Account</router-link>
            <router-link class="item" to="/logout">Log Out</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    billingRoute() {
      return this.$route.name.includes("billing");
      // return true;
    }
  }
};
</script>

<style lang="scss">
#user_nav {
  .ui.menu {
    min-height: 65px;
    border-radius: 0;
    box-shadow: 0px 3px 6px #00000029;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-top: 0px;

    a.item {
      color: $algdarkgrey;
    }

    a.item.router-link-active {
      background-color: inherit !important;
      color: $algred !important;
    }

    .header {
      border-right: none;
    }

    .ui.compact.menu {
      border: 0 !important;
      box-shadow: none !important;
    }

    .ui.compact.billing.menu {
      .menu {
        right: -15px !important;
        left: unset;
        top: 65px !important;
      }
      &:hover {
        .ui.dropdown {
          color: $algred !important;
        }

        .dropdown.icon {
          color: $algred !important;
        }
      }
    }

    .ui.compact.account.menu {
      // right
      .menu {
        right: 35px !important;
        left: unset;
        top: 65px !important;
      }
    }

    .item {
      font-size: 18px;
    }

    .item:before {
      display: none;
    }
  }

  .ui.dropdown {
    border: none !important;

    .menu {
      a.item {
        color: $alggrey;
        font-size: 18px !important;
      }

      a.item:hover {
        cursor: pointer;
        color: $algred !important;
      }
    }
  }

  #logo {
    width: 95px;
    padding-left: 20px;
  }

  .item.padding-right {
    padding-right: 45px;
  }
}
</style>
