var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"customer_numbers_index"}},[_c('h1',{staticClass:"ui dividing header"},[_vm._v(" Customer Numbers "),_c('router-link',{staticClass:"ui right floated button prim",attrs:{"to":"/admin/customernumbers/create"}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v("Add Customer #")])],1),(_vm.loading)?_c('div',{staticClass:"ui active centered inline massive loader"}):_c('v-client-table',{staticClass:"customer_numbers_table table-borderless",attrs:{"data":_vm.customerNumbers,"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"tracking_history",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.tracking_history ? "Not Showing" : "Showing")+" ")])}},{key:"show_miles",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.show_miles ? "Showing" : "Not Showing")+" ")])}},{key:"location_history",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.location_history ? "Not Showing" : "Showing")+" ")])}},{key:"manage",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"ui compact menu manage_dropdown"},[_c('div',{staticClass:"ui simple dropdown item"},[_vm._v(" Manage "),_c('i',{staticClass:"dropdown icon"}),_c('div',{staticClass:"menu"},[_c('router-link',{staticClass:"item",attrs:{"to":'/admin/customernumbers/' + row.id + '/edit'}},[_c('i',{staticClass:"fa fa-edit"}),_vm._v(" Edit")]),_c('button',{staticClass:"item",attrs:{"to":"javascript:void(0)"},on:{"click":function($event){return _vm.deleteNumber(row.id)}}},[_c('i',{staticClass:"fa fa-trash-alt"}),_vm._v(" Delete ")])],1)])])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }