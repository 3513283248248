<template>
  <div id="user_create">
    <h1 class="ui dividing header">Add New User</h1>

    <form class="ui form" v-on:submit.prevent="">
      <div class="inline field">
        <label>Name:</label>
        <input type="text" placeholder="Name" v-model="name" />
      </div>
      <div class="inline field">
        <label>Email:</label>
        <input type="text" placeholder="Email" v-model="email" />
      </div>
      <div class="inline field">
        <label>Password:</label>
        <input type="text" placeholder="Password" v-model="password" />
      </div>
      <div class="inline field">
        <label>Customer #'s:</label>
        <select
          multiple=""
          class="ui fluid search dropdown"
          v-model="customerNumbers"
        >
          <option :value="null" disabled></option>
          <option
            v-for="num in customerNumberOptions"
            :key="num.id"
            :value="num.id"
          >
            {{ num.number }}
          </option>
        </select>
      </div>
      <div class="inline field">
        <label>Companies:</label>
        <select
          multiple=""
          class="ui fluid search dropdown"
          v-model="companies"
        >
          <option :value="null" disabled></option>
          <option
            v-for="name in companyOptions"
            :key="name.id"
            :value="name.id"
          >
            {{ name.name }}
          </option>
        </select>
      </div>
      <div class="inline field">
        <label>Image Types:</label>
        <select
          multiple=""
          class="ui fluid search dropdown"
          v-model="imageTypes"
        >
          <option :value="null" disabled></option>
          <option
            v-for="type in imageTypeOptions"
            :key="type.ID"
            :value="type.ID"
          >
            {{ type.ImgDescription }}
          </option>
        </select>
      </div>
      <div class="inline field">
        <label>Logo:</label>
        <input type="file" name="logo" @change="processLogo($event)" />
      </div>
      <div class="inline field">
        <label>Admin:</label>
        <input type="checkbox" name="" v-model="admin" />
      </div>
      <div class="inline field">
        <label>Shipment Entry:</label>
        <input type="checkbox" name="" v-model="shipmentEntry" />
      </div>
      <div class="inline field">
        <label>Tracking Report:</label>
        <input type="checkbox" name="" v-model="trackingReport" />
      </div>
      <div class="inline field buttons">
        <button class="ui left floated button sec" @click="cancel()">
          <i class="fas fa-times"></i>Cancel
        </button>
        <button class="ui right floated button prim" @click="create()">
          <i class="fas fa-check"></i>Submit
        </button>
      </div>
    </form>

    <ErrorModal :errors="errors" />
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
import ErrorModal from "@/components/modals/ErrorModal";

export default {
  components: {
    ErrorModal,
  },
  data() {
    return {
      name: "",
      email: "",
      password: "",
      admin: 0,
      customerNumbers: [],
      companies: [],
      imageTypes: [],
      logo: "",
      shipmentEntry: "",
      trackingReport: "",
      created_by: this.$store.state.user.id,
      customerNumberOptions: [],
      companyOptions: [],
      imageTypeOptions: [],
      errors: {},
    };
  },
  mounted() {
    this.$http
      .get("/users/create")
      .then((response) => {
        this.companyOptions = response.data.companies;
        this.customerNumberOptions = response.data.customer_numbers;
        this.imageTypeOptions = response.data.image_types;
      })
      .catch((error) => {
        this.errors = error;
      });

    $(".ui.fluid.dropdown").dropdown();
  },
  methods: {
    create: function () {
      let params = {
        name: this.name,
        email: this.email,
        password: this.password,
        admin: this.admin,
        logo: this.logo,
        image_types: this.imageTypes,
        created_by: parseInt(this.created_by),
        shipment_entry: this.shipmentEntry,
        tracking_report: this.trackingReport,
        companies: this.companies,
        customer_numbers: this.customerNumbers,
      };

      this.$http
        .post("/users", params)
        .then((response) => {
          this.router.push("/admin/users/"+response.data.id+"/edit");
        })
        .catch((error) => {
          this.errors = error;
          // $('.ui.error_modal.modal').modal('show');
        });
    },
    processLogo(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.logo = files[0];
    },
    cancel: function () {
      this.router.push("/admin/users");
    },
  },
};
</script>

<style lang="scss">
#user_create {
  margin: $layoutmargin;

  .ui.form {
    margin-top: 20px;
    padding-left: 24px;

    label {
      font-size: 16px;
      font-weight: bold;
      color: $algdarkblue;
      width: 130px;
    }

    input {
      width: 300px;
      border: $darkblueborder;
    }

    select {
      // width: 300px;
      // height: 40px;
      // border: $darkblueborder;
      // border-radius: 4px;
      // font-size: 16px;
      // padding: 0px 0px 0px 10px !important;
    }

    .inline.field.buttons {
      width: 430px;
      margin-top: 54px;
      padding-bottom: 50px;
    }

    .single-select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      background-color: #fff;
      color: $alggrey;
    }

    .ui.fluid.dropdown {
      display: inline-block !important;
      width: 100% !important;
      max-width: 300px !important;
      border: $darkblueborder;
    }
  }
}
</style>