<template>
  <div id="customernumber_edit">
    <h1 class="ui dividing header">Edit Status</h1>
    <div class="ui form">
      <div class="inline field">
        <label>Real Status Name:</label>
        <input type="text" placeholder="Name" v-model="status.real_status_name" disabled />
      </div>
    </div>
    <div class="ui form">
      <div class="inline field">
        <label>Friendly Status Name:</label>
        <input type="text" placeholder="Name" v-model="status.friendly_status_name" />
      </div>
    </div>
    <div class="inline field buttons">
        <button class="ui left floated button sec" @click="cancel">
          <i class="fas fa-times"></i>Cancel
        </button>
        <button class="ui right floated button prim" @click="update()">
          <i class="fas fa-check"></i>Submit
        </button>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      status: {
        real_status_name: null,
        friendly_status_name: null
      },
      errors: []
    };
  },
  created() {
    this.$http
      .get("/statuses/" + this.$route.params.id + "/edit")
      .then(response => {
        // eslint-disable-next-line no-console
        //console.log(response.data);
        this.status.real_status_name = response.data.real_status_name;
        this.status.friendly_status_name = response.data.friendly_status_name;
        this.loading = !this.loading;
      })
      .catch(error => {
        this.errors = error;
      });
  },
  methods: {
    update: function() {
      let params = {
        real_status_name: this.status.real_status_name,
        friendly_status_name: this.status.friendly_status_name
      };

      this.$http
        .patch("/statuses/" + this.$route.params.id, params)
        .then(() => {
          this.router.push("/admin/statuses");
        })
        .catch(error => {
          this.errors = error;
        });
    },
    cancel: function() {
      this.router.push("/admin/statuses");
    }
  }
};
</script>

<style lang="scss">
#customernumber_edit {
  margin: $layoutmargin;

  .ui.form {
    margin-top: 20px;
    padding-left: 24px;

    label {
      font-size: 16px;
      font-weight: bold;
      color: $algdarkblue;
      width: 145px;
    }

    input {
      width: 300px;
      border: $darkblueborder;
    }

    select {
      width: 300px;
      border: $darkblueborder;
    }

    .inline.field.buttons {
      width: 430px;
      margin-top: 54px;
    }
  }
}
</style>
