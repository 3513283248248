<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  props: {
    labels: Array,
    data: Array
  },
  data: function() {
    return {
      chartdata: {
        labels: this.labels,
        datasets: [
          {
            label: 'Total',
            backgroundColor: '#1996D3',
            data: this.data
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        title: {
          display: true,
          text: ''
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.chartdata, this.options);
  }
}

// with props
// import { HorizontalBar } from 'vue-chartjs';

// export default {
//     extends: HorizontalBar,
//     props: {
//         chartdata: {
//             type: Object,
//             default: null
//         },
//         options: {
//             type: Object,
//             default: null
//         }
//     },
//     mounted () {
//         this.renderChart(this.chartdata, this.options)
//     }
// };
</script>

<style lang="scss">
</style>
