<template>
  <div id="open_invoices">
    <div class="ui grid form">
      <div class="sixteen wide column">
        <div class="ui grid">
          <div class="four wide column"><h1>Open Invoices</h1></div>
          <div class="one wide column" id="accounts">Accounts:</div>
          <div class="three wide column">
            <select class="ui fluid dropdown" @change="changeAccount($event)">
              <option value="all">All Accounts</option>
              <option v-for="number in allowedNumbers" :value="number" :key="number">{{number}}</option>
            </select>
          </div>
          <div class="eight wide column button_column">
            <button class="ui right floated button sec" :class="{ loading: downLoading }" @click="downloadExcel()"><i class="fas fa-file-download"></i>Download Excel</button>
            <button class="ui right floated button prim" :class="{ loading: downLoading }" @click="downloadPdf()"><i class="fas fa-download"></i>Download PDF</button>
          </div>
        </div>
      </div>
      <div class="sixteen wide column">
        <div v-if="customerInfo" class="ui grid">
          <div class="three wide column">
            {{customerInfo.CustomerNumber}} <br>
            {{customerInfo.Address1}} <br>
            {{customerInfo.Address2}} <br>
            {{customerInfo.csz}}
          </div>
          <div class="nine wide column"></div>
          <div class="four wide column">
            Credit Status: {{customerInfo.CreditStatus}} <br>
            Last Payment: {{customerInfo.LastPaidDate}} <br>
            Last Check Number: {{customerInfo.LastCheckNbr}} <br>
            Last Sale Date: {{customerInfo.LastSaleDate}}
          </div>
        </div>
      </div>
    </div>
    <div class="ui divider"></div>

    <div class="ui active centered inline massive loader" v-if="loading"></div>

    <div v-else>

      <v-client-table class="open_invoices_table" :columns="columns" :data="openInvoices" :options="options">
        <router-link class="invoice_number" :to="'/tracking/details?hawb_number='+row.InvoiceNumber" slot="InvoiceNumber" slot-scope="{row}" v-if="row.Print === 'Print Invoice'">{{row.InvoiceNumber}}</router-link>
        <router-link class="invoice_number" :to="'/billing/openinvoice/hawbs?invoice_number='+row.InvoiceNumber" slot="InvoiceNumber" slot-scope="{row}" v-else>{{row.InvoiceNumber}}</router-link>
        <p class="print_invoice" @click="downloadInvoice(row.InvoiceNumber, row.Print)" slot="Print" slot-scope="{row}">{{row.Print}}</p>
      </v-client-table>

      <h2  class="ui header balance">Balance</h2>
      <div v-if="customerInfo" class="ui five column grid balance">
        <div class="two wide column">
          <label>0 - 30 Days</label><br>
          <p>${{parseFloat(customerInfo.Balance0_30).toLocaleString('en-US', {minimumFractionDigits: 2})}}</p>
        </div>
        <div class="two wide column">
          <label>31 - 60 Days</label><br>
          <p>${{parseFloat(customerInfo.Balance30_60).toLocaleString('en-US', {minimumFractionDigits: 2})}}</p>
        </div>
        <div class="two wide column">
          <label>61 - 90 Days</label><br>
          <p>${{parseFloat(customerInfo.Balance60_90).toLocaleString('en-US', {minimumFractionDigits: 2})}}</p>
        </div>
        <div class="two wide column">
          <label>91+ Days</label><br>
          <p>${{parseFloat(customerInfo.Balance90Plus).toLocaleString('en-US', {minimumFractionDigits: 2})}}</p>
        </div>
        <div class="four wide column">
          <label>Total Balance (USD)</label><br>
          <p>${{total.toLocaleString('en-US', {minimumFractionDigits: 2})}}</p>
        </div>
      </div> 

      <div class="invoice" hidden>
      </div>
    </div>

  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
import TableExport from 'tableexport';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  components: {
  },
  data () {
    return {
      loading: true,
      downLoading: true,
      openInvoices: [],
      invoiceType: '',
      customerInfo: {},
      allowedNumbers: [],
      columns: [
        'InvoiceNumber',
        'DateInvoiced',
        'Amount',
        'Payment',
        'Balance',
        'Print'
      ],
      options: {
        headings: {
          InvoiceNumber: 'Invoice Number',
          DateInvoiced: 'Invoice Date',
          Amount: 'Amount',
          Payment: 'Payment',
          Balance: 'Balance',
          Print: ''
        },
        sortable: ['InvoiceNumber', 'DateInvoiced', 'Amount', 'Payment', 'Balance'],
        filterable: ['InvoiceNumber', 'DateInvoiced', 'Amount', 'Payment', 'Balance'],
        perPage: 10,
        perPageValues: [10, 25, 50],
        texts: {
            filter: '',
            filterBy: '',
            count: '',
            limit: '',
            noResults: "No Open Invoices Reported",
        },
        pagination: { chunk: 10, dropdown: false },
        sortIcon: {
          base : 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        },
        customSorting: {
          DateInvoiced: function (ascending) {
            return function (a, b) {
              let dateA = new Date(a.DateInvoiced);
              let dateB = new Date(b.DateInvoiced);

              if (ascending)
                  return dateA >= dateB ? 1 : -1;

              return dateA <= dateB ? 1 : -1;
            }
          },
          Amount: function(ascending) {
            return function (a, b) {
              let amountA = parseFloat(a.Amount.substr(1).replace(/,/g, ''));
              let amountB = parseFloat(b.Amount.substr(1).replace(/,/g, ''));

              if (ascending)
                  return amountA >= amountB ? 1 : -1;

              return amountA <= amountB ? 1 : -1;
            }
          },
          Payment: function(ascending) {
            return function (a, b) {
              let paymentA = parseFloat(a.Payment.substr(1).replace(/,/g, ''));
              let paymentB = parseFloat(b.Payment.substr(1).replace(/,/g, ''));

              if (ascending)
                  return paymentA >= paymentB ? 1 : -1;

              return paymentA <= paymentB ? 1 : -1;
            }
          },
          Balance: function(ascending) {
            return function (a, b) {
              let balanceA = parseFloat(a.Balance.substr(1).replace(/,/g, ''));
              let balanceB = parseFloat(b.Balance.substr(1).replace(/,/g, ''));

              if (ascending)
                  return balanceA >= balanceB ? 1 : -1;

              return balanceA <= balanceB ? 1 : -1;
            }
          }
        }
      },
      invoiceNumber: ''
    }
  },
  created () {
    this.$http.get('/billing/openinvoices?customer_numbers=all')
    .then((response) => {
      this.allowedNumbers = response.data.allowed_numbers;
      this.invoiceType = response.data.invoice_type;

      let keys = Object.keys(response.data.invoices);

      this.customerInfo = response.data.invoices[keys[0]];
      
      // map over invoices and structure for table
      this.openInvoices = keys.map((key) => {
        let currentInvoice = response.data.invoices[key];

        let invoiceNumber;
        let print;
        if (this.invoiceType === 'Single' || this.invoiceType == 'EDI') {
          invoiceNumber = currentInvoice.Hawb;
          print = "Print Invoice";
        } else {
          invoiceNumber = currentInvoice.MasterInvoice;
          print = "Print Master Invoice";
        }
        if(isNaN(currentInvoice.TotChkAmount)) {
          currentInvoice.TotChkAmount = 0.00;
        }
        let invoice = {
          'InvoiceNumber': invoiceNumber,
          'DateInvoiced': currentInvoice.FormattedDateInvoiced,
          'Amount': this.formatMoney(currentInvoice.Amount),
          'Payment': this.formatMoney(currentInvoice.TotChkAmount),
          'Balance': this.formatMoney(currentInvoice.Balance),
          'Print': print
        };
        
        return invoice;
      });

      this.initiateDownloadButtons();

      this.loading = !this.loading;
    })
    .catch((error) => {
      this.errors = error;
    });
  },
  mounted () {
    $('.ui.fluid.dropdown').dropdown();
  },
  methods: {
    changeAccount: function(e) {
      this.loading = !this.loading;
      this.$http.get('/billing/openinvoices?customer_number='+e.target.value)
      .then((response) => {
        this.customerNumbers = response.data.customer_numbers;
        this.invoiceType = response.data.invoice_type;

        let keys = Object.keys(response.data.invoices);

        this.customerInfo = response.data.invoices[keys[0]];
        
        // map over invoices and structure for table
        this.openInvoices = keys.map((key) => {
          let currentInvoice = response.data.invoices[key];

          let invoiceNumber;
          (this.invoiceType === 'Single' || this.invoiceType === 'EDI') ? invoiceNumber = currentInvoice.Hawb : invoiceNumber = currentInvoice.MasterInvoice;
          if(isNaN(currentInvoice.TotChkAmount)) {
            currentInvoice.TotChkAmount = 0;
          }
          let invoice = {
            'InvoiceNumber': invoiceNumber,
            'DateInvoiced': currentInvoice.FormattedDateInvoiced,
            'Amount': '$'+currentInvoice.Amount.toLocaleString('en-US', {minimumFractionDigits: 2}),
            'Payment': '$'+currentInvoice.TotChkAmount.toLocaleString('en-US', {minimumFractionDigits: 2}),
            'Balance': '$'+currentInvoice.Balance.toLocaleString('en-US', {minimumFractionDigits: 2}),
            'Print': 'print'
          };
          
          return invoice;
        });

        this.loading = !this.loading;
      })
      .catch((error) => {
        this.errors = error;
      });
    },
    initiateDownloadButtons: function() {
      // add id to base table to allow download
      // TODO: Change to on table load
      setTimeout(() => {
        $('.VueTables__table').attr("id", "open_invoices_table");

        $('.VueTables__limit-field > select').addClass("ui fluid limit dropdown");

        $('.ui.fluid.dropdown').dropdown();

        $('.VueTables__search__input').attr("placeholder", "Search All...");

        this.downLoading = !this.downLoading;
      }, 1000);
    },
    downloadExcel: function() {
      let table = document.getElementById('open_invoices_table');

      let excel = new TableExport(table, {
        formats: ["csv"],    // (String[]), filetype(s) for the export, (default: ['xlsx', 'csv', 'txt'])
        filename: "open_invoices",                     // (id, String), filename for the downloaded file, (default: 'id')
        bootstrap: false,                   // (Boolean), style buttons using bootstrap, (default: true)
        exportButtons: false,                // (Boolean), automatically generate the built-in export buttons for each of the specified formats (default: true)
        trimWhitespace: true,               // (Boolean), remove all leading/trailing newlines, spaces, and tabs from cell text in the exported file(s) (default: false)
        sheetname: "open_invoices"                     // (id, String), sheet name for the exported spreadsheet, (default: 'id')
      });

      let exportData = excel.getExportData()['open_invoices_table']['csv'];

      excel.export2file(exportData.data, exportData.mimeType, exportData.filename, exportData.fileExtension);
    },
    downloadPdf: function() {
      const doc = new jsPDF();

      doc.autoTable({ 
        html: '#open_invoices_table',
        styles: { minCellWidth: 10 }
      });

      doc.save('open_invoices.pdf');
    },
    downloadInvoice: function(invoiceNumber, invoiceType) {
      this.loading = !this.loading;
      this.invoiceNumber = invoiceNumber;

      let params = {
        invoice_num: invoiceNumber,
        invoice_type: invoiceType
      };

      this.$http.post('/billing/openinvoice/invoice', params, {responseType: 'blob'})
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        if (params.invoice_type === "Print Invoice") {
          link.setAttribute('download', 'Invoice-'+this.invoiceNumber+'.pdf');
        } else {
          link.setAttribute('download', 'MasterInvoice-'+this.invoiceNumber+'.pdf');
        }

        document.body.appendChild(link);
        link.click();

        this.loading = !this.loading;
      })
      .catch((error) => {
        this.errors = error;
        this.loading = !this.loading;
      });
    },
    formatMoney(str) {
      if ((str !== null) && !isNaN(str) && str !== "") {
        return '$'+parseFloat(str).toLocaleString('en-US', {minimumFractionDigits: 2});
      } else {
        return '$0.00';
      }
    }
  },
  computed: {
    total: function () {
      return parseFloat(this.customerInfo.Balance0_30) + parseFloat(this.customerInfo.Balance30_60) + parseFloat(this.customerInfo.Balance60_90) + parseFloat(this.customerInfo.Balance90Plus);
    }
  }
};
</script>

<style lang="scss">
#open_invoices {
  margin: $layoutmargin;

  #accounts {
    padding-top: 22px;
  }

  .ui.fluid.dropdown {
    width: 125px;
    height: 40px;
    border-radius: 20px !important;
    border: $darkblueborder;
    margin-left: 10px;
  }

  .ui.grid.form {
    max-width: unset;
  }

  select {
    width: 125px;
    height: 40px;
  }

  .invoice_number {
    color: $algdarkblue;
    text-decoration: underline;
  }

  .print_invoice {
    color: $algred !important;
    text-decoration: underline;
  }

  .VueTables__search {
    float: right !important;
  }

  .VueTables__search__input {
    width: 350px;
    height: 40px;
    border: $darkblueborder;
  }

  .ui.fluid.limit.dropdown {
    width: 60px;
    height: 40px;
    border-radius: 20px !important;
    border: $darkblueborder;
    margin-left: 10px;
  }

  .open_invoices_table {
    border: none;
    box-shadow: none;

    thead {
      tr {
        th {
            color: $algdarkblue;
            background-color: $algdarkbluetenopac;
            border: none;
        }

        th:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        th:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
      }
    }

    tbody {
      tr {
        td:first-child {
          border-top-left-radius: 8px !important;
          border-bottom-left-radius: 8px !important;
        }
        td:last-child {
          border-top-right-radius: 8px !important;
          border-bottom-right-radius: 8px !important;
        }

        td {
            border: none;
        }
      }
      tr:nth-child(odd) {
          background-color: #fff;
      }

      tr:nth-child(even) {
          background-color: rgba(0,0,0,.05);
      }
    }
  }

  .ui.header.balance {
    padding: 7px 0px 7px 24px;
    background-color: $algdarkbluetenopac;
    border-radius: 8px;
    font-size: 18px !important;
    font-weight: 600 !important;
  }

  .balance {
    label {
      color: $algdarkblue;
      font-weight: 600;
      padding-left: 15px;
    }

    p {
      padding-left: 15px;
    }
  }
}
</style>

