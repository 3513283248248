<template>
  <div id="united_states">
      <svg xmlns:x="http://ns.adobe.com/Extensibility/1.0/" xmlns:i="http://ns.adobe.com/AdobeIllustrator/10.0/" xmlns:graph="http://ns.adobe.com/Graphs/1.0/" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Tracking_Map" x="0px" y="0px" width="100%" height="736.5px" viewBox="0 0 1152 736.5" enable-background="new 0 0 1152 736.5" xml:space="preserve" class="style-svg replaced-svg svg-replaced-1" style="height:400px;">
        <g id="states">
            <g id="grey">
                <path id="NH" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M1077.3,183.4l1.101-1.3l1.3-4l-3.101-1.1l-0.6-3.8    l-4.8-1.4l-0.4-3.4l-8.899-28.8l-5.7-17.9h-1.101l-0.8,2l-0.8-0.6l-1.2-1.2l-1.8,2.4v6.1l0.4,7l2.399,3.4v4.9l-4.6,6.2l-3.2,1.5    v1.4l1.4,2.2v10.5l-1,11.3l-0.2,6l1.2,1.6l-0.2,5.6l-0.6,2.2l1.199,0.9l20.7-5.4l2.7-0.7l2.3-3.4L1077.3,183.4z"></path>
                <g id="MI">
                    <path id="MI-" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M852.3,226.2l-4-10.1l-2.8-11.1l-3-4l-3.2-2.2l-2,1.4     l-4.8,2.2l-2.4,6.2l-3.399,4.6l-1.4,0.8l-1.8-0.8c0,0-3.2-1.8-3-2.6c0.2-0.8,0.6-6.1,0.6-6.1l4.2-1.6l1-4.2l0.8-3.2l3-2     l-0.399-12.3l-2-2.8l-1.601-1l-1-2.6l1-1l2,0.4l0.2-2l-3.1-2.8l-1.601-3.2H820.4l-5.5-1.7l-6.801-4.2H805l-0.8,0.8l-1.2-0.6     l-3.8-2.8l-3.601,2.2l-3.6,2.8l0.4,4.4l1.199,0.4l2.601,0.6l0.6,1l-3.2,1l-3.199,0.4l-1.801,2.2l-0.399,2.6l0.399,2l0.4,6.8     l-4.4,2.6l-0.8-0.2v-5.2l1.601-3l0.8-3l-1-1l-2.4,1l-1.2,5.2l-3.3,1.3l-2.2,2.5l-0.199,1.2l0.8,1l-0.8,3.2l-2.801,0.6v1.4l1,3     l-1.399,7.6l-2,4.9l0.8,5.8l0.6,1.4l-1,3l-0.399,1l-0.4,3.4l4.4,7.4l3.6,8l1.8,6l-1,5.8l-1.2,7.4L775,262.7l-0.4,3.4l-4,3.8     l5.4-0.2l26.3-2.8l9-1.2l0.1,2.1l8.4-1.5l12.7-1.8l4.7-0.6l0.199-0.7l0.2-1.8l2.601-4.6l2.5-2.1l-0.301-6.2l2-2l1.301-0.4     l0.3-4.4l1.899-3.7l1.301,0.8l0.199,0.8l1,0.2l2.4-1.2L852.3,226.2z"></path>
                    <path id="SP-" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M709.3,109.2l2.3-2.5l2.7-1l6.601-4.8l2.8-0.7l0.6,0.6     l-6.3,6.3l-4.1,2.4l-2.5,1.1L709.3,109.2z M815.3,148.7l0.8,3.1l4,0.2l1.601-1.5c0,0-0.101-1.8-0.5-2c-0.7-0.7-1.4-1.5-2-2.3     l-2.7,0.3l-2,0.2l-0.4,1.4L815.3,148.7z M692,145l0.9-0.7l3.399-1l4.4-2.8v-1.2l0.8-0.8l7.4-1.2l3-2.4l5.399-2.6l0.2-1.6l2.3-3.5     l2.2-1l1.6-2.2l2.801-2.8l5.399-3l5.8-0.6l1.4,1.4l-0.4,1.2l-4.6,1.2l-1.8,3.8l-2.8,1l-0.601,3l-3,4l-0.399,3.2l1,0.6l1.2-1.4     L732,132l1.601,1.6h2.8l4,1.2l1.8,1.4l1.8,3.8l3.4,3.4l4.8-0.2l1.8-1.2l2,1.6l2,0.6l1.6-1h1.4l2-1.2l4.9-4.4l4.2-1.4l8.199-0.4     l5.601-2.4l3.2-1.6l1.8,0.2v7l0.6,0.4l3.6,1l2.4-0.6l7.6-2l1.4-1.4l1.8,0.6v8.6l4,3.8l1.601,0.8l1.6,1.2l-1.6,0.4l-1-0.4     l-4.601-0.6l-2.6,0.8l-2.8-0.2l-4,1.8h-2.2l-7.2-1.6l-6.4,0.2L780.7,155l-8.601,0.8l-3,1l-1.399,3.8L766.1,162l-0.6-0.2l-1.8-2     l-5.6,3H757l-1.4-2l-1,0.2l-2.399,5.4l-1.2,4.9l-3.9,8.6l-1.5-1.2l-1.699-1.2l-2.2-12.8l-4.4-1.7l-2.5-2.8l-14.9-3.4l-3.5-1.2     l-10.1-2.7l-9.7-1.4L692,145z"></path>
                </g>
                <path id="VT" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M1032.7,197.7l0.399-6.6l-3.6-13.3l-0.8-0.4l-3.601-1.6    l1-3.6l-1-2.6l-3.3-5.7l1.2-4.8l-1-6.4l-3-8l-1-6.1l32.5-8.3l0.4,6.8l2.399,3.4v4.9l-4.6,6.2l-3.2,1.5v1.4l1.6,1.9l-0.399,10    L1046,178l-0.3,6.8l1.2,1.6l-0.2,5.6l-0.601,2.1l1.2,0.9l-9.2,1.8L1032.7,197.7z"></path>
                <path id="ME" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M1129,105.2l2.4,2.6l2.699,4.6v2.4l-2.6,5.8l-2.4,0.8    l-4.1,3.8l-6,6.8h-1.6c-0.801,0-1.2-2.6-1.2-2.6l-2.2,0.2l-1.2,1.8l-3,1.8l-1.199,1.8l2,1.8l-0.601,0.8l-0.6,3.4l-2.4-0.2v-2    l-0.399-1.6l-1.801,0.4l-2.199-4l-2.601,1.6l1.601,1.8l0.399,1.4l-1,1.6l0.4,3.8l0.2,2l-2,3.2l-3.601,0.6l-0.399,3.6L1089,157    l-1.6,0.6l-2-1.8l-3.8,4.4l1.199,4l-1.8,1.6l-0.2,5.4l-1.399,7.7l-3-1.4l-0.601-3.8l-4.8-1.4l-0.4-3.4l-8.899-28.8l-5.8-18    l1.699-0.1l1.9,0.5v-3.1l1.6-5.5l3.2-5.8l1.8-4.9l-2.3-3.1v-7.4l1-1.2l1-3.4l-0.2-1.8l-0.199-6l2.1-5.9l3.7-10.9l2.6-5.2h1.601    l1.6,0.2v1.4l1.6,2.8l3.4,0.8l1-1v-1.2l4.9-3.6l2.199-2.2l1.801,0.2l7.399,3l2.4,1.2l11.1,36.8h7.4l1,2.4l0.2,6l3.6,2.8h1l0.2-0.6    l-0.601-1.4L1129,105.2z M1103.3,142.3l1.9-1.9l1.7,1.3l0.699,3l-2.1,1.1L1103.3,142.3z M1111.5,135.1l2.2,2.3    c0,0,1.6,0.1,1.6-0.3s0.3-2.5,0.3-2.5l1.101-1l-1-2.2l-2.5,0.9L1111.5,135.1z"></path>
                <path id="RI" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M1069,228.3l-4.6-18.4l7.699-2.3l2.7,2.4l4.101,5.3l3.3,5.4    l-3.7,2l-1.6-0.2l-1.4,2.3l-3,2.4L1069,228.3z"></path>
                <path id="NY" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M1015.3,240.4l-1.399-1.2l-3.2-0.2l-2.8-2.4l-2-7.5    l-4.301,0.1l-3-3.3l-23.8,5.4L922,242l-9.3,1.5l-0.9-8l1.8-1.4l1.601-1.4l1.2-2l2.199-1.4l2.4-2.2l0.6-2l2.601-3.4l1.399-1.2    l-0.199-1.2l-1.601-3.8l-2.2-0.2l-2.399-7.6l3.6-2.2l5.4-1.8l4.899-1.6l4-0.6l7.7-0.2l2.4,1.6l2,0.2l2.6-1.6l3.2-1.4l6.4-0.6    l2.6-2.2l2.2-4l2-2.4h2.6l2.4-1.4l0.2-2.8l-1.801-2.6l-0.399-1.8l1.399-2.6v-1.8H970.4l-2.2-1l-1-1.4l-0.2-3.2l7.2-6.8l0.8-1    l1.8-3.6l3.601-5.6l3.399-4.6l2.601-3l3-2.3l3.8-1.5l6.8-1.6l4,0.2l5.6-1.8l9.301-2.5l0.6,6.1l3,8l1,6.4l-1.2,4.8l3.2,5.6l1,2.6    l-1,3.6l3.6,1.6l0.801,0.4l3.8,13.5l-0.7,6.2l-0.6,13.3l1,6.8l1,4.4l1.8,9v9.9l-1.4,2.8l2.3,2.5l1,2.1l-2.399,2.2l0.399,1.6    l1.601-0.4l1.8-1.6l2.8-3.2l1.4-0.8l2,0.8l2.8,0.2l9.7-4.8l3.6-3.4l1.601-1.8l5.199,2l-4.199,4.4l-4.7,3.5l-8.7,6.6l-3.199,1.2    l-7.2,2.4l-4.9,1.4l-1.399-0.7l-0.301-4.5l0.601-3.4l-0.2-2.6l-3.5-2.1l-5.6-1.2l-4.801-1.4L1015.3,240.4z"></path>
                <path id="PA" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M1008.8,284.6l1.601-0.3l2.899-1.5l1.5-3.1l2-2.8l4-3.8v-1    l-3-2l-4.399-3l-1.2-3.2l-3.4-0.4l-0.2-1.4l-1-3.4l2.801-1.4l0.199-3l-1.6-1.6l0.2-2l2.399-3.8v-3.8l3.301-3.3l-1.101-0.8    l-3.1-0.2l-2.8-2.4l-1.9-7.5l-4.3,0.1l-3-3.3l-22.2,5.2l-52.9,10.7l-10.899,1.8l-0.8-8l-6.601,6.2l-1.6,0.6l-5.2,3.7l3.6,23.5    l3.101,12l4.399,23.7l4-0.8l14.7-1.8l46.7-9.4l18.3-3.5l10.2-2l0.3-0.3l2.601-2L1008.8,284.6z"></path>
                <path id="NJ" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M1014.4,240l-2.9,3.4v3.8l-2.4,3.8l-0.199,2l1.6,1.6l-0.2,3    l-2.8,1.4l1,3.4l0.2,1.4l3.399,0.4l1.2,3.2l4.4,3l3,2v1l-3.7,3.3l-2,2.8l-1.8,3.4l-2.8,1.6l-0.601,1.9l-0.3,1.5l-0.8,3.2l1.3,2.8    l4,3.6l6,2.8l4.9,0.8l0.199,1.8l-1,1.2l0.4,3.4h1l2.6-3l1-6l3.4-4.9l3.8-8l1.4-6.8l-0.8-1.4l-0.2-11.5l-2-4.2l-1.4,1l-3.399,0.4    l-0.601-0.6l1.4-1.2l2.6-2.4l0.101-1.3l-0.5-4.2l0.699-3.4l-0.1-2.5l-3.5-2.2l-6.3-1.5l-5.101-1.7L1014.4,240z"></path>
                <path id="DE" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M1009.5,289l0.5-2.6l0.5-2.1l-2,0.5l-2,0.6l-2.7,2.2    l2.101,6.1l2.8,7l2.6,11.9l2,7.7l6.2-0.2l7.6-1.5l-2.8-9.1l-1.2,0.6l-4.3-2.9l-2.2-5.8l-2.399-4.4l-3.9-3.5l-1.1-2.6L1009.5,289z"></path>
                <path id="MD" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M1026.9,318.7l-7.4,1.5l-6.3,0.1l-2.3-8.5l-2.4-11.3    l-3.2-7.6l-1.6-5.4l-9.2,2l-18.3,3.5l-46.101,9.3l1.4,6.1l1.2,7l0.399-0.4l2.601-3l2.8-3.2l3-0.8l1.8-1.8l2.2-3.2l1.6,0.8    l3.601-0.4l3.2-2.6l2.5-1.8l2.3-0.6l2,1.4l3.6,1.8l2.4,2.2l1.5,1.9l5.1,2.1v3.6l6.8,1.6l1.4,0.7l1.7-2.5l3.5,2.5l-1.601,3    l-0.899,4.9l-2.2,3.2v2.6l0.8,2.2l6.2,1.7l5.3-0.1l3.8,1.2l2.601,0.4l1.2-2.6l-1.801-2.6v-2.2l-3-2.6l-2.6-6.8l1.6-6.6l-0.199-2.6    l-1.601-1.6c0,0,1.8-2,1.8-2.8c0.101-0.9,0.301-1.7,0.601-2.6l2.399-1.6l2.4-2l0.6,1.2l-1.8,2l-1.6,4.6l0.399,1.4l2.2,0.4    l0.601,6.8l-2.601,1.2l0.4,4.4l0.6-0.2l1.4-2.4l2,2.2l-2,1.4l-0.4,4.2l3.2,4.2l4.8,0.6l2-1l4,5.1l1.7,0.7l8.2-3.4l2.5-4.9    L1026.9,318.7z M1007.2,329.8l1.399,3.1l0.2,2.2l1.4,2.3c0,0,1.1-1.1,1.1-1.5s-0.899-3.8-0.899-3.8l-0.9-2.9L1007.2,329.8z"></path>
                <path id="WV" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M930.2,302.2l1.399,6.1l1.301,7.4l2.6-3.2l2.8-3.8    l3.101-0.8l1.8-1.8l2.2-3.2l1.8,0.8l3.6-0.4l3.2-2.6l2.5-1.8l2.3-0.6l1.601,1.2l4.5,2.2l2.399,2.2l1.7,1.6l-0.9,6.8l-7.199-3.1    l-5.2-2l-0.101,6.4l-3.399,6.8l-3.101,3l-1.5,3.4l-3.199,0.6l-1.101,4.4l-1.2,4.9l-4.899,0.4l-2.9-3l-1.3,0.7l-0.8,6.7l-1.7,4.3    l-6.2,13.5l1.1,1.4l-0.3,2.3l-3.5,4.8l-2.2-0.7l-3.699,2.7l-3.101-0.7l-2.5,5.601c-1.5,0.7-3.2,1.3-4.8,1.7c-0.2,0-3-1.5-3-1.5    l-2.9,1.699l-3,1.2l-4.6-1.1l-1.4-1.4l-2.699-3.7l-3.9-2.5l-2-4.5l-5.3-4.3l-0.8-2.8l-3.2-1.8l-1-2l-0.3-6.5l2.699-0.1l2.4-1    l0.2-3.4l2-1.8l0.2-6.2l1.2-4.8l1.6-0.8l1.6,1.4l0.601,2.2l2.199-1.2l0.601-2l-1.4-2.2v-3l1.2-1.6l2.8-4.2l1.601-1.8l2.6,0.6    l2.8-2l3.8-4.2l2.8-4.8l0.4-7l0.6-6.2v-5.8l-1.399-3.8l1.2-1.8l1.6-1.6l4.3,24.4l5.7-0.9L930.2,302.2z"></path>
                <path id="OH" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M898.4,246l-7.5,5l-4.801,2.8l-4.1,4.6l-4.9,4.8l-4,1    l-3.6,0.6l-6.8,3.2l-2.601,0.2l-4.199-3.8l-6.4,0.8l-3.3-1.8l-2.9-1.7l-6,0.9l-12.5,2l-13.8,2.7l1.6,18l2.2,16.9L818,331l0.7,5.9    l5.1-0.2l3-1l4.101,1.8l2.5,5.4h6.3l2.3,2.6l2.2-0.1l3.1-1.6l3.101,0.5l6.699,0.6l2.101-2.6l2.899-1.6l2.5-0.8l0.801,3.4    l2.199,1.2l4.301,2.9l2.699-0.1l1.601-0.6l0.2-3.4l2-1.8l0.1-5.9l1.2-5.1l1.6-0.7l1.601,1.4l0.699,2.1l2.101-1.2l0.5-1.8l-1.4-2.3    l0.1-2.8l0.9-1.3l2.6-4.1l1.301-1.9l2.6,0.6l2.8-2l3.8-4.2l3.4-5l0.4-6.2l0.6-6.1l-0.2-6.5l-1.2-3.6l0.4-1.5l2.2-2.2l-2.8-11.1    L898.4,246z"></path>
                <path id="IN" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M756,377.2l0.1-3.5l0.601-5.6l2.8-3.6l2.2-4.8l3.2-5.2    l-0.601-7.2l-2.2-3.4l-0.399-4l1-6.8l-0.601-8.6l-1.6-19.7l-1.6-18.8l-1.2-14.4l3.8,1.1l1.8,1.2l1.4-0.4l2.6-2.4l3.5-2l6.3-0.2    l27.101-2.8l6.899-0.7l1.801,19.7l5.199,45.3l0.7,7.1l-0.5,2.8l1.5,2.2l0.101,1.7l-3.101,2l-4.399,1.9l-3.9,0.7l-0.7,6l-5.7,3.9    l-3.399,4.9l0.399,2.9l-0.699,1.9H794.3l-2-2l-3.1,1.5l-3.3,1.8l0.199,3.8l-1.5,0.3L784,373l-2.7-1.8l-3.899,1.7l-1.9,3.699    l-1.8-1l-1.8-2l-5.5,0.601l-6.9,1.2L756,377.2z"></path>
                <path id="CT" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M1069,228.2l-4.5-18.3l-5.8,1.1l-26.101,5.8l1.2,4l1.8,8.9    l0.2,11.1l-1.5,2.7l2.4,2.4l5.3-4.8l4.4-4l2.399-2.6l1,0.8l3.4-1.8l6.399-1.4L1069,228.2z"></path>
                <path id="WI" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M750.5,251l-0.1-3.9l-1.5-5.6l-0.801-7.6l-1.399-3l1.2-3.8    l1-3.6l1.8-3.2l-0.8-4.2l-0.801-4.4l0.601-2.2l2.399-3l0.2-3.4l-1-1.6l0.8-3.2l-0.5-5.1l3.4-7l3.6-8.4l0.2-2.8l-0.399-1.2l-1,0.6    l-5.2,7.7l-3.4,4.9l-2.399,2.2l-1,2.8l-2.5,1l-1.4,2.4l-1.8-0.4l-0.2-2.2l1.6-3l2.601-5.8l2.2-2l1.199-2.9l-3.1-2.3l-2.5-12.8    l-4.4-1.6l-2.399-2.8l-14.9-3.3l-3.5-1.2l-10.1-2.7l-9.7-1.4l-4.6-6.3l-0.9,0.7l-1.5-0.2l-0.8-1.4l-1.601,0.4l-1.399,0.2l-2.2,1.2    l-1.2-0.8l0.8-2.4l2.4-3.8l1.4-1.4l-2.4-1.8l-2.6,1l-3.601,2.4l-9.1,4l-3.601,0.8L662,143l-1.2-1.1l-2.6,3.5l-0.3,3.4v10.4l-1.4,2    L650,166l-2.8,7.3l0.6,0.3l3.101,2.5l0.8,3.9l-2.2,3.9v4.8l0.6,8.2l3.7,3.7h4.2l2.3,3.9l4.2,0.6l4.7,7l8.7,5.1l2.5,3.4l1.1,9.1    l0.8,4.1l2.8,2l0.301,1.7l-2.601,4.2l0.3,3.9l3.101,4.8l3.1,1.4l3.7,0.6l1.6,1.7L750.5,251z"></path>
                <path id="MA" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M1100.5,221.3l2.7-0.8l0.6-2.1l1.2,0.1l1.2,2.8l-1.5,0.6    l-4.801,0.1L1100.5,221.3z M1088.9,222.3l2.8-3.2h2l2.2,1.8l-3,1.2l-2.7,1.2L1088.9,222.3z M1046.1,195.3l21.7-5.7l2.8-0.8    l2.301-3.4l4.6-2l3.6,5.4l-3,6.4l-0.399,1.8l2.399,3.2l1.4-1h2.2l2.8,3.2l4.8,7.4l4.4,0.6l2.8-1.2l2.2-2.2l-1-3.4l-2.601-2l-1.8,1    l-1.2-1.6l0.601-0.6l2.6-0.2l2.2,1l2.4,3l1.199,3.6l0.4,3l-5.2,1.9l-4.8,2.4l-4.8,5.5l-2.4,1.8v-1.2l3-1.8l0.601-2.2l-1-3.8    l-3.601,1.8l-1,1.8l0.601,2.8l-2.5,1.2l-3.4-5.6l-4.2-5.4l-2.5-2.2l-8,2.4l-6.3,1.2l-25.4,5.6l-0.8-5.9l0.8-13l5.301-1.1    L1046.1,195.3z"></path>
                <path id="TN" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M850.9,399.7l-63.801,6.1L767.7,408l-5.7,0.6h-4.8l-0.3,5    L746.8,413.9l-8.6,0.8l-10-0.101l-1.7,8.7l-2,6.8l-4,3.4l-1.7,5.4L718.4,442.1l-5,2.801l1.8,4.399l-1.2,5.4l-1.2,1l133-12.8    l0.5-4.9l2.2-1.8l3.5-0.9l0.8-4.6l5-3.3l4.9-1.801l5-4.399l5.5-2.5l0.6-3.8l5-4.9l0.7-0.1c0,0,0,1.399,1,1.399    c0.8,0,1.6,0.2,2.4,0.4l2.8-4.4l2.5-0.8l2.8,0.4l2-4.301l3.7-3.199l0.5-2.4l0.399-4.6l-2.6-0.2l-3.2,2.5h-8.6L860.6,397.6    L850.9,399.7z"></path>
                <path id="MO" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M680.8,313.4l-3.1-3.8l-1.4-2.8l-79.2,3l-2.8,0.1l1.5,3.1    l-0.3,2.8l3.1,4.8l3.801,5.1l3.8,3.4l2.6,0.3l1.8,1.1v3.7l-2.3,2l-0.6,2.8l2.5,4.2l3.1,3.7l3.101,2.2l1.699,14.3l0.4,44.4l0.3,5.8    l0.601,6.601L647,419.1l28.5-0.8l25.6-1l14.3-0.3l2.7,4.2l-0.8,4.1l-3.8,3l-0.7,2.3l6.6,0.601l4.801-0.8l2.1-6.801l0.8-7.199    l2.601-3.101l3.199-1.8l0.101-3.8l1.2-2.4l-2.101-3.1l-1.6,1.2l-2.5-2.7l-1.601-5.899l1-3.101L725,387.5l-2.3-5.6l-5.9-1l-8.6-6.9    l-2-5.1l1-3.9l2.5-7.5l0.6-3.5l-2.4-1.2l-8.399-1l-1.2-2.1l-0.1-5.2l-6.801-4.2l-8.6-9.6l-2.8-9l-0.3-5.2L680.8,313.4z"></path>
                <path id="KY" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M886.9,371.4l-2.801,3L879.7,379.3L873.6,386l-1.5,2.1    L872,390.7l-5.4,2.7l-7,4.199l-8.899,2.2l-63.8,6l-19.4,2.2l-5.7,0.6H757l-0.3,5.2L746.6,414l-8.6,0.8l-9.8-0.1l1.5-1.601    l3.1-1.899l0.3-3.9l1.101-2.3l-1.9-3.2l1-2.3l2.8-2.2l2.601-0.8l3.399,1.6l4.4,1.601l1.4-0.4l0.199-2.8l-1.6-3l0.4-2.8l2.399-1.8    l3.2-0.801l2-0.799l-1-2.201l-0.8-2.399l1.9-1.2c0,0,1.5-4.3,1.5-4.5l3.8-1.8l6.5-1.2l5.5-0.6l1.7,2l1.899,1.1l2-3.8l3.9-1.601    l2.7,1.801l0.5,1.199l1.399-0.299l-0.2-3.701l3.9-2.199l2.6-1.3l1.9,2h4.1l0.7-1.9l-0.5-2.8l3.2-4.9l5.9-4.2l0.899-6l3.601-0.6    l4.7-2l3-2.1l-0.2-1.9l-1.4-1.8l0.7-3.7l5.1-0.1l2.8-0.9l4.101,1.8l2.5,5.4h6.3l2.5,2.7l2-0.2l3.2-1.6l6.399,0.7l3.2,0.3    l2.101-2.5l3.199-1.8l2.301-0.9l0.8,3.5l2.5,1.3l3.2,2.6l0.1,7l1,1.9l3.2,1.9l0.899,2.8l5.101,4.2l2.2,4.5L886.9,371.4z"></path>
                <path id="IA" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M694.1,253.7l0.301,3.4l2.699,0.7l1.2,1.5l0.601,2.3    l4.699,4.1l0.801,2.9l-0.801,4.2l-1.899,4l-1,3.4l-2.7,2l-2.1,0.7l-6.9,2.3l-1.7,4.7l0.9,1.7l2.3,2.1l-0.3,4.9l-2.2,1.9l-0.9,2    l0.2,3.4l-2.3,0.6l-2,1.4l-0.3,1.7l0.3,2.6l-1.9,1.4l-3.1-3.8l-1.5-3l-80.9,3.1l-1.1,0.2l-2.5-5.6l-0.3-8.2l-2-5.1l-0.8-6.5    l-2.801-4.5l-1.1-5.9l-3.4-9.3l-1.399-6.6l-1.7-2.7l-2-3.4l2.5-6l1.7-7l-3.4-2.5l-0.6-3.4l1.1-3.1h2.101l101.699-1.6l1,5.1    l2.801,1.9l0.3,1.7l-2.5,4.2l0.2,3.9l3.1,4.7l3.1,1.6l3.8,0.6L694.1,253.7z"></path>
                <path id="MN" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M578.5,166.7l-0.6-10.4l-2.2-9.1l-2.3-16.6l-0.601-12.1    l-2.3-4.2l-2-6.1V95.6l0.8-4.8l-2.2-6.7H604.2L604.6,74l0.801-0.2l2.8,0.6l2.399,1l1,6.8l1.801,7.6l2,2h6l0.399,1.8l7.7,0.4v2.6h6    l0.4-1.6l1.399-1.4l2.8-0.8L641.7,94h3.6l4.8,3.2l6.601,3l3,0.6l0.6-1.2l1.8-0.6l0.601,3.6l3.2,1.6l0.6-0.6l1.6,0.2v2.6l3.2,1.2    h3.8l2-1l4-4l3.2-0.6l1,2.2l0.601,1.6h1.199l1.2-1l10.9-0.4l2.2,3.8h0.8l0.899-1.3l5.5-0.5l-0.699,2.8l-4.801,2.3l-11.399,5    l-5.9,2.5l-3.8,3.2l-3,4.4l-2.8,4.8l-2.2,1l-5.6,6.1l-1.601,0.2l-5.3,3.4l-3.1,3.9l-0.301,3.9l0.101,9.8l-1.7,2.1l-6.2,4.6    l-2.7,7.4l3.5,2.7l0.801,4l-2.301,4l0.2,4.6l0.5,8.3l3.7,3.7h4.1l2.301,3.8l4.199,0.6l4.7,7l8.8,5l2.601,3.5l0.8,7.9l-99.9,1.4    l-0.399-43.9l-0.601-3.7l-5.1-4.2l-1.4-2.3v-1.9l2.5-2l1.7-1.7L578.5,166.7z"></path>
                <path id="NE" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M591.9,304.3l4,8.6l-0.2,2.8l4.3,6.8l3.3,3.9h-6.2    l-53.5-1.2l-50.199-1.1l-27.4-1l1.3-26.2l-39.8-3.6l5.3-54.1l19.101,1.2l24.699,1.5l21.9,1.4l29.2,1.4l13.2-0.6l2.5,2.8l5.899,3.7    l1.4,1.1l5.3-1.7l4.8-0.6l3.4-0.3l2.2,1.7l5,2l3.699,2l0.601,2l1.1,2.5h2.3h1l1.101,5.8l3.6,10.4l0.7,4.6l3.1,4.6l0.7,6.3l2,5.2    L591.9,304.3z"></path>
                <path id="SD" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M580,259.2v-0.7l-3.6-6l2.3-5.8l1.8-7.2l-3.4-2.6l-0.5-3.4    l1-3.1h3.9l-0.1-6.1l-0.4-37.1l-0.8-4.6l-5-4.1l-1.2-2.1l-0.1-2l2.5-1.9l1.899-2.1l0.3-3.3l-71.699-1.9l-67.4-4.2l-6.6,78.3    l17.899,1.1l24.601,1.5l21.8,1.1l29.3,1.6l14.8-0.5l2.5,2.8l6.4,4l0.899,0.9l5.601-1.8l8-0.8l2.1,1.6l5.2,2l3.6,2l0.5,1.8l1.2,2.8    L580,259.2z"></path>
                <path id="ND" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M578.6,166.8l-0.8-10.4l-2.1-8.4l-2.3-16l-0.601-13.5    l-2.1-3.8l-2.2-6.4V95.5l0.8-4.7l-2.2-6.8L531.9,83.3L509,82.5L476.4,81l-28.2-2.6L439.6,161l67.601,4.1L578.6,166.8z"></path>
                <path id="WY" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M437.2,184.5l-131.3-16.6l-17.3,108.8l139.3,16.7    L437.2,184.5z"></path>
                <path id="MT" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M448.1,78.4L410.4,74.9l-36-4.4l-36-4.9L298.6,59L276,54.8    l-40.2-8.6l-5.5,26.3l4.2,9.3l-1.7,5.6L235,93l3.9,1.7l5.7,13.2l3.3,3.9l0.6,1.4l4.2,1.4l0.6,2.5l-8.7,21.6v3.1l3.1,3.9h1.1    l5.9-3.7l0.8-1.4l2,0.8l-0.3,6.5l3.4,15.5l3.7,3.1l1.1,0.8l2.3,2.8l-0.6,4.2l0.8,4.2l1.4,1.1l2.8-2.8h3.4l3.9,2l3.1-1.1h5.1l4.5,2    l3.4-0.6l0.6-3.7l3.7-0.8l1.7,1.7l0.6,3.9l1.8,1l2.3-13.5l131.3,16.5L448.1,78.4z"></path>
                <path id="CO" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M461.4,403.1l6-106.2L328,281.3l-15,108.2L461.4,403.1z"></path>
                <path id="ID" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M176.6,225.3l10.8-43.3l1.7-5.2l3.1-7.3l-1.5-2.8l-3.1,0.1    l-1-1.2l0.6-1.4l0.4-3.8l5.5-6.8l2.3-0.6l1.4-1.4l0.7-3.9l1.1-0.8l4.8-7.2l4.8-5.3l0.3-4.6l-4.2-3.2l-1.9-5.4l16.6-78l16.6,3.1    l-5.4,26.3l4.4,9.2l-2,5.7l2.5,5.7l3.9,1.5l4.7,11.8l4.3,5.5l0.6,1.4l4.1,1.4l0.5,2.6l-8.6,21.4l-0.2,3.2l3.2,4.1h1.1l6-3.7    l0.8-1.3l1.9,0.8l-0.3,6.6l3.4,15.5l4.8,3.9l2.1,2.7l-0.9,5l1.3,3.5l1.3,1.3l3.1-2.9h3.5l3.6,1.6l3.4-0.8l4.7-0.2l4.9,2l3.4-0.4    l0.6-3.7l3.6-0.9l1.5,1.9l0.5,3.6l1.7,1.5L293.3,248C293.4,247.8,185.2,227.2,176.6,225.3z"></path>
                <path id="UT" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M313.2,389.7l-103-14.601L235.5,236.7l57.5,10.8l-1.8,13.1    l-2.8,16.2l9.6,1.1l20.2,2.2l10.1,1.1L313.2,389.7z"></path>
                <path id="NV" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M235.5,236.5l-29.1,158.4l-2.3,0.399l-1.9,3h-2.9    l-1.8-3.399l-3.2-0.5l-0.9-1.4l-1.2-0.1l-3.4,2l-0.4,8.399L188,410.4l-0.4,10.6l-1.8,2.6l-3-1.3l-85-128.2l23.4-83.1L235.5,236.5z    "></path>
                <path id="OR" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M176.9,224.1l10.9-42.8l1.3-5.2l2.9-6.9l-0.8-1.4h-3.1    l-1.6-2.1l0.6-1.8l0.6-4l5.5-6.8l2.3-1.4l1.4-1.4l1.8-4.4l4.9-7l4.4-4.7l0.3-4.2l-4-3l-2.2-5.7l-15.6-4.4l-18.6-4.4l-19,0.1    l-0.6-1.7l-6.8,2.5l-5.4-0.7l-3-2l-1.6,0.8l-5.8-0.3l-2.1-1.7l-6.5-2.5l-1,0.1l-5.3-1.9l-2.4,2.3l-7.6-0.4l-7.3-5l0.8-1l0.2-9.6    L92,86.9l-5-0.7l-0.8-3.1l-2.9-0.6L76.1,85l-2.8,8l-4,12.3l-3.9,8l-6.1,17.3l-8,16.7l-9.9,15.5l-2.4,3.6l-1,10.5l0.5,14.9    L176.9,224.1z"></path>
                <path id="WA" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M119.5,17.6l5.4,1.8l11.9,3.4l10.5,2.4l24.6,7l28.3,7    L219,43l-16.8,78.2l-15.3-4.3l-19.1-4.4h-18.7l-0.6-1.6l-6.9,2.7l-5.7-0.9l-2.6-1.9l-1.6,0.9l-5.8-0.2l-2.1-1.7l-6.5-2.6l-0.9,0.2    l-5.4-1.9l-2.3,2.2l-7.7-0.4l-7.3-5.1l1-1.1l0.1-9.4L92,86.9l-5-0.8L86.2,83l-2.8-0.6L79,84l-2.8-4l0.4-3.6L80,76l2-4.9l-3.2-1.4    l0.2-4.6l5.4-0.8L80.9,61l-1.8-8.7l0.8-3.6v-9.8l-2.2-4l2.8-11.5l2.6,0.6l3,3.6l3.4,3.2l4,2.4l5.6,2.6l3.8,0.8l3.6,1.8l4.2,1.2    l2.8-0.2v-3l1.6-1.4l2.6-1.6l0.4,1.4l0.4,2.2l-2.8,0.6l-0.4,2.6l2.2,1.8l1.4,3l0.8,2.4l1.8-0.2l0.2-1.6l-1.2-1.6l-0.6-4l1-2.2    l-0.8-1.8v-2.8l2.2-4.4l-1.4-3.2l-3-6l0.4-1L119.5,17.6z M107.9,25l2.5-0.2l0.6,1.7l1.9-2h2.9l1,1.9l-1.9,2.1l0.8,1l-0.9,2.5    l-1.7,0.5c0,0-1.1,0.1-1.1-0.3c0.5-1.1,1.1-2.2,1.8-3.2l-2.1-0.7l-0.4,1.8l-0.9,0.8l-1.9-2.8L107.9,25z"></path>
                <path id="DC" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M985.1,316.8l-2.199-2.3l-1.5-0.8l1.8-2.5l3.6,2.4 L985.1,316.8z">
                </path>
                <path id="FL" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M928.5,549.4l2.8,9l4.601,12l6.6,11.5l4.6,7.699     l6,6.801l4.9,4.6l2,3.6l-1.4,1.601l-1,1.6l3.601,9.2l3.6,3.6l3.2,6.601l4.4,7.2l5.6,10.1l1.6,9.3l0.601,14.8l0.8,2.2l-0.4,4.1     l-3,1.601l0.4,2.399l-0.8,2.4l0.399,3l0.601,2.4l-3.4,4l-3.8,1.8l-4.8,0.2l-1.801,2l-3,1.2l-1.6-0.601l-1.4-1.2L958,690.5l-1-4.2     l-4.2-6.399l-4.4-2.801l-4.8-0.399l-1,1.6l-3.8-5.4L938,668.5l-3.2-4.9l-2.2-1.399l-2,2.6l-2.2-0.4l-2.6-6.2l-3.6-4.8l-3.601-6.6     l-3.2-3.9L911,638.3l-0.4-4l-2.2-4.9l-1.6-6l-0.4-3.399l1.801-5.801V602.5l-3.801-4.601l-1.6-3.8l-6.3-1.6l-2.4-0.8l-2-3.2     l-4.2-2l-1.399-4.2l-3.4-1.2l-3-4.6l-5.2-1.8l-3.6-1.801h-3.2l-4.9,1L863,576.3l1,1.2l-0.601,1.399l-3.8-0.199L855,583.1     l-4.4,2.4h-4.8l-4,1.6l-0.4-3.399l-2-2.4l-3.6-1.4l-2-1.8l-9.9-4.8l-9.3-2.2l-5.4,0.8L801.8,572.5l-7.4,2.6l-4.3,0.8l-0.3-9.8     l-3.2-2.4l-2.2-2.199l0.4-3.801l12.5-1.6l31.399-3.6l8.5-0.9l6.7,0.3l3.2,4.8l1.8,1.801l10,0.6l13.3-0.8l26.5-1.601l6.7-0.8     l6.3,0.2l0.5,3.6l2.7,1l0.3-5.699l-1.899-5.101l1.6-1.8l6.8,0.6L928.5,549.4z M944,712.3l3-0.8l1.6-0.3l1.801-2.9l2.899-2     l1.601,0.601l2.1,0.399l0.5,1.3l-4.3,1.5l-5.101,1.7l-2.899,1.5L944,712.3z M960.6,706.1l1.5,1.301l3.4-2.601l6.6-5.2l4.601-4.8     l3.1-8.2l1.2-2.1l0.2-4.2l-0.9,0.601l-1.2,3.5l-1.8,5.699l-4,6.5l-5.399,5.2l-4.2,2.4L960.6,706.1z">
                </path>
                <path id="IL" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M756,377.7v-4l0.7-5.7l2.899-3.6l2.301-5     l2.699-4.9l-0.5-6.5l-2.5-4.4l-0.1-4.1l0.8-6.5l-1-8.8l-1.3-19.4l-1.6-18.5L757.3,272l-0.3-1.1l-1-3.2l-1.6-4.6l-2-2.2l-1.8-3.2     l-0.301-6.8l-56.3,3.2l0.3,2.9l2.801,0.8l1.1,1.4l0.6,2.3l4.8,4.2l0.801,2.8l-0.801,4.2l-2.199,4.5l-0.801,3.1l-2.8,2.2l-2.2,0.8     l-6.5,1.7l-0.8,2.3l-0.8,2.5l0.8,1.7l2.3,2l-0.3,5.1l-2.2,2l-0.8,2v3.4l-2.2,0.6l-2,1.4l-0.3,1.7l0.3,2.5l-2.1,1.6l-1.2,3.4     l0.601,4.5l2.8,9l9,9.3l6.8,4.5l-0.3,5.3l1.1,1.7l7.9,0.6l3.399,1.7l-0.8,4.5l-2.8,7.3l-0.8,3.9l2.8,4.8l7.9,6.5l5.6,0.8l2.5,6.2     l2.5,3.9l-1.1,3.7l2,5.1l2.2,2.5l1.699-1.1l1.101-2.5l2.7-2.2l2.6-0.8l3.2,1.5l4.5,1.699l1.5-0.399l0.2-2.8l-1.601-3l0.4-2.9     l2.3-1.7l3.7-1l1.5-0.6l-0.8-1.7l-1-2.9l1.8-1.199L756,377.7z">
                </path>
                <path id="VA" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M1016.9,336.5l-0.2-2.4l7.899-3.1l-0.899,4     l-3.601,4.7l-0.5,5.6l0.601,4.2l-2.3,6.1l-2.7,2.4l-1.8-5.7l0.6-6.7l2-5.1L1016.9,336.5z M1021,371.3l-71.6,15.5l-46,6.4     l-8.2-0.5L892,395.1l-9,0.301l-10.3,1.199l-13.4,2l12.9-6.899v-2.5l1.899-2.601l13-14.1l4.9,5.5l4.6,1.2l3.101-1.4l2.8-1.6     l3.1,1.6l4.9-1.8l2.3-5.6l3.2,0.699l3.5-2.6l2.2,0.6l3.5-4.5l0.399-2.6l-1.199-1.6l1.199-2.3l6.5-15.1l0.801-7.1l1.5-0.6l2.699,3     l4.801-0.4l2.399-9.3l3.4-0.7l1.2-3.4l3.199-2.9l3.4-7l0.1-6.2l12.101,4.7c0.8,0.4,1-6.1,1-6.1l4.5,2l0.1,3.6l7.101,1.6l2.6,1.5     l2,2.5l-0.8,4.5l-2.4,3.2l0.101,2.5l0.699,2.3l6.101,1.6h5.5l3.8,1.2l2.4,0.4l0.899,3.8l3.9,0.5l1.1,1.5l-0.5,5.8l1.7,1.4     l-0.6,2.4l1.5,1l-0.301,1.7l-3.3-0.1l0.101,2l2.8,1.9l0.1,1.7l2.2,2.2l0.6,3.1l-3.1,1.6l1.9,1.8l7.1-2.1L1021,371.3z">
                </path>
                <path id="AR" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M724.4,431.2l-4.9,0.899l-6.3-0.8l0.5-2l3.7-3.2     l1.199-4.5l-2.3-3.699l-96.5,3.1l2,8.4v10.1l1.7,13.5l0.3,46.5l2.8,2.4l3.7-1.7l3.4,1.399l0.8,8.101l68.4-1.4l1.399-2.6     l-0.399-4.4l-2.301-3.7l2-1.8l-2-3.1l0.801-3.101l1.699-6.899l3.101-2.5l-0.8-2.8l4.5-6.601l3.399-1.7l-0.1-1.8l-0.4-2.2     l3.5-6.899l3-1.5l0.5-4.2l2.2-1.5l-3.9-0.6l-1.6-4.9l3.4-2.9l0.7-2.5l1.6-4.899L724.4,431.2z">
                </path>
                <path id="SC" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M934.8,511.3l-2.2,1.2l-3.199-1.6L928.6,508.3     L927,503.9l-2.8-2.601l-3.2-0.8l-2-6l-3.4-7.4L910.4,484.7l-2.601-2.4l-1.6-3.1l-2.601-2.4l-2.8-1.6L898,471.6l-3.8-2.8     l-5.601-2.2l-0.6-1.8l-3-3.6l-0.6-1.8l-4.2-6.4l-4.2,0.2l-4.9-3l-1.6-1.601l-0.4-2.199l1-2.4l2.801-1.2L872.3,440l7.101-2.9     l11.199-5.6l9.601-1L920,430l3.2,2.3l2.1,4.101l5.3-0.801l15.5-1.8l3.601,1l15.5,9.3l12.399,10l-6.699,6.7l-3.2,7.601     L967.1,476.1l-2,1l-1.399,3.4l-3,0.8l-2.601,4.4L954.7,489.1l-2.8,4.2l-2,1l-4.5,4.101L941.8,498.6l1.2,4l-6.1,6.801L934.8,511.3 z">
                </path>
                <path id="AL" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M770.5,575.6l-1.8-17.6l-3.4-23.1l0.2-17.301     l1-38.1l-0.2-20.5l0.101-7.9l54.699-4.5l-0.199,2.7l0.199,2.601l0.801,4.199l4.199,9.7l3,12.101l1.801,7.6l2,6l1.8,8.6l2.6,7.7     l3.2,4.2l0.6,4.2l2.4,1l0.2,2.6l-2.101,5.9l-0.6,4l-0.2,2.4l2,5.399l0.4,6.601l-1,3l0.8,1l1.8,1l0.4,3.6l-6.9-0.4l-8.399,0.8     l-31.4,3.601l-12.8,1.7l-0.3,3.5l2.199,2.199l3.2,2.4l0.7,9.8l-6.8,3.2l-3.4-0.4l3.4-2.399v-1.2l-3.8-7.4l-2.801-0.8l-1.8,5.5     l-1.6,3.4l-0.8-0.2h-3.4V575.6z">
                </path>
                <path id="LA" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M741.8,574l-4-3.9l1.2-6.8l-0.8-1.1l-11.4,1.2     l-31.7,0.6l-0.8-2.9l1.101-10.5l4.1-7.3l6.1-10.7l-0.699-3l1.5-0.8l0.6-2.5l-2.8-2.5L705.1,521.4l-2.3-5.301l-0.2-7.8     L634.4,509.4v11.8l0.8,11.5l0.8,4.8l3.1,5.1l1.101,6.101l5.3,6.8l0.3,3.9l0.8,0.8l-0.8,10.399l-3.7,6.101l2,2.5l-0.7,3.2l-0.8,9     l-1.7,3.899l0.101,4.5l5.8-1.899l14.9,0.3l12.699,4.399l8,1.4l4.601-1.8l4,1.399l4,1.2l1-2.6l-4-1.4l-3.2,0.601l-3.4-2     c0,0,0.2-1.601,1-1.801c0.8-0.199,3.8-1.199,3.8-1.199l2.2,1.8l2.2-1.2l4,0.8l1.8,3l0.4,2.8l5.6,0.4l2.2,2.2l-1,2l-1.6,1l2,2     l10.3,4.399l4.4-1.6l1.199-3l3.2-0.8l2.2-1.8l1.6,1.199l1,3.601l-2.8,1l0.8,0.8l4.2-1.6l2.8-4.2l1-0.601l-2.6-0.399l1-2l-0.2-1.8     l2.601-0.601l1.399-1.6l0.8,1c0,0-0.199,3.8,0.801,3.8s5.199,0.8,5.199,0.8l4.9,2.4l1.2,1.8h3.6l1.4,1.2l2.8-3.8v-1.8H759     l-4.2-3.4l-7.2-1l-4-2.8l1.4-3.4l2.8,0.4l0.2-0.8l-2.2-1.2V594.8h4l2.2-3.8l-1.6-2.399l-0.4-3.4l-1.8,0.2l-2.4,2.6l-0.8,3.2     l-3.8-0.8l-1.2-2.2l2.2-2.4l2.3-4.2l-1.4-3L741.8,574z">
                </path>
                <path id="MS" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M770.8,574.2l-0.3,1.5h-6.4l-1.8-1l-2.6-0.4     l-8.4,2.4l-2.2-1L746,581l-1.4,1l-1.399-3.1l-1.4-4.801l-4.2-3.899l1.4-6.8l-0.8-1.101l-2.3,0.3l-9.7,1.101L696,564l-0.9-2.7     L696.2,551l3.8-7l6.4-11.2l-0.601-3l1.5-0.8l0.5-2.4L704.9,524l-0.2-2.6l-2.3-5.101L702.3,508.9l1.601-3l-0.301-4.2l-2.199-3.8     l1.899-1.801L701.4,493l0.6-2l1.9-8l3.1-2.5l-0.8-2.9l4.5-6.5l3.5-1.699l-0.3-2.101l-0.4-2.1l3.5-6.8l2.9-1.5l0.199-1.101     l45.9-4.7l0.2,7.7l0.2,20.5l-1,38.101l-0.2,17.3l3.399,23.1L770.8,574.2z">
                </path>
                <path id="OK" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M461.8,403.9l-20.5-1.601l-1.1,13.5l25.2,1.4     l39.399,1.6l-2.8,30l-0.6,21.9l0.3,2l5.3,4.5l2.5,1.399l0.8-0.3l0.8-2.5l1.7,2.3h2.5V476.4l3.4,1.699L518.1,482.9l5.101,0.3     l3.1,1.399l5.101,0.801l3.1,2.3l2.8-2.5l4.2,0.8l3.1,4.2h1.101v2.8l2.8,0.8l2.8-2.8l2.3,0.8h3.101l1.1,3.101l7.7,2.6l1.7-0.8     l2.3-5h1.399l1.4,2.5l5.1,0.8l4.5,1.7l3.7,1.1l2.3-1.1l0.801-3.101h5.3l2.5,1.101l3.399-2.5h1.4l0.8,2h5.101l2-2.5l2.3,0.6     l2.5,3.101l3.899,2.3l3.9,1.1l2.4,1.4l-0.601-45.8l-1.7-13.5L621.7,430l-1.801-8l-1-8.8l-0.1-4.7l-14.9,0.4L547,408l-55.3-2.5     L461.8,403.9z">
                </path>
                <path id="NM" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M348.4,530.8l-1-5.8l10.6,0.7l37.1,3.6l33.5,2.101     l2.7-23l4.7-68.7l2.1-23.8l1.9,0.199l1-13.699L313.1,389.3L291.6,537.4l19,2.5L312.2,527.6L348.4,530.8z">
                </path>
                <path id="KS" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M618.7,408.2l-15.5,0.2l-56.7-0.5l-54.8-2.5     l-31.3-1.601l4.8-79.5l27.2,0.8l49.6,1l54.5,1.2h6.3l2.7,2.7h2.5l2,1.2l-0.1,3.7l-2.2,2.1l-0.4,2.7l2.3,4.2l3.7,3.9l2.9,2     l1.6,13.8L618.7,408.2z">
                </path>
                <path id="AZ" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M172.2,478.9L169,481.6l-0.4,1.801l0.6,1.199     l23.3,13.101l14.9,9.3l18.1,10.4l20.7,12.3l15.1,3L292,536l21.2-146.4l-103-14.699l-3.8,20.199h-2l-2.1,3.2l-3.1-0.1l-1.5-3.4     l-3.4-0.399l-1.1-1.4H192l-1.1,0.7l-2.4,1.2l-0.1,8.6l-0.3,2.1l-0.7,15.5l-1.8,2.7l-0.7,4.101l3.4,6.1l1.5,7.2l1,1.2l1.2,0.7     l-0.1,2.8l-2,1.7l-4.2,2.1l-2.4,2.4l-1.8,4.5l-0.7,6.1l-3.5,3.4l-2.5,0.8l0.2,1l-0.6,2.1l0.6,1l4.5,0.7l-0.7,3.4L177,477.8     L172.2,478.9z">
                </path>
                <path id="TX" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M438.6,414.8l27.9,1.3l38.2,1.4l-2.9,28.9     l-0.399,22.3l0.1,2.6l5.3,4.7l2.5,1.8l1.5-0.7l0.5-2.199l1.4,2.199h2.6v-1.8l2.101,1.2l1.399,0.5l-0.399,4.9l5,0.1l3.6,1.5     l4.9,0.7l2.899,2.6l2.601-2.6l4.6,0.8l2.7,4l1.3,0.4l-0.2,2.5l2.7,1l2.9-2.5l2.6,0.8h2.7l1.1,3l7.8,2.6l2-0.899l1.801-5.101     h0.399l1.101,0.101l1.5,2.5l4.8,0.8l4.1,1.399l4.2,1.5l2.3-1.199l0.9-3.101h5.5l2.2,1.101l3.399-2.601h1.4l1,2h5l1.899-2.5     l2.301,0.5l2.399,3l4.3,2.5l3.5,1l1.9,1l3,2.5l3.7-1.6l3.3,1.399l0.7,7.5v11.9l0.8,11.7l0.9,4.399l3.3,5.4l1.1,6.1l5.2,6.801     l0.2,3.899l0.899,1l-0.899,10.3l-3.5,6.101l1.899,2.6l-0.8,2.9l-0.8,9.1l-1.8,4.101l0.399,4.3l-7,2l-12.1,5.6l-1.2,2.4l-3.2,2.3     l-2.6,1.8l-1.6,1l-6.9,6.6l-3.4,2.601l-6.6,4l-7,3l-7.7,4.2l-2.2,1.8l-7.199,4.399l-4.2,0.801l-4.8,6.8l-4.9,0.399l-1.2,2.4     l2.8,2.4l-1.8,6.8l-1.6,5.6l-1.4,4.8l-1,5.601l1,3l2.2,8.6l1.2,7.601l2.2,3.399l-1.2,1.8l-3.8,2.4l-7-4.8l-6.801-1.4l-1.6,0.601     l-4-0.801l-5.2-3.8L524.9,691.7l-9.301-4.2l-2.6-4.8l-1.6-8l-4-2.4l-0.8-2.8l0.8-0.8l0.399-4.2l-1.6-0.8l-0.8-1.2l1.6-5.4     l-2.1-2.8l-4-1.6l-4.2-5.4l-4.4-8.2l-5.2-3.2l0.2-2.399l-6.6-15.101l-1-5.2l-2.2-2.399l-0.2-1.8l-7.399-6.601l-3.2-3.8v-1.4     l-3.2-2.6l-8.4-1.4l-9.199-0.8l-3.801-2.8l-5.6,2.2l-4.4,1.8l-2.8,4l-1.2,4.6L422.7,609.8l-3,3l-3.2-1.2l-2.2-1.4l-2.399-0.8     l-4.801-2.8v-0.8l-2.199-2.4l-6.4-2.6l-9.2-9.5l-2.7-5.8v-9.9l-4-8l-0.6-3.4L380,563l-1.4-2.601l-6.1-2.6l-1.6-2l-8.7-9.7     l-1.601-3.9l-5.8-2.8L353,534l-3.2-3.601l-2.399-0.6L346.6,524l9.801,0.8l35.699,3.399l35.7,2l2.7-23.899l4.8-68.3l2-23.101h1.7      M561.7,697.3l-0.7-8.7l-3.4-8.8l-0.699-8.6L558.8,661.1l4.101-8.399l4.3-6.7l3.899-4.4l0.801,0.301L566,650.1l-5.4,8.101     l-2.5,8.2L557.7,672.8l1.1,7.601l3.2,8.8l0.6,6.399l0.2,1.801L561.7,697.3z">
                </path>
                <path id="GA" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M820.9,446.6v2.7l0.199,2.601l0.801,4.199     l4.199,9.801l3,12.1l1.801,7.6l2,6l1.8,8.601l2.6,7.8l3.2,4.2l0.6,4.2l2.4,1l0.2,2.6l-2.2,6l-0.6,4l-0.2,2.4l2,5.399l0.399,6.601     l-1,3l0.801,1l1.8,1l0.2,4l2.699,4.1l2.801,2.7l9.699,0.2L873.4,559.6l26.5-1.6l6.699-0.8h5.601l0.2,3.6l3.199,1l0.4-5.399     l-2-5.601l1.4-2l7.199,1l6.2,0.4l-1-7.7l2.8-12.3l1.801-5.2l-0.601-3.2l4.101-7.7L935.3,512.4L933,513.3l-3.2-1.6L929,509.1     l-1.6-4.399l-2.8-2.601l-3.2-0.8l-2-6l-3.601-7.8l-5.199-2.4L908,482.7l-1.6-3.2l-2.601-2.4l-2.8-1.6l-2.8-3.6l-3.8-2.801     l-5.6-2.199L888.2,465.1l-3-3.6l-0.6-1.8l-4.2-6.2l-4.3,0.1L871.5,450.7l-1.8-1.601l-0.399-2.199l1.1-2.4l2.7-1.4l-0.8-2.6     L820.9,446.6z">
                </path>
                <path id="NC" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M1021,371.3l2.6,6.101l4.4,8l3,3l0.8,2.8l-3,0.2     l1,0.8l-0.399,5.2l-3.2,1.6l-0.8,2.6l-1.601,3.601l-4.6,2l-3-0.4l-1.8-0.2l-2.101-1.6l0.4,1.6v1.2h2.4l1,1.601l-2.4,7.699h5.2     l0.8,2l2.8-2.8l1.6-0.6l-2.399,4.399l-3.8,6h-1.601l-1.399-0.6l-3.4,0.8l-6.4,3l-8,6.601l-4.199,5.8l-2.4,8l-0.6,3l-5.801,0.6     L977.4,454.9L965.2,444.8l-15.5-9.399l-3.601-1l-15.5,1.8l-5.3,0.899l-2-4l-3.7-2.6l-20.3,0.6l-8.899,1L879.3,437.7l-7.6,3.2     L845.6,444.1l0.601-5l2.2-1.8l3.399-0.8l0.8-4.6l5.2-3.4l4.8-1.8l5.2-4.4l5.4-2.6l0.8-3.8l4.8-4.801l0.8-0.199     c0,0,0,1.399,1,1.399c0.801,0,1.601,0.2,2.4,0.4l2.8-4.4l2.601-0.8l2.8,0.4l2-4.4l3.6-3.2l0.601-2.6l0.199-4.5h5.301l8.899-1.101     l19.4-2.799l18.6-2.601l26.601-5.8l24.6-5.2l13.8-3L1021,371.3z M1026.2,412.1l3.2-3.1l3.899-3.2l1.9-0.8l0.2-2.5l-0.801-7.6     l-1.8-2.9l-0.8-2.3l0.899-0.3l3.4,6.8l0.5,5.5l-0.2,4.2l-4.199,1.899l-3.5,3l-1.4,1.5L1026.2,412.1z">
                </path>
                <path id="CA" class="st0" stroke="#FFFFFF" stroke-width="0.75" d="M171.9,478.3l4.8-0.6l1.8-2.5l0.7-3.601l-4.4-0.699     l-0.6-0.801l0.6-2.5l-0.2-0.699l2.4-0.801l3.7-3.5l0.7-6.199l1.7-4.2l2.4-2.7l4.3-2l2-2l0.1-2.6l-1.2-0.7l-1.2-1.3l-1.4-7.2     l-3.3-5.9l0.7-4.3l-3-1.2L97.6,294.2l23.2-83.1l-82.5-19.3l-1.9,5.8l-0.2,9.2l-6.4,14.5l-3.8,3.2l-0.4,1.4l-2.2,1l-1.8,5.2l-1,4     l3.4,5.2l2,5.2l1.4,4.4l-0.4,8l-2.2,3.8l-0.8,7.2l-1.2,4.6l2.2,4.8l3.4,5.6l2.8,6l1.6,4.9l-0.4,4l-0.4,0.6v2.6l7,7.7l-0.6,3     l-0.8,2.8l-0.8,2.4L37,329l2.6,4.6l2.4,3.2l3.4,0.6l1.2,3.4l-1.4,4.4l-2.6,2h-1.4l-1,4.8l0.6,3.6l4,5.4l2,6.6l1.8,5.8l1.6,3.8     l4.2,7.2l1.8,3.199l0.6,3.601l2,1.2v3l-1,2.399l-2.2,8.7l-0.6,2.4l2.9,3.3l5.2,0.6l5.6,2.2l4.8,2.6H77l3.6,3.801l3.2,6l1.4,2.8     l4.8,2.6l6,1l1.8,2.601l0.8,4l-1.8,0.8l0.4,1.2l4,1l3.4,0.199l3.9-2.1l4.8,5.2l1,2.8l3.2,5.2l0.4,4v11.5l0.6,2.2l12.3,1.8     l24.3,3.399L171.9,478.3z M63.6,424.6l1.6,1.9l-0.2,1.6l-4-0.1l-0.7-1.5l-0.8-1.8L63.6,424.6z M65.9,424.6l1.5-0.8l4.4,2.601     l3.8,1.5l-1.1,0.8l-5.6-0.3l-2-2L65.9,424.6z M91.4,448.9l2.2,2.899l1,1.2l1.9,0.7l0.7-1.8l-1.2-2.2l-3.3-2.5l-1.3,0.2V448.9z      M89.6,459.6l2.2,3.9l1.5,2.4l-1.8,0.3l-1.6-1.5c-0.4-0.7-0.7-1.5-0.9-2.3v-2.7L89.6,459.6z">
                </path>

                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3" id="AK-div">
                            <img src="svgs/Alaska-Grey.svg" alt="" id="AK-G" height=200 width=200 style="">                                                    
                            <img src="svgs/Alaska-Blue.svg" alt="" id="AK-B" height=200 width=200 style="">                                                    
                        </div>
                        <div class="col-md-6">
                        </div>
                        <div class="col-md-3" id="HW-div">
                            <img src="svgs/Hawaii-Grey.svg" alt="" id="HI-G" height=200 width=200>
                            <img src="svgs/Hawaii-Blue.svg" alt="" id="HI-B" height=200 width=200>
                        </div>
                    </div>
                </div>
            </g>
        </g>
    </svg>
  </div>
</template>

<script>
export default {
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.st0{
    fill:#C8C8C8;
    fill-rule:evenodd;
    clip-rule:evenodd;
}
</style>

