<template>
    <div id="companies_index">
        <h1 class="ui dividing header">Companies <router-link to="/admin/companies/create" class="ui right floated button prim"><i class="fas fa-plus"></i>Add Company</router-link></h1>

        <div class="ui active centered inline massive loader" v-if="loading"></div>

        <v-client-table class="companies_table table-borderless" :data="companies" :columns="columns" :options="options" v-else>
          <div class="ui compact menu manage_dropdown" slot="manage" slot-scope="{row}">
            <div class="ui simple dropdown item">
              Manage
              <i class="dropdown icon"></i>
              <div class="menu">
                <router-link class="item" :to="'/admin/companies/' + row.id + '/edit'"><i class="fa fa-edit"></i> Edit</router-link>
                <div class="item" to="javascript:void(0);" @click="deleteCompany(row.id)"><i class="fa fa-trash-alt"></i> Delete</div>
              </div>
            </div>
          </div>
        </v-client-table>
    </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
export default {
    data () {
        return {
            loading: true,
            companies: [],
            columns: [
              'name',
              'number_of_users',
              'manage'
            ],
            options: {
              headings: {
                name: 'Name',
                number_of_users: '# of Users',
                manage: ''
              },
              sortable: ['name'],
              filterable: ['name'],
              perPage: 10,
              perPageValues: [10, 25, 50],
              texts: {
                filter: '',
                filterBy: 'Search by {column}',
                count: ''
              },
              pagination: { chunk: 10, dropdown: true },
              sortIcon: {
                base : 'fa',
                is: 'fa-sort',
                up: 'fa-sort-asc',
                down: 'fa-sort-desc'
              },
            },
            errors: []
        }
    },
    mounted () {
        this.$http.get('/companies')
        .then((response) => {
            this.companies = response.data

            this.loading = !this.loading;
            
            setTimeout(function() {
            $('.VueTables__limit-field > select').addClass("ui fluid limit dropdown");
            $('.VueTables__search__input').attr("placeholder", "Search All...");
          }, 100);
        })
        .catch((error) => {
            this.errors = error;
        });
    },
    methods: {
      deleteCompany: function(id) {
        this.loading = !this.loading;
        
        // alert('Delete id of ' + id);

        this.$http.delete('/companies/'+id)
        .then((response) => {
            this.companies = response.data

            this.loading = !this.loading;
        })
        .catch((error) => {
            this.errors = error;
        });
      }
    }
}
</script>

<style lang="scss">
#companies_index {
  margin: $layoutmargin;

  .VueTables__search {
    float: right !important;
  }

  .VueTables__search__input {
    width: 350px;
    height: 40px;
    border: $darkblueborder;
  }

  .ui.fluid.limit.dropdown {
    width: 60px;
    height: 40px;
    border-radius: 20px !important;
    border: $darkblueborder;
    margin-left: 10px;
  }

  .companies_table {

    .table-responsive {
      padding-bottom: 85px;
    }

    .VueTables__table {
      overflow: unset !important;
    }
    
    tr:nth-child(2n) {
        background-color: #F4F4F4;
    }

    thead {
      tr {
        th {
          color: $algdarkblue;
          background-color: $algdarkbluetenopac;
          border: none;
          padding-left: 15px;
        }

        th:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        th:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    tbody {
      tr {
        background-color: #fff;

        td {
          border: none;
          padding: 20px 0px 20px 15px;
        }

        td:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        td:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          padding: 10px 0px 10px 15px;
        }
      }
    }
  }
}
</style>