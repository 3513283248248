<template>
  <div id="company_edit">
    <h1 class="ui dividing header">Edit Company</h1>
    <div class="ui form">
        <div class="inline field">
            <label>Name:</label>
            <div class="ui icon input" :class="{loading: loading}">
              <input type="text" placeholder="Name" v-model="name">
              <i class="icon"></i>
            </div>
        </div>
        <div class="inline field buttons">
            <button class="ui left floated button sec" @click="cancel()"><i class="fas fa-times"></i>Cancel</button>
            <button class="ui right floated button prim" @click="update()"><i class="fas fa-check"></i>Submit</button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data () {
        return {
            loading: true,
            name: '',
            errors: []
        }
    },
    created () {
        this.$http.get('/companies/'+this.$route.params.id+'/edit')
        .then((response) => {
            this.name = response.data.name;

            this.loading = !this.loading;
        })
        .catch(error => {
            this.errors = error;
        });
    },
    mounted () {

    },
    methods: {
        update: function() {
            let params = {
              name: this.name
            };

            this.$http.patch('/companies/'+this.$route.params.id, params)
            .then(() => {
                this.router.push('/admin/companies');
            })
            .catch(error => {
                this.errors = error;
            });
        },
        cancel: function () {
          this.router.push('/admin/companies');
        }
    }
};
</script>

<style lang="scss">
#company_edit {
  margin: $layoutmargin;

  .ui.form {
    margin-top: 20px;
    padding-left: 24px;

    label {
      font-size: 16px;
      font-weight: bold;
      color: $algdarkblue;
      width: 130px;
    }

    input {
      width: 300px;
      border: $darkblueborder;
    }

    select {
      width: 300px;
      border: $darkblueborder;
    }

    .inline.field.buttons {
      width: 430px;
      margin-top: 54px;
    }
  }
}
</style>