<template>
    <div class="ui grid" :id="'po_'+(poCount+1)">
        <div class="fourteen wide column">
            <input type="text" :name="'po_'+(poCount+1)" placeholder="">
        </div>
        <div class="two wide column">
            <i class="fa fa-plus" @click="addPo()" v-if="poCount == 0"></i>
            <i class="fa fa-minus" @click="removePo()" v-else></i>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    poCount: Number,
    purchaseOrders: Array
  },
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    addPo: function() {
        this.$emit('clicked');
    },
    removePo: function() {
        this.purchaseOrders.splice(this.poCount, 1);
    }
  },
  computed: {
  }
};
</script>

<style lang="scss">
</style>