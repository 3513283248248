<template>
  <div class="ui notification_modal modal">
    <div class="header">Track Shipment: <span>#{{ hawb_number }}</span> <i class="close icon" @click="close()"></i></div>
    <div class="content" id="user_create">
      <form class="ui form" v-on:submit.prevent="create()">
      <input type="hidden" v-model="hawb_number" />
      <div class="inline field">
        <label>Notification Type:</label>
        <select type="checkbox" class="ui fluid search dropdown" v-model="notification_type" >
          <option value="email">Email</option>
          <option value="phone">Text</option>
        </select>
      </div>
      <div class="inline field" v-if="notification_type == 'email'">
        <label>Email:</label>
        <input type="text" v-model="email" />
      </div>
      <div class="inline field" v-if="notification_type == 'phone'">
        <label>Phone: <br>(ex: 5551234567)</label>
        <input type="text" maxlength="10" v-model="phone" />
      </div>
      
      <div class="inline field">
        <label>Interval:</label>
        <select type="checkbox" class="ui fluid search dropdown" v-model="interval">
          <option value="1_hr">1 Hour</option>
          <option value="4_hr">4 Hour</option>
          <option value="1_day">1 Day</option>
        </select>
      </div>
      <div class="inline field buttons">
        <button class="ui left floated button sec" @click="close()">
          <i class="fas fa-times"></i>Cancel
        </button>
        <button class="ui right floated button prim">
          <i class="fas fa-check"></i>Submit
        </button>
      </div>
    </form>
    </div>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
export default {
  props: {
    hawb_number: String,
  },
  components: {
  },
  data() {
    return {
      notification_type: "email",
      email: "",
      phone: "",
      interval: "1_hr",
      errors: {},
    };
  },
  mounted() {
    $(".ui.fluid.dropdown").dropdown();
  },
  methods: {
    create: function () {
      let params = {
        hawb_number: this.hawb_number,
        notification_type: this.notification_type,
        email: this.email,
        phone: this.phone,
        interval: this.interval,
      };

      this.$http
        .post("/notification/store", params)
        .then((response) => {
          this.close();
        })
        .catch((error) => {
          this.errors = 'Something went wrong, please double check your data is valid.';
          //$('.ui.error_modal.modal').modal('show');
        });
    },
    close: function() {
      $(".ui.notification_modal.modal").modal("hide");
      this.resetForm();
    },
    resetForm: function(){
      this.notification_type = "email";
      this.email = "";
      this.phone = "";
      this.interval = "1_hr";
    }

  },
};
</script>

<style lang="scss">
#user_create {
  margin: $layoutmargin;

  .ui.form {
    margin-top: 20px;
    padding-left: 24px;

    label {
      font-size: 16px;
      font-weight: bold;
      color: $algdarkblue;
      width: 130px;
    }

    input {
      width: 300px;
      border: $darkblueborder;
    }

    select {
      // width: 300px;
      // height: 40px;
      // border: $darkblueborder;
      // border-radius: 4px;
      // font-size: 16px;
      // padding: 0px 0px 0px 10px !important;
    }

    .inline.field.buttons {
      width: 430px;
      margin-top: 54px;
      padding-bottom: 50px;
    }

    .single-select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      background-color: #fff;
      color: $alggrey;
    }

    .ui.fluid.dropdown {
      display: inline-block !important;
      width: 100% !important;
      max-width: 300px !important;
      border: $darkblueborder;
    }
  }
}
</style>

<style lang="scss">
.ui.notification_modal.modal {
  padding-left: 40px;
  padding-top: 40px;

  .header {
    color: $algdarkblue !important;
    border-bottom: none !important;

    .close.icon {
      color: $algdarkblue !important;
      padding-right: 40px;
    }
  }

  .logo {
    display: block;
    margin: auto;
  }
}
</style>
