<template>
  <div class="ui location_modal modal">
    <div class="header">
      Location History <i class="close icon" @click="close()"></i>
    </div>
    <div class="body">
      
      <!--  
        Gmap defines the google map
        map-type-id options = roadmap, satellite, hybrid, terrain
        Zoom

      -->
      <GmapMap
        :center="center"
        ref="mapRef"
        :zoom="4"
        map-type-id="roadmap"  
        style="width: 100%; height: 500px"
        @tilesloaded=fitbounds
        :options="{
          disableDefaultUi: false,
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: true,
          streetViewControl: true,
          rotateControl: true,
          fullscreenControl: true,
        }"
        
      >
        <GmapMarker
          :key="index + 100"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="false"
          :draggable="false"
          @click="center = m.position"
          :icon="dotIcon"
        />
        <gmap-info-window 
            @closeclick="window_open=false" 
            :opened="window_open" 
            :position="infowindow"
            :options="{
              pixelOffset: {
                width: 0,
                height: -35
              }
            }"
        >
            {{ infowindow_text }}
        </gmap-info-window>  
        <GmapMarker
          :key="index + 200"
          v-for="(m, index) in finalMarkers"
          :position="m.position"
          :clickable="true"
          :draggable="false"
          @click="openWindow(m.position,m.address)"
          :icon="deliveredIcon"
          :zIndex=900
        />
        <GmapMarker
          :key="index"
          v-for="(m, index) in initialMarkers"
          :position="m.position"
          :clickable="true"
          :draggable="false"
          @click="openWindow(m.position,m.address)"
          :icon="originalDot"
        />
        <span v-for="path in paths" :key="path">
        <gmap-polyline
          v-bind:path="path"
          v-bind:options="{ strokeColor: '#2b62ad' }"
        >
        </gmap-polyline>
        </span>

      </GmapMap>
    </div>
  </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
import {gmapApi} from 'vue2-google-maps'
export default {
  name: "LocationMap",
  props: {
    markers: Array,
    initialMarkers: Array,
    finalMarkers: Array,
    center: Object,
    bounds: Object,
    //path: Array,
    paths: Array,
    delivered: Number
  },
  watch: {
    delivered: function(newval) {
      if (newval == 10) {
        this.deliveredIcon = this.checkIcon;
      } else {
        this.deliveredIcon = this.finalDot;
      }
    },
  },
  data() {
    return {
      dotIcon: require("../../assets/images/bluedot.png"),
      checkIcon: require("../../assets/images/finaldestinationdot.png"),
      finalDot: require("../../assets/images/finaldestinationdot.png"),
      originalDot: require("../../assets/images/originaldestination.png"),
      deliveredIcon: null,
      info_marker: null,
      infowindow: {lat: 10, lng: 10.0}, // This is arbitrary it gets reset on click
      window_open: false,
      infowindow_text: '',
      first_load: true,
    };
  },
  computed: {
    google: gmapApi
  },
  
  mounted() {
    if (this.delivered == 10) {
      this.deliveredIcon = this.checkIcon;
    } else {
      this.deliveredIcon = this.finalDot;
    }
  },
  methods: {
    fitbounds: function(){
      this.$refs.mapRef.$mapPromise.then((map) => {
        if(this.first_load){
          this.first_load = false;
          const bounds = new google.maps.LatLngBounds();
          for (let m of this.markers) {
            bounds.extend(m.position)
          }
          for (let m of this.initialMarkers) {
            bounds.extend(m.position)
          }
          for (let m of this.finalMarkers) {
            bounds.extend(m.position)
          }
          map.fitBounds(bounds);
        }
      })
    },
    openWindow (position,address) {
          this.infowindow = position;
          this.window_open = true;
          this.infowindow_text = address;
      },
    close: function() {
      $(".ui.location_modal.modal").modal("hide");
    }
  }
};
</script>