<template>
  <div id="company_create">
    <h1 class="ui dividing header">Create Company</h1>
    <div class="ui form">
        <div class="inline field">
            <label>Name:</label>
            <input type="text" placeholder="Name" v-model="name">
        </div>
        <div class="inline field buttons">
            <button class="ui left floated button sec" @click="cancel()"><i class="fas fa-times"></i>Cancel</button>
            <button class="ui right floated button prim" @click="createCompany"><i class="fas fa-check"></i>Submit</button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data () {
        return {
            name: '',
            errors: []
        }
    },
    mounted () {
    },
    methods: {
        createCompany: function() {
            let params = {
                name: this.name
            };

            this.$http.post('/companies', params)
            .then(() => {
                this.router.push('/admin/companies');
            })
            .catch(error => {
                this.errors = error;
            });
        },
        cancel: function() {
          this.router.push('/admin/companies');
        }
    }
};
</script>

<style lang="scss">
#company_create {
  margin: $layoutmargin;

  .ui.form {
    margin-top: 20px;
    padding-left: 24px;

    label {
      font-size: 16px;
      font-weight: bold;
      color: $algdarkblue;
      width: 130px;
    }

    input {
      width: 300px;
      border: $darkblueborder;
    }

    select {
      width: 300px;
      border: $darkblueborder;
    }

    .inline.field.buttons {
      width: 430px;
      margin-top: 54px;
    }
  }
}
</style>