<template>
  <div id="dashboard">
    <Admin v-if="user && user.admin" />
    <User v-else-if="user && !user.admin" />
  </div>
</template>

<script>
import Admin from "@/components/dashboards/Admin.vue";
import User from "@/components/dashboards/User.vue";

export default {
  components: {
    Admin,
    User,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
};
</script>
