<template>
  <div id="login">
    <div class="ui menu">
      <div class="header item">
        <img
          id="logo"
          src="../../assets/images/ALG_logo@2x.png"
          alt="ALG Logo"
        />
      </div>
    </div>
    <div class="ui container placeholder segment">
      <div class="ui two column very relaxed stackable grid">
        <div class="column">
          <h1>Track A Shipment</h1>
          <div class="ui form">
            <div class="field">
              <label>Choose A Tracking Method:</label>
              <select
                class="ui fluid dropdown"
                name="tracking_method"
                v-model="trackingMethod"
              >
                <option value="t">Tracking #</option>
                <option value="s">Shipper Ref</option>
                <option value="c">Consignee Ref</option>
                <option value="p">PO #'s</option>
              </select>
            </div>
            <div class="field">
              <label>Enter Tracking Information:</label>
              <textarea
                v-model="information"
                placeholder="Enter up to 30, one per line."
              ></textarea>
            </div>
            <button
              class="ui button prim"
              :class="{ loading: loading }"
              @click="track()"
            >
              <i class="fas fa-map-marked-alt"></i> Track
            </button>
          </div>
        </div>
        <div class="column" id="login_column">
          <h1>ALG Ship Log In</h1>
          <form class="ui form" v-on:submit.prevent="login()">
            <div class="field">
              <label>Email:</label>
              <div class="ui left icon input">
                <input type="email" v-model="email" />
                <i class="icon fas fa-envelope"></i>
              </div>
            </div>
            <div class="field">
              <label>Password:</label>
              <div class="ui left icon input">
                <input type="password" v-model="password" />
                <i class="icon fas fa-lock"></i>
              </div>
            </div>
            <button
              class="ui button prim"
              :class="{ loading: loading }"
              @click="login()"
            >
              <i class="fas fa-sign-in-alt"></i> Login
            </button>
          </form>

          <span class="forgot_password_link" @click="forgotPassword()"
            >Forgot Password</span
          >
        </div>
      </div>
    </div>

    <ForgotPasswordModal />
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
import ForgotPasswordModal from "@/components/modals/ForgotPasswordModal.vue";

export default {
  components: {
    ForgotPasswordModal,
  },
  data: function () {
    return {
      loading: false,
      trackingMethod: "t",
      information: "",
      email: "",
      password: "",
      errors: [],
    };
  },
  mounted() {
    $(".ui.fluid.dropdown").dropdown();
  },
  methods: {
    login: function () {
      this.loading = !this.loading;

      var params = {
        email: this.email,
        password: this.password,
      };

      this.$http
        .post("/login", params)
        .then((response) => {
          // set token for api calls
          this.$http.defaults.headers.common["Authorization"] =
            "Bearer " + response.data.user.api_token;
          this.$store.commit("setUser", { user: response.data.user });

          response.data.user.admin
            ? this.router.push("/admin/dashboard")
            : this.router.push("/dashboard");
        })
        .catch(() => {
          this.errors = ["Invalid email or password."];
          this.email = "";
          this.password = "";
        });

      this.loading = !this.loading;
    },
    track: function () {
      this.loading = !this.loading;

      let env = process.env.VUE_APP_ENV;
      let url = process.env.VUE_APP_TRACK_URL;
      let track = `${url}/details?hawb_number=${this.information}`;

      window.open(track, "_blank");
      console.log(".");

      this.loading = !this.loading;
    },
    forgotPassword: function () {
      $(".ui.forgot_password.modal").modal("show");
    },
  },
};
</script>

<style lang="scss">
#login {
  background-image: url("../../assets/images/login_bg.jpg");
  background-size: cover;
  min-height: 100vh;
  padding-bottom: 50px;

  .ui.menu {
    border-radius: 0;
    border-top: 0px;
  }

  .header.item {
    border-right: none;
  }

  .header.item:before {
    display: none;
  }

  .ui.placeholder.segment {
    // background-color: #fff;
    margin-top: 75px;
    border: none;
    box-shadow: none;
  }

  .ui.grid {
    .column {
      padding: 40px 80px 40px 80px !important;
    }
  }

  .ui.form {
    .field {
      max-width: 100% !important;
      margin-left: 0 !important;

      .ui.icon.input > i.icon {
        color: $algdarkblue;
      }

      input,
      select {
        height: 40px;
      }

      input,
      select,
      textarea {
        border: 2px $algdarkblue25opac solid !important;
        border-radius: 4px;
      }
    }

    .button {
      margin-left: 0px !important;
    }

    textarea {
      margin-left: 0 !important;
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  .ui.fluid.dropdown {
    width: 100%;
    height: 40px;
    border-radius: 4px !important;
    border: 2px solid $algdarkblue25opac;
  }

  #logo {
    width: 95px !important;
    margin: 2px 0px 2px 30px;
  }

  #login_column:before {
    content: "";
    position: absolute;
    height: 80%;
    width: 1px;
    background-color: $alggrey;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto !important;
  }

  .forgot_password_link {
    color: $algdarkblue;
    text-decoration: underline;
    position: absolute;
    right: 80px;
    bottom: 80px;
    cursor: pointer;
  }
}
</style>