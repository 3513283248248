<template>
  <div id="app">
    <AdminNav v-if="user && admin" />
    <UserNav v-else-if="user && !admin" />
    <router-view />
  </div>
</template>

<script>
import UserNav from "@/components/navs/UserNav.vue";
import AdminNav from "@/components/navs/AdminNav.vue";


export default {
  components: {
    UserNav,
    AdminNav
  },
  data () {
    return {
    }
  },
  mounted () {

  },
  computed: {
    user () {
      return this.$store.state.user
    },
    admin () {
      if (this.$store.state.user) {
        return this.$store.state.user.admin
      } else {
        return false
      }
    }
  }
};
</script>

<style lang="scss">
  @import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
  @import "../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";  
</style>
