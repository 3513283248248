<template>
  <div id="user_show">
    <h1>User Show</h1>
    {{user}}
  </div>
</template>

<script>
export default {
    data () {
        return {
            user: '',
            errors: []
        }
    },
    mounted () {
        this.$http.get('/users/'+this.$route.params.id)
        .then(response => {
            this.user = response.data;
        })
        .catch(error => {
            this.errors = error;
        });
    }
};
</script>