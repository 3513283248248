<template>
  <div id="tracking_report">
    <h1 class="ui dividing header">
      Tracking Report
      <button
        class="ui right floated button sec excel"
        :class="{ loading: downLoading }"
        @click="onBtnExportDataAsExcel()"
      >
        <i class="fas fa-file-download"></i>Download Excel
      </button>
      <button
        class="ui right floated button prim pdf"
        :class="{ loading: downLoading }"
        @click="onBtPrint()"
      >
        <i class="fas fa-print"></i>Print
      </button>
    </h1>

    <div class="ui active centered inline massive loader" v-if="false"></div>

    <div v-else>
      <div class="ui right floated compact menu">
        <div class="ui simple dropdown item">
          <button class="ui right floated button tert">
            <i class="fas fa-filter"></i>Report Options
          </button>
          <div class="menu">
            <div class="item" to="#" @click="open()">
              <i class="fa fa-columns"></i> Column Options
            </div>
            <div class="item" to="#" @click="clear()">
              <i class="fa fa-times-circle"></i> Clear Filters
            </div>
            <div class="item" to="#" @click="reset()">
              <i class="fa fa-undo-alt"></i> Reset To Defaults
            </div>
          </div>
        </div>
      </div>
      <div class="ui right floated last_loads menu">
        <p>Show Hawbs From Last:</p>
        <select
          class="ui fluid last dropdown"
          v-model="timeFrame"
          name="last_loads"
          @change="showHawbsFromLast($event)"
        >
          <option value="1_week">1 week</option>
          <option value="1_month">1 month</option>
          <option value="3_month">3 months</option>
          <option value="6_month">6 months</option>
          <option value="365_day">1 year</option>
          <option value="YTD">YTD</option>
          <option value="last_year">Last Year</option>
        </select>
      </div>
      <div class="ui left floated last_loads menu">
        <p>Show</p>
        <select
          v-on:change="onPageSizeChanged()"
          class="ui fluid last dropdown"
          id="page-size"
        >
          <option value="10" selected="">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <!--<option value="50000">All</option>-->
        </select>
        <p>Entries</p>
      </div>
      <div class="ui left floated last_loads menu">
        <select
          class="ui fluid dropdown accounts"
          @change="showHawbsFromAccount($event)"
        >
          <option value="all">All Accounts</option>
          <option
            v-for="(accounts, index) in allowedNumbers"
            :value="accounts.number"
            :key="index"
          >
            {{ accounts.number }}
          </option>
        </select>
      </div>
      <div class="ui left floated last_loads input far fa-search">
        <input
          type="text"
          id="filter-text-box"
          class="ui fluid input"
          placeholder="Search All..."
          v-on:keyup="onFilterTextBoxChanged()"
        />
      </div>
      <!-- <v-client-table class="tracking_report_table table-borderless" :data="hawbs" :columns="columns" :options="options">
        <router-link class="tracking_link" :to="'/tracking/details?hawb_number='+row.hawb_number" target= '_blank' slot="hawb_number" slot-scope="{row}">{{row.hawb_number}}</router-link>
      </v-client-table> -->
      <template>
        <ag-grid-vue
          style="width: 100%; height: 80vh; clear: both"
          class="ag-theme-balham"
          id="tracking_report_table"
          :columnDefs="columnDefs"
          :rowData="hawbs"
          :gridOptions="gridOptions"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :paginationNumberFormatter="paginationNumberFormatter"
          :sideBar="sideBar"
          @grid-ready="onGridReady"
          @first-data-rendered="onFirstDataRendered"
          :modules="modules"
        >
        </ag-grid-vue>
      </template>
    </div>
    <ColumnsModal
      @clicked="updateColumns"
      :possibleHeadings="possibleHeadings"
      :totalRevenue="totalRevenue"
    />
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>

<script>
import TableExport from "tableexport";
import jsPDF from "jspdf";
import "jspdf-autotable";
// import "ag-grid-enterprise";
import { AgGridVue } from "ag-grid-vue";
import { LicenseManager } from "@ag-grid-enterprise/core";
LicenseManager.setLicenseKey(
  "CompanyName=Build This, LLC,LicensedApplication=ALG Interface,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-026375,ExpiryDate=19_April_2024_[v2]_MTcxMzQ4MTIwMDAwMA==ee7c1dd1960a418478b92ab9ed66c256"
);
import { AllModules } from "@ag-grid-enterprise/all-modules";
import ColumnsModal from "../../components/modals/ColumnsModal.vue";
import axios from "axios";

export default {
  name: "App",
  components: {
    ColumnsModal,
    AgGridVue,
    AllModules,
  },
  data() {
    return {
      loading: true,
      downLoading: true,
      initialHawbs: [],
      hawbs: [],
      timeFrame: "1_week",
      currentAccount: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      autoGroupColumnDef: null,
      defaultColDef: null,
      rowSelection: null,
      rowGroupPanelShow: null,
      pivotPanelShow: null,
      paginationPageSize: 10,
      paginationNumberFormatter: null,
      modules: AllModules,
      rowData: null,
      sideBar: null,
      possibleHeadings: null,
      columnState: null,
      allowedNumbers: null,
      totalRevenue: null,
      errors: [],
    };
  },
  created() {},
  beforeMount() {
    this.gridOptions = {};
    this.gridApi = {};
    this.domLayout = "autoHeight";
    // this.sideBar = {
    //   toolPanels: [
    //     {
    //       id: "columns",
    //       labelDefault: "Columns",
    //       labelKey: "columns",
    //       iconKey: "columns",
    //       toolPanel: "agColumnsToolPanel",
    //       toolPanelParams: {
    //         suppressRowGroups: true,
    //         suppressValues: true,
    //         suppressPivots: true,
    //         suppressPivotMode: true,
    //         suppressSideButtons: true,
    //         suppressColumnFilter: true,
    //         suppressColumnSelectAll: true,
    //         suppressColumnExpandAll: true
    //       }
    //     }
    //   ],
    //   defaultToolPanel: "columns"
    // };
    this.columnDefs = [
      {
        headerName: "Tracking #",
        field: "hawb_number",
        sortable: true,
        resizable: true,
        filter: "agTextColumnFilter",
        cellRenderer: function (params) {
          return (
            '<a href="/tracking/details?hawb_number=' +
            params.value +
            '" target="_blank">' +
            params.value +
            "</a>"
          );
        },
        filterParams: {
          comparator: function (cellValue) {
            //console.log(filter);
            //console.log(cellValue);
            if (filter == Number(cellValue)) {
              return 0;
            }
            if (filter < Number(cellValue)) {
              return -1;
            }
            if (filter > Number(cellValue)) {
              return 1;
            }
          },
        },
      },
      {
        headerName: "Ship Date",
        field: "ship_date",
        sortable: true,
        resizable: true,
        comparator: dateComparator,
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var dateParts = dateAsString.split("/");
            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[0]) - 1,
              Number(dateParts[1])
            );

            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }

            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
        },
      },
      {
        headerName: "Due Date",
        field: "due_date",
        sortable: true,
        resizable: true,
        comparator: dateComparator,
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var dateParts = dateAsString.split("/");
            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[0]) - 1,
              Number(dateParts[1])
            );

            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }

            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
        },
      },
      {
        headerName: "PCs/Wt",
        field: "pcs_wt",
        sortable: true,
        resizable: true,
        filter: false,
      },
      {
        headerName: "Service",
        field: "service",
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        headerName: "Shipper",
        field: "shipper",
        sortable: true,
        filter: true,
        resizable: true,
        //cellStyle: { "white-space": "normal" }
      },
      {
        headerName: "Shipper Ref #",
        field: "shipper_ref",
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        headerName: "Consignee",
        field: "consignee",
        sortable: true,
        resizable: true,
        filter: true,
        //cellStyle: { "white-space": "normal" }
      },
      {
        headerName: "Consignee Ref #",
        field: "consignee_ref",
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        headerName: "Status",
        field: "status",
        sortable: true,
        resizable: true,
        filter: "agSetColumnFilter",
        //cellStyle: { "white-space": "normal" }
      },
      {
        headerName: "Total Miles",
        field: "miles",
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        headerName: "CO2 Emissions",
        field: "co2",
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        headerName: "CO2 Savings",
        field: "co2_savings",
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        headerName: "Customer POs",
        hide: true,
        field: "customer_pos",
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        headerName: "Origin City",
        hide: true,
        field: "origin_city",
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        headerName: "Origin State",
        hide: true,
        field: "origin_state",
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        headerName: "Origin Zip",
        hide: true,
        field: "origin_zip",
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        headerName: "Destination City",
        hide: true,
        field: "destination_city",
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        headerName: "Destination State",
        hide: true,
        field: "destination_state",
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        headerName: "Destination Zip",
        hide: true,
        field: "destination_zip",
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        headerName: "Total Charges",
        hide: true,
        field: "totalRevenue",
        sortable: true,
        resizable: true,
        filter: true,
        cellRenderer: function (params) {
          if(!isNaN(params.value))
          {
            return parseFloat(params.value).toFixed(2);
          }
          else{
            return params.value;
          }
        },
      },
      {
        headerName: "Last Location",
        hide: true,
        field: "lastLocation",
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        headerName: "Delay Reason",
        hide: true,
        field: "delay_reason",
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        headerName: "POD Name",
        hide: true,
        field: "PODName",
        sortable: true,
        resizable: true,
        filter: true,
      },
      {
        headerName: "POD Date",
        hide: true,
        field: "PODDate",
        sortable: true,
        resizable: true,
        comparator: dateComparator,
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var dateParts = dateAsString.split("/");
            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[0]) - 1,
              Number(dateParts[1])
            );

            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }

            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
        },
      },
      {
        headerName: "POD Time",
        hide: true,
        field: "PODTime",
        sortable: true,
        resizable: true,
        filter: true,
      },
    ];
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.gridApi.setDomLayout("autoHeight");
    this.possibleHeadings = this.gridColumnApi.getPrimaryColumns();
    this.columnState = this.gridColumnApi.getColumnState();
    window.addEventListener("resize", this.handleResize);
    //console.log(this.possibleHeadings);
    //document.querySelector("#tracking_report_table").style.height = "75vh";
  },
  methods: {
    handleResize() {
      //console.log("resizing");
      this.gridApi.sizeColumnsToFit();
    },
    onBtPrint() {
      var gridApi = this.gridApi;
      setPrinterFriendly(gridApi);
      setTimeout(function () {
        print();
        setNormal(gridApi);
      }, 2000);
    },
    onFirstDataRendered(params) {
      //console.log(params);
      params.api.sizeColumnsToFit();
    },
    onFilterTextBoxChanged() {
      this.gridOptions.api.setQuickFilter(
        document.getElementById("filter-text-box").value
      );
    },
    onPageSizeChanged(newPageSize) {
      var value = document.getElementById("page-size").value;
      this.gridApi.paginationSetPageSize(Number(value));
    },
    open: function () {
      $(".ui.columns_modal.modal").modal("show");
    },
    clear: function () {
      //console.log("Clearing");
      this.gridOptions.api.setFilterModel(null);
    },
    reset: function () {
      $(".ui.fluid.last.dropdown").dropdown("restore defaults");
      this.gridOptions.api.setFilterModel(null);
      this.gridApi.setSortModel(null);
      this.gridColumnApi.setColumnState(this.columnState);
      this.gridApi.sizeColumnsToFit();
    },
    updateColumns: function (allColumns, selectedColumns) {
      //console.log(allColumns);
      //console.log(selectedColumns);
      this.gridColumnApi.setColumnsVisible(allColumns, false);
      this.gridColumnApi.setColumnsVisible(selectedColumns, true);
      this.gridApi.sizeColumnsToFit();
      $(".ui.columns_modal.modal").modal("hide");
    },
    onGridReady(params) {
      this.$http
        .get("/track/report?time_frame=1_week")
        .then((response) => {
          
          this.hawbs = Object.keys(response.data.hawbs).map(function (key) {
            let hawb = response.data.hawbs[key];
            if (hawb.Finalized == "X") {
              return {
                hawb_number: hawb.Hawb,
                ship_date: hawb.HAWBDate,
                due_date: hawb.DueDate,
                pcs_wt: hawb.Pieces + "/" + hawb.ChargeableWeight,
                service: hawb.service.Description,
                shipper: hawb.ShipperName,
                shipper_ref: hawb.ShipReference,
                consignee: hawb.ConsigneeName,
                consignee_ref: hawb.ConsReference,
                status: hawb.status,
                miles: hawb.miles,
                co2: hawb.co2,
                co2_savings: hawb.co2_savings,
                customer_pos: hawb.PurchaseOrders.map(a => a.PurchaseOrder),
                origin_city: hawb.ShipperCity,
                origin_state: hawb.ShipperState,
                origin_zip: hawb.ShipperZip,
                destination_city: hawb.ConsigneeCity,
                destination_state: hawb.ConsigneeState,
                destination_zip: hawb.ConsigneeZip,
                delay_reason:
                  hawb.DelayReasonDescription !== null
                    ? hawb.DelayReasonDescription
                    : "No Delay",
                PODName: hawb.PODName,
                PODDate: hawb.PODDate,
                PODTime: hawb.PODTime,
                totalRevenue: hawb.TotalRevenue,
                lastLocation:
                  hawb.LastLocation !== null ? hawb.LastLocation : "",
              };
            } else {
              return {
                hawb_number: hawb.Hawb,
                ship_date: hawb.HAWBDate,
                due_date: hawb.DueDate,
                pcs_wt: hawb.Pieces + "/" + hawb.ChargeableWeight,
                service: hawb.service.Description,
                shipper: hawb.ShipperName,
                shipper_ref: hawb.ShipReference,
                consignee: hawb.ConsigneeName,
                consignee_ref: hawb.ConsReference,
                status: hawb.status,
                customer_pos: hawb.PurchaseOrders.map(a => a.PurchaseOrder),
                origin_city: hawb.ShipperCity,
                origin_state: hawb.ShipperState,
                origin_zip: hawb.ShipperZip,
                destination_city: hawb.ConsigneeCity,
                destination_state: hawb.ConsigneeState,
                destination_zip: hawb.ConsigneeZip,
                delay_reason:
                  hawb.DelayReasonDescription !== null
                    ? hawb.DelayReasonDescription
                    : "No Delay",
                PODName: hawb.PODName,
                PODDate: hawb.PODDate,
                PODTime: hawb.PODTime,
                totalRevenue: hawb.TotalRevenue,
                miles: hawb.miles,
                co2: hawb.co2,
                co2_savings: hawb.co2_savings,
                lastLocation:
                  hawb.LastLocation !== null ? hawb.LastLocation : "",
              };
            }
          });

          //console.log(response);
          this.allowedNumbers = response.data.allowed_numbers;
          //console.log(this.allowedNumbers);
          this.loading = !this.loading;

          this.initiateDownloadButtons();
          $(".ui.fluid.dropdown").dropdown({});
          
          this.totalRevenue = response.data.user.total_revenue;

          
          
        })
        .catch((errors) => {
          this.errors = errors.data;
          console.error(errors);
        });
    },
    initiateDownloadButtons: function () {
      // add id to base table to allow download
      // TODO: Change to on table load
      setTimeout(() => {
        $(".VueTables__table").attr("id", "tracking_report_table");

        // add semantic ui class to vue table limit
        $(".VueTables__limit-field > select").addClass("ui fluid dropdown");

        // set place holder of search
        $(".VueTables__search__input").attr("placeholder", "Search All...");

        $(".ui.fluid.dropdown").dropdown();
        

        this.downLoading = !this.downLoading;
      }, 1000);
    },
    // downloadExcel: function() {
    //   let table = document.getElementById("tracking_report_table");

    //   let excel = new TableExport(table, {
    //     formats: ["xlsx"], // (String[]), filetype(s) for the export, (default: ['xlsx', 'csv', 'txt'])
    //     filename: "tracking_report", // (id, String), filename for the downloaded file, (default: 'id')
    //     bootstrap: false, // (Boolean), style buttons using bootstrap, (default: true)
    //     exportButtons: false, // (Boolean), automatically generate the built-in export buttons for each of the specified formats (default: true)
    //     trimWhitespace: true, // (Boolean), remove all leading/trailing newlines, spaces, and tabs from cell text in the exported file(s) (default: false)
    //     sheetname: "tracking_report" // (id, String), sheet name for the exported spreadsheet, (default: 'id')
    //   });

    //   let exportData = excel.getExportData()["tracking_report_table"]["xlsx"];

    //   excel.export2file(
    //     exportData.data,
    //     exportData.mimeType,
    //     exportData.filename,
    //     exportData.fileExtension
    //   );
    // },
    // downloadPdf: function() {
    //   const doc = new jsPDF({ orientation: "l" });

    //   doc.autoTable({
    //     html: "#tracking_report_table",
    //     styles: { minCellWidth: 19 }
    //   });

    //   doc.save("tracking_report.pdf");
    // },
    onBtnExportDataAsExcel() {
      var params = {
        allColumns: true,
      };
      this.gridApi.exportDataAsExcel(params);
    },
    showHawbsFromLast: function (e) {
      this.timeFrame = e.target.value;
      this.loading = !this.loading;
      this.downLoading = !this.downLoading;

      let params = {
        time_frame: this.timeFrame,
        customer_number: this.currentAccount,
      };

      this.$http
        .get("/track/report", { params })
        .then((response) => {
          this.hawbs = Object.keys(response.data.hawbs).map(function (key) {
            let hawb = response.data.hawbs[key];

            if (hawb.Finalized == "X") {
              return {
                hawb_number: hawb.Hawb,
                ship_date: hawb.HAWBDate,
                due_date: hawb.DueDate,
                pcs_wt: hawb.Pieces + "/" + hawb.ChargeableWeight,
                service: hawb.service.Description,
                shipper: hawb.ShipperName,
                shipper_ref: hawb.ShipReference,
                consignee: hawb.ConsigneeName,
                consignee_ref: hawb.ConsReference,
                status: hawb.status,
                miles: hawb.miles,
                co2: hawb.co2,
                co2_savings: hawb.co2_savings,
                customer_pos: hawb.PurchaseOrders.map(a => a.PurchaseOrder),
                origin_city: hawb.ShipperCity,
                origin_state: hawb.ShipperState,
                origin_zip: hawb.ShipperZip,
                destination_city: hawb.ConsigneeCity,
                destination_state: hawb.ConsigneeState,
                destination_zip: hawb.ConsigneeZip,
                delay_reason:
                  hawb.DelayReasonDescription !== null
                    ? hawb.DelayReasonDescription
                    : "No Delay",
                PODName: hawb.PODName,
                PODDate: hawb.PODDate,
                PODTime: hawb.PODTime,
                totalRevenue: hawb.TotalRevenue,
                lastLocation:
                  hawb.LastLocation !== null ? hawb.LastLocation : "",
              };
            } else {
              return {
                hawb_number: hawb.Hawb,
                ship_date: hawb.HAWBDate,
                due_date: hawb.DueDate,
                pcs_wt: hawb.Pieces + "/" + hawb.ChargeableWeight,
                service:
                  hawb.service.Description !== null
                    ? hawb.service.Description
                    : "",
                shipper: hawb.ShipperName,
                shipper_ref: hawb.ShipReference,
                consignee: hawb.ConsigneeName,
                consignee_ref: hawb.ConsReference,
                status: hawb.status,
                miles: hawb.miles,
                co2: hawb.co2,
                co2_savings: hawb.co2_savings,
                customer_pos: hawb.PurchaseOrders.map(a => a.PurchaseOrder),
                origin_city: hawb.ShipperCity,
                origin_state: hawb.ShipperState,
                origin_zip: hawb.ShipperZip,
                destination_city: hawb.ConsigneeCity,
                destination_state: hawb.ConsigneeState,
                destination_zip: hawb.ConsigneeZip,
                delay_reason:
                  hawb.DelayReasonDescription !== null
                    ? hawb.DelayReasonDescription
                    : "No Delay",
                PODName: hawb.PODName,
                PODDate: hawb.PODDate,
                PODTime: hawb.PODTime,
                totalRevenue: hawb.TotalRevenue,
                lastLocation:
                  hawb.LastLocation !== null ? hawb.LastLocation : "",
              };
            }
          });

          this.loading = !this.loading;

          this.initiateDownloadButtons();
          $(".ui.fluid.dropdown").dropdown({});
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    showHawbsFromAccount: function (e) {
      this.currentAccount = e.target.value;
      this.loading = !this.loading;
      this.downLoading = !this.downLoading;
      //console.log(e.target.value);
      let params = {
        time_frame: this.timeFrame,
        customer_number: this.currentAccount,
      };

      this.$http
        .get("/track/report", { params })
        .then((response) => {
          //console.log(response);
          this.hawbs = Object.keys(response.data.hawbs).map(function (key) {
            let hawb = response.data.hawbs[key];

            if (hawb.Finalized == "X") {
              return {
                hawb_number: hawb.Hawb,
                ship_date: hawb.HAWBDate,
                due_date: hawb.DueDate,
                pcs_wt: hawb.Pieces + "/" + hawb.ChargeableWeight,
                service: hawb.service.Description,
                shipper: hawb.ShipperName,
                shipper_ref: hawb.ShipReference,
                consignee: hawb.ConsigneeName,
                consignee_ref: hawb.ConsReference,
                status: hawb.status,
                miles: hawb.miles,
                co2: hawb.co2,
                co2_savings: hawb.co2_savings,
                customer_pos: hawb.PurchaseOrders.map(a => a.PurchaseOrder),
                origin_city: hawb.ShipperCity,
                origin_state: hawb.ShipperState,
                origin_zip: hawb.ShipperZip,
                destination_city: hawb.ConsigneeCity,
                destination_state: hawb.ConsigneeState,
                destination_zip: hawb.ConsigneeZip,
                delay_reason:
                  hawb.DelayReason !== null ? hawb.DelayReason : "No Delay",
                PODName: hawb.PODName,
                PODDate: hawb.PODDate,
                PODTime: hawb.PODTime,
                totalRevenue: hawb.TotalRevenue,
                lastLocation:
                  hawb.LastLocation !== null ? hawb.LastLocation : "",
              };
            } else {
              return {
                hawb_number: hawb.Hawb,
                ship_date: hawb.HAWBDate,
                due_date: hawb.DueDate,
                pcs_wt: hawb.Pieces + "/" + hawb.ChargeableWeight,
                service: hawb.service.Description,
                shipper: hawb.ShipperName,
                shipper_ref: hawb.ShipReference,
                consignee: hawb.ConsigneeName,
                consignee_ref: hawb.ConsReference,
                status: hawb.status,
                miles: hawb.miles,
                co2: hawb.co2,
                co2_savings: hawb.co2_savings,
                customer_pos: hawb.PurchaseOrders.map(a => a.PurchaseOrder),
                origin_city: hawb.ShipperCity,
                origin_state: hawb.ShipperState,
                origin_zip: hawb.ShipperZip,
                destination_city: hawb.ConsigneeCity,
                destination_state: hawb.ConsigneeState,
                destination_zip: hawb.ConsigneeZip,
                delay_reason:
                  hawb.DelayReason !== null ? hawb.DelayReason : "No Delay",
                PODName: hawb.PODName,
                PODDate: hawb.PODDate,
                PODTime: hawb.PODTime,
                totalRevenue: hawb.TotalRevenue,
                lastLocation:
                  hawb.LastLocation !== null ? hawb.LastLocation : "",
              };
            }
          });

          this.loading = !this.loading;

          this.initiateDownloadButtons();
          $(".ui.fluid.dropdown").dropdown({});
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
  computed: {},
};

window.dateComparator = function dateComparator(date1, date2) {
  var date1Number = monthToComparableNumber(date1);
  var date2Number = monthToComparableNumber(date2);
  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }
  return date1Number - date2Number;
};

window.monthToComparableNumber = function monthToComparableNumber(date) {
  var result = new Date(date);
  return result;
};

window.setPrinterFriendly = function setPrinterFriendly(api) {
  var eGridDiv = document.querySelector("#tracking_report_table");
  eGridDiv.style.height = "";
  api.setDomLayout("print");
};

window.setNormal = function setNormal(api) {
  var eGridDiv = document.querySelector("#tracking_report_table");
  //eGridDiv.style.height = "75vh";
  //eGridDiv.style.width = "100%";
  //api.setDomLayout(null);
  api.setDomLayout("autoHeight");
};
</script>

<style lang="scss">
#tracking_report .ui.fluid.dropdown.accounts {
  width: 140px;
  height: 40px;
  border-radius: 20px !important;
  border: 2px solid #c1d1d9;
  margin-left: 60px;
  margin-right: 10px;
  margin-top: -4px;
}

.ag-theme-balham .ag-row-odd {
  background-color: ghostwhite;
}

.ag-body-viewport {
  min-height: 50vh;
}

.ag-theme-balham .ag-row-odd:hover {
  background-color: #f6f6f6;
}

span.ag-header-cell-text {
  color: #0d4b6a;
  font: Bold 14px/17px Lato;
}

.ag-theme-balham .ag-header {
  background-color: #e7edf0;
}

// .ag-row .ag-cell {
//   display: flex;
//   align-items: center;
// }

.ag-cell {
  font-size: 14px;
  font-family: Lato;
}

#tracking_report {
  margin: $layoutmargin;

  .ui.grid {
    padding-bottom: 10px;
  }

  .ui.input {
    margin-top: -12px;
    padding-left: 40px;
  }

  .ui.input > input {
    border-width: 2px;
    border-radius: 25px;
    color: #0d486a;
  }

  input#filter-text-box::placeholder {
    color: #0d4b6a;
    opacity: 1; /* Firefox */
  }

  input#filter-text-box:-ms-input-placeholder {
    color: #0d4b6a;
  }

  input#filter-text-box::-ms-input-placeholder {
    color: #0d4b6a;
  }

  .fa-search:before {
    content: "\f002";
    font-weight: 600;
    position: relative;
    left: 30px;
    z-index: 99;
    top: 2px;
    color: #0d4b6a;
    opacity: 0.7;
  }

  .ui.right.floated.last_loads.menu {
    border: none;
    background-color: inherit;
    box-shadow: none;
    margin-right: 0px;

    p {
      padding-top: 3px;
    }
  }

  .ui.left.floated.last_loads.menu {
    border: none;
    background-color: inherit;
    box-shadow: none;
    margin-right: -40px;

    p {
      padding-top: 3px;
    }
  }

  .ui.fluid.dropdown {
    width: 60px;
    height: 40px;
    border-radius: 20px !important;
    border: $darkblueborder;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -4px;
  }

  .ui.fluid.last.dropdown {
    width: 115px !important;
    height: 40px;
    border-radius: 20px !important;
    border: 2px solid $algdarkblue25opac;
    margin-left: 10px;
  }

  .ui.button.pdf {
    margin-right: 10px;
  }

  .ui.compact.menu {
    background-color: none;
    border-radius: none;
    border: none;

    .menu {
      .item:hover {
        color: $alglightblue !important;
      }
    }

    .dropdown {
      padding-top: 5px;

      i {
        padding-right: 10px;
      }
    }
  }

  .VueTables__search__input {
    position: absolute;
    left: 250px;
    width: 300px;
    height: 40px;
    top: -4px;
    border: $darkblueborder;
    padding-left: 20px;
  }

  .fa.fa-bars {
    z-index: 1000;
  }

  .tracking_report_table {
    border: none;
    box-shadow: none;

    .tracking_link {
      color: $algdarkblue !important;
      text-decoration: underline;
    }

    .fa,
    .fa-sort,
    .fa-sort-asc,
    .fa-sort-desc {
      float: unset !important;
      padding-left: 10px;
    }

    thead {
      tr {
        th {
          color: $algdarkblue;
          background-color: $algdarkbluetenopac;
          border: none;
        }

        th:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        th:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    tbody {
      tr {
        td:first-child {
          border-top-left-radius: 8px !important;
          border-bottom-left-radius: 8px !important;
        }
        td:last-child {
          border-top-right-radius: 8px !important;
          border-bottom-right-radius: 8px !important;
        }

        td {
          border: none;
        }
      }
      tr:nth-child(odd) {
        background-color: #fff;
      }

      tr:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}
</style>
