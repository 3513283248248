<template>
  <div class="ui card">
    <div class="header">
      <h2>Total Spend by Service</h2>
      <div v-cloak v-if="!loading && dateRange.startDate && dateRange.endDate">
        {{ dateRange.startDate }} - {{ dateRange.endDate }}
      </div>
    </div>
    <div class="content" style="min-height: 333px">
      <div style="min-width: 120px" class="ui right floated last_loads menu">
        <select
          class="ui fluid last dropdown"
          name="last_loads"
          v-model="defaults.date_range"
          @change="$emit('changedDate', $event)"
        >
          <option value="1_week">1 week</option>
          <option value="1_month">1 month</option>
          <option value="3_month">3 months</option>
          <option value="6_month">6 months</option>
          <option value="YTD">YTD</option>
        </select>
      </div>
      <!-- Vue Element Daterange Picker Here -->
      <!-- <date-range-picker
                          ref="picker"
                          opens="right"
                          :locale-data="{ firstDay: 1, format: 'mm/dd/yyyy' }"
                          :showWeekNumbers='false'
                          :showDropdowns="true"
                          :autoApply="false"
                          v-model="dateRange"
                          :linkedCalendars="true"
                  >
                      <template v-slot:input="picker" style="min-width: 350px;">
                          {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </template>
                  </date-range-picker> -->
      <div class="ui active centered inline large loader" v-if="loading"></div>

      <Bar class="chart" :labels="labels" :data="data" v-else />
    </div>
  </div>
</template>

<script>
import Bar from "@/components/charts/Bar.vue";
export default {
  components: {
    Bar,
  },
  data() {
    return {
      defaults: {
        date_range: this.date_range_bounds,
      },
    };
  },
  props: ["labels", "data", "loading", "dateRange", "date_range_bounds"],
};
</script>