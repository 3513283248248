<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,

  props: {
    title: String,
    labels: Array,
    datasets: Array,
    totals: Object,
  },

  data: function () {
    const self = this;
    return {
      htmlLegend: null,
      chartdata: {
        labels: this.labels,
        datasets: this.datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: this.title || "",
        },
        //cutoutPercentage: 50,
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              return (
                " " +
                data["labels"][tooltipItem["index"]] +
                ": " +
                data["datasets"][0]["data"][tooltipItem["index"]] +
                "%"
              );
            },
          },
        },

        legendCallback: function (chart) {
          var text = [];
          text.push('<ul class="' + chart.id + '-legend">');

          for (var i = 0; i < chart.data.datasets.length; i++) {
            for (var d = 0; d < chart.data.datasets[i].data.length; d++) {
              text.push(
                '<li><span style="background-color:' +
                  chart.data.datasets[i].backgroundColor[d] +
                  '"></span>'
              );
              if (chart.data.datasets[i].label) {
                text.push(chart.data.datasets[i].label[d]);
                if (self.totals) {
                  let totals = Object.values(self.totals);
                  text.push(` (${totals[d]})`);
                }
              }
              text.push("</li>");
            }
          }

          text.push("</ul>");
          return text.join("");
        },
      },
    };
  },

  mounted() {
    this.renderChart(this.chartdata, this.options);
    this.htmlLegend = this.generateLegend();
    this.$emit("legendReady", this.htmlLegend);
  },
  created() {},
};
</script>

<style lang="scss">
</style>
