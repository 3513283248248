<template>
    <div class="ui hawbs modal">
        <div class="header">Hawbs <i class="close icon" @click="close()"></i></div>
        <div class="content">
            <div class="ui active centered inline large loader" v-if="loading"></div>
    
            <v-client-table class="hawbs_table" :columns="columns" :data="hawbs" :options="options" v-else>
                <div class="check_date" slot="CheckDate" slot-scope="{row}">{{formatYear(row.CheckDate)}}</div>
                <div class="amount" slot="Amount" slot-scope="{row}">${{parseFloat(row.Amount).toLocaleString('en-US', {minimumFractionDigits: 2})}}</div>
                <div class="date_invoiced" slot="DateInvoiced" slot-scope="{row}">{{formatYear(row.DateInvoiced)}}</div>
                <div class="date_paid" slot="DatePaid" slot-scope="{row}">{{formatYear(row.DatePaid)}}</div>
            </v-client-table>
        </div>
    </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
export default {
    props: {
        hawbs: Array
    },
    data () {
        return {
            loading: true,
            columns: [
                'CheckNbr',
                'Number',
                'Hawb',
                'CheckDate',
                'Amount',
                'DateInvoiced',
                'DatePaid'
            ],
            options: {
                headings: {
                    CheckNbr: 'Check No.',
                    Number: 'Cust No.',
                    Hawb: 'Hawb',
                    CheckDate: 'Check Date',
                    Amount: 'Amount',
                    DateInvoiced: 'Date Invoiced',
                    DatePaid: 'Date Paid'
                },
                sortable: true,
                filterable: false,
                perPage: 10,
                perPageValues: [10, 25, 50],
                texts: {
                    filter: '',
                    filterBy: 'Search by {column}',
                    count: '',
                    limit: ''
                },
                pagination: { chunk: 10, dropdown: false },
            },
            errors: []
        }
    },
    created() {
    },
    mounted() {
        this.loading = !this.loading;
    },
    methods: {
        close: function () {
            $('.ui.hawbs.modal').modal('hide');
        },
        formatYear(year) {
            let splitYear = year.split('-');
            year = splitYear[1] + '/' + splitYear[2] + '/' + splitYear[0];
            return year;
        }
    },
    computed: {
    }
};
</script>


<style lang="scss">
.ui.hawbs.modal {
    margin: 12rem auto !important;
    
    .header {
        color: $algdarkblue !important;
        border-bottom: none !important;

        .close.icon {
            color: $algdarkblue !important;
        }
    }

    .VueTables__limit {
        display: none;
    }

    .hawbs_table {
        border: none;
        box-shadow: none;

        th, td {
            cursor: unset !important;
        }

        thead {
            tr {
                th {
                    color: $algdarkblue;
                    background-color: $algdarkbluetenopac;
                    border: none;
                }

                th:first-child {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }

                th:last-child {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
        }

        tbody {
            tr {
                background-color: #fff;

                td {
                    border: none;
                }
            }
        }
    }

    .ui.loader:after {
        border-color: $algred transparent transparent !important;
    }

    // .ui.loader:before {
    //     border-color: $alggrey transparent transparent !important;
    // }
}
</style>