<template>
  <div class="ui bill_to modal">
    <div class="header">
      Search for a BillTo <i class="close icon" @click="close()"></i>
    </div>
    <div class="content">
      <div class="col-md-12">
        <div class="form-group form-inline float-left VueTables__search">
          <div class="VueTables__search-field">
            <label for="VueTables__search_RzUbx" class=""></label>
            <input
              type="text"
              id="billto-filter-text-box"
              class="VueTables__search__input form-control "
              placeholder="Search All..."
              v-on:keyup="onFilterTextBoxChanged()"
            />
          </div>
        </div>
      </div>
      <div>
        <template>
          <ag-grid-vue
            style="width: 100%; height: 80vh;clear:both;"
            class="ag-theme-balham"
            id="bill_to_table"
            :columnDefs="columnDefs"
            :rowData="billTos"
            :gridOptions="gridOptions"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :paginationNumberFormatter="paginationNumberFormatter"
            :sideBar="sideBar"
            @grid-ready="onGridReady"
            @first-data-rendered="onFirstDataRendered"
            @cell-clicked="updateBillTo"
            :modules="modules"
          >
          </ag-grid-vue>
        </template>
      </div>
    </div>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>


import { AgGridVue } from "ag-grid-vue";
import { LicenseManager } from "@ag-grid-enterprise/core";
LicenseManager.setLicenseKey(
  "CompanyName=Build This, LLC,LicensedApplication=ALG Interface,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-026375,ExpiryDate=19_April_2024_[v2]_MTcxMzQ4MTIwMDAwMA==ee7c1dd1960a418478b92ab9ed66c256"
);
import { AllModules } from "@ag-grid-enterprise/all-modules";

export default {
  props: {
    shipment: Object
  },
  components: {
    AgGridVue,
    AllModules
  },
  data() {
    return {
      billTos: null,
      columnDefs: null,
      currentAccount: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      autoGroupColumnDef: null,
      defaultColDef: null,
      rowSelection: null,
      rowGroupPanelShow: null,
      pivotPanelShow: null,
      paginationPageSize: 10,
      paginationNumberFormatter: null,
      modules: AllModules,
      rowData: null,
      sideBar: null,
      possibleHeadings: null,
      columnState: null,
      allowedNumbers: null,
      billToTimeout: null
    };
  },
  beforeMount() {
    this.gridOptions = {};
    this.gridApi = {};
    this.columnDefs = [
      {
        headerName: "Number",
        field: "number",
        sortable: true,
        resizable: true,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Name",
        field: "name",
        sortable: true,
        resizable: true,
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Address",
        field: "fullAddress",
        sortable: true,
        resizable: true,
        filter: "agTextColumnFilter"
      }
    ];
  },
  created() {},
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.gridApi.setDomLayout("autoHeight");
    this.possibleHeadings = this.gridColumnApi.getPrimaryColumns();
    this.columnState = this.gridColumnApi.getColumnState();
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.gridApi.sizeColumnsToFit();
    },
    close: function() {
      $(".ui.bill_to.modal").modal("hide");
    },
    onFilterTextBoxChanged() {
      clearTimeout(this.billToTimeout);

      // Make a new timeout set to go off in 800ms
      this.billToTimeout = setTimeout(() => {
        let params = {
          search: document.getElementById("billto-filter-text-box").value
        };
        this.$http
          .get("/shipment/billtosearch", { params })
          .then(response => {
            this.billTos = Object.keys(response.data.bill_tos).map(function(
              index
            ) {
              let billto = response.data.bill_tos[index];

              return {
                number: billto.Number,
                name: billto.Name,
                address: billto.Address1,
                address2: billto.Address2,
                countryName: billto.CountryName,
                city: billto.City,
                state: billto.State,
                zip: billto.Zip,
                fullAddress:
                  billto.Address1 +
                  " " +
                  billto.Address2 +
                  " " +
                  billto.City +
                  " " +
                  billto.State +
                  " " +
                  billto.Zip,
                contact: billto.Contact,
                phone: billto.PhoneNbr
              };
            });

            this.loading = !this.loading;
          })
          .catch(error => {
            this.errors = error;
          });
      }, 800);
      // this.gridOptions.api.setQuickFilter(
      //   document.getElementById("filter-text-box").value
      // );
    },
    onFirstDataRendered() {
      this.gridApi.sizeColumnsToFit();
    },
    onPageSizeChanged(newPageSize) {
      var value = document.getElementById("page-size").value;
      this.gridApi.paginationSetPageSize(Number(value));
    },
    onGridReady(params) {
      this.gridApi.setRowData([]);
    },
    updateBillTo: function(billto) {
      this.shipment.billToNumberDefault = billto.data.number;
      this.shipment.billToNameDefault = billto.data.name;
      this.shipment.billToAddressDefault = billto.data.address;
      this.shipment.billToAddress2Default = billto.data.address2;
      this.shipment.billToCityDefault = billto.data.city;
      this.shipment.billToStateDefault = billto.data.state;
      this.shipment.billToZipDefault = billto.data.zip;
      this.shipment.billToContactDefault = billto.data.contact;
      this.shipment.billToPhoneDefault = billto.data.phone;
      this.shipment.billToCountry = billto.data.countryName;
      this.shipment.billToCountryDefault = billto.data.countryName;
      $(".ui.bill_to.modal").modal("hide");
    }
  },
  computed: {}
};
</script>

<style lang="scss">
.ui.bill_to.modal {
  min-height: 650px;
  margin: 7rem auto !important;
  overflow-y: auto;

  .header {
    color: $algdarkblue !important;
    border-bottom: none !important;

    .close.icon {
      color: $algdarkblue !important;
    }
  }

  select {
    width: 75px;
    height: 40px;
    border-radius: 20px !important;
  }

  .VueTables__search {
    float: right !important;
  }

  .VueTables__search__input {
    width: 350px;
    height: 40px;
    border: $darkblueborder;
  }

  .ui.fluid.limit.dropdown {
    width: 60px;
    height: 40px;
    border-radius: 20px !important;
    border: $darkblueborder;
    margin-left: 10px;
  }

  .bill_to_table {
    border: none;
    box-shadow: none;

    .fa,
    .fa-sort,
    .fa-sort-asc,
    .fa-sort-desc {
      float: unset !important;
      padding-left: 10px;
    }

    .number {
      color: $algdarkblue;
      text-decoration: underline;
    }

    thead {
      tr {
        th {
          color: $algdarkblue;
          background-color: $algdarkbluetenopac;
          border: none;
        }

        th:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        th:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    tbody {
      tr {
        td:first-child {
          border-top-left-radius: 8px !important;
          border-bottom-left-radius: 8px !important;
        }
        td:last-child {
          border-top-right-radius: 8px !important;
          border-bottom-right-radius: 8px !important;
        }

        td {
          border: none;
        }
      }
      tr:nth-child(odd) {
        background-color: #fff;
      }

      tr:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}
</style>
