var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui location_modal modal"},[_c('div',{staticClass:"header"},[_vm._v(" Location History "),_c('i',{staticClass:"close icon",on:{"click":function($event){return _vm.close()}}})]),_c('div',{staticClass:"body"},[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"500px"},attrs:{"center":_vm.center,"zoom":4,"map-type-id":"roadmap","options":{
        disableDefaultUi: false,
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
      }},on:{"tilesloaded":_vm.fitbounds}},[_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index + 100,attrs:{"position":m.position,"clickable":false,"draggable":false,"icon":_vm.dotIcon},on:{"click":function($event){_vm.center = m.position}}})}),_c('gmap-info-window',{attrs:{"opened":_vm.window_open,"position":_vm.infowindow,"options":{
            pixelOffset: {
              width: 0,
              height: -35
            }
          }},on:{"closeclick":function($event){_vm.window_open=false}}},[_vm._v(" "+_vm._s(_vm.infowindow_text)+" ")]),_vm._l((_vm.finalMarkers),function(m,index){return _c('GmapMarker',{key:index + 200,attrs:{"position":m.position,"clickable":true,"draggable":false,"icon":_vm.deliveredIcon,"zIndex":900},on:{"click":function($event){return _vm.openWindow(m.position,m.address)}}})}),_vm._l((_vm.initialMarkers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true,"draggable":false,"icon":_vm.originalDot},on:{"click":function($event){return _vm.openWindow(m.position,m.address)}}})}),_vm._l((_vm.paths),function(path){return _c('span',{key:path},[_c('gmap-polyline',{attrs:{"path":path,"options":{ strokeColor: '#2b62ad' }}})],1)})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }